
(function () {
    'use strict';

    class CalendarPopoverContentComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $element, $document, AnalyticsService, $timeout, $translate, moment, Enums, AppStates, CssVariablesService, CalendarService, PopupMessageService, FlowService, TimezoneService, ReactModalService, FeaturesService, WorkspacesManager) {
            super($scope, $injector);
            this.__objectType = 'CalendarPopoverContentComponent';

            this.AnalyticsService = AnalyticsService;
            this.$document = $document;
            this.moment = moment;
            this.$scope = $scope;
            this.$translate = $translate;
            this.Enums = Enums;
            this.$timeout = $timeout;
            this.AppStates = AppStates;
            this.CssVariablesService = CssVariablesService;
            this.CalendarService = CalendarService;
            this.CssVariablesService.init('calendar');
            this.PopupMessageService = PopupMessageService;
            this.FlowService = FlowService;
            this.TimezoneService = TimezoneService;
            this.ReactModalService = ReactModalService;
            this.FeaturesService = FeaturesService;
            this.WorkspacesManager = WorkspacesManager;

            this.popover = {};
            this.popoverTypes = {
                day: 'DAY_POPOVER',
                show_more: 'SHOW_MORE_POPOVER',
                google: 'GOOGLE_POPOVER',
                nylas: 'NYLAS_POPOVER',
                meeting: 'MEETING_POPOVER',
                project: 'PROJECT_POPOVER',
                payment: 'PAYMENT_POPOVER',
                team_project: 'TEAM_PROJECT',
                team_meeting: 'TEAM_MEETING'

            };
            this.actionTypes = {
                create_meeting: 'CREATE_MEETING',
                create_project: 'CREATE_PROJECT',
                edit_hb_meeting: 'EDIT_HB_MEETING',
                delete_hb_meeting: 'DELETE_HB_MEETING',
                go_to_project: 'GO_TO_PROJECT',
                view_payment: 'VIEW_PAYMENT'
            };
        }

        $onChanges() {
            this.popover = this._initPopover(this.popoverType);
        }

        onButtonXClose($event) {
            this.onClose({$event: $event});
        }

        onAction(type) {
            this.handleCallback({payload: { type: type, data: this.data }});
        }

        /**
         *  POPOVERS
         */
        _utilGetFormattedEventStartTime(start) {

            if (!start) {
                return '';
            }

            return this.moment(start).format("h:mm A");
        }

        _utilGetFormattedEventTime(allDay, start, end) {
            if (allDay) {
                return "All day";
            }

            if (!start && !end) {
                return '';
            }

            const { currentTimezone } = this.TimezoneService;
            const timezone = currentTimezone ? ` (${currentTimezone})` : '';

            if (!end) {
               return `${this.moment(start).format("h:mm A")}${timezone}`;
            }


            return `${this.moment(start).format("h:mm A") + " - " + this.moment(end).format("h:mm A")}${timezone}`;
        }

        _utilGetFormattedEventDate(start, end, allDay, source) {
            if (!start) {
                return '';
            }

            if (source && ['google', 'nylas'].includes(source) && allDay && end && end !== 'Invalid date') {
                end.subtract(1, 'day');
            }

            const eventStart = this.moment(start).format("MMM DD, YYYY");
            const eventEnd = this.moment(end).format("MMM DD, YYYY");

            return (eventStart === eventEnd || eventEnd === 'Invalid date') ? eventStart : (eventStart + " - " + eventEnd);
        }

        _utilFormatDateTitle(activeView, dateTitle, small) {
            const dayFormat = small ? this.moment(dateTitle).format('ddd D') : this.moment(dateTitle).format('MMM DD, YYYY');

            return activeView === 'month' ? dayFormat : this.moment(dateTitle).format('h:mm A');
        }

        _utilGetFormattedEventDateAndTime(start, end){
            if (!start) {
                return '';
            }

            const startDate = this.moment(start).format("MMM DD, YYYY, h:mm A");
            const endDate = this.moment(end).format("MMM DD, YYYY, h:mm A");

            return startDate + " - " + endDate;
        }

        /** POPOVER: Init type */
        _initPopover(popoverType){
            switch (popoverType) {
                case this.Enums.calendarPopoverTypes.google_event:
                    return this._getPopoverGoogleEvent(this.data);

                case this.Enums.calendarPopoverTypes.nylas_event:
                    return this._getPopoverNylasEvent(this.data);

                case this.Enums.calendarPopoverTypes.hb_meeting:
                    return this._getPopoverCalendarItem(this.data);

                case this.Enums.calendarPopoverTypes.lead_project:
                    return this._getPopoverLeadProject(this.data);

                case this.Enums.calendarPopoverTypes.booked_project:
                    return this._getPopoverBookedProject(this.data);

                case this.Enums.calendarPopoverTypes.payment:
                    return this._getPopoverPayments(this.data);

                case this.Enums.calendarPopoverTypes.day_click:
                    return this._getPopoverDayClick(this.activeView, this.data);

                case this.Enums.calendarPopoverTypes.event_limit:
                    return this._getPopoverEventLimit(this.activeView, this.data);

                case this.Enums.calendarPopoverTypes.team_project:
                    return this._getPopoverTeamProject(this.data);

                case this.Enums.calendarPopoverTypes.team_meeting:
                    return this._getPopoverTeamMeeting(this.data);
            }
        }

        /** POPOVER: Day click */
        _getPopoverDayClick(view, eventDate) {
            return {
                title: this._utilFormatDateTitle(view, eventDate, false),
                popover_type: this.popoverTypes.day,
                type: this.Enums.calendarPopoverTypes.day_click,
                calendar_view: view,
                data: [
                    {
                        label: "REPORTS.CALENDAR._CREATE_MEETING_" ,
                        analytics: "popover schedule meeting modal",
                        type: this.actionTypes.create_meeting,
                        icon: "icon-hb-nx-calendar-16"
                    },
                    {
                        label: "REPORTS.CALENDAR._CREATE_PROJECT_" ,
                        analytics: "open create project modal",
                        type: this.actionTypes.create_project,
                        icon: "icon-hb-nx-briefcase-16"
                    }
                ]
            };
        }

        /** POPOVER: Google imported event */
        _getPopoverGoogleEvent(calendarEvent){
            return {
                title: calendarEvent.title,
                popover_type: this.popoverTypes.google,
                type : this.Enums.calendarPopoverTypes.google_event,
                calendar_color : calendarEvent.color,
                title_color_label: true,
                event_url : calendarEvent.calendarLink,
                event_url_label : "View on Google",
                event_time: this._utilGetFormattedEventTime(calendarEvent._allDay, calendarEvent._start, calendarEvent._end),
                event_date: this._utilGetFormattedEventDate(calendarEvent._start, calendarEvent._end, calendarEvent._allDay, 'google'),
                availability_type: calendarEvent.availabilityType
            };
        }

        _getPopoverNylasEvent(calendarEvent){
            return {
                title: calendarEvent.title,
                popover_type: this.popoverTypes.nylas,
                type : this.Enums.calendarPopoverTypes.nylas_event,
                calendar_color : calendarEvent.color,
                title_color_label: true,
                event_url : calendarEvent.calendarLink,
                event_url_label : "View in Calendar",
                event_time: this._utilGetFormattedEventTime(calendarEvent._allDay, calendarEvent._start, calendarEvent._end),
                event_date: this._utilGetFormattedEventDate(calendarEvent._start, calendarEvent._end, calendarEvent._allDay,'nylas'),
                availability_type: calendarEvent.availabilityType
            };
        }

        /** POPOVER: HB meeting */
        _getPopoverCalendarItem(calendarEvent) {
            if (calendarEvent.hbData) {
                const { title, location, event_name, calendly_id, event_id, workspace_id, date_start,
                    date_end, all_day, date_time_start, date_time_end, client, description, video_meeting_link, phone_call_number, phone_call_caller, session_description,
                    flow_id, flow_title, company_notes, _id } = calendarEvent.hbData.calendarItem;

                // handle date & time
                const eventStartDate = date_start || calendarEvent.hbData.start;
                const eventEndDate = date_end || calendarEvent.hbData.end;
                let label, secondary_label = '';

                if (!all_day && (eventStartDate !== eventEndDate)) {
                    label = this._utilGetFormattedEventDateAndTime(date_time_start, date_time_end);
                } else {
                    label  = this._utilGetFormattedEventDate(date_time_start, date_time_end);
                    secondary_label = this._utilGetFormattedEventTime(all_day, date_time_start, date_time_end);
                }

                const meeting = {
                    title: title,
                    popover_type: this.popoverTypes.meeting,
                    type : this.Enums.calendarPopoverTypes.hb_meeting,
                    calendar_color: calendly_id ? "#9271f5" : "#fc8300",
                    title_color_label: true,
                    project_title: event_name,
                    data: [{
                        icon: "icon-hb-nx-calendar-16",
                        label: label,
                        secondary_label: secondary_label
                    }],
                    primary_cta_type: this.actionTypes.edit_hb_meeting,
                    primary_cta_label: "FREQUENT_BUTTONS._EDIT_",
                    secondary_cta_type: this.actionTypes.delete_hb_meeting,
                    secondary_cta_label: "FREQUENT_BUTTONS._DELETE_"
                };

                if (client) {
                    let projectProps;
                    if (!!client.phone_number) {
                         projectProps = {
                            link: `tel:${client.phone_number}`,
                            analytics: 'phone call',
                            type: 'participant',
                            phone_label: client.phone_number
                        };
                    }

                    meeting.data.push({ icon: "icon-hb-nx-user", label: client.full_name, projectProps });
                }

                if (location) {
                    const projectProps = {
                        type : 'location'
                    };
                    meeting.data.push({ icon: "icon-hb-nx-location-pin-16", label: location, projectProps});
                }

                // if connected to project
                if (event_name) {
                    const projectProps = {
                        event_id,
                        workspace_id,
                        type : 'project'
                    };

                    const displayLabel = flow_id ? `Project: ${event_name}` : event_name;

                    meeting.data.push({ icon: "icon-hb-nx-briefcase-16", label: displayLabel, projectProps});
                }

                // if connected to flow - scheduler block
                if (flow_id) {
                    const projectProps = {
                        flow_id,
                        flow_title,
                        type: 'flow',
                        onClick: this.goToFlow.bind(this)
                    };
                    meeting.data.push({icon: "icon-hb-file-sparks-16", label: `File: ${flow_title}`, projectProps});
                }

                if (video_meeting_link) {
                    const projectProps = {
                        link: video_meeting_link,
                        icon: "icon-hb-nx-link-16",
                        analytics: 'join meeting',
                        type: 'link'
                    };
                    meeting.data.push({label: "Join video call", projectProps });
                }

                if (phone_call_number && phone_call_caller !== "me") {
                    const projectProps = {
                        link: `tel:${phone_call_number}`,
                        icon: "icon-hb-nx-mobile-phone",
                        analytics: 'phone call',
                        type: 'link'
                    };
                    meeting.data.push({label: phone_call_number, projectProps });
                }

                if (description) {
                    meeting.data.push({ icon: "icon-hb-nx-text-align-left-16", label: description});
                }

                if (session_description) {
                    meeting.data.push({ icon: "icon-hb-nx-text-align-left-16", label: session_description});
                }

                if (workspace_id) {
                    const hasAdminPermission = this.user.hasCompanyAdminPermissions();
                    const workspace = this.WorkspacesManager.getWorkspace(workspace_id);
                    const memberInWorkspace = workspace.isMemberInWorkspace(this.user._id);

                    if (hasAdminPermission || memberInWorkspace) {
                        if (company_notes.length !== 0) {
                            for (let i = 0; i < company_notes.length; i++) {
                                let title = company_notes[i].title
                                const note_id = company_notes[i]._id
                                if (company_notes[i].title === '' || company_notes[i].title === null) {
                                    title = 'Untitled note'
                                }

                                let projectProps = {
                                    calendar_item_id: _id,
                                    workspace_id,
                                    note_id,
                                    type: 'note',
                                    onClick: this.openNotesModal.bind(this),
                                    onEditorClose: () => {
                                        this.reloadItems()
                                    },
                                    shouldShowDivider: i === 0
                                };
                                meeting.data.push({icon: "icon-hb-nx-text-16", label: title, projectProps});
                            }
                        }

                        let projectProps = {
                            calendar_item_id: _id,
                            workspace_id,
                            type: 'note',
                            onClick: this.openNotesModal.bind(this),
                            onEditorClose: () => {
                                this.reloadItems()
                            },
                            shouldShowDivider: company_notes.length === 0
                        };
                        meeting.data.push({icon: "icon-hb-nx-plus-16", label: 'Add note', projectProps});
                    }
                }

                return meeting;
            }
        }

        /** POPOVER: Lead */
        _getPopoverLeadProject(lead) {
            const endTime = lead.hbData.has_event_time_end ? lead._end : false;

            return {
                title: lead.title,
                popover_type: this.popoverTypes.project,
                type : this.Enums.calendarPopoverTypes.lead_project,
                calendar_color: "#59ccff",
                cssClass: lead.className[0] || 'hb-calendar__color--event_lead',
                title_color_label: true,
                location: lead.hbData.event_location,
                event_time: lead._allDay ? '' : this._utilGetFormattedEventTime(false, lead._start, endTime),
                event_date: this._utilGetFormattedEventDate(lead.start, lead.end),
                availability_type: lead.availabilityType
            };
        }

        /** POPOVER: Team */
        _getPopoverTeamProject(team) {
          return {
              title: team.title,
              popover_type: this.popoverTypes.team_project,
              type : team.otherType,
              calendar_color: team.color,
              title_color_label: true,
              location: team.hbData.event_location,
              event_date: this._utilGetFormattedEventDate(team.start, team.end),
          };
        }

        /** POPOVER: Team */
        _getPopoverTeamMeeting(teamMeeting) {
            let meeting = this._getPopoverCalendarItem(teamMeeting);
            meeting.popover_type = this.popoverTypes.team_meeting;
            meeting.type = teamMeeting.otherType;
            return meeting;
        }

        /** POPOVER: Booked project */
        _getPopoverBookedProject(bookedProject) {
            const endTime = bookedProject.hbData.has_event_time_end ? bookedProject._end : false;

            return {
                title: bookedProject.title,
                popover_type: this.popoverTypes.project,
                type : this.Enums.calendarPopoverTypes.booked_project,
                calendar_color: "#3758c8",
                title_color_label: true,
                location: bookedProject.hbData.event_location,
                event_time: bookedProject._allDay ? '' : this._utilGetFormattedEventTime(false, bookedProject._start, endTime),
                event_date: this._utilGetFormattedEventDate(bookedProject.start, bookedProject.end),
                availability_type: bookedProject.availabilityType
            };
        }

        /** POPOVER: Payments */
        _getPopoverPayments(payment) {
            return {
                title: payment.amount + " - " + payment.project_title,
                popover_type: this.popoverTypes.payment,
                type : this.Enums.calendarPopoverTypes.payment,
                calendar_color: "#4CC676",
                title_color_label: true,
                data: [
                  {
                    icon: "icon-hb-nx-calendar-16",
                    title: (payment.is_milestone ? this.$translate.instant('REPORTS.CALENDAR.POPOVER._MILESTONE_PAYMENT_REQUESTED_ON_') : '') + this.moment(payment.start).format("MMM DD, YYYY")
                  },
                  {
                    icon: "icon-hb-nx-briefcase-16",
                    title: payment.project_title
                  },
                  {
                    icon: "icon-hb-nx-info-circle-16",
                    title: payment.paymentPopupTitle
                  }
                ]
            };
        }

        /** POPOVER: Summary on click more */
        _getPopoverEventLimit(view, eventData) {

            // transform segs
            let items = eventData.segs.map((item) => {

                let _item = { event: item.event, eventType: item.event.eventType, title: item.event.title, color: item.event.color };

                if (item.event.hbData && item.event.hbData.calendarItem && item.event.hbData.calendarItem.date_time_start) {
                    _item['time'] = this._utilGetFormattedEventStartTime(item.event.hbData.calendarItem.date_time_start);
                } else if (item.event.start) {
                    _item['time'] = this._utilGetFormattedEventStartTime(item.event.start);
                }

                return _item;
            });

            return {
                title: this._utilFormatDateTitle(view, eventData.date, true),
                popover_type: this.popoverTypes.show_more,
                type: this.Enums.calendarPopoverTypes.event_limit,
                calendar_view: view,
                data: items
            };
        }

        /** POPOVER: Summary: On list item click: callback to trigger secondary popover */
        showExtraInfo($event, dataItem){
            this.CssVariablesService.setStyle('currTeamMemberBackgroundColor', angular.element($event.target).css("background"));
            this.handleShowMore({$event: $event, dataItem: dataItem});
        }

        showReSync(){
            return this.user && this.user.isAdminLogin() && this.user.getIntegratedCalendarProvider();
        }

        onReSync(){
            this.CalendarService.onReSyncCalendarItem(this.data, this.popoverType)
                .then(function success(res) {
                    if (res && res.data && res.data.result) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.success, res.data.result);
                    } else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.success, "Done");
                    }
                }.bind(this))
                .catch(function fail(e) {
                    if (e.data && e.data.error_message) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, e.data.error_message);
                    }
                    else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                    }
                }.bind(this));
        }

        goToFlow(flowId) {
            this.FlowService.goToFlow(flowId);
        }

        openNotesModal(noteId, calendarItemId, workspaceId, onEditorClose) {
            this.ReactModalService.openGlobalModal('MeetingNoteEditorWrapper', {noteId, calendarItemId, workspaceId, onEditorClose})
        }
    }

    Components.CalendarPopoverContent = {
        bindings: {
            handleCallback: '&',
            reloadItems: '&',
            handleShowMore: '&',
            onClose: '&',
            popoverType: '<',
            data: '<',
            activeView: '<',
            user: '<'
        },
        controller: CalendarPopoverContentComponent,
        name : 'hbCalendarPopoverContent',
        templateUrl: 'angular/app/modules/core/features/calendar/calendar_popover/calendar_popover_content.html',
    };

}());
