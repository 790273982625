/**
 * Created by inonstelman on 2/24/15.
 */

 Directives.HelpDirective = function HelpDirective() {

    // @ngInject
    var HelpDirectiveControllerFunc = function controller($element, $scope, $injector, UsersManager, $timeout, $state, AnalyticsService,
                                                          AppConfigService, IntercomService, UserService, AppStates, DeviceService,
                                                          HelpService, AccountsService, Enums, ClientPortalService, FullStoryService,
                                                          FlowsBetaUserTypeService, StatsigService, AbTestService, ReactModalService, FeaturesService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'HelpDirectiveController';

        this.AppStates = AppStates;
        this.UserService = UserService;
        this.UsersManager = UsersManager;
        this.AppConfigService = AppConfigService;
        this.IntercomService = IntercomService;
        this.DeviceService = DeviceService;
        this.ClientPortalService = ClientPortalService;
        this.$timeout = $timeout;
        this.AnalyticsService = AnalyticsService;
        this.FullStoryService = FullStoryService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.$state = $state;
        this.StatsigService = StatsigService;

        this.ReactModalService = ReactModalService;
        this.FeaturesService = FeaturesService;
        this.isInAppBrowser = this.DeviceService.isInAppBrowser();

        var isAIOnboardingChatExperimentEnabledPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.aiOnboardingChat, 'chat_enabled', false);
        this.UsersManager.on('loggingOut', this._onLoggingOut.bind(this));
        this.UsersManager.on('loggingIn', this.onLogginIn.bind(this));
        this.UsersManager.on('resetPsw', this._initHelpDirective.bind(this));
        AccountsService.on(Enums.PubSubTriggers.companyChanged, function() {
            IntercomService.identify();
        });

        HelpService.on('hideHelp',this.onHideHelp.bind(this));
        this.toggleContentVisibility= this.toggleContentVisibility.bind(this);

        Promise.all([isAIOnboardingChatExperimentEnabledPromise]).then(
            function (results) {
                this.isAIOnboardingChatExperimentEnabled = results[0]; 
                HelpService.on('showHelp',this.onShowHelp.bind(this));
                this._initHelpDirective();
            }.bind(this)
        );
    };

    var HelpDirectiveController = Class(Controllers.BaseController, {
        constructor: HelpDirectiveControllerFunc,

        currUserIsUnsubscribedTrialer: function() {
            return (this.currUser && this.currUser.has_trial && !this.currUser.companyHasSubscription());
        },

        shouldShowIntercom: function shouldShowIntercom() {
            return this.IntercomService.shouldUseIntercom(this.currUser, false);
        },

        onLogginIn: function onLogginIn() {
            this._initHelpDirective();
            if (this.currUser.isClient()) {
                this.IntercomService.shutdown(true);
            }
        },

        onShowHelp: function onShowHelp() {
            this.showHelp = this._shouldShowHelp();
        },

        onHideHelp: function onHideHelp() {
            this.showHelp = false;
        },

        showHelpCenterModal: function showHelpCenterModal(isForced, forceState) {
            this.helpDropMoreContentIsVisible = isForced ? forceState : !this.helpDropMoreContentIsVisible;
            if (this.helpDropMoreContentIsVisible) {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.help_button, {
                    quiz_intent: this.currUser.user_intent,
                    intent_infra_variant: this.currUser && this.currUser.getExistingAbTestVariation(this.Enums.ABTests.intentInfra)
                });
            }},

        toggleContentVisibility: function toggleContentVisibility(forceState) {
            var isForced = angular.isDefined(forceState);
            this.showHelpCenterModal(isForced, forceState);
        },

        _initHelpDirective: function() {
            this.showHelp = false;
            this.currUser = this.UsersManager.getCurrUser();
            this.helpDropMoreContentIsVisible = false;
            setTimeout(function() {
                //setTimeout is because we want this function will invoked last
                // we use document.location.pathname in this._shouldShowHelp and we need to make sure the value is correct
                // (without setTimeout document.location.pathname value updating after we call this._shouldShowHelp so we get wrong value)
                this.showHelp = this._shouldShowHelp();
                if(this._shouldInitChat()){
                    this._initChat();
                } else {
                    /*  intercom load immediately when app is loaded and shows the  
                    launcher after finish so we want to shutdown if _shouldShowHelp return false  */
                    this.IntercomService.shutdown(true);
                }
            }.bind(this),0);
        },

        _isInLoginPage: function _isInLoginPage() {
            return (document.location.pathname === '/app/login');
        },

        _isInSignupPage: function _isInSignupPage() {
            return (document.location.pathname === '/app/signup');
        },

        _onLoggingOut: function _onLoggingOut() {
            this.currUser = undefined;
            this.$timeout(function() {
                this.IntercomService.shutdown(true);
                this._initHelpDirective();
            }.bind(this), 2000);
        },

        isOnOnboaringChatAiQuiz: function isOnOnboaringChatAiQuiz() {
            return this.isAIOnboardingChatExperimentEnabled && this.$state.current.name === 'root.core.onboardingMobileQuiz';
        },

        _shouldShowHelp: function _shouldShowHelp() {
           if ((window.isClientPortal && window.isClientPortal())
                || this.$state.current.name === "root.core.yearInReview"
                || (this.currUser && this.currUser.isClient())
                || (this._isInLoginPage()
                || this._isInSignupPage())
                || this.isOnOnboaringChatAiQuiz()
            ) {
               return false;
            }
            return (this.AppConfigService.isProduction() || this.AppConfigService.appConfiguration().enable_help_menu_in_dev);
        },

        _shouldInitChat: function _shouldInitChat() {
           if ((window.isClientPortal && window.isClientPortal())
                || this.$state.current.name === "root.core.yearInReview"
                || (this.currUser && this.currUser.isClient())
                || this.isOnOnboaringChatAiQuiz()
            ) {
               return false;
            }
            return (this.AppConfigService.isProduction() || this.AppConfigService.appConfiguration().enable_help_menu_in_dev);
        },

        _initChat: function _initChat() {
            if (this.shouldShowIntercom()) {
                this.IntercomService.init(this.currUser);
                this.IntercomService.identify();
            }
        },

        _shutdownChat: function _shutdownChat() {
            if (this.shouldShowIntercom()) {
                this.IntercomService.shutdown();
            }
        },
    });
    return {
        scope: {},
        controller: HelpDirectiveController,
        controllerAs: 'helpVm',
        bindToController: true,
        templateUrl: 'angular/app/modules/common/ui_components/help/help_directive_template.html'
    };
};
