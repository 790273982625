/**
 * Created by inonstelman on 12/30/14.
 */

// Example usage in html file:
// <li>{{'ARTICLE_PAYMENTS.LABELS._STATUS_' | translate}}</li>
// better way:
// <span class="name-file-type" translate>{{link.label}}</span>

// in the JS:
// this.link.label:'FILE.FILMSTRIP._EMAILS_'

//Using Translate with a filter in the controller:
// Remember to inject $translate and $filter.
// this.message = $translate.instant('WORKSPACE.EMAILS._EMAIL_VIEWED_', {date: $filter('date')(this.lastDateViewed, 'M/d/yyyy')});


common_module.constant('Translations', {

    en: {

        TWO_FACTOR_AUTH: {
            _TITLE_: 'Check your phone',
            _TITLE_SENT_: 'Code sent!',
            _PROMPT_: 'We just sent a 6 digit code to your mobile device ending in (•••) •••-{{number}}.',
            _LOST_ACCESS_TO_YOUR_PHONE_: 'Lost access to your phone?',
            _CONTACT_SUPPORT_: 'Contact support',
            _DIDNT_GET_THE_CODE_: 'Didn’t get the code?',
            _RESEND_: 'Resend',
            _OR_: 'or',
            _SEND_BY_PHONE_CALL: 'send by phone call',
            _CTA_: 'Continue'
        },

        GENERAL: {
            PROJECT: 'Project',
            WORKSPACE: 'Workspace'
        },

        GENERIC_VIDEO_MODAL: {
            _REPLAY_: 'REPLAY VIDEO',
            _GET_STARTED_: 'GET STARTED'
        },
        FREQUENT_MESSAGES: {
            _ARE_YOU_SURE_: 'Are you sure?',
            _CONFIRM_DELETE_: 'Are you sure you want to delete this {{type}}?',
            _EXIT_WITHOUT_SAVING_: 'Discard your changes?',
            _CHANGES_LOST_: 'Your changes will be permanently deleted.'
        },
        FREQUENT_TIME_INDICATORS: {
            _DAY_: 'day',
            _DAYS_: 'days',
            _MONTH_: 'month',
            _MONTHS_: 'months'
        },
        FREQUENT_BUTTONS: {
            _SAVE_: 'Save',
            _SAVE_CHANGES_: 'Save Changes',
            _SAVE_AND_EXIT_: 'Save and exit',
            _SEND_: 'Send',
            _INSERT_: 'Insert',
            _NEXT_: 'Next',
            _CANCEL_: 'Cancel',
            _CONFIRM_: 'Confirm',
            _DELETE_: 'Delete',
            _DUPLICATE_: 'Duplicate',
            _BLOCK_: 'Expired',
            _MARKASEXPIRED_: 'Mark as Expired',
            _EXPIRE_NOW_: 'Expire now',
            _UNEXPIRE_: 'Unexpire',
            _SELECT_: 'Select',
            _SELECTED_: 'Selected',
            _DONE_: 'Done',
            _ADD_: 'Add',
            _CONTINUE_: 'Continue',
            _CONTINUE_CANCELLING_: 'Continue Canceling',
            _CANCEL_PLAN_: 'Cancel Plan',
            _ARCHIVE_: 'Archive',
            _UNARCHIVE_: 'Un-archive',
            _YES_: 'Yes',
            _YES_STOP_: 'Yes, stop',
            _YES_EXIT_: 'Yes, Exit',
            _KEEP_EDITING_: 'Keep Editing',
            _DISCARD_: 'Discard',
            _SKIP_: 'Skip',
            _NO_: 'No',
            _NO_THANKYOU_: 'No, Thank you',
            _EDIT_: 'Edit',
            _SUBMIT_: 'Submit',
            _OK_: 'Ok',
            _CLOSE_: 'Close',
            _ACCEPT_: 'Accept',
            _CONNECT_: 'Connect',
            _CUSTOMIZE_: 'Customize',
            _REMOVE_: 'Remove',
            _ATTACH_: 'Attach',
            _LETS_GO_: 'Let\'s go!',
            _INQUIRE_: 'Inquire',
            _GOT_IT_: 'Got It',
            _OK_GOT_IT_: 'OK, Got It',
            _LEARN_MORE_: 'Learn more',
            _COPY_: 'Copy',
            _COPIED_: 'Copied',
            _PROMO_: 'Get a discount',
            _PREVIEW_: 'Preview',
            _ISSUE_REFUND_: 'Issue Refund',
            _REVERT_MAP_: 'Confirm',
            _CLICK_HERE_: 'Click here',
            _PUBLISH_: 'Publish',
            _OPTIONS_: 'Options',
            _LIKE_: 'Like',
            _FIRST_LIKE_: 'Be the first to like',
            _LIKE_WITH_COUNTER_: '{{likes}} Like',
            _LIKES_WITH_COUNTER_: '{{likes}} Likes',
            _FIRST_VIEW_: 'No views :(',
            _VIEW_WITH_COUNTER_: '{{views}} View',
            _VIEWS_WITH_COUNTER_: '{{views}} Views',
            _SHOW_MORE_: 'Show More',
            _SHOW_LESS_: 'Show Less',
            _CREATE_: 'Create',
            _FORCE_: 'Force',
            _PREVIOUS_: 'Previous',
            _VIEW_: 'VIEW',
            _NONE_: 'None',
            _SCHEDULE_SEND_: 'Schedule',
            _CANCEL_SCHEDULE_SEND_: 'Cancel schedule',
            _YES_DELETE_: 'Yes, Delete',
            _DONT_DELETE_: 'DON\'T DELETE',
            _YES_DELETE_DONT_SEND_: 'Yes, Delete file & don\'t send',
            _YES_DELETE_CANCEL_SESSION_: 'DELETE FILE & CANCEL SESSION',
            _YES_DELETE_SESSION_: 'DELETE FILE & SESSION',
            _APPLY_: 'Apply',
            _CLEAR_: 'Clear',
            _IMPORT_: 'Import'
        },
        FREQUENT_LABELS: {
            _NOT_AVAILABLE_: 'N/A',
            _TBD_: 'TBD',
            _PREVIEW_: 'Preview',
            _NOTIFICATIONS_: 'Notifications',
            _HONEYBOOK_: 'HoneyBook',
            _ACTIONS_: 'Actions',
            _NEW_: 'New',
            _SUBJECT_: 'Subject',
            _SAVING_: 'Saving',
            _SAVED_: 'Saved',
            _PRO_TIP_: 'PRO TIP:',
            _OR_: 'or'
        },
        FREQUENT_ERRORS: {
            _REQUIRED_: "Required"
        },
        TIME_LABELS: {
            _TODAY_: 'Today',
            _YESTERDAY_: 'Yesterday'
        },

        NAVIGATION: {
            _BACK_: 'Back'
        },

        FORM_LABELS: {
            _EMAIL_: 'Email',
            _PASSWORD_: 'Password',
            _FORGOT_PASSWORD_: 'Forgot Password?',
            _LOGIN_: 'LOG IN',
            _LOGGING_IN_: 'Logging In...',
            _PHONE_NUMBER_: "Phone Number",
            _CITY_: "City",
            _POSTAL_CODE_: "Postal Code",
            _ZIP_CODE_: "Zip Code",
            _FIRST_NAME_: "First Name",
            _LAST_NAME_: "Last Name",
            _DOB_: "Date of Birth",
            _SSN_: "SSN",
            _SIN_: "SIN",
            _STREET_ADDRESS_: "Street Address",
            _FULL_NAME_: 'Full name'
        },

        PLACEHOLDERS: {
            _EMAIL_: 'Start typing email address',
            _EMAIL_EXAMPLE_: 'example@gmail.com',
            _PASSWORD_: '********',
            _FULL_NAME_: 'John Doe',
            _FULL_NAME_DEFAULT_: 'Full Name',
            _FIRST_NAME_DEFAULT_: 'First Name',
            _LAST_NAME_DEFAULT_: 'Last Name',
            _CREDIT_CARD_NUMBER_: 'Credit Card Number',
            _CVV_: 'CVV',
            _MM_: 'MM',
            _YYYY_: 'YYYY',
            _ZIP_CODE_: 'Zip Code',
            _POSTAL_CODE_: 'Postal Code',
            _UNTITLED_: 'UNTITLED',
            _MMDDYYYY_: 'MM/DD/YYYY',
            _STREET_ADRRESS_: 'Street Address',
            _CITY_: 'City',
            _STATE_: 'State',
            _PROVINCE_: 'Province',
            _COUNTRY_: 'Country'
        },

        HB_MENU: {
            _CREATE_NEW_: 'Create new {{type}}',
            _CREATE_NEW_PROJECT_TYPE_: 'Add',
            _NEW_TYPE_: 'New {{type}}',
            _ERROR_: '{{type}} already exists',
            _LIMIT_REACHED_: ' limit reached'
        },

        BIRTHDAY_MODAL: {
            _INTRO_: 'Hi {{firstName}},',
            _TITLE_: 'Happy Birthday!',
            _SUBTITLE_: 'There aren’t many days you get to sit back and just relax. Make sure you get a little you time today!',
        },

        COVID_DASHBOARD: {
            TOOLTIPS: {
                _SCORE_WITH_GUESTS_: 'Determined by assessing <b>total project value</b>, <b>number of days until project</b>, and <b>guest count</b>.',
                _SCORE_NO_GUESTS_: 'Determined by assessing the <b>total project value</b> and <b>number of days until project</b>.'
            }
        },

        MULTI_BRAND: {
            BRANDS_PAGE: {
                _ADD_: 'Manage your companies',
                _SIGN_IN_ADD_: 'Sign in / Add other companies',
                _ADD_BRANDS_: "Streamline company management—access them all using a single email address. <br /> <a href='https://help.honeybook.com/my-account/company-account-settings/using-multiple-companies-in-honeybook' target='_blank'>Learn how to add multiple companies</a>"
            },

            ADD_BRAND_MODAL: {
                _WELCOME_: 'Welcome!',
                _YOUR_COMPANY_TYPE_: 'Your company name',
                _YOUR_INDUSTRY_TYPE_: 'Your industry type',
                _BODY_: 'We\'re so happy you\'ve chosen HoneyBook to help grow your business empire. First we\'ll need to set up your company details and branding. To start, what industry does your additional company specialize in?',
                _CTA_: 'SET UP MY BUSINESS'
            },

            LABELS: {
                _ADD_NEW_: 'add new',
                _MAIN_COMPANY_: 'main company',
                _GO_TO_BRAND_: 'Go to brand'
            },

            UPGRADE_PLAN: {
                _TITLE_: 'Manage your companies in one place, using one login',
                _TITLE_MOBILE_: 'Manage your companies in one place',
                BENEFITS: {
                    _MEMBER_ROLE_: 'Run your companies, each with their own brand, projects, bank accounts and more...',
                    _WORKSPACES_: 'Stay organized and on top of tasks by smoothly switching between companies.'
                },
                BENEFITS_MOBILE: {
                    _MEMBER_ROLE_: 'Run your distinct companies with ease',
                    _WORKSPACES_: 'Switch between companies to handle tasks'
                }
            }
        },

        PIPELINE_TOUR: {
            _BEGIN_: "Take the tour",
            _NEXT_: "Next",
            _CUSTOMIZE_: "Customize your pipeline",
            _DONE_: "Done for now",
            _VIDEO_: "Watch video",
            _CUSTOMIZATION_WARNING_: "Customization is only available for account owners",
            STEP_1: {
                _TITLE_: "Meet your Project Pipeline",
                _BODY_: "This is where you track incoming leads and ongoing projects, so you always know where each project stands."
            },
            STEP_2: {
                _TITLE_: "Inquiries come in straight from your website",
                _BODY_: "When you install the HoneyBook contact form on your website, new inquiries automatically pop into your Pipeline. You can also add inquiries manually."
            },
            STEP_3: {
                _TITLE_: "Projects move stages automatically",
                _BODY_: "Our system knows when you send a Proposal (or invoice), when a client signs a contract, and when they pay. Based on those actions, projects automatically move to the correct stage."
            },
            STEP_4: {
                _TITLE_: "Customize it!",
                _BODY_: "Your Project Pipeline should represent the stages of your client process. Customize it to make sure it fits how your business works."
            },
            FINAL: {
                _TITLE_: "Great Job!",
                _BODY_: "Ready to step a level up?!"
            }
        },

        CONTACT_FORM_TOUR: {
            _BEGIN_: "Take the tour",
        },

        ONBOARDING_MOBILE_QUIZ: {
            _SKIP_: 'skip',
            _WELCOME_: 'Welcome {{first_name}}!',
            _WELCOME_TEXT_: 'We want to get to know your business better. Here are {{number_of_questions}} quick questions that will help us support you in a serious way 🙌',
            _PURCHASE_SUCCESS_TEXT_: 'You’ve purchased {{plan_name}}!\n Let HoneyBook jump start your account',
            _INSTANT_BRANDING_WELCOME_: 'Let’s import your brand in seconds',
            _INSTANT_BRANDING_WELCOME_SERVICES_HUB: 'Welcome {{first_name}},\n Let HoneyBook jump start your account!',
            _INSTANT_BRANDING_WELCOME_TEXT_: 'Our AI-powered magic pulls your website\'s brand elements into your account.',
            _INSTANT_BRANDING_QUESTIONS_INTRO_: 'Let’s personalize your account',
            _INSTANT_BRANDING_QUESTIONS_INTRO_TEXT_: '{{number_of_questions}} quick questions to get things going.',
            _INTRO_TEXT_: 'Help us make smart recommendations.',
            _CONGRATS_: '{{first_name}}, congrats!',
            _CONGRATS_TEXT_: 'Now get a taste of HoneyBook by exploring our features and start making it your own',
            _DIVE_IN_TEXT_: 'or dive right in',
            _START_EXPLORING_: 'start exploring',
            _FINISHING_TEXT_: 'Finishing up...',
            QUESTIONS: {
                HOW_LONG_IN_BUSINESS: {
                    _TEXT_: '{{first_name}}, how long have you had your business?',
                    ANSWERS: {
                        NOT_STARTED: {
                            _TITLE_: 'Haven’t started my business yet',
                            _HELPER_: 'Planning on starting one soon',
                            _FEEDBACK_: 'No problem, let’s get you prepared to succeed.'
                        },
                        NEW: {
                            _TITLE_: 'I’m new',
                            _HELPER_: 'Less than a year',
                            _FEEDBACK_: 'Great, we love helping new businesses.'
                        },
                        ONE_TO_THREE: {
                            _TITLE_: 'I’ve been around',
                            _HELPER_: '1-2 years',
                            _FEEDBACK_: 'Great, we love helping new businesses.'
                        },
                        FOUR_TO_FIVE: {
                            _TITLE_: 'I’ve seen a lot of clients',
                            _HELPER_: '3-4 years',
                            _FEEDBACK_: 'Great, we’ll help you organize your process.'
                        },
                        SIX_PLUS: {
                            _TITLE_: 'I’m a vet',
                            _HELPER_: '5+ years',
                            _FEEDBACK_: 'Nice, you’ve worked hard to get here.'
                        }
                    }
                },
                WHAT_CLIENT_TYPE: {
                    _TEXT_: '{{first_name}}, do you mostly work with businesses or individuals?',
                    ANSWERS: {
                        BUSINESSES: {
                            _TITLE_: 'Businesses',
                            _HELPER_: 'My clients are primarily businesses'
                        },
                        INDIVIDUALS: {
                            _TITLE_: 'Individuals',
                            _HELPER_: 'My clients are primarily individuals'
                        },
                        BOTH: {
                            _TITLE_: 'Both',
                            _HELPER_: 'I serve both businesses and individuals'
                        }
                    }
                },
                SERVICE_GOODS_OR_BOTH: {
                    _TEXT_: 'Is most of your work providing a service, selling physical or digital goods, or both?',
                    ANSWERS: {
                        SERVICE: {
                            _TITLE_: 'Service'
                        },
                        GOODS: {
                            _TITLE_: 'Selling physical or digital goods'
                        },
                        BOTH: {
                            _TITLE_: 'Both'
                        }
                    }
                },
                WHAT_NEED_MOST: {
                    _TEXT_: 'What do you need the most help with?',
                    ANSWERS: {
                        LEADS: {
                            _TITLE_: 'Turning more inquiries into clients'
                        },
                        IDEAL: {
                            _TITLE_: 'Booking more of my ideal clients'
                        },
                        REVENUE: {
                            _TITLE_: 'Growing my revenue'
                        },
                        AUTOMATING: {
                            _TITLE_: 'Automating my booking process'
                        },
                        ONE_PLACE: {
                            _TITLE_: 'Keep all of my communications and files in one place'
                        },
                        EXPERIENCE: {
                            _TITLE_: 'Providing a great experience to my clients'
                        },
                        BOOKING: {
                            _TITLE_: 'Keep my booking process organized'
                        }

                    },
                },
                HOW_MANY_INQUIRIES: {
                    _TEXT_: 'How many inquiries or leads do you get a month?',
                    ANSWERS: {
                        UP_TO_FIVE: {
                            _TITLE_: '0-5'
                        },
                        SIX_TO_TEN: {
                            _TITLE_: '6-10'
                        },
                        UP_TO_TWENTY: {
                            _TITLE_: '11-20'
                        },
                        UP_TO_FIFTY: {
                            _TITLE_: '21-50'
                        },
                        FIFTY_PLUS: {
                            _TITLE_: '51+'
                        }
                    },
                },
                HOW_MANY_PROJECTS: {
                    _TEXT_: 'How many new projects do you book every month?',
                    ANSWERS: {
                        ZERO_TO_ONE: {
                            _TITLE_: '0-1'
                        },
                        TWO_TO_THREE: {
                            _TITLE_: '2-3'
                        },
                        FOUR_TO_FIVE: {
                            _TITLE_: '4-5'
                        },
                        SIX_TO_TEN: {
                            _TITLE_: '6-10'
                        },
                        TEN_PLUS: {
                            _TITLE_: '11+'
                        }
                    },
                },
                TIME_TO_BOOK: {
                    _TEXT_: 'From the moment you get a new lead, how long does it take till they book you?',
                    ANSWERS: {
                        LESS_THAN_DAY: {
                            _TITLE_: 'Less than a day'
                        },
                        LESS_THEN_WEEK: {
                            _TITLE_: 'Less than a week'
                        },
                        ONE_TO_TWO_WEEKS: {
                            _TITLE_: '1-2 weeks'
                        },
                        THREE_TO_FOUR_WEEKS: {
                            _TITLE_: '3-4 weeks'
                        },
                        MONTH: {
                            _TITLE_: 'Over a month'
                        }
                    },
                },
                WHAT_AVERAGE_ANNUAL_REVENUE: {
                    _TEXT_: 'What is the average annual revenue from your business?',
                    ANSWERS: {
                        LESS10K: {
                            _TITLE_: '$0-$5k'
                        },
                        K5_TO_K20: {
                            _TITLE_: '$5k-$20k'
                        },
                        K20_TO_K50: {
                            _TITLE_: '$20k-$50k'
                        },
                        K50_TO_K200: {
                            _TITLE_: '$50k-$200k'
                        },
                        MORE200K: {
                            _TITLE_: '$200k+'
                        }
                    }
                },
                CURRENT_TOOL_YOU_USE: {
                    _TEXT_: 'What’s the main tool you currently use to manage your clients?',
                    ANSWERS: {
                        ANOTHER_CRM: {
                            _TITLE_: 'Another CRM or business management platform',
                        },
                        ACCOUNTING_SOFTWARE: {
                            _TITLE_: 'Accounting software',
                        },
                        GOOGLE_DOCS_EMAIL: {
                            _TITLE_: 'Google Docs (or similar) and email',
                        },
                        SCHEDULING_SOFTWARE: {
                            _TITLE_: 'Scheduling software',
                        },
                        OFFLINE_METHODS: {
                            _TITLE_: 'Offline methods (pen & paper)',
                        },
                        OTHER: {
                            _TITLE_: 'Other'
                        }
                    }
                },
                HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST: {
                    _TEXT_: '{{first_name}}, what do you want to start with?',
                    ANSWERS: {
                        INVOICES: {
                            _TITLE_: 'Invoices',
                            _HELPER_: 'Get paid on time, online'
                        },
                        CONTRACTS: {
                            _TITLE_: 'Contracts',
                            _HELPER_: 'eSign agreements, fast & simple'
                        },
                        BROCHURES_AND_QUESTIONNAIRES: {
                            _TITLE_: 'Service guide',
                            _HELPER_: 'Collect info and set expectations'
                        },
                        LEAD_CAPTURE: {
                            _TITLE_: 'Lead Capture',
                            _HELPER_: 'Manage your leads, all in one place'
                        },
                        BUSINESS_MANAGEMENT: {
                            _TITLE_: 'CRM',
                            _HELPER_: 'Streamline client & business management'
                        },
                        SCHEDULING: {
                            _TITLE_: 'Scheduling',
                            _HELPER_: 'Get meetings on your calendar'
                        }
                    }
                }
            },
            FACEBOOK_MOBILE_FLOW: {
                _BUILDING_TEMPLATES_: 'Building templates...',
                _TITLE_: 'Your templates are ready!',
                _SUBTITLE_: 'Let’s put them to work.',
                LIST: {
                    _ITEM_1_: 'Better communication',
                    _ITEM_2_: 'Better automation',
                    _ITEM_3_: 'Faster payments',
                },
                _CTA_: 'Get started',
            },
        },

        ERRORS: {
            _FIELD_IS_REQUIRED_: 'This field is required',
            _FIELD_MIN_LENGTH_: 'It is too short',
            _FIELD_MAX_LENGTH_: 'It is too long',
            _MIN_LENGTH_INT_: 'has a minimum length of ',
            _MAX_LENGTH_INT_: 'has a maximum length of ',
            _MIN_LENGTH_PASSWORD_: 'For your security, passwords must be at least ',
            _BAD_URL_: 'Illegal URL format. Please enter full URL (e.g. http://www.honeybook.com)',
            _NAME_TOO_SHORT_: 'Names must be at least three characters',
            _NAME_MISSING_: 'Please enter a name',


            _INVALID_URL: 'Please enter a valid URL',
            _INVALID_PHONE_NUMBER_: 'Please enter a valid phone number',
            _INVALID_EMAIL_ADDRESS_: 'Please enter a valid email address',
            _BAD_EMAIL_: 'Illegal email address', //this will occur when used with <input type="email">
            _WRONG_PATTERN_: 'Illegal email address', //for now we consider all pattern issues - email
            _SMS_WASNT_SENT: 'Oops, something went wrong while trying to send the SMS.',
            _SERVICE_IS_REQUIRED_: 'A service or product is required',
            _PRICE_IS_REQUIRED_: 'Price is required',
            SERVER_API: {
                _COULD_NOT_CONNECT_TO_SERVER_: 'Could not connect to the server. Please check your internet connection.',
                _EMAIL_TIMEOUT_: 'Your integrated email service did not respond in a timely manner. Please check your Sent folder to confirm that your message has been sent before attempting to resend.',
                _UNKNOWN_: 'Unknown error',
                _UNEXPECTED_: 'Some unexpected error has occurred, for assistance please contact us',
                _TRY_AGAIN_: 'Oops, something went wrong. Please try again',
                _BACKEND_TIMEOUT_: 'Oops, the action takes too long. Please refresh the page and retry'
            }
        },

        STATUS: {
            _NEW_: 'New',
            _LEAD_: 'Lead',
            _ARCHIVED_: 'Archived',
            _CANCELLED_: 'Expired'

        },
        STATES: {
            _NEW_: 'New',
            _SENT_: 'Sent',
            _VIEWED_: 'Viewed',
            _SIGNED_: 'Signed',
            _REPLIED_: 'Replied',
            //_PAY_VIEWED_:'',
            _PAID_: 'Review Payment',
            _PAYMENT_PENDING_: 'Payment Pending'
        },

        USER: {
            TYPES: {
                _VENDOR_: 'Vendor',
                _CLIENT_: 'Client',
                _PLANNER_: 'Planner',
                _CREATIVE_: 'Creative'
            },
            WORKSPACE_ROLES: {
                _VENDOR_: 'Creative',
                _CLIENT_: 'Client',
                _PLANNER_: 'Planner',
                _VENDOR_DESCRIPTION_: 'Add another creative',
                _PLANNER_DESCRIPTION_: 'Add a project planner',
                _CLIENT_DESCRIPTION_: 'Add a client or project organizer'
            },
            AVATAR: {
                _PROFILE_: 'PROFILE'
            }
        },

        LOGIN: {
            BUTTONS: {
                _FACEBOOK_: 'Sign in with Facebook',
                _APPLE_: 'Sign in with Apple'
            },
            TITLE: {
                _WELCOME_: 'Welcome back!'
            },
            TRIAL: {
                _EXTENSION_TEXT_: 'Growing a business is busy work! We\'re gifting you a complimentary trial extension, so you have time to fully experience all that HoneyBook has to offer. Simply log in here and your trial will be extended automatically!',
                _DISCOUNT_TEXT_: 'ONE TIME OFFER: SIMPLY LOG IN TO GET 20% OFF YOUR HONEYBOOK SUBSCRIPTION'
            },

            REFERRAL: {
                _EXISTING_USER_REFERRED_: "Almost there! Please log in to claim your referral discount."
            },

            LABELS: {
                _EMAIL_: 'Email',
                _TWO_FACTOR_AUTH_: 'Code',
                _PASSWORD_: 'Password',
                _FORGOT_: 'Forgot?',
                _TOS_: 'By logging in, you agree to the ',
                _TOS_TERMS_: 'Terms of Service',
                _AND_: ' and ',
                _TOS_PRIVACY_: 'Privacy Policy',
                _CORRECT_FOLLOWING_: 'Please correct the following:',
                _BUTTON_: 'Log in',
                _BUTTON_LOADING_: 'Logging in...',
                _TRUST_THIS_DEVICE_: 'Trust this device',
                ERROR: {
                    _EMAIL_NULL_: 'Please enter an email',
                    _EMAIL_INVALID_: 'Please enter a valid email',
                    _PASSWORD_NULL_: 'Please enter a password',
                    _GENERIC_: 'Oops, something went wrong. Please try again'
                }
            },

            PLACEHOLDERS: {
                _EMAIL_: 'johndoe@email.com',
                _PASSWORD_: '********',

            },

            FORGOT_PASSWORD: {
                _TITLE_: 'Forgot your password?',
                _TITLE_SENT_: 'Reset link sent!',
                _PROMPT_: 'Enter the email associated with your account, and we\'ll send you a link to reset your password.',
                _CTA_: 'Send Reset Link',
                _BACK_TO_LOGIN_: 'Return to log in',
                _CHECK_INSTRUCTIONS_: 'We sent you an email with instructions to reset your password. For more help, please contact us at:'
            },

            CLIENT_LOGIN_ERROR: {
                _TITLE_: 'Your portal isn’t here...',
                _SEND_: 'Change Password',
                _BODY_: 'But, it’s just a click away— if you can’t find the link in earlier emails, check with your business contact.'
            },

            EXPIRED_PASSWORD_MODAL:{
                _TITLE_: 'Your password has expired',
                _BODY_: ''
            },

            SERVER_LOGIN_ERROR: {
                _TITLE_: 'We couldn\'t log you in'
            }
        },

        EXPIRED_LINK: {
            _TITLE_: 'Confirm your email',
            _SECURITY_EXPLANATION_: 'This is a one-time security measure that’ll be used to protect your info.',
            _INBOX_MESSAGE_: 'Check your inbox and confirm your email address, then you can securely access your portal.',
            _SEND_EMAIL_: 'SEND EMAIL',
            _TITLE_AFTER_SEND_: 'Check your inbox',
            _EMAIL_SENT_: 'An email has been sent to <b>{{email}}</b>.<br>It\'ll arrive soon.',
            _DONT_SEE_EMAIL_: 'You may have to check your spam folder.',
            _RESEND_MESSAGE_: 'Still don’t see it?',
            _RESEND_: 'Resend email'
        },

        TEAM_MEMBERS: {
            SELECT_MEMBERS_MODAL: {
                _NO_MEMBERS_: "It looks like you don't have any team members yet.",
                _GOTO_TEAM_SETTINGS_1_: "Go to ",
                _GOTO_TEAM_SETTINGS_2_: "Company Settings",
                _GOTO_TEAM_SETTINGS_3_: " to add members"

            }
        },

        SET_PASSWORD: {
            _HEADER_: 'Set password for {{email}}'
        },

        RESET_PASSWORD: {
            NEW_USER: {
                _HEADER_1_: "Looks like you're new around here",
                _HEADER_2_: "Create a password for your new account",
                _CREATE_: "Create a password",
                _BUTTON_: "Save & Continue",
                _ERRORS_: "Please correct the following:",
                _PWD_REQUIRE_COPY_1_: 'Why do we require a password?',
                _PWD_REQUIRE_COPY_2_: 'To provide you with a secure workplace to',
                _PWD_REQUIRE_COPY_3_: 'collaborate on contracts and make online payments.',
                _INVITED_: 'invited you to collaborate.',
                _INVITED_TO_BOOKKEEPER_: 'granted you bookkeeper access.',
                _INVITED_1_: 'invited you to collaborate',
                _WELCOME_TO_HB_: 'Welcome to HoneyBook',
                _HB_IS_: 'HoneyBook is the one-stop shop for creative professionals to streamline their business - from proposals and contracts, to payments.',
                _REFERRED_: 'referred you for a project on',
                _CREATIVES_USING_HB_: 'more creative(s) you know already use HoneyBook to run their business.',
                _CREATIVE_USING_HB_ONE_: ', a creative you know, already use HoneyBook to run their business.',
                _CREATIVE_USING_HB_TWO_: ', creatives you know, already use HoneyBook to run their business.',
                _PVL_INQUIRE_RESET_PWD_MESSAGE_: 'You have a lead waiting, thanks to a referral by <b>{{user_name}}</b> from <b>{{company_name}}</b>',
                _PVL_REFERRED_RESET_PWD_MESSAGE_: '<b>{{user_name}}</b> from <b>{{company_name}}</b> referred you for a project on <b>{{event_date}}</b>',
                _PVL_REFERRED_RESET_PWD_MESSAGE_NO_DATE_: '<b>{{user_name}}</b> from <b>{{company_name}}</b> referred you for a project</b>',
                _ATWS_RESET_PWD_MESSAGE_: '<b>{{user_name}}</b> from <b>{{company_name}}</b> invited you to collaborate on <b>{{event_name}}</b>'
            },

            NETWORK_NEW_USER: {
                _TITLE_: 'Join {{name}} on the HoneyBook Community',
                _INVITED_: 'The HoneyBook Community is a place to share your work, find opportunities, and meet other creative professionals like you',
                _LABEL_: 'Create password for {{email}}',
                _FREE_TO_JOIN_: 'It\'s free to join the HoneyBook community',
                _BUTTON_: 'Join Community'
            },

            EXISTING_USER: {
                _HEADER_: "Reset password for {{email}}",
                _REMEMBER_: "Remember your password? ",
                _LOGIN_: "Log In",
                _USERNAME_: "Username",
                _ERRORS_: "Please correct the following:",
                _FACEBOOK_CONNECT_: "Connect with Facebook for 1-click login"
            }
        },

        MAIN_NAV: {
            _TRIAL_TEXT: {
                _TRIAL_EXPIRED_: 'Trial over',
                _X_DAYS_LEFT_: "{{remainingTrialDays}} day{{ ( remainingTrialDays !== 1) ? 's' : ''}} left",
                _SEE_PRICING_: 'see pricing',
                _SUBSCRIBE_NOW_: 'upgrade now'
            },
            _PAUSED_MEMBERSHIP: {
                _UNPAUSE_NOW_: 'unpause now',
                _UNPAUSE_: 'unpause',
                _MEMBERSHIP_PAUSED_: 'Membership paused',
            },
            _TAGLINE_: {
                BUSINESS_CONSULTANT: 'consultants',
                COACHING: 'coaches'
            },
            _ALL_PROJECTS_: 'All Projects',
            _WORKFLOW_: 'Projects',
            _TOOLS_: 'Tools',
            _TOOLS_HOME_: 'Business Tools',
            _PEOPLE_: 'People',
            _CLIENTS_: 'Clients',
            _CONTACTS_: 'Contacts',
            _CALENDAR_: 'Calendar',
            _REPORTS_: 'Reports',
            _BOOKKEEPING_: 'Bookkeeping',
            _CORONA_: 'COVID-19',
            _COMMUNITY_: 'Opportunities',
            _FINANCE_: 'Finance',
            _INTEGRATIONS_: 'Integrations',
            _NEW_: 'NEW',
            _NOTIFICATIONS_: 'Notifications',
            _PROFILE_: 'Profile',
            _HOME_: 'Home',
            _SEARCH_: 'Search',
            _REFERRALS_: 'Referrals',
            _SETUP_GUIDE_: 'Setup Guide',
            _SEE_PRICING_: 'See pricing',
            _PROFILE_SETTINGS_: 'Profile & Settings',
            CREATE_MENU: {
                _TITLE_: 'Where do you want to start?',
                _PROJECT_: 'Project',
                _NEW_PROJECT_: 'New Project',
                _CLIENT_: 'Client',
                _NEW_CLIENT_: 'New Client',
                _INVOICE_: 'Invoice',
                _NEW_INVOICE_: 'New Invoice',
                _CONTRACT_: 'Contract',
                _NEW_CONTRACT_: 'New Contract',
                _SERVICE_: 'Service',
                _NEW_SERVICE_: 'New Service',
                _SCHEDULE_: 'Meeting',
                _NEW_SCHEDULE_: 'New Meeting',
                _FLOW_: 'Smart File',
                _NEW_FLOW_: 'New Smart File',
                _CREATE_: 'Create',
                _LEAD_FORM_: 'Lead Form',
                _NEW_LEAD_FORM_: 'New Lead Form',
            },
            NETWORK: {
                _UPGRADE_: 'UPGRADE',
                _FREE_: 'FREE TOOLS',
            },
            PRODUCT: {
                _UPGRADE_: 'UPGRADE',
                _FREE_: 'FREE TRIAL',
            },

            NEW_TOOLS: {
                TOOLS_CATEGORIES: {
                    _PRODUCTIVITY_: 'Productivity',
                    _ESSENTIALS_: 'Essentials',
                    _FINANCE_: 'Finance',
                    _LEAD_CAPTURE_: 'Lead Capture',
                    _COMMUNITY_: 'Community',
                    _TEMPLATES_: 'Templates',
                },

                ITEMS: {
                    _ALL_TEMPLATES_: 'All Templates',
                    _REPORTS_: 'Reports',
                    _CALENDAR_: 'Calendar',
                    _INTEGRATIONS_: 'Integrations',
                    _SCHEDULING_: 'Scheduler',
                    _AUTOMATIONS_: 'Automations',
                    _WORKFLOW_: 'Workflows',
                    _TEMPLATES_: 'My Templates',
                    _SERVICES_: 'Services',
                    _PAYMENTS_: 'Payments',
                    _BOOKKEEPING_: 'Profit & Loss',
                    _UPLOAD_FILES_: 'Library',
                    _IOS_APP_: 'Mobile App',
                    _QUICKBOOKS_: 'QuickBooks',
                    _CONTACT_FORM_: 'Contact Form',
                    _GIFT_CARD_: 'Gift Cards',
                    _HELP_CENTER_: 'Help Center',
                    _CREATIVES_: 'Business Directory',
                    _OPPORTUNITIES_: 'Opportunities',
                    _COMMUNITY_: 'Community',
                    _TASKS_: 'Tasks',
                    _REFERRAL_: 'Refer & perks',
                    _BATCH_EMAIL_: 'Batch Emails',
                    _TIME_TRACKER_: 'Time Tracker',
                    _PROCESS_MAPPING_: 'My Process',
                    _TEMPLATE_GALLERY_: 'Template Gallery',
                    _LEAD_FORMS_: 'Lead Forms',
                    _EXPENSES_: 'Expenses',
                    _CAPITAL_: 'Capital',
                    _CHROME_EXTENSION_: 'Chrome Extension',
                    _COVID_19_: 'Covid-19 Toolkit ',
                    _PRODUCT_UPDATES_: 'Product Updates',
                    _INVOICES_: 'Invoices',
                    _INVOICES_HUB_: 'Invoices',
                    _FILES_HUB_: 'Files',
                    _CONTRACTS_: 'Contracts',
                    _PROPOSALS_: 'Proposals',
                    _QUESTIONNAIRES_: 'Questionnaires',
                    _FLOWS_TEMPLATES_: 'Smart File Templates',
                    _HONEYBOOK_PROS_: 'HoneyBook Pros',
                    _FINANCE_OVERVIEW_: 'Overview',
                },

                MOBILE_APP: {
                    _IOS_: 'Download iOS app',
                    _ANDROID_: 'Download Android app'
                },

                BADGES: {
                    _NEW_: 'new'
                },

                _SEE_MORE_: 'SEE MORE',
            },

            TOOLS: {
                _COVID_19_: 'Covid-19 Kit ',
                _REPORTS_: 'Reports',
                _CALENDAR_: 'Calendar',
                _SCHEDULING_: 'Scheduling',
                _AUTOMATION_: 'Automation',
                _WORKFLOW_: 'Workflows',
                _TEMPLATES_: 'Templates',
                _PAYMENTS_: 'Payments',
                _BOOKKEEPING_: 'Bookkeeping',
                _UPLOAD_FILES_: 'Library',
                _IOS_APP_: 'Mobile App',
                _QUICKBOOKS_: 'QuickBooks',
                _CONTACT_FORM_: 'Contact Form',
                _GIFT_CARD_: 'Gift Cards',
                _HELP_CENTER_: 'Help Center',
                _CREATIVES_: 'Creatives',
                _OPPORTUNITIES_: 'Opportunities',
                _TASKS_: 'Tasks',
                _REFERRAL_: 'My Referrals',
                _BATCH_EMAIL_: 'Batch Emails',
                _TIME_TRACKER_: 'Time Tracker',
                _FLOWS_TEMPLATES: 'Smart File Templates'
            },

            PEOPLE: {

                _CREATIVES_: 'CREATIVES',
                _DISCOVER_: 'DISCOVER',
                _MY_CONNECTIONS_: 'MY CREATIVES',
                _CLIENTS_: 'CLIENTS',
                _SYNC_GOOGLE_CONTACTS_: 'Sync Google Contacts'
            },

            COMMUNITY: {
                _OPPORTUNITIES_: 'OPPORTUNITIES',
                _PUBLIC_PROJECTS_: 'PUBLIC PROJECTS',
                _CREATIVE_DIRECTORY_: 'CREATIVE DIRECTORY',
                _CONNECTIONS_: 'CONNECTIONS',
                _MY_POSTS_: 'My Posts',
                _SETTINGS_: 'Settings',
                _MORE_: 'MORE',
                _MODERATION_: 'Moderation'
            },

            PLACEHOLDER: {
                _SEARCH_: 'Search for Projects, Workspaces or People'
            },

            SETUP_GUIDE: {
                _SET_UP_: "{{percentage}}% set up",
                _SET_UP_TEXT_: "Setup"
            }

        },

        LEFT_PANEL: {
            _NEW_PROJECT_: '+ NEW PROJECT',
            _START_FREE_TRIAL_: "START FREE TRIAL",
            _PROJECTS_: 'PROJECTS',
            _PIPELINE_: 'PIPELINE',
            _DASHBOARD_: 'DASHBOARD',
            _FILES_: 'FILES',
            _SETUP_GUIDE_: 'SETUP GUIDE',
            _CONTACTS_: 'Connections',
            _REPORTS_: 'REPORTS',
            _SETTINGS_: 'SETTINGS',
            _WORKSPACELIST_: 'WORKSPACES',
            _CALENDAR_: 'CALENDAR',
            _ACTIVITY_NOTIFICATIONS_: 'NOTIFICATIONS',
            USER_INFO: {
                _PROFILE_: 'PROFILE',
                _REFER_: 'SHARE THE LOVE',
                _LOGOUT_: 'LOG OUT',
                _ACCOUNT_: 'ACCOUNT'
            },
            _RECENT_PROJECTS_: 'Activity',
            _UPCOMING_PROJECTS_: 'Project Date',
            _SEARCH_RESULTS_: 'Search Results',
            _EMPTY_PROJECTS_SEARCH_RESULTS_: 'Sorry! No projects match your search.',
            _INVITES_: 'My Referrals',
            _ONBOARDING_: {
                _MODALS_: {
                    _INVOICE_: {
                        _HEADER_: 'Create Your First Invoice',
                        _SUBTITLE_: 'Let me show you how to easily send an invoice and get paid faster. Watch the 40 second clip below to see how HoneyBook will enhance your clients\' experience.',
                        _BTN_: 'Edit my First Invoice'
                    },
                    _TEMPLATES_: {
                        _HEADER_: 'Add Your Templates',
                        _SUBTITLE_: 'Your brand is everything. We help you put your best face forward.',
                        _BTN_: 'Start customizing'
                    }
                }
            }
        },

        DASHBOARD: {
            _PROJECTS_TAB_: 'Projects',
            _FILES_TAB_: 'Files',
            _CONTACTS_TAB_: 'Contacts',
            LABELS: {
                _REFERRAL_: 'Refer a Friend',
                _SHARE_LOVE_: 'Share The Love'
            },
            SUB_NAVIGATION: {
                _ALL_: 'All',
                _LEADS_: 'Leads',
                _BOOKED_: 'Booked'
            },
            _SHOW_BLOCKED_: 'Show Archive',
            SORT: {
                _SORT_BY_: 'Sort by:',
                _CREATE_DATE_: 'Created Date',
                _PROJECT_DATE_: 'Project Date',
                _STATUS_: 'Status',
                _ALPHABETICAL_: 'A-Z Name',
                _ALPHABETICAL_REVERSE_: 'Z-A Name',
                _ALPHABETICAL_SHORT_: 'A-Z',
                _RECENT_ACTIVITY_: 'Recent Activity'
            },
            CONTACTS: {
                SUB_NAVIGATION: {
                    _ALL_: 'All',
                    _VENDORS_: 'Vendors',
                    _CLIENTS_: 'Clients',
                    _PREFERRED_VENDORS_: 'Preferred Vendors'
                },
                ADD_CONTACT: {
                    _HEADER_TITLE_: 'Build your network and add connections',
                    _SUBTEXT_: 'Enter the email address of your new connection.',
                    _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added. Are you sure you\'re done editing?'
                },
                EDIT_CONTACT: {
                    _HEADER_TITLE_: 'Edit contact',
                },
                ADD_PREFERRED_VENDOR_CONTACT: {
                    _HEADER_TITLE_: 'Add Preferred Vendors',
                    _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added. Are you sure you\'re done editing?',
                    _ADD_VENDORS_: 'Add Vendors',
                    _CANNOT_ADD_CLIENT_AS_VENDOR_: 'This email address is already in use as one of your clients'

                },
                GOOGLE: {
                    _ADD_CONNECTIONS_: 'Add Contacts',
                    _CONNECTIONS_GOOGLE_: 'Add Google Connections',
                    _CONNECTIONS_: '+ Connections',
                    _CONNECT_TO_GOOGLE_: 'Connect to Google',
                    _ADD_YOUR_CONNECTIONS_: 'Add Your Connections',
                    GOOGLE_API: {
                        _ACCESS_WAS_REVOKED_: 'Hmm, it looks like HoneyBook\'s permission to your Gmail contacts has expired. Click Confirm to allow access.',
                        _UNKNOWN_ERROR_: 'Oops, it looks like we couldn\'t connect to your Google account.',
                        _AUTH_ERROR_: 'Oops, it looks like we couldn\'t connect to your Google account.'
                    },
                    _PICK_A_TAG_: 'PICK A TAG',
                    _VENDOR_: 'Creative',
                    _CLIENT_: 'Client',
                    _WHO_ARE_YOU_LOOKING_FOR_: 'Who are you looking for?'

                },
                LABELS: {
                    _VENDORS_YOU_HAVE_ADDED_: 'Vendors you\'ve added',
                    _FUN_: 'Oh, what fun!',
                    _NETWORK_: 'Add your professional connections and start collaborating today.',
                    _START_: 'Start adding connections.',
                    _CONNECTED_IN_: '{{workspaces_count}} Shared Project{{plural}}:',
                    _ADD_NOTES_: 'Add private comments',
                    _NOTES_: 'Private notes'
                },
                BUTTONS: {
                    _CONNECT_YOUR_NETWORK_: 'See who you already know on HoneyBook',
                    _ADD_NEW_: 'Add New',
                    _EXPORT_TO_CSV_: 'Export CSV'

                },
                CONVERSATION: {
                    _START_CHAT_: 'Message'
                },
                INVITATION: {
                    _INVITE_: 'Invite',
                    _SENDING_: 'Sending...',
                    _SENT_: 'Invitation Sent',
                    _ERROR_: "Oops, there was a problem sending the invite. Please try again."
                },
                PREFERRED_VENDOR: {
                    _HEADER_: 'Build Your Preferred Vendor List',
                    _SUB_HEADER_: 'Keep track of your best vendors so they\'re always available when you need to connect with them or share them with your clients.'
                },
                FILTERS: {
                    _LABEL_: 'FILTERS:',
                    _VENDOR_: 'Vendor',
                    _CLIENT_: 'Client',
                    _IS_PREFERRED_: 'Preferred',
                    _SHARED_PROJECTS_: 'Shared Projects',
                    _LEAD_: 'Lead',
                    _ARCHIVED_: 'Archived',
                    _BOOKED_: 'Booked'

                },
                CARD: {
                    _SHOW_MORE_: 'Show more',
                    _SHOW_LESS_: 'Show less',
                    _ADD_PROJECT_: 'NEW PROJECT',
                    VOUCHER: {
                        _DELETE_: 'Delete Gift Card',
                        _DELETE_CONFIRM_: 'Are you sure you want to delete this gift card? Once deleted, the unpaid gift card invoice will get deleted as well.',
                        _TITLE_: 'Gift card',
                        _PAID_: "Paid",
                        _DISCOUNT_: "Discount",
                        _REDEMPTION_CODE_: "Redemption Code",
                        _REDEEMED_VALUE_: "Redeemed Value",
                        _AMOUNT_REDEEMED_: "Amount Redeemed",
                        _VIEW_: "View",
                        _HIDE_: "Hide",
                        _RESPONSES_TO_QUESTIONS_: "responses to questions"
                    }
                }
            }
        },

        CHAT: {
            _PRIVATE_MESSAGE_: 'Private Message'
        },

        BANK_INFO: {
            _TITLE_: 'Receiving Your Payments',
            _TYPE_: 'Bank Account Type',
            _ADD_ACCOUNT_TITLE_: 'Add a Bank Account',
            _PERSONAL_BANK_ACCOUNT_FIRST_NAME_: 'First Name (associated with SSN)',
            _PERSONAL_BANK_ACCOUNT_LAST_NAME_: 'Last Name (associated with SSN)',
            _CAD_PERSONAL_BANK_ACCOUNT_FIRST_NAME_: 'First Name (associated with SIN)',
            _CAD_PERSONAL_BANK_ACCOUNT_LAST_NAME_: 'Last Name (associated with SIN)',
            _BUSINESS_BANK_ACCOUNT_NAME_: 'Legal Business Name',
            _ACCOUNT_NAME_: 'Account Name',
            _BANK_NAME_: 'Bank Name',
            _ROUTING_NUMBER_: 'Routing Number',
            _ACCOUNT_NUMBER_: 'Account Number',
            _ACCOUNT_TYPE_: 'Account Type',
            _CA_TRANSIT_NUMBER_: 'Transit Number',
            _CA_INSTITUTION_NUMBER_: 'Institution Number',
            _CA_ACCOUNT_NUMBER_: 'Account Number',
            _PERSONAL_SOCIAL_SECURITY_NUMBER_: 'SSN',
            _BUSINESS_SOCIAL_SECURITY_NUMBER_: 'SSN (last 4 digits)',
            _TAX_IDENTIFICATION_NUMBER_: 'Employer Identification Number (EIN)',
            _BUSINESS_NUMBER_: 'Business number',
            _TYPE_BUSINESS_: 'Business',
            _TYPE_PERSONAL_: 'Personal',
            _DELETE_CONFIRM_MESSAGE_: 'Are you sure you want to remove this bank account transactions?',
            _WHY_CONNECT_: 'Pay with a bank account',
            _WHY_CONNECT_CREDIT_: 'Pay with a credit card',
            _CONNECT_COPY_: 'Save time and connect a bank account. No need to worry about expiration dates. Connect it once, and make easy, secure payments to all your project vendors.',
            _CONNECT_CREDIT_COPY_: 'Paying with a credit card is fast, easy and secure. When you add a credit card here, your payments are instant and secured through your issuing bank.',
            _IS_THIS_SECURE_: 'Is this secure?',
            _SECURE_COPY_: 'Our system uses SSL encryption technology, which is the same technology banks use to protect sensitive information.',
            QUICKBOOKS: {
                _QUICKBOOKS_: 'QuickBooks',
                _CONNECTED_: 'Connected',
                _CONNECTED_SINCE_: 'Since: {{since_date}}',
                _DISCONNECT_: 'Disconnect:',
                _DISCONNECT_CONFIRM_: "Are you sure you want to disconnect your QuickBooks account?",
                _DISCONNECT_MESSAGE_: 'Invoices will sync to QuickBooks Online after a payment is received',
                _ACCOUNT_CONNECTED_: 'You are connected to QuickBooks Online',
                _CONNECT_ACCOUNT_: 'Connect your HoneyBook account to QuickBooks Online!',
                _CLICK_TO_CONNECT_: 'Click the button to authorize access to your QuickBooks Online account from HoneyBook',
                _CONNECT_BUTTON_: 'Connect To QuickBooks',
                _QUICKBOOKS_ACCESS_MESSAGE_: 'Providing access will allow your invoices to automatically sync with your QuickBooks Online account whenever a payment is received',
                _TAX_CODE_LABEL_: 'Choose a QuickBooks tax rate to link with your HoneyBook account:',
                _DEPOSIT_ACCOUNT_LABEL_: 'Choose a default QuickBooks account to link with incoming deposits:',
                _ZERO_TAX_CODE_LABEL_: 'Choose a default Zero-rated/Exempt tax rate:',
                _UPDATE_DEPOSIT_ACCOUNT_IN_QB_: 'Don\'t see the account you want to link your deposits to? Update this information in your QuickBooks account under Chart of Accounts',
                _UPDATE_ZERO_TAX_CODE_IN_QB_: 'Canadian accounts must select Zero-taxed / Exempt tax rate to sync HB payments',
                _UPDATE_TAX_IN_QB_: 'Don\'t see any tax rate options? Update this information in your QuickBooks account within the Sales Tax Center',
                _QUESTIONS_: 'Questions? Check out our',
                _FAQ_: 'FAQ',
            },
            QUICKBOOKS_SETTINGS_INTEGRATION: {
                _TITLE_: 'QuickBooks Integration',
                _CONNECT_ACCOUNT_: 'Connect to allow HoneyBook payments to automatically sync to your QuickBooks Online account for easy management.',
                _SMALL_PRINT_: 'Note: QuickBooks Online integration is only available for Simple Start, Essentials, and Plus QuickBooks customers.',
                _CONNECT_BUTTON_: 'Connect',
                _DISCONNECT_BUTTON_: 'Disconnect',
                _DISCONNECT_CONFIRM_: 'Are you sure you want to disconnect your QuickBooks account?',
                _QUESTIONS_: 'Questions? Check out our',
                _FAQ_: 'FAQ',
            },
            FB_LEAD_ADS_SETTINGS_INTEGRATION: {
                _TITLE_: 'Facebook/Instagram Leads Integration',
                _CONNECT_ACCOUNT_: 'Integrate Facebook/Instagram Lead Ads with HoneyBook.<br>' +
                    'Once linked, any new lead created on Facebook or Instagram lead ads will instantly turn into a new HoneyBook inquiry and show up in your pipeline!',
                _SMALL_PRINT_: 'What are Facebook/Instagram Lead Ads?',
                _CONNECT_BUTTON_: 'CONFIGURE LEAD ADS',
                _DISCONNECT_BUTTON_: 'Disconnect',
                _DISCONNECT_CONFIRM_: 'Are you sure you want to disconnect your Facebook/Instagram lead Ads?'
            },
            CHROME_EXTENSION_INTEGRATION: {
                _TITLE_: 'HoneyBook Chrome Extension for Gmail',
                _CONNECT_BUTTON_: 'Get the extension',
                _DETAILS_: 'The HoneyBook Chrome extension lets HoneyBook members manage projects and customer relationships from right inside Gmail while also tracking everything you need in HoneyBook.',
                _FAQ_: 'Learn more'
            },
            ZOOM: {
                _ZOOM_: 'Zoom',
                _CONNECTED_: 'Connected',
                _DISCONNECT_: 'Disconnect:',
                _DISCONNECT_CONFIRM_: "Are you sure you want to disconnect your Zoom account?",
                _ACCOUNT_CONNECTED_: 'You are connected to Zoom',
                _CONNECT_ACCOUNT_: 'Connect your HoneyBook account to Zoom!',
                _CLICK_TO_CONNECT_: 'Click the button to authorize access to your Zoom account from HoneyBook',
                _CONNECT_BUTTON_: 'Connect To Zoom',
                _QUESTIONS_: 'Questions? Check out our',
                _FAQ_: 'FAQ',
            },

            ZOOM_SETTINGS_INTEGRATION: {
                _TITLE_: 'Zoom Integration',
                _CONNECT_ACCOUNT_: 'By integrating with your Zoom account, you will be able to automatically add video conference details when creating a meeting in HoneyBook ',
                _CONNECT_BUTTON_: 'Connect',
                _DISCONNECT_BUTTON_: 'Disconnect',
                _DISCONNECT_CONFIRM_: 'Are you sure you want to disconnect your Zoom account?',
                _QUESTIONS_: 'Questions? Check out our',
                _FAQ_: 'FAQ',
                _REVOKED_ERROR_: 'There is an issue connecting to your Zoom account. To continue using Zoom services, please reconnect using the \'Integrations\' tab on the HoneyBook Company Settings page.'
            },
            _UPLOAD_ID_: 'Identification Document',
            PLACEHOLDERS: {
                _BANK_ACCOUNT_PERSONAL_FIRST_NAME_PLACEHOLDER_: 'My Legal First Name',
                _BANK_ACCOUNT_PERSONAL_LAST_NAME_PLACEHOLDER_: 'My Legal Last Name',
                _BANK_ACCOUNT_BUSINESS_PLACEHOLDER_: 'My Business Name',
                _BANK_ROUTING_: '110000000',
                _BANK_ACCOUNT_NUMBER_: '000123456789',
                _BANK_NAME_: 'Bank Name',
                _PERSONAL_SOCIAL_SECURITY_NUMBER_: '00000000',
                _BUSINESS_SOCIAL_SECURITY_NUMBER_: '0000',
                _TAX_IDENTIFICATION_NUMBER_: '000000000',
                _BUSINESS_NUMBER_: '000000000',
                _DATE_OF_BIRTH_: 'Click to select',
                _UPLOAD_ID_: 'Click to upload'
            },
            HELP: {
                _BANK_ACCOUNT_TYPE_: 'If you select business account, you must provide your business\'s IRS issued TIN/EIN. If you do not have an IRS issued TIN/EIN, then select personal account.',
                _BANK_ACCOUNT_FULL_NAME_: 'Business name associated with your IRS issued TIN/EIN. If you do not have an IRS issued TIN/EIN, then change the account type to: Personal',
                _DATE_OF_BIRTH_: 'Date of birth of the individual or company representative',
                _ADDRESS_FIELD_: 'Address of the individual or business',
                _TAX_IDENTIFICATION_NUMBER_: 'Employer Identification Number / Taxpayer identification number',
                _UPLOAD_ID_: 'Verify your payout method by uploading your passport or driver’s license.'
            },
            ERRORS: {
                _FIELD_IS_REQUIRED_: 'This field is required',
                _ROUTING_NUMBER_: 'Incorrect Routing Number',
                _ACCOUNT_NUMBER_: 'Incorrect Account Number',
                _CA_TRANSIT_NUMBER_: 'Incorrect Transit Number',
                _CA_INSTITUTION_NUMBER_: 'Incorrect Institution Number',
                _CA_ACCOUNT_NUMBER_: 'Incorrect Account Number',
                _SOCIAL_INSURANCE_NUMBER_: 'Incorrect Social Insurance Number',
                _PERSONAL_SOCIAL_SECURITY_NUMBER_: 'Incorrect Social Security Number',
                _BUSINESS_SOCIAL_SECURITY_NUMBER_: 'Incorrect Social Security Number.Please enter last 4 digits OR full SSN',
                _TAX_IDENTIFICATION_NUMBER_: 'Employer Identification Number (EIN) must be 9 digits',
                _BUSINESS_NUMBER_: 'Business number must be 9 digits',
                _MISSING_SSN_OR_TIN_: 'In order to continue receiving payments,',
                _MISSING_TAX_ID_ACTION_BUTTON_: 'please complete',
                _MISSING_SSN_OR_TIN_LAST_: ' your bank information and confirm your bank account name, social security number and tax identification number/ EIN (for business accounts) and photo identification are correct.',
                _MISSING_BANK_ACCOUNT_: 'You need to',
                _MISSING_BANK_ACCOUNT_BUTTON_: 'add',
                _MISSING_BANK_ACCOUNT_LAST: ' your bank account as you have pending payments.',
                _DATE_OF_BIRTH_: 'Incorrect Date of Birth',
                _IDENTIFICAITON_DOCUMENT_MISSING_: 'Please upload photo identification'
            }
        },

        COMPANY_SETTING: {
            ERRORS: {
                _INVALID_PHONE_: 'Please enter a valid phone number',
                _INVALID_WEBSITE_: 'Sure you got the address right?',
                _WEBSITE_ERROR_: 'This address doesn\'t lead anywhere 😳 Check for typos and make sure your website is online',
                _INVALID_COMPANY_: 'Please enter a valid company type',
                _MISSING_COMPANY_NAME_: 'Please enter a company name',
                _INVALID_NOTES_: 'Notes must be under 5000 characters',
                _MISSING_URL_: 'Please enter a valid URL',
                _MISSING_SECURITY_PHONE_NUMBER_: 'Please set your security phone number before updating email',
            },
            HELP_CENTER_LINKS: {
                NOTIFICATIONS: {
                    _SUBTITLE_: 'Select the notifications that you (and team members) will receive via email,',
                    _SUBTITLE_TEXT_1_: 'activity feed and/or notification center.',
                    _HELP_CENTER_LINK_INNER_TEXT_: 'Learn more'
                },
                _ACTIONS_TITLE_: 'Automatically send emails to your clients.',
                _HELP_CENTER_LINK_TEXT_1_: 'Visit our',
                _HELP_CENTER_LINK_INNER_TEXT_: 'Help Center',
                _HELP_CENTER_LINK_TEXT_2_: 'to learn more.',
            },
            BANK_INFO: {
                _TITLE_: 'Receiving Your Payments'
            },

            FILE_NAMES_SETTINGS: {
                _TITLE_: 'Default Name Format',
                _SUB_TITLE_: 'Set a default name format to apply to files that are created via Templates and Automations.',
                FLOWS: {
                    _SUB_TITLE_: 'Set a default name format to apply to smart files that are created via Templates and Automations.',
                    _SUB_TITLE_WITH_FILES_: 'Set a default name format to apply to smart files (or files) that are created via Templates and Automations.',
                    _FLOW_FROM_TEMPLATE_NAME_DESC: 'When creating a file via templates, default the name format to:',
                    _FLOW_OR_FILE_FROM_TEMPLATE_NAME_DESC: 'When creating a file via templates, default the name format to:',
                    _FLOW_FROM_WORKFLOW_NAME_DESC: 'When a file is created via Automations, default the name format to:',
                    _FLOW_OR_FILE_FROM_WORKFLOW_NAME_DESC: 'When a file is created via Automations, default the name format to:',
                },
                OPTIONS: {
                    _FILE_TYPE_: 'File type',
                    _PROJECT_WITH_FILE_TYPE_: 'Project name + File type',
                    _TEMPLATE_NAME_: 'Template name',
                    _PROJECT_WITH_TEMPLATE_NAME_: 'Project name + Template name',
                    _PROJECT_WITH_TEMPLATE_NAME_OR_FILE_TYPE_: 'Project name + Template name (or file type for legacy files)',
                    _PROJECT_WITH_FLOW_OR_FILE_TYPE_: 'Project name + smart file (or file type for legacy files)',
                    _PROJECT_NAME_: 'Project name'
                },
                _TEMPLATE_: 'Template',
                _AUTOMATION_: 'Automation',
                _FILE_FROM_TEMPLATE_NAME_DESC: 'When creating a file via templates, default the name format to:',
                _FILE_FROM_WORKFLOW_NAME_DESC: 'When a file is created via Automations, default the name format to:',
            },
            FLOW_DEFAULT_SETTINGS: {
                _TITLE_: 'Smart File default settings',
                _FLOW_THUMBNAIL_VISIBLE_TO_CLIENTS_TEXT_: 'Display Smart File thumbnail images to clients within projects',
                VISIBILITY_MODAL_CONFIRMATION: {
                    _HEADER_HIDE_TEXT_: 'Hide thumbnails to clients',
                    _HEADER_SHOW_TEXT_: 'Show thumbnails to clients',
                    _SUB_COPY_HIDE_TEXT_: 'This will hide Smart File thumbnail images from clients, including those for previously sent Smart Files.',
                    _SUB_COPY_SHOW_TEXT_: 'This will display Smart File thumbnail images to clients, including those for previously sent Smart Files.',
                    _CANCEL_BTN_: 'Cancel',
                    _CONFIRM_BTN_: 'Confirm'

                }
            },

            SMS_REMINDERS_SETTINGS: {
                _TITLE_: 'SMS reminders',
                _SUB_TITLE_: 'SMS (text) reminders are a great help—they remind clients to show up to meetings and make payments, on time!',
                _SUB_TITLE_LEARN_MORE_LINK_: "Learn about SMS reminders",
                _BUTTON_TEXT_: "Send SMS reminders (free during Beta)",
                _LABEL_STUFF_: "Go to the Scheduler to set & edit session meeting reminders—you can turn them on/off and configure their send times.",
                _LABEL_LINK_: "Learn more",
                _FOOTER_NOTE_: "When SMS reminders are activated, a note about legal consent is added to your contact/lead forms and files so clients can agree to get SMS from you."
            },

            AUTOMATION_TEMPLATE: {
                _VIEW_: 'View your templates',
                REMINDER_COPY: {
                    FLOWS: {
                        _UNACTED_FLOW_: 'Notify me by email if a file I’ve sent has not been signed or paid after {{timeFrame}}',
                        _UNACTED_FLOW_OR_FILE_: 'Notify me by email if a file I’ve sent has not been signed or paid after {{timeFrame}}',
                        _INQUIRY_REMINDER_: "Remind me when I haven’t answered a new lead for more than {{timeFrame}}",
                        _UNREAD_EMAIL_WITH_FLOW_: 'Notify me by email within {{timeFrame}} if my client has not viewed an email I sent with a file',
                        _UNREAD_EMAIL_WITH_FLOW_OR_FILE_: 'Notify me by email within {{timeFrame}} if my client has not viewed an email I sent with a file',
                        _UNSENT_FLOW_: 'Notify me by email if I have not sent a file within {{timeFrame}} of creating it',
                        _UNSENT_FLOW_OR_FILE_: 'Notify me by email if I have not sent a file within {{timeFrame}} of creating it',
                    },
                    _UNREAD_EMAIL_WITH_FILE_: 'Notify me when an email I sent with a file hasn\'t been viewed ',
                    _WITHIN_: 'Within:',
                    _UPCOMING_PROJECT_: 'Notify me by email 1 week prior to my project dates',
                    _UNSENT_FILE_: 'Remind me when I haven\'t sent a file 3 days after creating it',
                    _UNACTED_FILE_: 'Remind me when a file I sent was not booked by the client within a week',
                    _DATE_CONFLICT_: 'Display a notification when projects have conflicting dates',
                    _AUTOMATED_ACTIVITY_EMAILS_: 'Display automated emails that are sent to my clients in my project activity feed'
                },
                ACTION_COPY: {
                    FLOWS: {
                        _DEFAULT_FLOW_OR_FILE_EXPIRATION_TEXT_: 'Expire Smart Files (and proposal / invoice files) {{timeFrame}} after they\'re sent',
                        _DEFAULT_FLOW_EXPIRATION_TEXT_: 'Expire Smart Files {{timeFrame}} after they\'re sent',
                        _DEFAULT_FLOW_OR_FILE_EXPIRATION_PRE: 'Expire Smart Files (and proposal / invoice files)',
                        _DEFAULT_FLOW_EXPIRATION_PRE: 'Expire Smart Files',
                        _PROJECT_FLOW_: 'Send a smart file to my client {{timeFrame}} before the project date',
                        _CLIENT_FILE_OR_FLOW_NOT_VIEWED_: 'Send a reminder to my client if they have not viewed my sent smart file (or file) within {{timeFrame}}',
                        _CLIENT_FLOW_NOT_VIEWED_: 'Send a reminder to my client if they have not viewed my sent smart file within {{timeFrame}}',
                        _CLIENT_SEND_FLOW_: 'Send this smart file:',
                        _CLIENT_FLOW_NOT_VIEWED_EMAIL_: 'With this email:',
                        _SEND_FLOW_BEFORE_PROJECT_: 'Send a smart file to my client {{timeFrame}} before the project starts'
                    },
                    _THANK_YOU_: 'Send my client a thank you email when they submit an inquiry through the contact form',
                    _THANK_YOU_EDIT_: 'When a new inquiry comes in, send this email:',
                    _PROJECT_QUESTIONNAIRE_: 'Send a questionnaire to my client {{timeFrame}} before the project date',
                    _PROJECT_QUESTIONNAIRE_EMAIL_: 'Introduce questionnaire with this email:',
                    _PROJECT_QUESTIONNAIRE_EMAIL_ABBREVIATED_: 'with this email',
                    _PROJECT_QUESTIONNAIRE_BEFORE_: 'before the project date',
                    _PROJECT_QUESTIONNAIRE_QUESTIONNAIRE_: 'Send this questionnaire',
                    _PROJECT_ATTACHMENT_ATTACHMENT_: 'Also attach this to the email:',
                    _PROJECT_ATTACHMENT_NO_ATTACHMENT_: 'No Attachment',
                    _PROJECT_ATTACHMENT_QUESTIONNAIRE_TEMPLATE_: 'Questionnaire template:',
                    _PROJECT_ATTACHMENT_QUESTIONNAIRE_TEXT_: 'A Questionnaire',
                    _PROJECT_ATTACHMENT_BROCHURE_TEMPLATE_: 'Brochure template:',
                    _PROJECT_ATTACHMENT_BROCHURE_TEXT_: 'A Brochure',
                    _CLIENT_FILE_NOT_VIEWED_: 'Send a reminder to my client if they have not viewed my sent file within {{timeFrame}}',
                    _CLIENT_FILE_NOT_VIEWED_EMAIL_: 'with this email:',
                    _DEFAULT_FILE_EXPIRATION_PRE: 'Expire proposals and invoices',
                    _DEFAULT_FILE_EXPIRATION_POST_: 'after they\'re sent',
                    _DEFAULT_FILE_EXPIRATION_TEXT_SMAF_ENABLED_: 'Expire legacy proposals and invoices {{timeFrame}} after they\'re sent',
                    _DEFAULT_FILE_EXPIRATION_TEXT_: 'Expire proposals and invoices {{timeFrame}} after they\'re sent',
                },
                TEMPLATE_TYPES: {
                    _EMAIL_TEMPLATE_: 'email template',
                    _REJECTION_: 'rejection email',
                    _PROJECT_DATE_: 'project date coming up',
                    _THANK_YOU_: 'thank you email',
                    _QUESTIONNAIRE_: 'questionnaire',
                    _ANY_PROJECT_TYPE_: 'Any project type'
                },
                MISSING_TEMPLATE: {
                    _TITLE_: 'Missing template',
                    _QUESTIONNAIRE_: 'In order to enable this action, please first create a questionnaire template.',
                    _BROCHURE_: 'In order to enable this action, please first create a brochure template.',
                    _GO_TO_TEMPLATES_: 'Go to templates',

                },
                PAYMENT_REMINDERS: {
                    _CTA_: 'edit email',
                    _TITLE_: 'Payment reminders',
                    _SUB_TITLE_: 'Write custom payment reminders—they won\'t be sent if payment has been made. Note that some email details are auto-populated and cannot be edited.',
                    UPCOMING: {
                        _LABEL_: 'Upcoming',
                        _DESC_: 'Sent 7 days before due date'
                    },
                    OUTSTANDING: {
                        _LABEL_: 'Overdue +2',
                        _DESC_: 'Sent 2 days after due date'
                    },
                    LONGER_OUTSTANDING: {
                        _LABEL_: 'Overdue +7',
                        _DESC_: 'Sent 7 days after due date'
                    },
                    DUE_DATE: {
                        _LABEL_: 'Due date',
                        _DESC_: 'Sent on the due date'
                    },
                    AUTO: {
                        _LABEL_: 'Recurring/Autopay',
                        _DESC_: 'Sent 1 day before due date'
                    },
                    LATE_FEE: {
                        _LABEL_: 'Late fee',
                        _DESC_: 'Sent 1 day before late fee is charged'
                    },
                    SMS_PAYMENT_REMINDERS: {
                        _LABEL_: 'SMS reminders',
                        _DESC_: 'SMS is sent on the due date and 2 days after a payment due date.'
                    },
                    EDIT_MODAL: {
                        _TITLE_: 'Edit email message',
                        _DESC_UPCOMING_: 'You can edit the default \'payment reminder\' message sent to clients.',
                        _DESC_OUTSTANDING_: 'You can edit the default \'payment overdue\' message sent to clients.',
                        _DESC_LONGER_OUTSTANDING_: 'You can edit the default \'payment overdue\' message sent to clients.',
                        _DESC_DUE_DATE_: 'You can edit the default \'payment due\' message sent to clients.',
                        _DESC_AUTO_: 'You can edit the default \'payment reminder\' message for clients who are set up for recurring/autopay payments.',
                        _DESC_LATE_FEE_: 'You can edit the default \'avoid late fee\' message sent to clients.',
                        _KEEP_GENERAL_: ' Keep messaging generic to apply to all emails of this type.',

                        EMAIL_BODY: {
                            _TITLE_: 'Hi [client name],',
                            _COMPANY_NAME_: 'Company name: ',
                            _COMPANY_CONTACT_: "Company contact: [XXX]",
                            _PROJECT_NAME_: 'Project name: [XXX]',
                            _DATE_: 'Due date [MM/DD/YYYY]',
                            _AMOUNT_: 'Amount: [$XX]',
                            _PAYMENT_METHOD_: 'Payment method: [XXXX]',
                            _LATE_FEE_: 'Important: Late payment will result in an additional [X]% late fee. Please pay on time to avoid this additional charge.',
                            _LATE_FEE_TOMORROW_: 'Important: Tomorrow a [X]% late fee will be added to your overdue payment. Please pay today to avoid this additional charge.'
                        },

                        _PAY_NOW_: 'PAY NOW',
                        _VIEW_PAYMENT_: 'VIEW PAYMENT',
                        _RESET_: 'Reset to default message',

                        TOOLTIPS: {
                            _CTA_: 'Button color can be changed from the "Company Brand" tab in Settings.',
                            _EDIT_INFO_: 'Dates and amounts are auto-filled and cannot be edited.',
                            _LATE_FEE_: 'Late-fee disclaimer will be included in emails only if you activate the late-fee toggle option.'
                        },

                        DEFAULTS: {
                            UPCOMING: {
                                _TITLE_: 'Your upcoming payment',
                                _BODY_: 'This is a reminder that you have a payment coming up. You can view the details and click the button below to make your payment now.'
                            },

                            OUTSTANDING: {
                                _TITLE_: 'Your payment is overdue',
                                _BODY_: 'This is a follow-up to remind you of your payment that is past due. Please take a moment to view the details and click the button below to make your payment now.'
                            },

                            LONGER_OUTSTANDING: {
                                _TITLE_: 'Your payment is overdue',
                                _BODY_: 'This is a follow-up to remind you of your payment that is past due. Please take a moment to view the details and click the button below to make your payment now.'
                            },

                            DUE: {
                                _TITLE_: 'Your payment is due',
                                _BODY_: 'This is to remind you that you have a payment due today. Please take a moment to view the details and click the button below to make your payment now.'
                            },

                            LATE_FEE: {
                                _TITLE_: 'Late fee will be charged tomorrow',
                                _BODY_: 'This is a follow-up to remind you of your payment that is past due. Please take a moment to view the details and click the button below to make your payment now.'
                            },

                            AUTO_RECURRING: {
                                _TITLE_: 'Your upcoming payment',
                                _BODY_: 'This is a reminder that you have a payment coming up that will be automatically processed. You can view the details of the payment below.'
                            }
                        }
                    }
                }
            },
            _PERSONAL_SETTINGS_TITLE_: 'MY SETTINGS',
            _MY_ACCOUNT_: 'MY ACCOUNT',
            MY_ACCOUNT: {
                _VIEW_MY_PROFILE_: 'VIEW MY PROFILE',

                NAVIGATION: {
                    _NAVIGATION_: 'Navigation',
                    _TOGGLE_BETWEEN_NAVS_: 'Toggle between sidebar navigation or top-bar navigation'
                },
                BADGE: {
                    LABELS: {
                        _HEADER_: 'Get Your HoneyBadge',
                        _COPY_CODE_: 'Copy the code below and paste it anywhere on your site where you have access to the source code or use it as an email signature. The badge will link to your public profile page.'
                    }
                }
            },
            _COMPANY_TITLE_: 'COMPANY SETTINGS',
            DATA_MANAGEMENT: {
                _TITLE_: 'Data management',
                _SUBTITLE_: 'Set how you collect and organize project and contact details for your business.',
                CONTACT_ENRICHMENT: {
                    _TITLE_: 'Client data enrichment',
                    _SUB_TITLE_: 'Automatically enrich contact details when adding new contacts.'
                }
                },
            _COMPANY_NOTIFICATIONS_: 'Company Notifications',
            _OVERVIEW_TAB_: 'COMPANY',
            _GALLERY_TAB_: 'LIBRARY',
            _WIDGET_TAB_: 'CONTACT FORM',
            _CONTACT_FORM_TAB_: 'CONTACT FORM',
            _BANK_TAB_: 'FINANCIALS',
            _TEMPLATES_TAB_: 'TEMPLATES',
            _SUBSCRIPTION_TAB_: 'MEMBERSHIP',
            _AUTOMATION_TAB_: 'AUTOMATION',
            _TEAM_TAB_: 'TEAM',
            _REFERRALS_TAB_: 'REFERRALS (NEW)',
            _SETUP_TAB_: 'SETUP',
            NOTIFICATION_CENTER: {
                _TITLE_: 'Notification center',
                _SUBTITLE_: 'Select the types of notifications you\'d like to receive in your notification center'
            },
            CLIENT_FACING: {
                _TITLE_: 'Client-facing Preferences',
                _SUBTITLE_: 'Customize the reminders and actions that will be visible to clients and directly affect their experience.'
            },
            GALLERY: {
                _ERROR_UPLOADING_FILE_: 'Oops, there seems to be a problem with the file upload. Please try again.',
                _CAN_NOT_REMOVE_LAST_IMAGE_MESSAGE_: 'Hey, looks like you\'re trying to delete the last image in your library, but your library shouldn\'t be empty! Please add other images before deleting this one.'
            },
            SETUP: {
                _NOT_AVAILABLE_: 'Free account set up is a one-time service and can take up to {{time_to_upload_materials}}.' + '<br> Please contact ' + '<a href=\"mailto:concierge@honeybook.com\" style=\"color:#131416;\">concierge@honeybook.com</a>' + ' with any questions about your account.',
                _INTRO_HEADER_1_: "Pricing and Services",
                _INTRO_HEADER_1_NEW_COPY_: "Upload service & pricing details",
                _INTRO_HEADER_2_: "Questionnaires",
                _INTRO_HEADER_2_NEW_COPY_: "Upload questionnaires",
                _INTRO_HEADER_3_: "Contracts",
                _INTRO_HEADER_3_NEW_COPY_: "Upload contracts",
                _INTRO_HEADER_4_: "List your unpaid invoices",
                _INTRO_HEADER_4_NEW_COPY_: "List unpaid and upcoming invoices",
                _INTRO_TEXT_1_: "Upload any pricing or service lists you’d like us to build into your account as templates.",
                _INTRO_TEXT_1_NEW_COPY_: "Do you have a price list, packages, or a brochure? Great!<br />With HoneyBook files, it’ll be easy for clients to see & select your services.",
                _INTRO_TEXT_2_: "Upload any questionnaires you’d like us to build into your account as templates.",
                _INTRO_TEXT_2_NEW_COPY_: "Have a list of questions you like to ask clients? Add them here.<br /> You’ll get back a smart, interactive way to collect info on leads and clients.",
                _INTRO_TEXT_3_: "Upload any contracts you’d like us to build into your account as templates.",
                _INTRO_TEXT_3_NEW_COPY_: " Use contracts, agreements, release forms, or something else?<br /> Now you’ll have signature-ready docs that can be auto-filled with client details and summaries.",
                _INTRO_TEXT_4_: "Give us the details of any unpaid invoices and we’ll create ready-to-use invoices for you.",
                _INTRO_TEXT_4_NEW_COPY_: "We’ll take your list of open invoices and create active projects in HoneyBook.<br /> You’ll decide when you want to send the draft invoices to your clients.",
                _CTA_1_: 'Upload Services',
                _CTA_1_NEW_COPY_: 'Upload Files',
                _CTA_2_: 'Upload Questionnaires',
                _CTA_2_NEW_COPY_: 'Upload Files',
                _CTA_3_: 'Upload Contracts',
                _CTA_3_NEW_COPY_: 'Upload Files',
                _CTA_4_: 'Upload Spreadsheet',
                _CTA_4_NEW_COPY_: 'Upload Completed Spreadsheet',
                _UPLOADER_TOOLTIP_: "Download & complete the spreadsheet, then upload the completed file.",
                _UPLOADER_TOOLTIP_NEW_COPY_: "Download & fill the spreadsheet, then upload the completed file.",
                _UPLOADER_TITLE_4_: 'Then, upload the invoice spreadsheet',
                _UPLOADER_SUBTITLE_4_: 'Drag & drop or upload the spreadsheet.<br/>We’ll create invoices that are ready-to-use.',
                _UPLOADER_SUBTITLE_4_NEW_COPY_: 'Or, drag & drop',
                _UPLOADER_FILE_TYPE_4_: 'Spreadsheet must be in .csv or .xlsx format.',
                _UPLOADER_FILE_TYPE_4_NEW_COPY_: 'File format: csv or xlsx',
                _DOWNLOAD_TITLE_4_: 'First, gather & add invoice details',
                _DOWNLOAD_SUBTITLE_4_: 'Download the invoice spreadsheet.<br/>Add details to the preset columns.',
                _DOWNLOAD_SUBTITLE_4_NEW_COPY_: 'The column titles will guide you in<br /> adding needed invoice details.',
                _DOWNLOAD_TEXT_4_: 'Download spreadsheet',
                _DOWNLOAD_TEXT_4_NEW_COPY_: 'Download invoice spreadsheet',
                _UPLOAD_BIG_TEXT_: "Drop your templates here",
                _UPLOAD_SMALL_TEXT_: "Contract, Invoices, Questionnaires, Brochure, Pricing, PDF, Packages ",
                _UPLOADED_FILES_HEADER_: "Your uploaded files",
                _UPLOADED_IMAGES_HEADER_: "My Images",
                _FOOTER_DONE_HEADER_: "Done Uploading?",
                _FOOTER_DONE_TEXT_: "Click \"set up my account\" to have us build your account. <br> We'll notify you by email once it's ready!",
                _SET_ME_UP_BTN_: "Set up my account",
                _SET_ME_UP_BTN_NEW_COPY_: "Submit Files",
                _DOWNLOAD_INVOICE_CSV: "DOWNLOAD INVOICE IMPORT CSV",
                _FOOTER_CONFIRM_HEADER_: "GREAT!",
                _FOOTER_CONFIRM_TEXT_: "Great, your account is being set up! <br> In the meantime, why not <a class='underline' href='/app/contacts'>invite your colleagues </a> or <a class='underline' href='/app/settings/company/library'>upload your images?</a>",
                _GOT_IT_BTN_: "Got it",
                _DRAG_AND_DROP_NOT_SUPPORTED_: 'File Drag/Drop is not supported for this browser',
                _ERROR_UPLOADING_FILE_: 'Oops, there seems to be a problem with the file upload. Please try again.',
                _CHOOSE_FILES_: 'Choose Files',
                _UPLOAD_SERVICES: 'UPLOAD SERVICES',
                _UPLOAD_FILES: 'UPLOAD FILES',
                _NEXT_: 'Next',
                _SKIP_: 'Skip',
                _DRAG_: 'Drag into this screen to upload',
                _DRAG_NEW_COPY_: 'Drag & drop or upload files',
                _PREVIOUS_: 'Back',
                _DONE_: 'Next: Review and Upload',
                _REVIEW_UPLOADS_: 'Almost there! Review your files below to make sure we have everything.',
                _REVIEW_UPLOADS_NEW_COPY_: 'Added all your go-to files? Submit them and let the transformation begin!',
                _TIME_TO_SETUP_ACCOUNT_: 'Now it\'s time to setup your account. Upload your materials and we\'ll help you build your account for free.',
                _SETUP_ACCOUNT_: 'Set up My Account',
                _FILE_LIMIT_TITLE_: "You've reached the limit for this file type",
                _FILE_UPLOADED_SUCCESSFULLY_TITLE_: "File uploaded successfully",
                _FILE_UPLOADED_SUCCESSFULLY_SUBTITLE_: "In about {{time_to_upload_materials}} you’ll have projects with draft<br/>invoices waiting in your HoneyBook account.",
                _FILE_LIMIT_SUBTITLE_: 'Continue to other file typ"es or contact us for further assistance.',
                _FILE_LIMIT_SUBTITLE_CONTACT_: 'please contact',
                _FILE_LIMIT_MODAL_TEXT_: "You've reached the limit for this file type. Continue to other file types or contact us for further assistance.",
                _FILE_WAIT_: 'Please wait for all files to finish upload and try again',
                _NO_FILES_: "You haven't uploaded any files. Please choose some files and try again",
                _FILE_ERROR_: "An error occurred while trying to submit your files. Please try again or contact us if the error persists",
                _LATER_: 'Not now',
                _WELCOME_TITLE_: 'How to get your HoneyBook smart templates',
                _WELCOME_SUBTITLE_: 'It’s as easy as 1–2–3. You upload. We transform. You get smart templates!',
                _WELCOME_STEP_1_TITLE_: 'Gather & upload your files',
                _WELCOME_STEP_1_SUBTITLE_: 'Price/Service lists, brochures,<br/>proposals, contracts, questionnaires,<br/>& open invoice info.',
                _WELCOME_STEP_2_TITLE_: 'We do the work',
                _WELCOME_STEP_2_SUBTITLE_: 'Our team will prepare smart templates and draft invoices in about {{time_to_upload_materials}}.',
                _WELCOME_STEP_3_TITLE_: 'Start using your smart templates',
                _WELCOME_STEP_3_SUBTITLE_: 'And track active clients in your<br/>new HoneyBook projects.',
                _WELCOME_BTN_TEXT_: 'LET’S GET STARTED',
                _WELCOME_LINK_: 'What can I upload?',

                _SUCCESS_STEP_TITLE_: 'We got your files',
                _SUCCESS_STEP_SUBTITLE_: 'Our team will have your HoneyBook smart templates and projects with draft invoices<br/>ready in about {{time_to_upload_materials}}—we’ll notify you by email.',
                _SUCCESS_STEP_REMAINING_DAYS_: 'The uploaded files will also be transferred by our migration team.<br/>Please note you can upload additional files once more during the next {{daysLeft}}.',
                _SUCCESS_STEP_MAIN_TEXT: 'Download this simple csv and input any invoices with upcoming payments. Once complete, email it to <a href="mailto:concierge@honeybook.com">concierge@honeybook.com</a> </br> We’ll transfer your invoices into your account, and save them as drafts',
                _SUCCESS_STEP_CTA: 'DOWNLOAD INVOICE IMPORT CSV',
                _SUCCESS_STEP_UPLOAD_TEXT_: 'Found more files or open invoices?',
                _SUCCESS_STEP_UPLOAD_LINK_: 'Upload now',
                _SUCCESS_STEP_PENDING: {
                    _TITLE_: "Your files are being smartened up",
                    _SUBTITLE_: 'We’ll email you when your HoneyBook smart templates and projects with draft invoices are ready.<br />Meanwhile, get some inspiration...',
                    _SUBTITLE_2_: 'In the meantime, you can explore the <a href="/app/template-gallery">template gallery</a> for inspiration.',
                    _CTA_: 'Explore Template Gallery'
                },
                _SUCCESS_STEP_PARTIAL: {
                    _TITLE_: "You’ve got smart templates ready & waiting!",
                    _SUBTITLE_: 'While we finish up the rest of them—go to Projects to find your draft invoices<br /> and <a href="/app/my-templates">My Templates</a> to explore your new smart templates.',
                    _SUBTITLE_2_: 'you can start using those that are ready. <a href="/app/my-templates">View my templates.</a>',
                    _CTA_: 'Go to my templates'
                },
                _SUCCESS_STEP_COMPLETE: {
                    _TITLE_: "Your templates & draft invoices are ready for use! ",
                    _SUBTITLE_: 'Your draft invoices, they were added to Projects<br /> And your new smart templates, you’ll find them in My Templates',
                    _CTA_: 'Go to my templates'
                },

                _TITLE_DONE_SCREEN_: 'Great, we\'re building your account...',
                _TITLE_DONE_SCREEN_2: 'we\'re building your account as we speak...',
                _SUBTITLE_DONE_SCREEN_: 'Our team will have your account built within {{time_to_upload_materials}}',
                _TEXT_DONE_SCREEN_: 'Feel free to handle other business in HoneyBook and we’ll notify you when it’s complete.',
                _TEXT_DONE_SCREEN_2: 'We\'ll notify you via email when your materials are ready. until then, continue exploring!',
                _BUTTON_DONE_SCREEN_: "Ok, got it",
                _FILE_TYPES_: "Up to 15MB per file in doc, png, jpeg, or pdf format.",
                _INVOICE_CSV_: "The file should be the CSV file you have downloaded",
                _UPLOAD_MORE_: "Upload more",
                _UPLOAD_MATERIALS_: "Get Started",
                _REVIEW_AND_SUBMIT_: "Review & submit your business files",
                _REVIEW_AND_SUBMIT_NO_FILES_: "Still gathering your business files?",
                _NO_FILES_YET_: "Don’t miss out! Upload your business files within 14 days<br /> so our team can transform them into HoneyBook smart templates.",
                _POPUP_TEXT_: "Click here to continue your account setup.",
                DISABLED: {
                    _HEADER_: "Subscribe and we'll turn your files into interactive,<br/>reusable templates—for FREE",
                    _SUBHEADER_: "Time-saving win-win! Enjoy an efficient clientflow while using your new, interactive smart templates.",
                    _LINK_: "Learn more",
                    _CTA_: "Pick a plan",
                    _SMALL_PRINT_: "All plans include transforming your business files—for FREE.",
                    FAQS: {
                        _FAQ_HEADER_: "How it works",
                        FAQ_1: {
                            _NAME_: "HowFilesTurnedIntoHoneyBook",
                            _QUESTION_: "How do I get my business files turned into HoneyBook files?",
                            _ANSWER_: "<p>It's super easy! Just send us your files and, in about {{time_to_upload_materials}}, you’ll get new ones back. <b>Note!</b> You’ve got 14 days to send your docs but, why wait, start getting the most out of Honeybook right away.</p><p><b>What to do</b></p><ol><li>Upload files related to pricing & services, questionnaires, contracts, and invoices.</li><li>HoneyBook team transforms your files into interactive, smart templates.</li><li>Within {{time_to_upload_materials}}, you’ll be emailed a link to your Templates section where you’ll discover your new templates.</li></ol><p>Your new smart templates are a starting point—turn it into a project smart file and use as is or tweak it to fit a specific project. Fixes you make to a project smart file don’t affect the original smart template.</p>",
                        },
                        FAQ_2: {
                            _NAME_: "WhatTypes",
                            _QUESTION_: "What types of files can I upload?",
                            _ANSWER_: "<p>We’ll take your client-facing brochures, contracts, questionnaires, and more and transform them into smart HoneyBook templates. Your files should be in doc, png, jpeg, or pdf format and up to 15MB per file.</p><p>Gather up files you use for these categories...</p><ul><li><b>Pricing: </b>Brochures, proposals, or any docs that detail item/package prices</li><li><b>Contracts: </b>Contracts, agreements, permits, or contract language you use</li><li><b>Questions: </b>Questionnaires, forms, or a list of questions you use</li></ul>"
                        },
                        FAQ_3: {
                            _NAME_: "ExportFilesFromServiceToHoneyBook",
                            _QUESTION_: "Can I export files from my current service to HoneyBook?",
                            _ANSWER_: "<p>There’s no direct way, but go to the Help Center of your current platform service to learn how to export your info and save content as PDFs.</p><p>Then, you’ll upload these files and the HoneyBook team will transform them into smart templates and files.</p>"
                        },
                        FAQ_4: {
                            _NAME_: "HowMuchCost",
                            _QUESTION_: "How much does it cost to turn my files into HoneyBook files?",
                            _ANSWER_: "<p>It’s FREE! This 1-time service is included in your subscription plan. We want you to focus on the business you love, so we’re here to prepare ready-to-use templates and files for you.</p>"
                        },
                        FAQ_5: {
                            _NAME_: "UploadLater",
                            _QUESTION_: "Can I upload my files later?",
                            _ANSWER_: "<p>Yes, this service is available for the first 14d after you subscribe, you can get back to this screen from your home dashboard, where the setup guide is.</p>"
                        }

                    },
                },
            },
            WIDGET: {
                _TITLE_: 'Generate a contact form for your website.',
                _EXPLANATION_: 'Send new inquiries straight into the HoneyBook Pipeline.',
                _ADD_URL_: 'Before installing the code, go to the Settings Overview page and make sure the Company Website field has been filled out.',
                _UPDATE_URL_: 'Before installing the code go to the <a href="/app/settings/company/general">Settings Overview</a> page and make sure the <strong>Company Website</strong> field has been filled out.',
                _PUBLISH_: 'Save & Publish Changes',
                _COLOUR_LABEL_: 'Choose Color:',
                _EMBED_THE_CONTACT_FORM_: 'Embed the contact form',
                _GET_CODE_BUTTON_: 'Get Code',
                _CHANGES_PUBLISHED_TEXT_: 'Changes were published successfully',
                _CHANGES_PUBLISHED__TOAST_TEXT_: 'Changes to your contact form were published successfully!',
                _CHANGES_PUBLISHED__TOAST_TEXT_GIFT_CARD_: 'Changes to your gift card form were published successfully!',
                _CHANGES_PUBLISHED__TOAST_TEXT_INACTIVE_GIFT_CARD_: 'Changes to your <b>Inactive Gift Card</b> were published successfully! But no one’s gonna see them 😬',
                _CHANGES_RESET__TOAST_TEXT_: 'Your changes have been successfully reverted!',
                _NEED_TO_PUBLISH_TRADE_SHOW_: 'Please click on Add to Website in order to get your contact form\'s direct link',
                _SPAM_PROTECTION_OFF_: 'Google Spam Protection protects you against spam and other types of automated abuse. We highly recommend you to keep it ON',
                _HELP_MESSAGE_: 'We\'re here to help! Please feel free to reach out to ' +
                    '<a href="mailto:concierge@honeybook.com" style="color:#131416;">concierge@honeybook.com</a>  if you need any assistance.',
                FAQ: {
                    WORDPRESS: {
                        _QUESTION_: 'How do I install the form on a WordPress website?',
                        _A1_: 'Locate the page in which you want to install the form.',
                        _A2_: 'Click on the <strong>Text</strong> tab on the top right of the text editor.',
                        _A3_: 'Paste the code into the page where you want it to appear.',
                        _A4_: 'For more information about adding HTML see the <a href="https://en.support.wordpress.com/beginning-html/" target="_blank">Wordpress user guide</a>'
                    },
                    SQUARE_SPACE: {
                        _QUESTION_: 'How do I install the form on a SquareSpace website?',
                        _A1_: 'Locate the page you want to install the form.',
                        _A2_: 'Click on the <strong>Insert point</strong> button to open the block menu.',
                        _A3_: 'In the block menu, scroll down and select <strong>code</strong>.',
                        _A4_: 'Paste the code into the new code block.',
                        _A5_: 'For more information about adding HTML to code blocks see the <a href="http://help.squarespace.com/guides/using-the-code-block" target="_blank">SquareSpace user guide</a>'
                    },
                    WIX: {
                        _QUESTION_: 'How do I install the form on a Wix website?',
                        _A1_: 'Go to the <strong>Wix editor</strong>.',
                        _A2_: 'Click on the <strong>(+) Add button</strong> in the left then select <strong>Apps</strong>.',
                        _A3_: 'Under the Apps, select <strong>HTML</strong>.',
                        _A4_: 'Click inside the HTML section in the page.',
                        _A5_: 'Select <strong>Settings</strong> from the menu.',
                        _A6_: 'In the HTML settings window select <strong>HTML code</strong> in the <strong>Mode</strong> dropdown menu.',
                        _A7_: 'Paste the code in the <strong>HTML/embed code</strong> section and click <strong>Update</strong>.',
                        _A8_: 'For more information about adding HTML code see the <a href="https://www.wix.com/support/html5/wix-app-market/apps-made-by-wix/kb/html-app#anchor-adding-html-code2" target="_blank"> Wix help section</a>'
                    },
                    SHOWIT: {
                        _QUESTION_: 'How do I install the form on a Showit website?',
                        _A1_: 'Locate the page or style group you want to install the form.',
                        _A2_: 'Go to the <strong>Style</strong> tab and click on the <strong>Advanced</strong> button at the bottom.',
                        _A3_: 'Click on the <strong>HTML Template</strong> tab.',
                        _A4_: 'Paste the code in the HTML section and click <strong>Apply</strong>.',
                        _A5_: 'For more information about adding HTML code see the <a href="http://wiki.showitfast.com/HTML_Template" target="_blank"> Showit help section</a>'
                    }
                },
                FORM: {
                    _ALERT_DATA_SENT_: 'Thank You!',
                    LABELS: {
                        _FULL_NAME_: 'Full Name',
                        _EMAIL_: 'Email Address',
                        _PHONE_: 'Phone',
                        _PROJECT_DATE_: 'Project Date',
                        _LOCATION_: 'Location',
                        _GUESTS_: 'Estimated Guests?',
                        _BUDGET_: 'Estimated Budget?',
                        _DETAILS_: 'Tell us about your project.',
                        _SERVICES_: 'What services are you interested in?',
                        _HEAR_ABOUT_: 'How did you hear about us?'
                    },
                    PLACEHOLDERS: {
                        _EMAIL_: 'Email Address',
                        _FULL_NAME_: 'Full Name',
                        _PHONE_: 'Phone',
                        _PROJECT_DATE_: 'MM/DD/YY',
                        _LOCATION_: 'Location',
                        _GUESTS_: 'Estimated Guests?',
                        _BUDGET_: 'Estimated Budget?',
                        _DETAILS_: 'Tell us about your project.',
                        _SERVICES_: 'What services are you interested in?',
                        _HEAR_ABOUT_: 'How did you hear about us?'
                    },
                    _SEND_BUTTON_: 'Send'
                },
                EMBED: {
                    LABELS: {
                        _EMBED_CONTACT_FORM_: 'Embed the Contact Form',
                        _ADD_TO_WEBSITE_: 'Add to your website',
                        _COPY_CODE_LINE_BREAK_: 'Copy the code below and paste it anywhere on your site <br /> where you have access to the source code',
                        _COPY_CODE_: 'Copy the code below and paste it anywhere on your site where you have access to the source code',
                        _COPY_CLIPBOARD_: 'COPY CODE',
                        _PUBLIC_URL_: 'Open Link',
                        _COPY_EMBED_CODE_: 'VIEW EMBED CODE',
                        _NEED_HELP_: 'Need Help?',
                        _VISIT_HELP_CENTER_: 'Visit our help center',
                        _CHAT_WITH_US_: 'Chat with us',
                        _Email_US_: 'Email us',
                        _FILL_COMPANY_URL_: 'Fill it out now',
                        _MORE_QUESTIONS_: 'Have more questions? ',
                        _GET_PUBLIC_URL_: 'Get a Direct Link',
                        _GENERATE_URL_TEXT_: 'No embedding necessary!<br /> Share with your clients instantly',
                        _VIEW_SHARING_OPTIONS_: 'View sharing options',
                        _COPY_LINK_: 'Copy link address',
                        _ADD_CODE_TO_BODY_: 'Paste this code into your site to embed',
                        _COPY_CODE_WITH_STEPS: '1. Copy the code below',
                        _PASTE_CODE_WITH_STEPS: '2. Paste right into your website’s code to embed!',
                        _YOU_HAVE_UNPUBLISHED_: 'To update your live Contact Form click',
                        _RE_EMBED_FORM_: 'To publish the new features, re-embed the form.',
                        _CF_TAKEOVER_TITLE_: 'Your existing Contact\nForm hasn’t changed yet!',
                        _CF_TAKEOVER_DESCRIPTION_: 'To make sure your published form includes the new features,\nyou’ll need to embed the code again—just this once!',
                        _CF_TAKEOVER_SECONDARY_: 'Not right now',
                        _CF_TAKEOVER_PRIMARY_: 'Next',
                        _YOUR_CONTACT_FORM_ACTIVE_: 'Your Contact Form is active! Leads will appear in your Pipeline',
                        _YOUR_CONTACT_FORM_CREATED_: 'Your Contact Form has been successfully created',
                        _GET_LEADS_OUT_OF_WEBSITE_: 'Get leads from outside your site — without embedding!',
                        _VIEW_OPTIONS_: 'VIEW OPTIONS'
                    }
                }
            },

            LABELS: {
                _COMPANY_NAME_: 'Company Name',
                _COMPANY_TYPE_: 'Company Type',
                _ABOUT_COMPANY_: 'About your company',
                _EMAIL_SIGNATURE_: 'Email signature',
                _MORE_INFO_: 'More info',
                _OTHER_COMPANY_TYPE_: 'Other Company Type',
                _COMPANY_HB_URL_: 'HoneyBook Contact Form URL',
                _COMPANY_WEBSITE_: 'Company Website',
                _HONEYBOOK_COMPANY_URL_: 'HoneyBook Company Url',
                _ESTABLISHED_AT_: 'Year of Establishment',
                _COMPANY_DATA_: 'Company Data',
                _NO_OF_EMPLOYEES_: 'Number of Employees',
                _PHONE_: 'Phone Number',
                _EMAIL_: 'Company Email',
                _ADDRESS_: 'Address',
                _CITY_ADDRESS_: 'City',
                _STATE_: 'State',
                _PROVINCE_: 'Province',
                _COUNTRY_: 'Country',
                _ZIP_CODE_: 'Zip Code',
                _POSTAL_CODE_: 'Postal Code',
                _TIMEZONE_: 'Timezone',
                _STREET_ADDRESS_: 'Street Address',
                _ABOUT_ONE_LINE_: 'One Line',
                _ABOUT_PARAGRAPH_: 'Paragraph',

                _TYPE_PLANNING_: 'Planning',
                _TYPE_EVENT_PLANNING_: 'Event Planning',
                _TYPE_BAKERY_: 'Bakery / Dessert',
                _TYPE_VENUE_: 'Venue',
                _TYPE_PHOTOGRAPHY_: 'Photography',
                _TYPE_RENTALS_: 'Rentals / Photo booths',
                _TYPE_HAIR_MAKEUP_: 'Hair & Makeup',
                _TYPE_FLORAL_: 'Floral Design',
                _TYPE_MUSIC_: 'DJ / Entertainment',
                _TYPE_FOOD_DRINKS_: 'Food & Drinks',
                _TYPE_STATIONERY_: 'Stationery',
                _TYPE_ATTIRE_: 'Fashion / Accessories',
                _TYPE_CINEMATOGRAPHY_: 'Videography',
                _TYPE_PROJECT_LIGHTING_: 'Lighting / AV',
                _TYPE_OFFICIANT_: 'Officiant',
                _TYPE_PHOTOBOOTH_: 'Photo Booth',
                _TYPE_OTHER_: 'Other',
                _TYPE_MARKETING_: 'Marketing / PR',
                _TYPE_MARKETING_CONSULTING_: 'Marketing Consulting',
                _TYPE_MARKETING_AGENCY_: 'Marketing Agency',
                _TYPE_DIGITAL_MARKETING_: 'Digital Marketing',
                _TYPE_CONTENT_CREATION_: 'Content Creation',
                _TYPE_COPYWRITING_: 'Copywriting',
                _TYPE_PUBLIC_RELATIONS_: 'Public Relations',
                _TYPE_VISUAL_ARTIST_: 'Visual Art',
                _TYPE_BLOGGER_: 'Blogging',
                _TYPE_BUSINESS_CONSULTANT_: 'Business Consulting',
                _TYPE_CONSULTANT_: 'Consulting',
                _TYPE_CALLIGRAPHY_: 'Calligraphy',
                _TYPE_INTERIOR_DESIGNER_: 'Interior Design',
                _TYPE_GRAPHIC_DESIGNER_: 'Graphic Design',
                _TYPE_MAKER_ARTISAN_: 'Maker / Artisan',
                _TYPE_WEB_DESIGNER_: 'Web Design',
                _TYPE_HEALTH_WELLNESS_: 'Health & Wellness',
                _TYPE_BOOKKEEPING_ACCOUNTING_: 'Bookkeeping / Accounting',
                _TYPE_COACHING_: 'Coaching',
                _TYPE_CATERING_: 'Catering',
                _TYPE_AGENCY_FRANCHISE_: 'Agency / Franchise',
                _TYPE_DOULA_: 'Doula',
                _TYPE_LAWN_LANDSCAPING_: 'Lawn / Landscaping',
                _TYPE_EDUCATION_TUTORING_: 'Education / Tutoring',
                _TYPE_CLEANING_MAINTENANCE_: 'Cleaning / Maintenance',
                _TYPE_FREELANCE_: 'Freelance',
                _TYPE_CONTRACTOR_: 'Contractor / Home Improvement',
                _TYPE_VIRTUAL_ASSISTANT_: 'Virtual Assistant',
                _TYPE_REAL_ESTATE_: 'Real Estate',
                _TYPE_SOCIAL_MEDIA_: 'Social Media',

                _TYPE_INFORMAL_PLANNING_: 'planning',
                _TYPE_INFORMAL_BAKERY_: 'bakery',
                _TYPE_INFORMAL_VENUE_: 'venue',
                _TYPE_INFORMAL_PHOTOGRAPHY_: 'photography',
                _TYPE_INFORMAL_RENTALS_: 'rental',
                _TYPE_INFORMAL_HAIR_MAKEUP_: 'hair and makeup',
                _TYPE_INFORMAL_FLORAL_: 'florist',
                _TYPE_INFORMAL_MUSIC_: 'entertainment',
                _TYPE_INFORMAL_FOOD_DRINKS_: 'food and drink',
                _TYPE_INFORMAL_STATIONERY_: 'stationery',
                _TYPE_INFORMAL_ATTIRE_: 'fashion',
                _TYPE_INFORMAL_CINEMATOGRAPHY_: 'videography',
                _TYPE_INFORMAL_PROJECT_LIGHTING_: 'lighting and AV',
                _TYPE_INFORMAL_OFFICIANT_: 'officiant',
                _TYPE_INFORMAL_PHOTOBOOTH_: 'photo booth',
                _TYPE_INFORMAL_OTHER_: 'other',
                _TYPE_INFORMAL_MARKETING_: 'marketing',
                _TYPE_INFORMAL_MARKETING_CONSULTING_: 'marketing consulting',
                _TYPE_INFORMAL_DIGITAL_MARKETING_: 'digital marketing',
                _TYPE_INFORMAL_CONTENT_CREATION_: 'content creation',
                _TYPE_INFORMAL_COPYWRITING_: 'copywriting',
                _TYPE_INFORMAL_PUBLIC_RELATIONS_: 'public relations',
                _TYPE_INFORMAL_VISUAL_ARTIST_: 'visual art',
                _TYPE_INFORMAL_BLOGGER_: 'blogging',
                _TYPE_INFORMAL_BUSINESS_CONSULTANT_: 'consulting',
                _TYPE_INFORMAL_CALLIGRAPHY_: 'calligraphy',
                _TYPE_INFORMAL_INTERIOR_DESIGNER_: 'interior design',
                _TYPE_INFORMAL_GRAPHIC_DESIGNER_: 'graphic design',
                _TYPE_INFORMAL_MAKER_ARTISAN_: 'artisan',
                _TYPE_INFORMAL_WEB_DESIGNER_: 'web design',
                _TYPE_INFORMAL_BOOKKEEPING_ACCOUNTING_: 'accounting',
                _TYPE_INFORMAL_CATERING_: 'catering',
                _TYPE_INFORMAL_COACHING_: 'coaching',
                _TYPE_INFORMAL_HEALTH_WELLNESS_: 'wellness',
                _TYPE_INFORMAL_AGENCY_FRANCHISE_: 'agency',
                _TYPE_INFORMAL_DOULA_: 'doula',
                _TYPE_INFORMAL_CONTRACTOR_: 'contractor',
                _TYPE_INFORMAL_FREELANCER_: 'freelance',
                _TYPE_INFORMAL_VIRTUAL_ASSISTANT_: 'virtual assistant',
                _TYPE_INFORMAL_REAL_ESTATE_: 'real estate',
                _TYPE_INFORMAL_LAWN_LANDSCAPING_: 'landscape',
                _TYPE_INFORMAL_CLEANING_MAINTENANCE_: 'cleaning',
                _TYPE_INFORMAL_SOCIAL_MEDIA_: 'social media',
                _TYPE_INFORMAL_EDUCATION_TUTORING_: 'education',

                _SELECT_: 'Select...',
                _CHOOSE_TEMPLATE_: 'Choose a template to edit',
                _CHOOSE_TEMPLATE_TWO: 'Go ahead, Choose a template to edit!',
                _CHOOSE_YOUR_TEMPLATE_: 'Choose your template',
                _CURRENCY_: 'Currency',
                _ONLY_VISIBLE_: 'Only visible to project collaborators',
                _EDIT_EMAIL_SIGNATURE_: 'Edit Email Signature',
                _BUILD_UP_YOUR_PROFESSIONAL_PRESENCE_: 'Build up your professional presence',
                _YOUR_PERSONALIZED_SIGNATURE_: 'Your personalized signature will appear on all communication and email templates.',
                _BRAND_LOGO_AND_COLOR_: 'Brand elements',
                _ENABLE_CTA_: 'Enable'
            },
            PLACEHOLDERS: {
                _COMPANY_NAME_: 'Company Name',
                _COMPANY_TYPE_: 'Company Type',
                _COMPANY_OWNER_: 'Company Owner',
                _OTHER_COMPANY_TYPE_: 'Other Company Type',
                _COMPANY_HB_URL_: 'HoneyBook Contact Form URL',
                _COMPANY_WEBSITE_: 'Website URL',
                _HONEYBOOK_COMPANY_URL_: 'HoneyBook Website URL',
                _PHONE_: '(123) 456-7890',
                _EMAIL_: 'info@company.com',
                _ADDRESS_: 'Office Address',
                _ABOUT_ONE_LINE_: 'Describe Your Company',
                _ABOUT_PARAGRAPH_: 'Tell Us About Your Company'
            },
            TEMPLATES: {
                _AGREEMENTS_: 'Contracts',
                _PACKAGES_: 'Packages',
                _EMAILS_: 'Emails',
                _QUESTIONNAIRES_: 'Questionnaires',
                _BROCHURES_: 'Brochures',
                _EMPTY_VIEW_: 'Go ahead, Choose a template to edit!',
                _RENAME_TEMPLATE_: 'Rename template',
                _DELETE_TEMPLATE_: 'Delete this template',
                _TEMPLATES_: 'Templates',
                _TEMPLATES_AGREEMENT_: 'Contract Templates',
                _TEMPLATES_CONTRACT_: 'Contract',
                _TEMPLATES_PACKAGES_: 'Package',
                _TEMPLATES_EMAIL_: 'Email',
                _TEMPLATES_PAYMENTS_: 'Payment Templates',
                _TEMPLATES_SERVICES_: 'Services Templates',
                _TEMPLATES_QUESTIONNAIRE_: 'Questionnaire',
                _TEMPLATES_BROCHURE_: 'Brochure',
                _TEMPLATES_TIMELINE_: 'Timeline Templates',
                _TEMPLATES_EMAIL_SIGNATURE_: 'Email Signature',
                _TEMPLATES_INVOICE_: 'Invoice',
                _TEMPLATES_PROPOSAL_: 'Proposal',
                _TEMPLATES_CONTACT_FORM_: 'Contact Form',
                _TEMPLATES_GIFT_CARD_: 'Gift Cards',
                _GO_TO_YOUR_SIGNATURE_: 'Go to your signature',
                _ADD_TEMPLATE_: 'Add Template',
                ACCOUNT_SETUP: {
                    _TITLE_: 'Have existing templates?',
                    _TEXT_: 'Use our free ',
                    _LINK_: 'set up service'
                },
                BROCHURE: {
                    _ADD_BROCHURE_COLLECTION_: 'Add Collection to this Brochure',
                    _DELETE_BROCHURE_COLLECTION_: 'Delete Brochure Collection',
                    _DELETE_BROCHURE_INTRO_: 'Delete Brochure Intro'
                },
                ERRORS: {
                    _EMPTY_TITLE_: 'Please fill a template name',
                    _ERROR_OCCUR_MESSAGE_: 'Oops, an error occurred'
                },
                SIGNATURES: {
                    _TITLE_: 'Showcase your services and book faster than ever',
                    _SUB_HEADER_: 'Create a personalized signature here. Once you\'ve created one, you will be able to add it to all your emails!',
                }
            },
            SAVEAS_MODAL: {
                _HEADER_LABEL_: 'Please name your template',
                _HEADER_LABEL_LINE_TWO: 'Already exists.',
                _INPUT_PLACEHOLDER: 'Template name'
            },
            TEAM: {
                _SEATS_USED_: '{{teamMembersNb}} of {{maxTeamMembersNb}} team member seats used',
                _HEADER_LABEL_: 'Invite your team',
                _HEADER_LABEL_LINE_TWO_: 'Team members will have access to all of your company\'s saved proposals and templates. As a company admin you can assign workspaces and see what each team member is working on.',
                _BUTTON_INVITE_: ' Invite',
                _TITLE_LABEL_MEMBER_LIST_: 'Team members',
                _ADD_MEMBER_TITLE_: 'Invite team members',
                _ADD_MEMBER_TO_ACCOUNT_TITLE_: 'Merge accounts',
                _ADD_MEMBER_SUBTITLE_: 'Add members to your team here. You’ll be able to access their info, send them files, store notes on them and more.',
                _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added to your team. Are you sure you\'re done editing?',
                _CANNOT_ADD_ADDED_USER_: 'Hmm, it looks like this email address is already in your company team',
                _INVITE_EXISTING_USER_: 'Looks like a user with that email already exists in HoneyBook. Do you want to invite them to your team?',
                _CANNOT_ADD_EXISTING_USER_: "The invitee has a HoneyBook account. Please add a different email address.",
                _APPROVE_COMPANY_INVITE_HEADER_: 'Join team with your current email?',
                _APPROVE_COMPANY_INVITE_: 'Your existing account will be added to the inviter\'s if you accept this invite.</br>if you wish to keep your current account separate send the inviter an alternate email address.',
                _INVITATION_BANNER_TEXT_: 'You have a pending invite, {{companyName}} wants you to join as a team member.',
                UPGRADE_PLAN: {
                    STARTER: {
                        _TITLE_:'Add team members',
                        BENEFITS: {
                            _BENEFIT1_: 'Assign each team member their appropriate role: basic, moderator or admin.',
                            _BENEFIT2_: 'Assign workspaces and see what each team member is working on.'
                        }
                    },
                    ESSENTIALS: {
                        _TITLE_:'Unlimited team members—freely grow your team',
                        _TITLE_MOBILE_:'Unlimited team members—grow your team',
                        BENEFITS: {
                            _BENEFIT1_: 'You’ve got 2 team members, upgrade to add everyone you need to get work done',
                            _BENEFIT2_: 'Assign roles that allow, or limit, access to your your files, projects, calendars, and more'
                        } ,
                        BENEFITS_MOBILE: {
                            _BENEFIT1_: 'Add everyone you need to get the work done',
                            _BENEFIT2_: 'Assign roles that allow, or limit, company access'
                        }
                    }

                },
                ROLES: {
                    _MEMBER_: 'Member',
                    _OWNER_: 'Owner'
                },
                PERMISSIONS: {
                    _CHOOSE_: 'Choose a role',
                    _BASIC_: 'Basic',
                    _BASIC_DESCRIPTION_: 'Can access company templates.',
                    _MODERATOR_: 'Moderator',
                    _MODERATOR_DESCRIPTION_: 'Can access company templates. Can view any team member\'s files and workspaces.',
                    _ADMIN_: 'Admin',
                    _ADMIN_DESCRIPTION_: 'Can access company templates. Can view, edit and send any team member\'s files and workspaces. Can invite new team members.',
                    _SUPER_ADMIN_: 'Super Admin',
                    _SUPER_ADMIN_DESCRIPTION_: 'Super admin users have full access permissions on the company level, together with ability to edit and manage other projects. Super admin users cannot access account bank details and account membership.',
                    _DISABLED_: 'Disabled',
                },
                NOTIFICATIONS: {
                    _NOTIFICATION_TITLE_: 'Emails',
                    _CLIENT_PAYS_: 'Send me an email whenever a client makes a payment to a team member',
                    _CLIENT_SIGNS_: 'Send me an email whenever a client signs a team member\'s contract'
                },
                TOOLTIP: {
                    _DELETE_: 'Remove user from company team',
                    _DECLINE_: 'Decline company invitation',
                    _CANCEL_: 'Cancel invitation',
                    _APPROVE_: 'Approve company invitation',
                    _CHANGE_ROLE_: 'Change role',
                },
                CALENDAR: {
                    _CALENDAR_TITLE_: 'Team Calendars'
                }
            },

            PROJECT_TYPES: {
                _DELETE_CONFIRM_: '{{projectType}} projects will now be marked "Other"'
            },

            REFERRALS: {
                _HEADER_LABEL_: 'Better Together',
                _HEADER_LABEL_LINE_TWO_: "Invite your friends. They'll get 20% off their first month or year, and you'll earn $80 when they join!",
                _BUTTON_INVITE_: 'Invite friends',
                _SHARE_LINK_LABEL_: 'Share your referral link:',
                _GUIDE_TITLE_: 'It\'s easy to get started',
                _GUIDE_STEP_1_LABEL_: 'Spread the word with your link or over email.',
                _GUIDE_STEP_2_LABEL_: 'See your referrals join HoneyBook.',
                _GUIDE_STEP_3_LABEL_: 'Get rewards when they become a subscribed member!'
            },

            DISCOUNTS: {
                _HEADER_: 'As a HoneyBook member, you deserve the very BEST'
            },

            SETTING: {
                _TITLE_: 'Calendar',
                _SHARE_TEAM_MEMBERS_CALENDAR_: 'Allow all team members\' calendars to be visible to everyone'
            },

            ALERT: {
                _CONFIRM_DELETE_: 'Are you sure you want to delete this file?'
            },

            BADGE_PILLS: {
                LEAD_SOURCES: {
                    _TITLE_: 'Lead sources',
                    _DESC_: 'Edit your lead sources to get better idea of where you\'re getting work from.'
                },
                CONTACT_FIELDS: {
                    _TITLE_: 'Contact fields',
                    _DESC_: 'Collect details about contacts and use them across templates, emails, and files.'
                },
                PROJECT_TYPES: {
                    _TITLE_: 'Project types',
                    _DESC_: 'Stay organized by personalizing your project types to reflect the work you offer.'
                },
                TAGS: {
                    _TITLE_: 'Tags',
                    _DESC_: 'Organize your business with tags for projects and contacts.'
                },
                PROJECT_FIELDS: {
                    _TITLE_: 'Project fields',
                    _DESC_: 'Customize the information that you collect about your projects.'
                },
                PROJECT_FIELDS_V2: {
                    _TITLE_: 'Project fields',
                    _DESC_: 'Collect details about projects and use them across templates, emails, and files.'
                },
                SPACES: {
                    _TITLE_: 'Spaces',
                    _DESC_: 'Edit the spaces for your venue to keep track of their availability in your calendar.'
                },
                LABELS: {
                    _LEAD_SOURCE_: 'lead source',
                    _PROJECT_TYPE_: 'project type',
                    _ARE_YOU_SURE_: 'Are you sure?',
                }
            },

            BRANDING: {
                INSPIRATION_MODAL: {
                    _TITLE_: 'Let other brands be your muse',
                    _SUB_TITLE_: 'See how other HoneyBook members show off their brands.',
                    _GET_INSPIRED_: 'Get inspired by other brands',
                    _SEE_WHAT_IS_LIKE_: 'See what it’s like',
                    _TAKE_A_LOOK_: 'Take a look',
                },

                SOCIAL_MEDIA: {
                    _TITLE_: 'Spread the word',
                    _SUB_TITLE_: 'Add your go-to links to showcase the cool stuff you’re working on and to drive brand awareness.',
                    _BLOG_: 'Blog',
                    _FACEBOOK_: 'Facebook',
                    _LINKEDIN_: 'LinkedIn',
                    _TWITTER_: 'X',
                    _INSTAGRAM_: 'Instagram',
                    _PINTEREST_: 'Pinterest',
                    _BEHANCE_: 'Behance',
                    _DRIBBBLE_: 'Dribbble',
                    _TIKTOK_: 'TikTok',
                    _OTHER_: 'Other',
                },

                LABELS: {
                    _UPLOAD_FULL_LOGO_: 'Secondary logo:',
                    _UPLOAD_LOGO_: 'Main logo:',
                    _UPLOAD_DEFAULT_HEADER_: 'Default header image:',
                    _EDIT_BRAND_COLOR_: 'Edit brand color:',
                    _MAIN_BUTTON_COLOR_: 'Main button color',
                    _BRAND_IMAGERY_: 'Additional images',
                    _ADD_IMAGES_: 'Add images',
                    _BUTTON_: 'BUTTON',
                    _MY_FILE_: 'My File Title',
                    _FILE_SUBTITLE_: 'file subtitle',
                    _PROJECT_NAME_: 'My Project Name',
                    _LOGO_DIMENSIONS_LARGE_: '600 X 200',
                    _LOGO_DIMENSIONS_SMALL_: '100 X 100',
                    _LOGO_DIMENSIONS_DEFAULT_HEADER_: '750 x 250 png, jpg or gif',
                    _BRAND_IMAGERY_DESC_: 'Imagery is your brand’s secret weapon. Add plenty of visuals to show your clients what you’re all about.',
                    _BRAND_IMAGERY_TOOLTIP_: 'Add frequently used images to use in client-facing files.',
                    _ADDITIONAL_IMAGES_: 'Additional images'
                },

                POPUPS: {
                    _BRAND_TITLE_POPUP_: 'Logo, header image, and button color appear on client-facing files. You can always edit within a file.',
                    _ADDITIONAL_INFO_POPUP_: 'Add frequently used images to use in client-facing files.'
                },

                MOBILE: {
                    _COMPANY_BRAND_: 'Company Brand',
                    _CLIENT_PORTAL_: 'Client Portal',
                    _INVITE_YOUR_TEAM_: 'Invite Your Team',
                    _MEMBERSHIP_: 'Membership',
                    _BANK_ACCOUNT_: 'Bank details'
                },

                COMPANY_FLOW_THEME: {
                    _TITLE_: 'Company Smart File Theme',
                    _SUB_TITLE_: 'Save time and automatically apply a default theme to all smart files you start from blank.',
                    _CUSTOMIZE_COMPANY_THEME_: 'CUSTOMIZE COMPANY THEME',
                    _DEFAULT_THEME_PREVIEW_: 'Default Theme Preview',
                    _BANNER_MOBILE_TEXT_: 'Visit the HoneyBook desktop app to customize',
                    _CHOOSE_A_COLOR_: 'Choose color:',
                    _BRAND_COLOR_: 'Brand color:',
                    _BRAND_COLOR_DESC_: 'Your brand color will be used in buttons in emails, your contact form, your client portal and much more.',
                    _BRAND_IMAGERY_DESC_: 'Imagery is your brand\'s secret weapon. Add plenty of visuals to show your clients what you’re all about.'

                },

            },
            BANK_ACCOUNT: {
                _TITLE_: 'BANK DETAILS',
                _BANK_INFO_NEED_VERIFICATION_: 'Your business and owner info must be verified so you can connect your bank account and start getting convenient online payments.',
                _SETUP_BUSINESS_: 'Set up your business for online payments ',
                _CHOOSE_BUSINESS_TYPE_: 'Select your business type',
                _SETUP_BUSINESS_SUB_TITLE_: 'Your business and owner info must be verified so you can connect your bank account and start getting convenient online payments.',
                _BUSINESS_BANK_DETAILS_: 'Business and bank details',
                _SUBTITLE_: 'Your security is our first priority. We need some details to verify you\'re you and make sure your money is safe when you receive payments.',
                _NEW_STRIPE_SUBTITLE_: 'We\'ve changed the way we collect bank account information in order to better protect you from fraud and to comply with <a href="https://www.fincen.gov/resources/statutes-and-regulations/cdd-final-rule" target="_blank">federal regulations</a>. If you\'d like to update your account, please first delete your current account and then add a new one.',
                _PENDING_STRIPE_VERIFICATION_UPDATE_: 'Staying up to date with the latest financial regulations is one of the ways HoneyBook helps you protect yourself.  To continue to receive deposits to your bank account, we’re asking you to update your business information before {{ due_date | hbdate}}. </br><b>The highlighted fields below require your attention.</b>',
                _PENDING_STRIPE_VERIFICATION_RECREATE_: 'Staying up to date with the latest financial regulations is one of the ways HoneyBook helps you protect yourself.  To continue to receive deposits to your bank account, we’re asking you to update your business information before {{ due_date | hbdate}}. </br><b>To do so, please delete the bank account below and add the information back again</b>. The system will ask you for the additional information needed.',
                _NEED_HELP_: 'Need help? Check out',
                _NEED_HELP_LINK_TEXT_: 'How to Connect your Bank Account to HoneyBook',
                _SECURITY_PHONE_ERROR_: '<b>IMPORTANT: </b>A security phone number is required before updating your bank account. Please first update it in ',
                _SECURITY_PHONE_ERROR_LINK_: 'your account settings',
                _MISSING_SECURITY_PHONE_NUMBER_: 'Please set your security phone number before updating this',
                _VISIBLE_TO_COMPANY_OWNER_: 'Only visible to company owner',
                _OWNERS_: 'Business Owners',
                _DIRECTORS_: 'Business Directors',
                _LEARN_ABOUT_PAYMENTS: 'Learn about payments',
                _OWNERS_DESCRIPTION_: 'Please make sure details in this section are that of the Business Owner.',
                _ADD_OWNER_: 'Add Owner Info',
                _ADD_DIRECTOR_: 'Add Director Info',
                _COMPLETE_INFO_: 'Complete info',
                _VIEW_AND_EDIT_: 'VIEW AND EDIT',
                _BANK_ACCOUNT_: 'Bank Account',
                _BANK_ACCOUNT_NOT_CAPITALIZED: 'Bank account',
                _BANK_ACCOUNT_TYPE_QUEST_: 'What type of bank account do I have?',
                _BANK_ACCOUNT_TYPE_TOOLTIP_: 'If you have a government issued Employer Identification Number (EIN), select Business Account. If you have not filled out an SS-4 form to register your business with the IRS, then you do not have an EIN. <br><br>If you do not have an EIN, select Personal Account.',
                _ADD_BUSINESS_: 'Add your business bank account',
                _ADD_BUSINESS_SHORT_: 'Business account',
                _REQUIRES_EMP_NUMBER_: 'Requires Employer Identification Number (EIN)',
                _NO_EMP_NUMBER_: 'No Employer Identification Number (EIN)',
                _ADD_PERSONAL_: 'Add your personal bank account',
                _ADD_PERSONAL_SHORT_: 'Personal account',
                _STRIPE_DISCLAIMER_: 'By registering your bank account, you agree to <a href="https://stripe.com/connect-account/legal" target="_blank">Stripe Connected Account Agreement.</a>',
                _UPDATED_STRIPE_DISCLAIMER_: 'Registering your account means you consent to the <a href="https://stripe.com/connect-account/legal" target="_blank">Stripe Connected Account Agreement</a>',
                _EIN_COLON_: "EIN: ",
                _BN_COLON_: "BN: ",
                _EIN_9_DIGIT_: " 9-digit IRS-issued employer ID number.",
                _BN_INFO_: " 9-digit business number.",
                _IDENTIFY_BUSINESS_TYPE_: "How to identify your business type",
                _DELETE_OWNER_POPUP_TEXT_: "Are you sure you want to delete all information related to this Business Owner?",
                _DELETE_ACCOUNT_POPUP_TEXT_: " Are you sure you want to delete Bank Account ending in {{ accountNumber }}?",
                _DELETE_ACCOUNT_OPENER_TOOLTIP_TEXT_: "In order to delete the Business Owner, you must first delete your bank account above",
                _ADD_OWNER_DISLABED_TOOLTIP_TEXT_: "In order to add a Business Owner you must first add a Bank Account.",
                _FULL_OWNERSHIP_TOOLTIP_TEXT_: "Existing Business Owners currently account above 75% of business ownership. In order to add an additional owner, please adjust the business ownership percentages by editing the existing Business Owners",
                _ENTITY_NOT_EDITABLE_TOOLTIP_TEXT_: "Please add a Bank Account in order to edit a Business Owner",
                _BANK_ACCOUNT_ENDING_IN_: "Bank account ending in {{ accountNumber }}",
                _DEBIT_CARD_ENDING_IN_: 'Debit card ending in {{ accountNumber }}',
                _ENDING_IN_: 'account ending in {{ accountNumber }}',
                _BANK_INFO_MISSING_: "Please update missing/incorrect information in the 'Bank Account' section",
                _OWNER_INFO_MISSING_: "Please update missing/incorrect information in the 'Business Owners' section",
                _DIRECTOR_INFO_MISSING_: "Please update missing/incorrect information in the 'Business Directors' section",
                _OWNER_AND_DIRECTOR_INFO_MISSING_: "Please update missing/incorrect information in the 'Business Owners' and 'Business Directors' sections",
                _BOTH_INFO_MISSING_: "Please update missing/incorrect information in BOTH the Bank Account and Business Owner sections",
                _INFO_MISSING_TITLE_: "Missing information",
                _MULTI_OWNER_INFO_MISSING_: "Please update missing/incorrect information for {{names}} below",
                _INDIVIDUAL_CARD_TITLE_: 'Individual',
                _SOLE_PROPRIETOR_CARD_TITLE_: 'Sole proprietor',
                _LLC_PARTNERSHIP_CORP_CARD_TITLE_: 'LLC-Partnership-Corp.',
                _CANADA_CORP_CARD_TITLE_: 'Corporation',
                _CANADA_PARTNERSHIP_CARD_TITLE_: 'Partnership',
                _INDIVIDUAL_CARD_DESCRIPTION_: 'You own & run a business, but ',
                _INDIVIDUAL_CARD_DESCRIPTION_NO_EIN_: 'do not have an EIN.',
                _INDIVIDUAL_CARD_DESCRIPTION_NO_BN_: 'do not have a BN.',
                _INDIVIDUAL_EIN_TOOLTIP_: 'Individuals, for tax purposes, can use their SSN. If you have a 9-digit employer ID number (EIN), then select the Sole proprietor option.',
                _SOLE_PROPRIETOR_CARD_DESCRIPTION_: 'You own & run a business and were issued an ',
                _SOLE_PROPRIETOR_CARD_DESCRIPTION_CA_: 'You own & run a business and were issued a ',
                _LLC_PARTNERSHIP_CORP_CARD_DESCRIPTION_: 'You pay taxes as an LLC, partnership, or corporation.',
                _CANADA_PARTNERSHIP_CARD_DESCRIPTION_: 'You pay taxes as a partnership.',
                _CANADA_CORP_CARD_DESCRIPTION_: 'You pay taxes as a corporation.',
                _EIN_: 'EIN.',
                _BN_: 'BN.',
                _PENDING_: 'PENDING',
                _HELP_CENTER_LINK_: 'Learn how to set up for online payments',
                _PENDING_STATUS_: 'Account status: Pending',
                _PENDING_DESC_: 'Carefully review the info you entered to make sure it is complete and accurate.',
                _INDIVIDUAL_CARD_TOOLTIP_HEADER_: 'Why choose <span>Individual</span>?',
                _INDIVIDUAL_CARD_TOOLTIP_BODY_: 'You do not have a 9-digit employer ID number (EIN). Instead, you use your SSN for tax purposes.',
                _INDIVIDUAL_CARD_TOOLTIP_BODY_CA_: 'You do not have a 9-digit business number (BN).',
                _SOLE_PROPRIETOR_CARD_TOOLTIP_HEADER_: 'Why choose <span>Sole proprietor</span>?',
                _SOLE_PROPRIETOR_CARD_TOOLTIP_BODY_: 'You applied to the IRS and got a 9-digit employer ID number (EIN) to use for tax purposes.',
                _SOLE_PROPRIETOR_CARD_TOOLTIP_BODY_CA_: 'You applied to the Canada Revenue Agency (CRA) and got a 9-digit business number (BN) to identify a business',
                _ADD_ADDITIONAL_OWNER_: 'ADD OWNER INFO',
                _ADD_ADDITIONAL_DIRECTOR_: 'ADD DIRECTOR INFO',
                _INSTANT_DEPOSIT_ACCOUNT_: {
                    _TITLE_: 'Instant Deposit Account',
                    _SUBTITLE_: 'Instant Deposit allows you to receive funds as soon as your client pays (typically within 30 minutes). To opt into the Instant Deposit\n' +
                        'feature and have funds deposited directly, add a debit card below.',
                    _LEARN_MORE_: 'Learn more about our Instant Deposit feature',
                    _ADD_DEBIT_CARD_: 'Add your debit card'
                },
                COMMON_MODAL: {
                    _ALL_FIELDS_REQ_: "All fields are required",
                    _MISSING_INFO_ERROR_MESSAGE_: "Incorrect/Re-enter",
                    _SSN_ERROR_MESSAGE_: "Please re-enter"
                },
                BUSINESS_INFO_MODAL: {
                    _TITLE_: "About the business",
                    _LEGAL_NAME_: "Legal business name",
                    _DBA_NAME_: "Doing Business As name",
                    _STREET_ADDRESS_: "Business street address",
                    _TAX_ID_: "Employer Identification Number (EIN)",
                    _BUSINESS_NUMBER_: 'Business number',
                    _MERCHANT_CATEGORY_CODE_: "Merchant category code",
                    _MERCHANT_CATEGORY_TOOLTIP_: "A 4 digit number that was assigned to you when you applied to accept credit cards",
                    _PRODUCT_DESC_: "Website URL or product description",
                    _PRODUCT_DESC_TOOLTIP_: "Provide an active URL to your business website or social media profile. No URL? Please provide a detailed description about your business.",
                    _WEBSITE_ONLY_TOOLTIP_: "Provide an active URL to your business website or social media profile.",
                    _PRODUCT_DESC_ONLY_TOOLTIP_: "Provide a detailed description about your business.",
                    _SUPPORT_PHONE_NUMBER_TOOLTIP_: "Phone number your clients can call regarding any support related questions.",
                    _BANK_INFO_: "Bank information",
                    _ROUTING_NUM_: "Routing number",
                    _ACCOUNT_NUM_: "Account number",
                    _TRANSIT_NUM_: "Transit number",
                    _INSTITUTION_NUM_: "Institution number",
                    _BANK_NAME_: "Bank name",
                    _SAVE_BANK_ACCOUNT_: "SAVE BANK ACCOUNT",
                    _UPDATE_BANK_ACCOUNT_: "UPDATE BANK ACCOUNT",
                    _PHONE_ERROR_: "Invalid phone number",
                    _ZIP_ERROR_: 'Invalid ZIP code',
                    _ROUTING_NUMBER_ERROR_: "Invalid routing number",
                    _ACCOUNT_NUMBER_ERROR_: "Invalid account number",
                    _TAX_ID_ERROR_: "Invalid EIN/TIN",
                    _BUSINESS_NUMBER_ERROR_: "Invalid business number",
                    _TRANSIT_NUMBER_ERROR_: "Invalid transit number",
                    _INSTITUTION_NUMBER_ERROR_: "Invalid institution number",
                    _MCC_ERROR_: "Please select from available options",
                    _INVALID_ERROR: "Invalid"
                },
                ADDITIONAL_DOCUMENTS: {
                    _TITLE_: 'Verification Documents',
                    _SUBTITLE_: 'Upload required documents to complete account verification',
                    _UPLOAD_CTA_: 'Complete Verification',
                    _UPLOAD_BANK_VERIFICATION_: 'Upload Bank Document',
                    _DOCUMENT_SUBTITLE_: "The following documents are needed to complete account verification.",
                    _DOCUMENT_SUBTITLE_2_: "Please make sure images are clear and legible, in JPG, PNG or PDF format and less than 10 MB in size",
                    _DONE_CTA_: 'Done',
                    _ALL_FIELDS_REQ_: 'All documents are needed for submission'
                },
                ACCOUNT_OWNER_MODAL: {
                    _TITLE_: "Owner Info",
                    _MUST_BE_RELATED_: "All info must be related to the owner of the business.",
                    _MUST_BE_RELATED_DIRECTOR_: "All info must be related to the director of the business.",
                    _DO_YOU_HAVE_DIRECTORS_: 'Does your company have any directors? Please select \'Yes\' if there are members on the governing board.',
                    _AFTER_BANK_ACCOUNT_FORM_: "Thank you for filling your bank account details. Please provide additional information about the owner(s) of your business",
                    _AFTER_BANK_ACCOUNT_FORM_DIRECTOR_: " Thank you for providing your company owner details. This is the last step—we promise!",
                    _BUSINESS_OWNERSHIP_: "Business Ownership %",
                    _JOB_TITLE_: "Job Title",
                    _PERSONAL_STREET_ADDRESS_: "Personal Street Address",
                    _ID_DOCUMENT_: "Identification document",
                    _ID_DOCUMENT_SUBTITLE_: "Verify your payout method by uploading both sides of a government-issued ID, driver’s license, or passport.",
                    _ID_DOCUMENT_SUBTITLE_REQUIREMENTS_: "Upload a color image of up to 10 MB in JPG, PNG, or PDF format.",
                    _UPLOAD_SUCCESS_: "Image uploaded",
                    _SAVE_OWNER_: "SAVE OWNER",
                    _UPDATE_OWNER_: "UPDATE OWNER",
                    _SSN_ERROR_: "Invalid ssn format",
                    _ZIP_ERROR_: 'Invalid ZIP code',
                    _SSN_TOOLTIP_: "Your SSN is required for identity verification, we will not store your full SSN on our servers.",
                    _SIN_TOOLTIP_: "Your SIN is required for identity verification, we will not store your full SIN on our servers.",
                    _BUSINESS_OWNERSHIP_TOOLTIP_: "The percent of the business a member currently owns.",
                    _EMAIL_ERROR_: "Invalid Email address",
                    _PHONE_ERROR_: "Invalid phone number",
                    _DOB_ERROR_: "Must be over 18",
                    _BUSINESS_OWNERSHIP_ERROR_: "Can not exceed 100%",
                    _BUSINESS_OWNERSHIP_MIN_ERROR_: "Must be at least 25%",
                    _BUSINESS_OWNERSHIP_MAX_ERROR_: "Must be less than 100%",
                    _INVALID_ERROR_: "Invalid",
                    _INVALID_SSN_BANNER_: "The name you’ve entered may not match the name associated with your social security number. Please re-enter SSN, and your first and last name exactly as it appears on your social security card."

                },

                ADD_BANK_ACCOUNT: {
                    _SSN_: 'Social security number (SSN)',
                    _SIN_: 'Social insurance number',
                    _PHONE_ERROR_: "Invalid phone number",
                    _EMAIL_ERROR_: 'Invalid email address',
                    _SSN_ERROR_: 'Invalid SSN',
                    _SIN_ERROR_: 'Invalid SIN',
                    _EIN_ERROR_: 'Enter your 9-digit employer identification number.',
                    _ZIP_ERROR_: 'Invalid ZIP code',
                    _POSTAL_ERROR_: 'Invalid postal code',
                    _JOB_TITLE_: 'Job title',
                    _JOB_TITLE_TOOLTIP_: 'Your title is needed for verification purposes. Ex. CEO, owner, account representative.',
                    _OWNERSHIP_PERCENTAGE_: '% of ownership',
                    _ADD_DIRECTOR_: 'Add director',
                    _DIRECTOR_DETAILS_: 'Director details',
                    _CONFIRM_IDENTITY_UPLOAD_TITLE_: 'Confirm your identity',
                    _CONFIRM_COMPANY_UPLOAD_TITLE_: 'Confirm your company',
                    _CONFIRM_ADDRESS_UPLOAD_TITLE_: 'Confirm your address',
                    _CONFIRM_REGISTRATION_UPLOAD_TITLE_: 'Confirm business directorship',
                    _CONFIRM_REGISTRATION_UPLOAD_SUB_TITLE_: 'Current and valid municipal, provincial, territorial, or federal government-issued document that contains your business directorship.',
                    _CONFIRM_IDENTITY_UPLOAD_SUB_TITLE_: 'Upload an image of your passport or driver’s license.',
                    _CONFIRM_COMPANY_UPLOAD_SUB_TITLE_: 'Upload an image of a current and valid municipal, provincial, territorial or federal government issued document that contains the entity name and address.',
                    _CONFIRM_ADDRESS_UPLOAD_SUB_TITLE_: 'Upload a different image from the one submitted above, (e,g, a provincial or territorial photo ID card or a utility bill).',
                    _OTHER_DOCS_TO_USE_LINK_: 'Other documents you can use',
                    _ROUTING_NUMBER_ERROR_: "Invalid routing number",
                    _ACCOUNT_NUMBER_ERROR_: "Invalid account number",
                    _TAX_ID_ERROR_: "Invalid EIN/TIN",
                    _BUSINESS_NUMBER_ERROR_: "Invalid business number",
                    _TRANSIT_NUMBER_ERROR_: "Invalid transit number",
                    _INSTITUTION_NUMBER_ERROR_: "Invalid institution number",
                    _MCC_ERROR_: "Select a merchant category code.",
                    _INVALID_ERROR: "Invalid",
                    _DOB_ERROR_: 'Must be over 18',
                    _IDENTIFICATION_UPLOAD_: 'Confirm your identity—upload a color copy of your passport or driver’s license in JPG, PNG, or PDF format (max. 10 MB).',
                    _IDENTIFICATION_UPLOAD_TITLE_: 'Identification documents',
                    _IDENTIFICATION_UPLOAD_SUB_TITLE_: 'Make sure to upload documents that match the owner information above.',
                    _ID_DOCUMENT_SUBTITLE_REQUIREMENTS_: 'Add a color image of up to 10 MB in JPG, PNG, or PDF format.',
                    _BUSINESS_NAME_: 'Business Name',
                    _DBA_NAME_: 'Doing business as (DBA) name',
                    _WEBSITE_URL_OR_PRODUCT_DESCRIPTION_: 'Website URL or product description',
                    _WEBSITE_URL_: 'Website URL',
                    _WEBSITE_URL_OR_SOCIAL_: 'Business website or social media URL',
                    _PRODUCT_DESCRIPTION_: 'Business description',
                    _STATEMENT_DESCRIPTOR_: 'Statement descriptor',
                    _MMC_: 'Merchant category code (MCC)',
                    _STREET_ADRRESS_: 'Home street address',
                    _BANK_NAME_: 'Bank name',
                    _BUSINESS_LEGAL_NAME_: 'Legal business name',
                    _EIN_: 'Employer identification number (EIN)',
                    _BUSINESS_NUMBER_: 'Business number (BN)',
                    _BUSINESS_STREET_ADDRESS_: 'Business street address',
                    _BUSINESS_PHONE_NUMBER_: 'Business phone number',
                    _SUPPORT_PHONE_NUMBER_: 'Support phone number',
                    _WEBSITE_URL_TOOLTIP_: 'Provide an active URL to your business website or social media profile. No URL? Please provide a detailed description about your business.',
                    _MCC_TOOLTIP_: 'A 4-digit code that lets you accept credit cards.',
                    _BUSINESS_NUMBER_CA_: 'Business Number (BN) (9 digit number). Alternatively you can also provide a Corporation Number (7 digit number), or Québec Enterprise Number (NEQ) (10 digit number).',
                    _DBA_TOOLTIP_: 'Shows up on your customers\' bank or credit card statements. To update, change your HoneyBook company name.',
                    _STATEMENT_DESC_COMPANY_: 'Shows up on your customers\' bank or credit card statements. To update, change your company name in your Company settings.',
                    _STATEMENT_DESC_OWNER_: 'Shows up on your customers\' bank or credit card statements. HoneyBook used your full name since your company name is less than 5 characters. To update, change your company name in your Company settings.',
                    _SHOW_SS4_EXAMPLE_: 'SS-4 example',
                    _INFO_BEING_VERIFIED_TITLE_: 'Your info is being verified this very moment',
                    _INFO_WILL_BE_READY_FIRST_: 'Soon you’ll be all set to get online payments from your clients.',
                    _WE_WILL_UPDATE_: 'Should there be another question or two, we\'ll send you an email.',
                    _GOT_IT_: 'GOT IT',
                    _OPTIONAL_TAG_: '(Optional)',
                    _WEBSITE_URL_ERROR_: 'Invalid website URL',
                    _PRODUCT_DESCRIPTION_ERROR_: 'Must be at least 20 characters.',
                    MISSING: {
                        _BUSINESS_NAME_ERROR_: 'Enter business name',
                        _WEBSITE_URL_OR_PRODUCT_DESCRIPTION_ERROR_: 'Enter your website URL / description',
                        _WEBSITE_URL_ERROR_: 'Enter website URL',
                        _PRODUCT_DESCRIPTION_ERROR_: 'Enter business description',
                        _MCC_ERROR_: 'Select a merchant category code.',
                        _FIRST_NAME_ERROR_: 'Enter first name.',
                        _LAST_NAME_ERROR_: 'Enter last name.',
                        _DOB_ERROR_: 'Enter date of birth.',
                        _SSN_ERROR_: 'Enter social security number.',
                        _SIN_ERROR_: 'Enter social insurance number.',
                        _STREET_ADRRESS_ERROR_: 'Enter home address.',
                        _CITY_ERROR_: 'Enter city.',
                        _STATE_ERROR_: 'Select a state.',
                        _ZIP_ERROR_: 'Enter ZIP code.',
                        _POSTAL_ERROR_: 'Enter postal code.',
                        _EMAIL_ERROR_: 'Enter email address.',
                        _PHONE_ERROR_: 'Enter phone number.',
                        _ACCOUNT_NUMBER_ERROR_: 'Enter account number.',
                        _ROUTING_NUMBER_ERROR_: 'Enter routing number.',
                        _BANK_NAME_ERROR_: 'Enter bank name.',
                        _JOB_TITLE_ERROR_: 'Enter job title.',
                        _OWNERSHIP_PERCENTAGE_ERROR_: 'Enter % of ownership.',
                        _BUSINESS_LEGAL_NAME_ERROR_: 'Enter legal entity name.',
                        _DIRECTOR_FIRST_NAME_ERROR_: 'Enter director first name.',
                        _DIRECTOR_LAST_NAME_ERROR_: 'Enter director last name.',
                        _DIRECTOR_EMAIL_ADDRESS_ERROR_: 'Enter director email address.',
                        _EIN_ERROR_: 'Enter 9-digit employer identification number.',
                        _BUSINESS_NUMBER_ERROR_: "Enter company business number.",
                        _BUSINESS_STREET_ADDRESS_ERROR_: 'Enter business address.',
                        _BUSINESS_PHONE_NUMBER_: 'Enter business phone',
                        _BUSINESS_PHONE_ERROR_: 'Enter business phone.',
                        _ID_PHOTO_: 'Use a color image of up to 10 MB in JPG, PNG, or PDF format.'
                    }
                },
                INDIVIDUAL_MODAL: {
                    _TITLE_: 'Let’s verify your business and owner info',
                    _SUB_TITLE_: 'All your info is kept secure using SSL encryption.',
                    _SAVE_: 'SAVE',
                    _BANK_DETAILS_: 'Bank details, for your online payments',
                    _OWNER_INFO_: 'About you, the business owner'
                },
                CORP_MODAL_SETUP: {
                    _TITLE_: 'Prepare a few things...',
                    _SETUP_INFO_: 'To set up your account, you need exactly the info that’s in your SS-4 form:',
                    _LEGAL_ENTITY_NAME_: 'Legal entity name',
                    _EIN_: 'Employer Identification Number (EIN)',
                    _EXAMPLE_TEXT_: 'Example SS-4 form',
                    _NEXT_: 'NEXT'
                },

                CORP_MODAL: {
                    _BUSINESS_TITLE_: 'About the business',
                    _BUSINESS_SUB_TITLE_: 'All your info is kept secure using SSL encryption.',
                    _OWNER_TITLE: 'About you, the business owner',
                    _DIRECTOR_TITLE: 'About your business director',
                    _OWNER_SUB_TITLE_: 'All your info is kept secure using SSL encryption.',
                    _BANK_DETAILS_: 'Bank details, for your online payments',
                    _BANK_DETAILS_SUB_TITLE: 'Add a bank account that’s in your personal name or under your DBA name.',
                    _BANK_NAME_: 'Bank name',
                    _CORP_FIELDS_REQUIRED_: '*All fields are required',
                    _SAVE_: 'SAVE'
                },
                DEBIT_CARD_MODAL: {
                    _TITLE_: 'Add your debit card',
                    _DESCRIPTION_: 'A valid debit card is required in order to begin receiving funds. This card will only be used for Instant Deposit payments.',
                    _BLUEVINE_WARNING_: '<b>Note:</b> Bluevine-issued debit cards with account-based (RTP) processing will experience issues receiving payouts instantly.',
                    _BLUEVINE_WARNING_TOOLTIP_: 'If you are adding a Bluevine-issued debit card, please check that your card is eligible for card based (e.g. OCT/Send) payments, and not account-based (RTP) processing. Bluevine-issued debit cards with account-based (RTP) processing will experience issues receiving payouts instantly.',
                    _FROM_: 'From',
                    _TO_: 'To',
                    _CLIENT_: 'Your client',
                    _YOU_: 'Your debit card',
                    _ADD_CARD_: 'Add card',
                    _CANCEL_: 'No thanks',
                    _DEFAULT_CARD_ERROR_: 'Failed to add card'
                },
                INSTANT_DEPOSIT_PROMOTE_AUTO_TOGGLE_MODAL: {
                    _TITLE_: 'Set it and forget it',
                    _DESCRIPTION_: 'Get all of your eligible payments automatically deposited within minutes, even on weekends!',
                    _TOGGLE_TEXT_: 'Automate Instant Deposit for all future payments',
                    _NOTE_: '(Instant Deposit and processing fees apply)',
                    _MAIN_CTA_: 'Ok, confirm',
                    _CANCEL_: 'No thanks'
                },
                INSTANT_DEPOSIT_CONFIRM_MULTI_TOGGLE_MODAL: {
                    _TITLE_: 'Boom! You’re all set.',
                    _DESCRIPTION_: 'Your selected payments will be deposited on the same day they\'re paid to you. You can automate Instant Deposits to get paid quickly every time– just toggle it on below.',
                    _NOTE_: '(Instant Deposit charge and processing fees apply)'
                },
                INSTANT_DEPOSIT_MODAL: {
                    CONFIRM_INSTANT_DEPOSIT: {
                        _TITLE_: 'Get funds sooner with Instant Deposit',
                        _DESCRIPTION_: 'Funds will be deposited to your debit card ending in {{ last4 }} - typically within 30 minutes.',
                        _FEE_: 'Instant Deposit charge*: {{ fee_amount | currency:"$"}}',
                        _MAIN_CTA_: 'Deposit now',
                        _CANCEL_: 'No thanks',
                        _NOTE_: '*In addition to standard processing fees'
                    },
                    CONFIRM_INSTANT_DEPOSIT_INITIATED: {
                        _TITLE_: 'Instant Deposit initiated',
                        _DESCRIPTION_: 'Your funds are being transferred to your debit card. Total deposit amount: {{ total_amount | currency: "$"}}',
                        _MAIN_CTA_: 'OK, GOT IT',
                        _MAIN_CTA_WITH_UNIVESAL_TOGGLED_: 'Confirm',
                        _NOTE_: 'Instant Deposit and processing fees applied.'
                    },
                    ERROR: {
                        _EXPIRED_TITLE_: 'Instant Deposit expired',
                        _NOT_ALLOWED_TITLE_: 'Instant Deposit not available',
                        _MISSING_PAYMENT_TITLE_: 'Unexpected error',
                        _IS_INSTANT_TITLE_: 'Action is not allowed',
                        _EXPIRED_DESCRIPTION_: 'Instant Deposit is available within 24 hours after any payment. You can always opt in to this for future payments from Bookkeeping.',
                        _NOT_ALLOWED_DESCRIPTION_: 'This payment cannot be processed as an Instant Deposit. It will be sent to your bank via standard deposit.',
                        _MISSING_PAYMENT_DESCRIPTION_: 'Oops, we can’t find this payment. Please contact us',
                        _IS_INSTANT_DESCRIPTION_: 'This payment is already being processed as an instant deposit',
                        _MAIN_CTA_: 'OK, GOT IT'
                    }
                },
            },
            PAYMENT_METHODS: {
                _VISIBLE_TO_COMPANY_OWNER_: 'Only visible to company owner',
            }

        },

        UPLOAD_PHOTO: {
            _TITLE_LOGO_: 'Upload New Logo',
            _TITLE_ICON_: 'Upload New Logo',
            _TITLE_COVER_: 'Upload Cover Images',
            _TITLE_PROFILE_IMAGE_: 'Upload New Profile Image',
            _TITLE_WORKSPACE_MESSAGE_: 'Attach Images',
            _TITLE_PROJECT_MESSAGE_: 'Attach Images',
            _TITLE_USER_IDENTIFICATION_DOC_: 'Upload Identification',
            _ICON_FOOTER_NOTE_: 'Please note, this new icon will replace all icons on every file you\'ve sent - past and present',
            _DISCLAIMER_LOGO_: 'For best results, upload a 300 x 100 PNG or GIF with a transparent background. There is a 15mb file limit.',
            _DISCLAIMER_ICON_: 'For best results, upload a 100 x 100 PNG or GIF. There is a 15mb file limit.',
            _DISCLAIMER_COVER_: 'For best results, upload a 760 x 250 PNG, JPG or GIF. There is a 15mb file limit.',
            _DISCLAIMER_PROFILE_IMAGE_: 'For best results, upload a 360 x 360 PNG, JPG or GIF. There is a 15mb file limit.',
            _DISCLAIMER_WORKSPACE_MESSAGE_: 'You may add multiple image files. Images will be uploaded when the message is sent.',
            _DISCLAIMER_PROJECT_MESSAGE_: 'You may add multiple image files. Images will be uploaded when the message is sent.',
            _DISCLAIMER_USER_IDENTIFICATION_DOC_: 'Upload a bright, clear, photo of your passport or driver\'s license. Make sure you can clearly see your name, date of birth and photo. Name on ID must match bank account owner\'s name. Upload JPEG or PNG image.',
            _DRAG_AND_DROP_NOT_SUPPORTED: 'File Drag/Drop is not supported for this browser',
            _FILE_TYPE_NOT_SUPPORTED: 'File type is not supported. Please select image files only',
            LABELS: {
                _CHOOSE_FILE_: 'Choose file',
                _CHOOSE_FILES_: 'Or choose files',
                _DROP_FILES_1_MULTIPLE_: 'Drop images here',
                _DROP_ALL_FILES_1_MULTIPLE_: 'Drop files or images here',
                _DROP_FILES_1_: 'Drop image here',
                _DROP_FILES_2_: 'or select image to upload',
                _DROP_FILES_NO_OR_: 'select image to upload',
                _DROP_ANOTHER_: "Drop another image here",
                _DROP_OR_UPLOAD: 'Drag & drop or upload',
                _DROP_IMAGE_OR_UPLOAD: 'Drop image here or select image to upload',
                _UPLOAD_DOC_INSTRUCTIONS_: 'Upload a color image of up to 10MB in JPG, PNG, or PDF format.'
            }
        },

        UPLOAD_FILE: {
            _TITLE_WORKSPACE_MESSAGE_: 'Attach Files',
            _TITLE_PROJECT_MESSAGE_: 'Attach Files',
            _DISCLAIMER_WORKSPACE_MESSAGE_: 'You may add multiple files. Files will be uploaded when the message is sent.',
            _DISCLAIMER_PROJECT_MESSAGE_: 'You may add multiple files. Files will be uploaded when the message is sent.',
            _DRAG_AND_DROP_NOT_SUPPORTED: 'File Drag/Drop is not supported for this browser',
            _ERROR_FILE_TOO_LARGE_: 'Oops, this file is bigger than our 15MB limit. Try a smaller file.',
            _ERROR_FILE_TOO_LARGE_SHORTER_: 'Oops, this file is bigger than our 10MB limit.',
            _ERROR_FILES_TOO_LARGE_: 'Oops, some of the selected files are bigger than our 15MB limit. Try a smaller files.',
            _ERROR_NO_FILES_TO_UPLOAD_: "Oops, couldn't find any files to upload, maybe the files are already in the library?",
            _ERROR_FILE_TYPE_NOT_SUPPORTED_: "This file type is not supported. Please use a color image in JPG, PNG or PDF format, and less than 10 MB in size",
            LABELS: {
                _CHOOSE_FILE_: 'Choose File',
                _DROP_FILES_1_MULTIPLE_: 'Drag into this screen to upload',
                _DROP_FILES_1_: 'Drop file here',
                _DROP_FILES_2_: 'Choose any file type under 15MB',
                _DROP_FILES_3_: 'Choose image files under 15MB',
                _UPLOAD_: 'UPLOAD',
                _IMPORT_FROM_CANVA_: 'IMPORT FROM CANVA'
            }
        },

        FILE: {
            FILMSTRIP: {
                _PROPOSAL_: 'PROPOSAL',
                _PAYMENTS_: 'PAYMENT PLAN',
                _AGREEMENT_: 'CONTRACT',
                _EMAILS_: 'EMAILS',
                _PAY_: 'PAY'
            },
            COMMENTS: {
                _TEXT_: 'QUESTIONS OR COMMENTS?',
                _COMMENTS_: 'COMMENTS',
                _COMMENT_: 'Comment',
                _NO_COMMENTS_: 'Have a comment or a question? Post it here!',
                _DRAFT_: 'Send the draft in order to comment',
                _NEW_COMMENT_PLACEHOLDER_: "Comment here...",
                _ONLY_WORKSPACE_MEMBERS_PLACEHOLDER_: "Only workspace members can comment",
                _INTRO_: 'Want to drop a little comment? Click here to add one. All comments will show on the file and on the Activity Stream, and will be sent by email to workspace members'
            },
            DEMO_MODAL: {
                _INFO_: 'New to HoneyBook? Join an expert-lead webinar to learn how to use HoneyBook to get organized and grow your business.',
                _CONFIRM_: 'Confirm',
                _WEBINAR_CONFIRM_: 'Learn more'
            },
            RIBBON_LABEL: {
                _EDIT_: 'Edit',
                _PROPOSAL_: 'Proposal',
                _INVOICE_: 'Invoice',
                _QUESTIONNAIRE_: 'questionnaire',
                _QUESTIONS_: 'Questions',
                _BROCHURE_: 'Brochure',
                _TIMELINE_: 'Timeline',
                _PAYMENTS_: 'Payment Plan',
                _AGREEMENT_: 'Contract',
                _EMAILS_: 'Emails',
                _RECEIVED_EMAILS_: 'RECEIVED EMAILS',
                _PIPELINE_: 'Pipeline'
            },
            ALERTS: {
                _PRINT_ERROR_POPUP_: 'Unable to print your file. Your browser may be preventing the print window from opening',
                _PRINT_ERROR_POPUP_BTN_: 'Ok',
                _UNABLE_TO_LOAD_MESSAGE_: 'Oops, we\'ve encountered an error. Please try again later.',
                //_ACCEPT_VERSION_CHANGE_: 'Changes have been made to this {{fileType}} on {{dateSent}}. Please review and accept it',
                _ACCEPT_VERSION_CHANGE_: 'Hi, {{vendorName}} here! I made some changes to your {{fileType}}. Please review, then click accept to agree upon the changes. Thanks!',
                _ACCEPT_: 'Accept Changes',
                _VENDOR_VERSION_NOTIFICATION_: 'You are now editing a sent {{fileType}}. This version is a draft, and the edits will not be seen by other workspace participants until the {{fileType}} is re-sent. ',
                _VENDOR_VERSION_NOTIFICATION_WITH_TEAM_MEMBERS_: 'You are now editing a sent {{fileType}}. This version is a draft and the edits can only be seen by company admin members. No other workspace participant will see these edits until the {{fileType}} is re-sent.',
                _VENDOR_AUTO_PAY_NOTIFICATION_: 'You are now editing a sent {{fileType}} that is set to auto-pay. Your client will not see these edits until you resend the file. Once resent, your client must approve the changes before future auto-payments can process.',
                _VENDOR_VERSION_INVALIDATED_1_: 'The client has made changes on this file.',
                _VENDOR_VERSION_INVALIDATED_SIGNED_1_: 'The client has signed this proposal.',
                _VENDOR_VERSION_INVALIDATED_PAID_1_: 'The client has made a payment on this file.',
                _VENDOR_VERSION_INVALIDATED_PAYMENT_REVERTED_1_: 'A pending payment was rejected and that caused the draft to be invalid.',
                _VENDOR_VERSION_INVALIDATED_DISCARD_: 'Discard draft',
                _VENDOR_VERSION_INVALIDATED_2_: 'to discard your current changes.',
                _UNABLE_TO_FIND_THE_FILE_: 'Sorry {{userName}}, we\'re unable to find the file you\'re looking for at this time. It might not have been shared with you or has been deleted.',
                _UNABLE_TO_SET_AUTO_EXPIRATION: 'Oops, there was an error setting the {{fileType}} expiration, please reopen the file settings and try again.',
                _UNABLE_TO_TOGGLE_ON_AUTO_PAY_: 'Sorry, auto-pay can’t be enabled for the last payment as it would require your client to approve the auto-pay anyway.',
                _UNABLE_TO_TOGGLE_OFF_AUTO_PAY_: 'Sorry, auto-pay can’t be disabled since your client chose to enable it.',
                _PRE_SAVE_AS_PDF_MESSAGE: 'To save as PDF, click and select \'Save as PDF\' inside browser print screen',
                _FILE_EXPIRATION_INVOICE_: 'This invoice will expire on {{expirationDate}}. Please make a payment at your earliest convenience',
                _FILE_EXPIRATION_PROPOSAL_: 'This proposal will expire on {{expirationDate}}. Please sign at your earliest convenience.',
                _VIEW_BROCHURE_SELECTION_: '<b>{{clientFirstName}} has made a selection,</b> view it at the bottom of this page.',
                _VIEW_BROCHURE_SELECTION_CTA_: 'VIEW SELECTION'
            },

            HEADER: {
                _CLIENT_VIEW_: 'Client view',
                _CLIENT_VIEW_TOOLTIP_: 'See what your client will experience when you send a HoneyBook file',
                _CLIENT_VIEW_TOOLTIP_TEMPLATE_PREVIEW_: 'See what your client will experience when you use this template and send it as a HoneyBook file',
                _SEND_TEST_FILE_CTA_: 'Send test',
                _SEND_TEST_FILE_EXIT_: 'Exit test {{fileType}}',
                _SEND_TEST_FILE_TOOLTIP_: 'Send yourself the file to test out your client’s experience',
                _EDIT_: 'edit',
                _STATUS_: 'Status',
                _PREVIEW_MODE_BAR_: 'You are in PREVIEW MODE. This is what your file looks like to your client.',
                _YOU_ARE_IN_PREVIEW_MODE_: '<strong>You are currently in client view. </strong> Exit this view to continue editing and send your file.',
                _YOU_ARE_IN_TEMPLATE_PREVIEW_MODE_: '<strong>You are currently in client view. </strong> This is how a file created from this template will appear to the client.',
                _CANT_EDIT_RECURRING_FILE_: 'You are not able to edit this {{fileType}} as it is set to recurring payment',
                _GO_BACK_TO_FILE_: 'EXIT CLIENT VIEW',
                _CLIENT_MODE_CLIENT_BAR_: 'You are in CLIENT MODE, signed in as {{client_email}}. Click ',
                _CLIENT_MODE_VENDOR_BAR_: ' to switch back to {{vendor_email}}',
                _VERSIONS_: 'Version {{version}}',
                _MOBILE_VERSIONS_: 'V{{version}}',
                _DRAFT_: 'draft',
                _TITLE_DRAFT_: '(draft)',
                _BOOKED_: 'booked',
                _SIGNED_: 'signed',
                _ACCEPTED_: 'accepted',
                _CONFIRM_DELETE_DRAFT_: 'Are you sure you want to delete this draft?',
                _LAST_EDITED_BY_USER_: 'Last edited by {{name}} on<br />{{date}}',
                _LAST_EDITED_BY_YOU_: 'Last edited by You on<br /> {{date}}',
                _LAST_EDITED_AT_: 'Last edited on<br /> {{date}}',
                _WATCH_WELCOME_VIDEO_: 'Watch Intro'
            },
            DRAWER: {

                TITLES: {
                    _EXPIRATION_: 'Expiration',
                    _PAYMENTS_: 'Payment Options',
                },

                LABELS: {
                    _CHANGE_DEFAULT_SETTING_: 'Change default settings for all files under',
                    _AUTOMATION_: 'Automation',
                    _ACCEPT_PAYMENTS_BY_: 'Accept payments by',
                    _PRINT_TEXT_: 'Print Text Only',
                    _PRINT_ALL_: 'Print',
                    _DOWNLOAD_PDF_: 'Download PDF',
                    _ENABLE_PAYMENT_BY_: 'Enable payment by',
                    _TOGGLE_AUTO_PAY_: 'Set to auto-pay',
                    _FILE_EXPIRATION_: 'File Expiration',
                    _EXPIRE_THIS_FILE_: 'Expire this file'
                },

                EXPIRY: {
                    _EXPIRE_: 'Expire this file',
                    _NOT_SET_TO_EXPIRE_: 'This file is not set to expire',
                    _FILE_EXPIRES_: 'This file expires on',
                    _EXPIRY_WARNING_: 'Clients will still be able to view expired files, but they will not be able to sign or make payments on them. You can set all invoices or proposals to automatically expire from “Actions” under',
                    _EXPIRY_WARNING_AGREEMENT_: 'Your client won’t be able to pay or sign an expired file (though they can still view it).',
                    _AFTER_SEND_DATE_: 'after send date',
                    _NOW_: 'Now',
                    _NEVER_: 'Never',
                    _ONE_WEEK_: '1 Week',
                    _TWO_WEEKS_: '2 Weeks',
                    _THREE_WEEKS_: '3 Weeks',
                    _FOUR_WEEKS_: '4 Weeks',

                    CONFIRM_MODAL: {
                        _CONFIRM_MSG_: 'Are you sure you want to change the expiration date of this file? This will automatically update.'
                    }
                },

                PAYMENT_METHODS: {
                    _BOTH_: 'Credit Card & Bank Transfer',
                    _CREDIT_CARD_: 'Credit Card Only',
                    _BANK_TRANSFER_: 'Bank Transfer Only'
                },

                DESCRIPTIONS: {
                    _AUTO_PAY_: 'Auto-pay automatically charges payments on the due dates defined in the payment schedule. Clients will be prompted to opt in and must agree before auto-pay can take effect.'
                }
            },
            FOOTER: {
                _REVIEW_PROPOSAL_TITLE_: 'Review Your Proposal',
                _REVIEW_PROPOSAL_: 'You may go to the next step to view my contract with no commitment to book.',
                _AGREEMENT_TITLE_: 'Contract',
                _AGREEMENT_: 'E-Sign my contract by typing your name and drawing your signature. Be advised this is a legally binding contract.',
                _INVOICE_TITLE: 'Great!',
                _INVOICE: 'Now let\'s continue to make a payment.',
                _QUESTIONNAIRE_TITLE_: 'Great!',
                _QUESTIONNAIRE_: 'Now let\'s submit your answers.' // todo text!
            },
            SHARE_PVL: {
                _TEXT_1_: 'Recommend amazing creatives for this project',
                _TEXT_2_: 'We\'ll automatically share them with this client AFTER they book',
                _ATTACHMENT_LABEL_: '{{vendor}}\'s Recommended Creatives',
                _ACTION_: 'Choose Creatives'
            },
            PAYMENT: {
                _NEW_CARD_: 'New Card',
                _ADD_ACCOUNT_: 'Add Account',
                _USE_ANOTHER_CARD_: 'Use another card',
                _CHOOSE_ACCOUNT_: 'Choose Account',
                _PAYMENT_NUMBER_: '{{current}} of {{total}} Payments',
                _RECURRING_PAYMENT_START_: 'Starting {{start_date}}',
                _DUE_DATE_: 'Due Date:',
                _DUE_: 'Due: ',
                _DELETE_METHODS_: 'Done',
                _SELECT_METHODS_: 'Edit',
                _ADD_CARD_: 'Add Card',
                _NO_THANKS_: 'No Thanks',
                _CUSTOM_TIP_: 'Custom',
                _BILLING_ADDRESS_: 'Billing Address',
                _STREET_ADDRESS_: 'Street Address',
                _ZIP_CODE_: 'Zip Code',
                _CITY_: 'City',
                _STATE_: 'State',
                _COUNTRY_: 'Country',
                _PENDING_PAYMENT_TOOLTIP_: 'Bank transfers take up to 8 business days to process.',
                _AUTO_PAY_TITLE_: 'Auto-pay activated',
                _AUTO_PAY_DESC_: 'By continuing, you authorize future payments to be automatically charged in accordance with the dates and amounts defined in your payment schedule. Milestone payments will be processed once the milestone is marked complete.',
                _AUTO_PAY_CTA_: 'COMPLETE PAYMENT',
                _PREVIEW_MODE_TITLE_DONE_: 'Test complete!',
                _PREVIEW_MODE_TEST_DONE_: 'You’ve now seen what your client will experience.',
                _TEMPLATES_PREVIEW_MODE_TEST_DONE_: 'You’ve now seen what your client would experience with a file from this template.',
                _PREVIEW_MODE_DONE_: 'You’re done with the client experience!',
                _RETURN_TO_FILE_: 'Return to file',
                _RETURN_TO_TEMPLATE_: 'Return to template',
                _AUTO_PAY_BANNER_: 'This payment schedule has been set to auto-pay by {{name}}',
                _AUTO_PAY_BANNER_M_: 'Set to auto-pay by {{name}}',
                _AUTO_PAY_BY_VENDOR_DESC_: "{{name}} has set you up for auto-pay. <br />\n Any future payments on this file will be automatically charged on the due dates indicated in the payment schedule.",
                _AUTO_PAY_BY_CLIENT_DESC_: 'Any future payments on this file will be automatically charged on the due dates indicated in the payment schedule.',
                _AUTO_PAY_ACTIVE_BY_VENDOR_DESC_: "{{name}}  has set you up for auto-pay. <br /> Making a manual payment will only cover this payment. Any other future payments on this file will be automatically charged on the due dates indicated in the payment schedule.",
                _AUTO_PAY_ACTIVE_DESC_: "{{name}}  has set you up for auto-pay. <br /> Making a manual payment will only cover this payment. Any other future payments on this file will be automatically charged on the due dates indicated in the payment schedule.",
                _AUTO_PAY_ACTIVE_BY_CLIENT_DESC_: "Making a manual payment will only cover this payment. Any other future payments on this file will be automatically charged on the due dates indicated in the payment schedule.",
                _AUTO_PAY_NEXT_PAYMENTS_START_: 'Automate my next',
                _AUTO_PAY_NEXT_PAYMENTS_CARD_END_: 'with this card',
                _AUTO_PAY_NEXT_PAYMENTS_ACH_END_: 'with this account',
                _TOTAL_AMOUNT_ERROR_: 'Payment plan is <b>{{diff_amount}}</b> {{diff_type}} than grand total of <b>{{total_amount}}</b>',

                ERRORS: {
                    _INVALID_ZIP_CODE_: 'Invalid Zip Code',
                    _ENTER_CITY_: 'Enter City',
                    _SELECT_STATE_: 'Select State',
                    _ENTER_BILLING_ADDRESS_: 'Enter billing address',
                    _CARD_DECLINED_: "Oops! Your card was declined.<br />Please try again, or contact your bank for assistance.<br />",
                    _FORTER_PRE_CHARGE_DECLINE: "Oops! Your payment method was declined. Please try again, or contact your bank for assistance.<br />Any further questions? Please contact concierge@honeybook.com"
                },

                RECURRING_PAYMENT: {
                    _TITLE_: 'Your {{fileType}} is set up as a recurring payment.',
                    _DESCRIPTION_: "{{name}}  has set you up on a recurring payment. <br /> This means you will be automatically charged the indicated amount on the same day each month."
                },

                ADD_NEW_CARD: {
                    _TITLE_: 'Add Credit Card',
                    LABELS: {
                        _NAME_: 'Your Card Nickname',
                        _NUMBER_: 'Card Number',
                        _CVV_: 'CVV',
                        _DATE_: 'Expiration Date',
                        _ZIP_: 'Zip Code ',
                        _EXPIRY_: 'Expiry',
                        _POSTAL_CODE_: 'Postal Code',
                        _STREET_: 'Street Address',
                        _CITY_: 'City',
                        _STATE_: 'State',
                        _PROVINCE_: 'Province',
                        _COUNTRY_: 'Country',
                        _MESSAGE_: 'Your information is safe. All of our online transactions are processed through a secure third-party server, and we do not store your credit card information.',
                        _CORRECT_FOLLOWING_: 'Please correct the following:',
                        _NEW_CARD_: 'New Card',
                        _PAYMENT_METHOD_: 'Payment Method',
                        _CREDIT_CARD_: 'Credit Card',
                        _BILLING_ADDRESS_: 'Billing Address',
                        _ADD_CARD_: 'Add Card',
                        _PAYING_OUTSIDE_US_: 'I\'m paying from outside the US',
                        _USA_: 'United States',
                        _CANADA_: 'Canada'
                    },
                    PLACEHOLDERS: {
                        _NAME_: 'such as: Mom\'s card, Sophie\'s card, etc.',
                        _NUMBER_: '1111222233334444',
                        _CVV_: 'CVV',
                        _MONTH_: 'Month',
                        _YEAR_: 'Year',
                        _EXPIRY_: 'MM / YY',
                        _ZIP_: '12345',
                        _STREET_: '21 Jump Street',
                        _CITY_: 'Gotham',
                        _STATE_: 'State'
                    },
                    ERRORS: {
                        _FIELD_IS_REQUIRED_: 'This field is required.',
                        _REQUIRED_FIELD_: 'Required',
                        _INVALID_CARD_NUMBER_: 'The card number is invalid.',
                        _INVALID_: 'Invalid',
                        _MIN_LENGTH_: 'Please enter at least 14 characters.',
                        _DIGITS_ONLY_: 'Please enter digits only.',
                        _VALID_ZIP_: 'Invalid ZIP/Postal code',
                        _VALID_CVV_: 'Invalid CVV',
                        _FIX_INVALID_FIELDS_: 'Please fix invalid fields',
                        _GENERAL_ERROR_: 'An error occurred'
                    }
                },
                ALERT: {
                    _CONFIRM_DELETE_: 'Are you sure you want to delete this payment method?',
                    _NO_CARD_SELECTED_: 'Please select a payment method.',
                    _NO_REFUND_PAYMENT_METHOD_: 'Oops, looks like we could not find a payment method that could be used for the refund.'
                },
                TOOLTIP: {
                    _PAYMENT_DETAILS_: 'Your card can be saved for future use. All card details are encrypted and only visible to you. Our payments are powered by Stripe.',
                    _AUTO_PAY_TOOLTIP_VENDOR_: 'To cancel auto-pay turn off the toggle in the options setting on the left . Your client will need to manually pay future payments.',
                    _AUTO_PAY_TOOLTIP_CLIENT_: 'To cancel auto-pay, direct your client to the payment schedule section of their file'
                },
                LABELS: {
                    _GRATUITY_: 'Gratuity',
                    _OPTIONAL_: '(optional)',
                    _APPRECIATION_: 'Show your vendor appreciation',
                    _AMOUNT_DUE_: 'Amount Due',
                    _MONTHLY_DUE_: 'Monthly Due',
                    _ENDS_UPON_TERMINATION_: 'Ends upon termination',
                    _AMOUNT_: 'Amount',
                    _TOTAL_: 'Total',
                    _SEND_TO_: 'Sent To',
                    _NEXT_PAYMENT_: 'Your next payment is due:',
                    _NEXT_AUTOMATIC_PAYMENT_: 'Your next payment will be automatically paid:',
                    _CONTINUE_TO_PROJECT_: 'CONTINUE TO MY PROJECT',
                    _SUCCESS_: 'Success!',
                    _PAYMENT_COMPLETED_: 'You\'ve completed payment {{current}} of {{total}} for:',
                    _PAYMENT_COMPLETED_UNKNOWN_: 'Payment due on {{date}} is complete!',
                    _PROCESSING_: 'Processing your payment',
                    _NOTIFY_: 'We\'ll notify you as soon as your payment goes through.',
                    _ENABLE_AUTO_PAY_: 'Enable AutoPay',
                    _DISABLE_AUTO_PAY_: ' Turn off automatic payments.',
                    _AUTO_PAY_BY_VENDOR_: 'Your payment schedule was set to auto-pay',
                    _AUTO_PAY_BY_CLIENT_: 'Save time and set payments to auto-pay',
                    _AUTO_PAY_BY_VENDOR_M_: 'Payment schedule set to auto-pay',
                    _AUTO_PAY_BY_VENDOR_SMALL_M_: 'Payment set to auto-pay',
                    _AUTO_PAY_BY_CLIENT_SMALL_M_: 'Set payments to auto-pay',
                    _AUTO_PAY_BY_CLIENT_M_: 'Save time, set payments to auto-pay',
                    _AUTO_PAY_ACTIVE_BY_CLIENT_: 'Your payment schedule was set to auto-pay',
                    _AUTO_PAY_ACTIVE_BY_CLIENT_M_: 'Set to auto-pay by you',
                    _AUTO_PAY_AGREE_: 'I agree that future payments will be charged automatically',
                    _AUTO_PAY_AGREE_M_: 'I agree',
                    _END_OF_MONTH_: 'Charge will occur on the last day of the month for shorter months'
                },
                RECEIPT: {
                    REQUEST_PVL: {
                        _HEADER_1_: 'Still need awesome vendors for your project?',
                        _HEADER_2_: 'Tap into {{vendor_full_name}}\'s preferred vendor network.',
                        _HEADER_RECOMMEND_: 'In need of awesome vendors for your project?',
                        _SUB_HEADER_RECOMMEND_: 'Select the different types of vendors you\'re still looking for, and we\'ll ask your vendor if they know anyone to recommend!',
                        _BODY_: ' We\'ll ask if {{vendor_first_name}} has any recommendations for you.',
                        _LOOKING_FOR_: 'Select services you\'re looking for:',
                        _SEND_REQUEST_: 'REQUEST RECOMMENDATIONS',
                        _REQUEST_: 'REQUEST',
                        _SKIP_: 'SKIP',
                        _NEXT_: 'Next',
                        _RECOMENDATION_REQUESTION_: 'Click each recommendation you are interested in requesting',
                        _SELECT_THE_DIFFERENT_TYPES_: 'Select the different types of vendors you\'re sill looking for.',
                        _SELECT_THE_DIFFERENT_TYPES_HINT_: 'Hint: Booking vendors with a history of working together increases the success of your project!',
                        _INTERESTED_: 'Interested',
                        _CHECK_OUT_YOUR_PROJECT_: 'Check out your client portal',
                        _ALL_DETAILS_: 'Review your project details, upload files and message {{vendor_first_name}} directly',
                        _VIEW_PROJECT_: 'Explore Client Portal',
                        _PRINT_: 'Print this receipt',
                        _ERROR_IN_REQUEST_: "Oops, there was a problem sending the request. Please try again."
                    },
                    VIEW_PVL: {
                        _HEADER_: 'Hooray! {{vendor_name}} recommends these project professionals.',
                        _HEADER_V2_: 'Huzzah! {{vendor_name}} recommends these project pros',
                        _BODY_: 'Check out {{vendor_first_name}}\'s recommendations of trusted professionals selected <i>just for you</i>, or skip these recommendations for now and continue to your project.',
                        _BODY_V2_: 'Check out {{vendor_first_name}}\'s recommendations of trusted professionals selected just for you.',
                        _YES_BUTTON_: 'VIEW VENDOR RECOMMENDATIONS',
                        _YES_BUTTON_MOBILE_: 'VIEW VENDORS',
                        _NO_BUTTON_: 'SKIP'
                    },
                    VIEW_PUBLIC_PAGE: {
                        _BODY_: 'Check out {{vendor_first_name}}\'s recommendations of trusted professionals selected <i>just for you</i> and request a free quote today.'
                    },
                    CLIENT_LOGIN: {
                        _BOOKED_AND_PAID_: 'You\'ve now booked and paid {{vendor_name}}',
                        _CONFIRMATION_EMAIL_: 'A confirmation email was sent to your inbox.',
                        _RETURN_TO_ACCOUNT_: 'Return to {{vendor_name}}\'s account'
                    },
                },

                FOOTER: {
                    _SUBMIT_PAYMENT_: 'Subscribe now',
                    _SUBMIT_PAYMENT_AND_AUTOMATE_: 'SUBMIT AND AUTOMATE YOUR PAYMENTS',
                    _PAYMENT_METHODS_: 'Payment methods we accept',
                    _SECURE_CONNECTION_: 'Secure connection'
                },

                CARD_CONFIRM: {
                    _TITLE_: 'Confirm expiration date for card ending in ***',
                    _EXP_DATE_: 'MM/YY',
                    _PLEASE_ENTER_EXP_DATE_: 'Please enter expiration date',
                    _INCORRECT_TRY_AGAIN_: 'Incorrect. Try again',
                }
            },

            FILE_STATUS_NEW: {
                _DRAFT_: 'DRAFT',
                _SENT_: 'SENT',
                _NEW_: 'NEW',
                _VIEWED_: 'VIEWED',
                _SIGNED_: 'SIGNED',
                _PENDING_PAYMENT_: 'PENDING PAYMENT',
                _PAYMENT_VIEWED_: 'PAYMENT VIEWED',
                _RETAINER_PAID_: 'RETAINER PAID',
                _RECURRING_: 'RECURRING',
                _SUBMITTED_: 'SUBMITTED',
                _IN_PROGRESS_: 'IN PROGRESS',
                _COMPLETE_: 'COMPLETE',
                _COMPLETED_: 'COMPLETED'
            },

            FILE_STATUS: {
                UNSENT_FILE_WARNING: {
                    _REMINDER_: 'You’ve made changes to this invoice without resending it to your client. You can resend it now or save it as a draft.',
                    _REMINDER_WITH_PAYMENT_: 'Your changes have been saved as a draft.',
                    _DONT_SHOW_: 'Don\'t show me this again',
                    _OK_: 'Ok, got it',
                    _STAY_: 'Keep me here',
                    _STAY_HERE_: 'STAY HERE',
                    _RESEND_FILE_: 'RESEND {{fileType}}',
                    _KEEP_DRAFT_: 'KEEP DRAFT &amp; EXIT',
                    _WE_SAVED_YOUR_FILE_: 'Send changes to client?'
                },
                CLIENT: {
                    _CONTINUE_TO_AGREEMENT_: 'CONTINUE TO CONTRACT',
                    _SIGN_AGREEMENT_: 'SIGN CONTRACT',
                    _CONTINUE_TO_PAY_: 'CONTINUE TO PAY',
                    _LINK_TO_WORKSPACE_: 'EXPLORE HONEYBOOK',
                    _SUBMIT_QUESTIONNAIRE_: 'SUBMIT QUESTIONNAIRE',

                    PROPOSAL: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'New', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Proposal Viewed', //8,
                        _AGREEMENT_SEEN_: 'Contract Viewed', //9
                        _AGREEMENT_SIGNED_: 'Signed', //10
                        _PAYMENTS_SEEN_: 'Pay', //11
                        _PAYMENT_PENDING_: 'Payment Pending',
                        _RETAINER_PAID_: 'Retainer Paid', //12
                        _PAYMENT_DONE_: 'Complete' //13
                    },
                    INVOICE: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'New', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Invoice Viewed', //8,
                        _AGREEMENT_SEEN_: 'Invoice Viewed', //9
                        _AGREEMENT_SIGNED_: 'Invoice Viewed', //10
                        _PAYMENTS_SEEN_: 'Pay', //11
                        _PAYMENT_PENDING_: 'Payment Pending',
                        _RETAINER_PAID_: 'Invoice Paid', //12
                        _PAYMENT_DONE_: 'Complete' //13
                    },
                    QUESTIONNAIRE: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'New', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Questionnaire Viewed', //8,
                        _AGREEMENT_SEEN_: 'Questionnaire Viewed', //9
                        _AGREEMENT_SIGNED_: 'Questionnaire Viewed', //10
                        _PAYMENTS_SEEN_: 'Questionnaire Viewed', //11
                        _RETAINER_PAID_: 'Questionnaire Viewed', //12
                        _PAYMENT_DONE_: 'Submitted' //13
                    },
                    BROCHURE: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'New', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Brochure Viewed', //8,
                        _AGREEMENT_SEEN_: 'Brochure Viewed', //9
                        _AGREEMENT_SIGNED_: 'Brochure Viewed', //10
                        _PAYMENTS_SEEN_: 'Brochure Viewed', //11
                        _RETAINER_PAID_: 'Brochure Viewed', //12
                        _PAYMENT_DONE_: 'Replied', //13
                        _ADD_PACKAGE_: 'Add', //14
                        _PACKAGE_TITLE_: 'Package Title'
                    },
                    TIMELINE: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'New', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Timeline Viewed', //8,
                        _AGREEMENT_SEEN_: 'Timeline Viewed', //9
                        _AGREEMENT_SIGNED_: 'Timeline Viewed', //10
                        _PAYMENTS_SEEN_: 'Timeline Viewed', //11
                        _RETAINER_PAID_: 'Timeline Viewed', //12
                        _PAYMENT_DONE_: 'Complete' //13
                    },
                    AGREEMENT: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'Contract Sent', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Contract Viewed', //8,
                        _AGREEMENT_SEEN_: 'Contract Viewed', //9
                        _AGREEMENT_SIGNED_: 'Contract Signed', //10
                        _PAYMENTS_SEEN_: 'Contract Signed', //11
                        _RETAINER_PAID_: 'Contract Signed', //12
                        _PAYMENT_DONE_: 'Contract Signed' //13
                    }
                },
                VENDOR: {
                    PROPOSAL: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'Sent', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Proposal Viewed', //8,
                        _AGREEMENT_SEEN_: 'Contract Viewed', //9
                        _AGREEMENT_SIGNED_: 'Signed', //10
                        _PAYMENTS_SEEN_: 'Payment Viewed', //11
                        _PAYMENT_PENDING_: 'Payment Pending',
                        _RETAINER_PAID_: 'Retainer Paid', //12
                        _PAYMENT_DONE_: 'Complete' //13
                    },
                    INVOICE: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'Sent', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Invoice Viewed', //8,
                        _AGREEMENT_SEEN_: 'Invoice Viewed', //9
                        _AGREEMENT_SIGNED_: 'Invoice Viewed', //10
                        _PAYMENTS_SEEN_: 'Payment Viewed', //11
                        _PAYMENT_PENDING_: 'Payment Pending',
                        _RETAINER_PAID_: 'Invoice Paid', //12
                        _PAYMENT_DONE_: 'Complete' //13
                    },
                    QUESTIONNAIRE: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'Sent', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Questionnaire Viewed', //8,
                        _AGREEMENT_SEEN_: 'Questionnaire Viewed', //9
                        _AGREEMENT_SIGNED_: 'Questionnaire Viewed', //10
                        _PAYMENTS_SEEN_: 'Questionnaire Viewed', //11
                        _RETAINER_PAID_: 'Questionnaire Viewed', //12
                        _PAYMENT_DONE_: 'Submitted' //13
                    },
                    BROCHURE: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'Sent', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Brochure Viewed', //8,
                        _AGREEMENT_SEEN_: 'Brochure Viewed', //9
                        _AGREEMENT_SIGNED_: 'Brochure Viewed', //10
                        _PAYMENTS_SEEN_: 'Brochure Viewed', //11
                        _RETAINER_PAID_: 'Brochure Viewed', //12
                        _PAYMENT_DONE_: 'Replied' //13
                    },
                    TIMELINE: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'Sent', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Timeline Viewed', //8,
                        _AGREEMENT_SEEN_: 'Timeline Viewed', //9
                        _AGREEMENT_SIGNED_: 'Timeline Viewed', //10
                        _PAYMENTS_SEEN_: 'Timeline Viewed', //11
                        _RETAINER_PAID_: 'Timeline Viewed', //12
                        _PAYMENT_DONE_: 'Complete' //13
                    },
                    AGREEMENT: {
                        _NEW_WORKSPACE_CREATE_: 'New', //0
                        _WELCOME_EMAIL_SENT_: '',//1
                        _WELCOME_BROCHURE_SEEN_: '',// 2,
                        _QUESTIONNAIRE_SEEN_: '',//3,
                        _QUESTIONNAIRE_ANSWER_: '',//4,
                        _PROPOSAL_CREATED_: 'New', //5
                        _PROPOSAL_SENT_: 'Contract Sent', //6
                        _PROPOSAL_BROCHURE_SEEN_: '',//7,
                        _PROPOSAL_SEEN_: 'Contract Viewed', //8,
                        _AGREEMENT_SEEN_: 'Contract Viewed', //9
                        _AGREEMENT_SIGNED_: 'Contract Signed', //10
                        _PAYMENTS_SEEN_: 'Contract Signed', //11
                        _RETAINER_PAID_: 'Contract Signed', //12
                        _PAYMENT_DONE_: 'Contract Signed' //13
                    }
                }
            },
            DELETE: {
                _ALERT_: 'Are you sure you want to delete ',
                _ALERT_FILE_NAME_: ' "{{ workspace_file_name }}" ?',
                _ALERT_ERROR_: 'Error Deleting the File.',
                _YES_: 'YES',
                _NO_: 'NO',
                _ERROR_: 'Error deleting file.'
            },
            BLOCK: {
                _ERROR_: 'Error marking file as expired'
            },
            UNBLOCK: {
                _ERROR_: 'This file cannot be reactivated. Please contact us for more info'
            },
            TITLE_MODAL: {
                _DEFAULT_: 'Untitled File',
                _TITLE_: 'Name before sharing',
                _SUMMARY_: 'Give your untitled document a name before it\'s shared with your client. For example, "Office Holiday Party."',
                _INPUT_PLACEHOLDER: 'File Name',
                _NAME_ERROR_: '* Please rename your file'
            },
            SEND_FILE: {
                ERROR: 'There are no members in the workspace and the add user dialog is implemented!!!',
                _SEND_ON_BEHALF_: 'You\'re about to send an email on behalf of {{ownerName}} ({{ownerEmail}}). Are you sure you want to send?',
                _NO_MEMBERS_ON_BEHALF_: 'There are currently no clients in the workspace. You\'ll be able to send the file once the file owner {{ownerName}} adds members to the workspace.',
                _NO_CLIENT_SIGNATORY_: 'Please add a signature field before sending this {{fileType}}.',
                _ADD_SIGNATURE_BUTTON_: 'Add Signature',
                _RESEND_: 'Resend',
                EMAIL_MODAL: {
                    _NOT_OWNER_LABEL_: 'You\'re not the owner of this file. This message will be sent on behalf of<br>{{ownerName}} ({{ownerEmail}})',
                    PVL: {
                        _NO_PREFERRED_VENDORS_: 'Oops! You requested to share your recommended creatives but your list is still empty. Please edit your list before sending the file.'
                    }
                },
                GENERAL_FILE: {
                    _NEXT_: 'Next: Review Email',
                    _NEXT_ADD_CLIENT_: 'Next: Add Recipient'
                },
                PROPOSAL: {
                    _SEND_: 'Send Proposal',
                    _RESEND_: 'Resend Proposal',
                    _SEND_DOTS_: 'Send Proposal...',
                    _NEXT_: 'Next: Review Email'
                },
                INVOICE: {
                    _SEND_: 'Send Invoice',
                    _RESEND_: 'Resend Invoice',
                    _SEND_DOTS_: 'Send Invoice...',
                    _NEXT_: 'Next: Review Email'
                },
                QUESTIONNAIRE: {
                    _SEND_: 'Send Questionnaire',
                    _RESEND_: 'Resend Questionnaire',
                    _NO_QUESTIONS_MESSAGE_: 'Hmm, it looks like you didn’t add any questions. Please add at least one question to your questionnaire before sending it.',
                    _NUMBER_OF_QUESTIONS_LEFT_: 'There are {{emptyRequiredQuestionsCounter}} required questions highlighted. Please answer these questions.',
                    _ONE_QUESTION_LEFT_: 'There is 1 required question highlighted. Please answer this question.',
                    _ALL_FIELDS_FILLED_: 'Excellent! All fields are filled! You may sign the contract now.'
                },
                BROCHURE: {
                    _SEND_: 'Send Brochure',
                    _RESEND_: 'Resend Brochure',
                    _ONE_COLLECTION_WARNING_: 'Only one package?',
                    _NO_COLLECTIONS_WARNING_: 'No packages?',
                    _NO_DATA_WARNING_: 'Add more packages to let your client select the one most suitable for them.'
                },
                DIRECT_PAYMENT: {
                    _PAY_TO_: 'pay to:',
                    _PAYMENT_DONE_: 'Congrats, you have successfully paid your client'
                },
                TIMELINE: {
                    _SEND_: 'Send Timeline',
                    _RESEND_: 'Resend Timeline'
                },
                AGREEMENT: {
                    _SEND_: 'Send Contract',
                    _RESEND_: 'Resend Contract'
                }
            },
            TEMPLATES: {
                PACKAGES: {
                    _DELETE_CONFIRM_MESSAGE_: "Are you sure? Click Confirm to delete this package.",
                    _ERROR_DELETE_LAST_MESSAGE_: "Cannot delete last package template",
                    _NEW_PACKAGE_LABEL_: 'New Package',
                    _TEMPLATE_TITLE_PLACEHOLDER_: 'Template Title'
                },
                PROPOSAL: {
                    _SELECT_CONFIRM_MESSAGE_: 'Are you sure you want to use a different proposal template? Selecting a new template will delete existing services within the Proposal.'
                },
                PAYMENTS: {
                    _SELECT_CONFIRM_MESSAGE_: 'Are you sure you want to use a new payment schedule? Any changes made to the existing schedule will be lost.'
                },
                QUESTIONNAIRE: {
                    _SELECT_CONFIRM_MESSAGE_: 'Are you sure you want to use a different questionnaire template? Selecting a new template will delete your existing questions.'
                },
                BROCHURE: {
                    _SELECT_CONFIRM_MESSAGE_: 'Are you sure you want to use a different brochure template? Any changes made to the existing brochure will be lost.',
                    _DELETE_A_LA_CARTE_CONFIRM_MESSAGE_: 'Are you sure? Click Confirm to delete this a la carte section.',
                    _DELETE_COLLECTION_CONFIRM_MESSAGE_: 'Are you sure? Click Confirm to delete this collection.',
                    _DELETE_INTRO_CONFIRM_MESSAGE_: 'Are you sure? Click Confirm to delete this intro.'

                },
                AGREEMENT: {
                    _SELECT_CONFIRM_MESSAGE_: 'Are you sure you want to use a new contract? Any changes made to the existing contract will be lost.',
                    _ERROR_DELETE_LAST_MESSAGE_: "Cannot delete last contract template",
                    _ERROR_DELETE_EXAMPLE_TEMPLATE_: "Sorry, you can not delete an example template"
                },
                TIMELINE: {
                    _SELECT_CONFIRM_MESSAGE_: 'Are you sure you want to use a different timeline template? Selecting a new template will delete your existing sections.'
                },
                EMAIL: {
                    _ERROR_DELETE_DEFAULT_EMAIL_MESSAGE_: 'Cannot delete default email template',
                    _DELETE_EMAIL_CONFIRM_MESSAGE_: 'Are you sure? click confirm to delete this email template.'
                },
                _TEMPLATE_LABEL_: 'Template',
                _MISSING_TEMPLATE_NAME_: 'Add Template Name',
                _ADD_TEMPLATE_: 'Save file as new template',
                _TEMPLATE_PDF_INVALID_: 'Could not read PDF file. Your file may be corrupt for unreadable at this time. Please try a different file',
                _CHOOSE_TEMPLATE_: 'Choose Template',
                _SAVE_TEMPLATE_AS_: 'Duplicate',
                _SAVE_TEMPLATE_: 'Save',
                _ERROR_OCCUR_MESSAGE_: 'Oops, there was a problem deleting the template. Please try again.',
                _ERROR_SAVE_OCCUR_MESSAGE_: 'Oops, there was a problem saving the template. Please try again.',
                _ERROR_SELECT_OCCUR_MESSAGE_: 'Oops, there was a problem selecting the template. Please try again.',
                _CONFIRM_SAVE_TEMPLATE_MESSAGE_: 'This action will override {{template_name}} and replace it with this new template. Are you sure you want to proceed?',
                _SELECTION_TITLE_: '{{type}} Templates',
                _SELECTION_SUB_TITLE_: 'Your saved templates are stored here. Select a template to build on.',
                _DELETE_CONFIRM_MESSAGE_: 'Are you sure? Click Confirm to delete this {{type}} template.',
                _DELETE_CONTACT_FORM_CONFIRM_MESSAGE_: 'Published forms must be manually removed from your website. Click Confirm to delete this template',
                _ERROR_DELETE_LAST_MESSAGE_: 'Cannot delete last {{type}} template',
                _ERROR_DELETE_EXAMPLE_TEMPLATE_: 'Sorry, you can\'t delete an example template',
                _NEW_TEMPLATE_TITLE_: 'Add a title to your new template',
                _CANNOT_SELECT_TEMPLATE_SENT_: 'You can not select a template after the file was sent',
                _CANNOT_SELECT_TEMPLATE_EXPIRED_: 'You can not select a template after the file was expired',
                _CANNOT_SELECT_TEMPLATE_SUBMITTED_: 'You can not select a template after the file was submitted',
                _SET_AS_DEFAULT_: 'Set as Default',
                _CONFIRM_TEMPLATE_CHANGE_: 'Are you sure you want to load this template? Any changes made will be lost',
                _CONFIRM_CREATE_NEW_VERSION_: 'Applying this template will create a new version of this file. Are you sure you want to continue?',
                _CANT_SAVE_AS_RECURRING_TEMPLATE_: 'You are not able to save this {{fileType}} as a template as it is set to recurring payment',
                _CANT_APPLY_OLD_BROCHURE_TEMPLATE_: 'You are not able to select an old brochure template in a new brochure file',
                _CANT_APPLY_NEW_BROCHURE_TEMPLATE_: 'You are not able to select a new brochure template in an old brochure file',
            },
            _CHANGE_COMPANY_ICON_: 'Change icon',
            CLIENT_ONSITE: {
                _BOOK_IN_PERSON_: 'Book In Person',
                _VERIFY_CLIENT_: 'Verification Required',
                _SEND_CODE_DESCRIPTION_: 'Send your client a verification code to {{client_email}} to proceed with your in person booking.',
                _SEND_CODE_: 'Send code',
                _ENTER_CODE_TITLE_: 'Enter Code',
                _ENTER_CODE_DESCRIPTION_: 'Enter the verification code that was just sent to the email {{client_email}}.',
                _VERIFICATION_CODE_: 'Verification code',
                _RESEND_CODE_: 'Resend Code',
                _CODE_SENT_: 'Code sent!',
                _CODE_ERROR_: 'Verification code is not valid',
                _SUCCESS_: 'Verified!',
                _SUCCESS_DESCRIPTION_: 'You will be taken to {{client_first_name}}\'s account to complete the transaction.',
                _HAPPY_BOOKING_: 'Happy booking!',
                _GO_TO_ACCOUNT_: 'OK',

                UNAUTHORIZED_POPUP: {
                    _TITLE_: 'Booking incomplete',
                    _BODY_: 'Complete this payment to finalize your in-person booking, or click below to exit and return to your account.',
                    _COMPLETE_: 'COMPLETE BOOKING',
                    _RETURN_: 'RETURN TO ACCOUNT'
                }
            },
            TOAST: {
                _LEARN_MORE_: 'Learn more',
                _ACH_PUSH_TOAST_: 'Did you know that you can save ${{ach_saving}} in transaction fees by having your client pay via bank transfer?',
                RESCHEDULE_SUCCESS_TOAST: {
                    _TITLE_: 'Project successfully updated'
                }

            }
        },

        QUESTIONNAIRE: {
            _CONNECTED_TO_PROJECT_TOOLTIP_TITLE_: 'answer will be shared with the project',
            _CONNECTED_TO_PROJECT_TOOLTIP_LOCATION_INFO_: 'this answer will fill out the location info in your project',
            _CONNECTED_TO_PROJECT_TOOLTIP_LOCATION_SHORT_: 'This answer is connected to the location info in your project',
            _THANK_YOU_: 'Thank You!',
            _QUESTIONNAIRE_SUBMISSION_CONFIRMATION_: 'Are you sure you want to submit? After submitting your answers you won’t be able to edit them.',
            _QUESTIONNAIRE_SUBMITTED_: 'You\'ve submitted questionnaire\'s answers for:',
            _QUESTION_TITLE_PLACEHOLDER_: 'Type Your Question Here...',
            _INTRO_TEXTBOX_TITLE_PLACEHOLDER_: 'This is a static text area where you can add an introduction or welcome message.',
            _TEXTBOX_TITLE_PLACEHOLDER_: 'Type Your Text Here...',
            _QUESTION_ANSWER_PLACEHOLDER_: 'Customize Your Placeholder Text Here...',
            _QUESTION_DROPDOWN_DEFAULT_: 'Choose an answer',
            _ADD_OPTIONAL_ANSWER_: 'Add answer option',
            _REMOVE_OPTIONAL_ANSWER_: 'Remove',
            _PROJECT_QUESTIONS_EXISTS_WARNNING_: 'Some of the project connected questions already exists on your questionnaire. Only missing questions will be added.',
            VIEW_PREVIEW: {
                _QUESTION_RADIO_TITLE_PLACEHOLDER_: 'Select the best option:',
                _QUESTION_CHECKBOX_TITLE_PLACEHOLDER_: 'Check all that apply:',
                _QUESTION_DROPDOWN_TITLE_PLACEHOLDER_: 'Select the best option:'
            },
            MORE_TEMPLATES_MENU: {
                _QUESTIONNAIRE_TEMPLATE_: "Questionnaire Template"
            },
            TOOLTIP: {
                _EDIT_QUESTION_: 'Click to edit question'
            },
            ADD_QUESTION_OR_SECTION: {
                _BUTTON_: 'Add Question or Section',
                _CUSTOM_QUESTIONS_: 'CUSTOM FIELDS',
                _TEXT_: 'One line of text',
                _TEXT_DESCRIPTION_: 'Free form answers.',
                _TEXTAREA_: 'Text Area',
                _TEXTAREA_DESCRIPTION_: 'Longer free form answers.',
                _MULTIPLE_CHOICE_: 'Radio Buttons',
                _MULTIPLE_CHOICE_DESCRIPTION_: 'Choose one from given options.',
                _CHECKBOXES_: 'Checkboxes',
                _CHECKBOXES_DESCRIPTION_: 'Choose one or more of given options.',
                _DROPDOWN_LIST_: 'Dropdown List',
                _DROPDOWN_LIST_DESCRIPTION_: 'Choose one from list of given options.',
                _SECTIONS_: 'SECTIONS',
                _STATIC_TEXT_AREA_: 'Static Text Area',
                _STATIC_TEXT_AREA_DESCRIPTION_: 'Use this for intros, tips, and other messages.',
                _COLLECT_PROJECT_INFORMATION_: 'Collect Project Information',
                _COLLECT_PROJECT_INFORMATION_DESCRIPTION_: 'Collect general information about the project including location and time.',
                _ADD_ANSWER_OPTION_: 'Add answer option',
                _REQUIRED_QUESTION_: 'Required question',
                _ANSWER_CONNECTED_TO_: 'Answer connected to',
                _IN_YOUR_PROJECT_: 'in your project.',
                _ADD_SUGGESTED_FIELDS_: 'SUGGESTED FIELDS',
                _PROJECT_TYPE_: 'Project Type',
                _CLIENT_PHONE_NUMBER_: 'Phone Number',
                _ADDITIONAL_CLIENT_INFO_: 'Additional Client Info',
                _PROJECT_BUDGET_: 'Project Budget',
                _GET_DEETS_CLIENT_BUDGET_: 'Get deets on your client\'s budget',
                _PROJECT_LOCATION_: 'Project Location',
                _WHERE_IS_HAPPENING_: 'Where is this project happening?',
                _GUEST_COUNT_: 'Guest Count',
                _HOW_DID_YOU_HEAR_ABOUT_: 'Lead Source',
                _HOW_MANY_GUESTS_: 'Hmm. How many guests are there?',
                _PROJECT_DATE_: 'Project Date'

            },
            SELECT_INQUIRY_RECIPIENT: {
                _TITLE_: 'Select Inquiry Recipient'
            }
        },

        CONTACT_FORM: {
            HOME_AWARENESS: {
                _PRO_TIP_: 'PRO TIP:',
                _TEXT_: 'Streamline your projects into HoneyBook',
                _LINK_TEXT: 'by installing the Contact Form'
            },
            LABELS: {
                _GOOGLE_SPAM_PROTECTION_DISCLAIMER_: 'HoneyBook uses <strong>Google</strong> as your<br />{{contactFormType}} spam protection',
                _LEARN_MORE_: 'Learn more about this',
                _STYLE_: 'Style',
                _AUTOMATION_: 'Automation',
                _INPUT_FIELD_COLOR_: 'Input Field Color',
                _FIELD_BORDER_COLOR_: 'Field border color:',
                _FONT_COLOR_: 'Font Color:',
                _TEXT_COLOR_: 'Text color:',
                _FONT_TYPE_: 'Font Type:',
                _FONT_: 'Font:',
                _SAVE_TIME_: 'Save Time',
                _SEND_EMAIL_: 'Send email',
                _CHOOSE_AN_ATTACHMENT_: 'Choose an attachment',
                _BROCHURE_: 'Brochure',
                _QUESTIONNAIRE_: 'Questionnaire',
                _PUBLISH_CHANGES_: 'PUBLISH CHANGES',
                _PUBLISH_: 'PUBLISH',
                _UNDO_: 'Undo',
                _ADD_TO_WEB_SITE_: 'ADD TO WEBSITE',
                _EMBED_CODE_: 'EMBED CODE',
                _TRADE_SHOW_CONTACT_: 'Trade show contact form',
                _PRO_TIP_: 'Pro tip: Quickly collect client info in person at trade shows',
                _COLLECTING_INQUIRIES_IN_PERSON_: 'A full screen experience for collecting inquiries in person. Great for professional projects and bridal shows. This form will have the same content, settings and style as the embedded contact form below.',
                _UNPUBLISHED_: 'Important: You have unpublished changes.',
                _UNPUBLISHED_BOLD_: 'Important:',
                _UNPUBLISHED_TEXT_: ' You have unpublished changes.',
                _INACTIVE_UNPUBLISHED_TEXT_: 'You have unpublished changes on an inactive gift card',
                _INACTIVE_UNPUBLISHED_SUB_TEXT_: 'Your clients will not be able to access this page. ',
                _WHAT_WILL_THEY_SEE_: 'What will they see?',
                _STREAMLINE_LEADS_BANNER_TEXT_: 'Your Contact Form should feel like you! Not sure where to start?',
                _STREAMLINE_LEADS_VIEW_EXAMPLE_TEXT_: 'Check out some examples',
                _STREAMLINE_LEADS_BANNER_TEXT_GIFT_: 'Your Gift Card should feel like you! Get inspired',
                _STREAMLINE_LEADS_VIEW_EXAMPLE_TEXT_GIFT_: 'Start from a design',
                _ACTIVE_BOLD: 'Your Contact form is Active: ',
                _ACTIVE_TEXT: 'Last inquiry received on {{date}}',
                _MOBILE_LEAD_SHARE_: 'Mobile Lead Share App',
                _SELECT_TEAM_MEMBER_: 'Select team member',
                _SELECT_SPAM_PROTECTION_: 'Select spam protection',
                _SPAM_PROTECTION_: 'spam protection',
                _INSTANT_PAYMENT_: 'Instant Payment',
                _INSTANT_PAYMENT_POPOVER_: 'Our Instant Payments feature <br/> automatically directs clients to a <br/> payment screen once their gift <br/> card form is submitted!',
                _BETA_: 'BETA',
                _GOOGLE_PROTECTION_: 'Google protection',
                _REDIRECT_: 'after form completion Redirect to',
                _AUTOMATION_SETTINGS_: 'Automate Contact Form',
                _ASSIGN_PROJECT_TYPE_: 'Assign Project Type:',
                _NEW_AUTOMATION_DESCRIPTION_: 'Set a sequence of actions that happen <br/>after the form is submitted.',
                _AUTOMATE_PROJECT_TYPE_HOVER_: 'Assign a default project type to <br/>your contact form that will trigger <br/>an automation based on that <br/>project type for all incoming <br/>inquiries.',
                _USE_WORKFLOWS_: 'VIEW AUTOMATIONS',
                _GO_TO_WORKFLOWS_: 'Go to automations',
                _GOOGLE_SPAM_PROTECTION_: 'Google spam protection',
                _HONEYBOOK_PROTECTION_: 'HoneyBook spam protection',
                _SELECT_REDIRECT_URL_: 'After form completion redirect to',
                _DEFAULT_THANK_YOU_: 'Default Thank you message',
                _THANK_YOU_HOVER_: 'When your client submits a <br/>form, we’ll show a default <br/>Thank You message to confirm <br/>receipt.',
                _THANK_YOU_URL_: 'Your own custom URL',
                _DIRECT_LINK_: 'direct link',
                _DIRECT_LINK_DESCRIPTION_: 'Open this Contact Form anywhere\nby using the link below',
                _EXIT_CONTACT_FORM_PREVIEW_: 'EXIT PREVIEW',
                _RESET_CONTACT_FORM_PREVIEW_: 'RESET FORM',
                _INCOMING_LEADS_RECEPIENTS_: 'incoming leads recipients',
                _INCOMING_LEADS_RECEPIENT_: 'incoming leads recipient',
                _INACTIVE_PREVIEW_TEXT_: 'This gift card is currently set to <b>"inactive"</b>, so clients cannot access this page.'
            },
            ERRORS: {
                _INVALID_QUESTION_: 'One of your questions does not have all the mandatory data',
                _INVALID_FORM_: 'Just a sec, some of the fields are still empty or invalid. press Confirm and look for the red fields.',
                _CANT_SET_PROJECT_TYPE_: 'You must remove the Project Type question from the contact form before you can assign a default Project Type in the settings.',
                _PROJECT_TYPE_QUESTION_CONFLICT_: 'You must remove the assigned Project Type in the contact form settings before you can add a Project Type question to the form.',
                _INVALID_REDIRECT_URL_: 'Hold on! You need a valid custom URL to successfully redirect your clients.',
            },
            VALIDATIONS: {
                _UNDO_: 'Are you sure you want to undo all changes that have been made since last publish?',
                _CONFIRM_: 'Confirm',
                _CANCEL_: 'Cancel'
            },
            ACTIVATION: {
                _TITLE_: 'Are you sure?',
                _MESSAGE_: 'Making the gift card "inactive" will prevent clients from viewing or purchasing this gift card. If they access the link, they’ll be prompted to contact you or visit your website. <a target="_blank" href="{{url}}">What will clients see?</a>',
                _CONFIRM_: 'YES, MAKE IT INACTIVE'
            },
            AUTOMATION: {
                _AUTOMATION_DESC_: 'Automatically send clients a templated email when they submit an inquiry through my Contact Form.',
                _WORKFLOW_LINK_: 'Use workflows to automate your inquiry responses'
            },
            COMPANY: {
                DYNAMIC_CF_TEASER: {
                    _TITLE_: 'The new Contact Form builder is here!',
                    _DESC_: 'Get the awesome new features that enhance the way you capture leads 💪',
                    _CONVERT_BUTTON_: 'CONVERT NOW',
                    _EXPLORE_BUTTON_: 'EXPLORE THE NEW FEATURES',
                },
                MISSING_URL: {
                    _UPDATE_URL_PREFIX_: 'Before adding the form to your site go to the',
                    _UPDATE_URL_LINK_: 'Company tab',
                    _UPDATE_URL_SUFFIX_: 'and make sure the <strong>Company Website</strong> field has been filled out.'
                },

                IS_INSTALLED: {
                    _IS_INSTALLED_HEADER_: 'Successfully Installed',
                    _IS_INSTALLED_DESC_: 'Automagically bring leads into the Pipeline using the customizable HoneyBook contact form on your site. Click below on a field to edit, delete, or re-order.'
                },
                IS_INSTALLED_OLD: {
                    _IS_INSTALLED_OLD_HEADER_: 'Old version installed warning',
                    _IS_INSTALLED_OLD_DESC_: 'Your existing contact form will be overwritten after publishing the new form. Make sure to migrate all your existing fields and design.'
                }
            },
            WEBSITE_BUILDER: {
                _IDENTIFYING_BUILDER_: 'Identifying your website builder…',
                _WORKS_WITH_YOUR_WEBSITE_: 'Works with your {{builder}} website!',
                _GENERAL_WEBSITE_: 'Check out our',
                _LINK_LABEL_: 'Visit our guide',
                _GENERAL_LINK_LABEL_: 'Step by step guides',
                _NOT_USING_FOUND_GENERAL_TRY_: 'Not using {{builder}}? Try our other guides',
                _EMBED_CODE_BUILDER_FOUND_: 'VISIT THE {{builder}} GUIDE',
                _NOT_USING_NOT_FOUND_GENERAL_TRY_: 'Visit our guides supporting major website builders:',
                _HELP_TOOL_TIP_: 'For general instructions <br/>use the help center',
                _HELP_CENTER_: 'Help Center'
            },
            INTRO_MODAL: {
                _TITLE_: 'The new contact form is here!',
                _SUBTITLE_1_: 'Add your images and content',
                _SUBTITLE_2_: 'Create custom layouts',
                _SUBTITLE_3_: 'Choose more fonts and colors',
                _DISCLAIMER_: 'Don’t worry! Your published Contact Form remains intact.\nTo go live with the new features, you’ll just need to re-embed the code.',
                _DISCLAIMER_LAST_STEP_: 'Converting will remove your ability to return to the old builder.\nYour published Contact Forms will remain intact.',
                _CTA_CONFIRM_: 'CONVERT TO THE NEW BUILDER',
                _CTA_CANCEL_: 'NOT RIGHT NOW',
            },
            TEMPLATE_EXAMPLES_MODAL: {
                _TITLE_: 'Let your brand shine',
                _SUBTITLE_1_: 'See some examples of what you can do with the new Contact Form builder',
                _SUBTITLE_GIFT_CARD_: 'See some examples of what you can do to make your Gift Card campaign your own',
                _CTA_CONFIRM_: 'I\'M IMPRESSED, LET\'S START',
                _CTA_HOVER: 'LIVE PREVIEW',
                _TITLE_CREATE_NEW_: 'How would you like to start?',
                _SUBTITLE_CREATE_NEW_: 'Let your brand shine by selecting one of these {{templateType}} templates',
                _GIFT_CARD_: 'Gift Card',
                _CONTACT_FORM_: 'Contact Form',
            },

            DIRECT_LINK_MODAL: {
                _TITLE_: 'Collect leads from anywhere',
                _SUBTITLE_: 'Your Contact Form is already live and ready to collect leads — so share a link and get booked faster!',
                _GET_DIRECT_LINK_: 'Get a Direct link',
                _NO_EMBEDED_: 'No embedding necessary!',
                _COPY_CLIPBOARD_: 'COPY LINK',
                _DIRECT_BOOKING_: 'Need sharing ideas?',
                _DIRECT_BOOKING_SUBTITLE_: 'Get booked through channels like Facebook and Linktree',
                _DISPLAY_HEADER_: 'Display header',
                _DISPLAY_HEADER_TOOLTIP_: '{{"What\'s in the header?" | hbmarker:"bold"}} <br/> Your direct default header displays company info from your settings e.g your logo, company website ect.'
            },
            GIFT_CARD_DIRECT_LINK_MODAL: {
                _FIRST_PUBLISH_TITLE_: 'Your Gift Card is now live!',
                _TITLE_: 'Your changes have been successfully published!',
                _SHARE_: 'Use your link and gift card image to <b>share everywhere</b>',
                _SHARE_INACTIVE_: 'Be sure to <b>activate your Gift Card</b> so you can share it everywhere',
                _LEARN_MORE_: "Learn about your clients’ experience with your gift card",
                _COPY_: 'COPY',
                _COPIED_: 'COPIED',
                _DOWNLOAD_: 'Download gift card sharable image',
                _HELP_CENTER_: 'This is your company’s URL you can customize it under "Client portal" in your Company Settings',
                _INACTIVE_: 'Changes to your <b>Inactive Gift Card</b> were published successfully!',
                _GET_DIRECT_LINK_: "Your Gift Card link"
            },
            DYNAMIC_INTRO: {
                _TITLE_: 'Meet the new Contact Form',
                _DESC_: 'Hi {{firstName}}! Capturing leads just got even easier. Check out this quick tour to learn more!',
                _DISCLAIMER_: 'Don\'t worry! Any contact form currently on your website will stay the same until you actively embed the new code.',
                _SHOW_ME_: 'Show Me',
                _SKIP_TOUR_: 'Skip Tour',
                _GET_TO_WORK_: 'Let\'s get to work',
                _TAKE_THE_TOUR_: 'Take The Tour',
            },
            DYNAMIC_TUTORIAL: {
                _TITLE_: 'Check out what’s new',
                _SUBTITLE_: 'Capturing your leads just got better!',
                _SUBTITLE_LINK_: 'Watch this later',
            },
            MEMBERS: {
                ROLES: {
                    _OWNER_: 'Project owner',
                    _OWNER_SUBTEXT_: 'Controls the Project’s Workspace',
                    _LEADS_RECIPIENT_: 'Leads recipient',
                    _LEADS_RECIPIENT_SUBTEXT_: 'Only receives an email notification',
                    _WORKSPACE_PARTICIPANT_: 'Workspace participant',
                    _WORKSPACE_PARTICIPANT_SUBTEXT_: 'Can collaborate in a Project’s Workspace'
                },
                _MODAL_TITLE_: 'Select team members as incoming leads recipients',
                _MODAL_SUBTITLE_: 'Selected leads recipients will receive notifications about incoming leads only via email. <br/>You can also choose to set members as workspace participant or project owner'
            },
            _DELETE_LEAD_SOURCE_CONFIRMATION_TITLE_: 'Are you sure?',
            _DELETE_LEAD_SOURCE_CONFIRMATION_MESSAGE_: 'This question capture information about where your leads are coming from and the insights are shared in your reports.',
            _YES_DELETE_: 'YES, DELETE',
            _CANCEL_: 'CANCEL',
            FLOW_TYPES: {
                _GIFT_CARD_: 'Gift Card'
            }

        },

        REPORTS: {
            _TITLE_: 'REPORTS',
            BANNER: {
                UPDATE: {
                    _HEADING_: 'UPDATE',
                    _BODY_: 'We\'ve updated Reports to give you a quick glance at all your future HoneyBook projects by date range. Adjust dates under <strong>"Your HoneyBook Projects"</strong> and download the CSV for more information!',
                },
            },
            ERRORS: {
                _UNKOWN_ERROR_: 'Oops! There seems to be a problem getting some of the information for this page. Please try to open this page again.',
                _GENERATE_REPORT_ERROR_: 'Oops! Something went wrong while generating a requested report. Please contact us if this error persists',
                _TIMEOUT_: 'Sorry, the action took too long. Please refresh the page or try again later.'
            },
            TABS: {
                _ANALYTICS_: 'Analytics',
                _CALENDAR_: 'Calendar',
                _CALENDARS_: 'Calendars',
                _PAYMENTS_: 'Payments',
                _REFERRALS_: 'Referrals'
            },

            FILTER_START_DATE: {
                _30_DAYS_ : 'Last 30 days',
                _90_DAYS_ : 'Last 90 days',
                _120_DAYS_ : 'Last 120 days',
                _365_DAYS_ : 'Last 365 days',
                _CUSTOM_ : 'Custom',

            },
            PROFIT_AND_LOSS_TABLE: {
                _TOTAL_INCOME_: 'Total Income',
                _COST_OF_GOODS_: 'Cost of Goods Sold',
                _GROSS_PROFIT_: 'Gross Profit',
                _TOTAL_EXPENSES_: 'Total Expenses',
                _NET_PROFIT_AND_LOSS_: 'Net Profit/Loss'
            },
            BLURB: {
                _HOW_IS_GOING_: 'How is your business doing?',
                _WANT_TO_KNOW_: 'Want to know what you’re really bringing home at the end of the day?',
                _WANT_TO_KNOW_REST_: 'to ensure you’re not spending more than you’re bringing in.'
            },
            ANALYTICS: {
                _PREPARING_: 'Preparing Report...',
                _MONTHLY_REPORT_: 'Your Monthly Report',
                _ANNUAL_REPORT_: 'Your Yearly Report',
                _MONTHLY_REPORTS_TITLE_: 'Monthly Analytics Reports',
                _ANNUAL_REPORTS_TITLE_: 'Yearly Analytics Reports',

                UPGRADE_BANNER :{
                    _TITLE_ : 'Make your leads work for you',
                    _BENEFIT1_: 'Get the full picture—graphs & spreadsheets  give you deep insights into your leads',
                    _BENEFIT2_: 'Know your best leads—their source, speed of conversion, and $ value.'
                },

                SUMMARY: {
                    _COLLECTED_: 'Collected Payments',
                    _DATE_FILTER_ERROR_MESSAGE_: '"To" date should not be earlier than "From" date',
                    _DOWNLOAD_LINK_: 'DOWNLOAD CSV',
                    _DOWNLOAD_LINK_TOOLTIP_: 'Download CSV',
                    _CSV_: 'CSV',
                    _END_DATE_: 'to',
                    _FOOTER_: '* Booked projects occurring within specified date range, does not include TBD',
                    _NUM_PROJECTS_: '# of Projects',
                    _OUTSTANDING_: 'Outstanding Payments',
                    _REFUNDED_: 'Refunded Payments',
                    _START_DATE_: 'from',
                    _TITLE_: 'Your HoneyBook Projects',
                    _TOTAL_VALUE_: 'Total Value of Bookings',
                    _GRATUITY_: 'Gratuity',
                    _REFUND_: 'Refund',
                    _LATE_FEE_: 'Late fee'
                },

                TABLE_COLUMNS: {
                    _MONTH_: 'Month',
                    _YEAR_: 'Year',
                    _FILES_AND_FLOWS_SENT_: 'Files Sent',
                    _FLOWS_SENT_: 'Smart Files Sent',
                    _FILES_SENT_: 'Files Sent',
                    _BOOKED_: 'Booked',
                    _TAX_: 'Tax',
                    _VALUE_OF_BOOKED_: 'Value of Booked Files',
                    _SUCCESS_RATE_: 'Your Success Rate'
                },

                GRAPH: {
                    _TITLE_: 'Monthly Report - Bar Chart',
                    _SUCCESS_RATE_: 'Your Success Rate',
                    _FILES_AND_FLOWS_BOOKED_: 'Files Booked',
                    _BOOKED_: 'Files Booked',
                    _FLOWS_SENT_: 'Smart Files Sent',
                    _FLOWS_BOOKED_: 'Smart Files Booked',
                    _FILES_AND_FLOWS_SENT_: 'Files Sent',
                    _FILES_SENT_: 'Files Sent',
                    _CONTRACT_AND_INVOICE_: 'Contract & Invoice',
                    _PROPOSALS_: 'Proposals',
                    _INVOICES_: 'Invoices',
                    _YEAR_: 'Year',
                    _LEAD_: 'Lead'
                },

                LEAD_SOURCE: {
                    _TITLE_: 'Leads from contact forms',
                    _SUBTITLE_: 'See what sources gave you the most leads within the selected time period.',
                    _VENDOR_REFERRAL_: 'Vendor Referral',
                    _CLIENT_REFERRAL_: 'Client Referral',
                    _PERSONAL_WEBSITE_: 'Personal Website',
                    _WEDDING_SPOT_: 'Wedding Spot',
                    _YELP_: 'YELP',
                    _FACEBOOK_: 'Facebook',
                    _GOOGLE_: 'Google',
                    _INSTAGRAM_: 'Instagram',
                    _THE_KNOT_: 'The Knot',
                    _WEDDING_WIRE_: 'Wedding Wire',
                    _OTHER_: 'Other',
                    _FOOTER_: '* All projects occurring within specified date range, does not include TBD',
                    _EDIT_LEAD_SOURCES_: 'Edit Lead Sources',

                    MODAL: {
                        _HEADER_: 'Lead Source Tracking is here!',
                        _SUB_HEADER_: 'Track where your leads are coming from and view more details in your Reports section',
                        _PUNCH_LINE_: 'Make sure to update your contact form with the new Lead Source field!'
                    }
                }
            },
            PAYMENTS: {
                _DUE_TODAY_: 'Due Today',
                _OVERDUE_: 'Overdue',
                _UPCOMING_: 'Upcoming',
                _PROCESSING_: 'Processing',
                _OUTSTANDING_: 'Outstanding Payments',
                _PAID_PAYMENTS_: 'Paid Payments',
                _DISPUTE_RESERVED_: 'Held For Dispute',
                _DISPUTE_RESERVED_LOST_: 'Paid*',
                _DISPUTED_PENDING_: 'Dispute In Process',
                _DISPUTED_WON_: 'Dispute Won',
                _DISPUTED_PARTIALLY_WON_: 'Dispute Partially Won',
                _DISPUTED_LOST_: 'Dispute Lost',
                _PAID_: 'Paid',
                _REFUNDED_: 'Full Refund',
                _PARTIALLY_REFUNDED_: 'Partial Refund',
                _REFUND_PENDING_: 'Refund In Process',
                _REFUND_ERROR_: 'Refund Error',
                _PROJECT_NAME_: 'Project Name',
                _PROJECT_: 'Project',
                _STATUS_: 'Status',
                _DUE_DATE_: 'Due date',
                _INSTANT_DEPOSIT_: 'Instant deposit',
                _INSTANT_DEPOSIT_POPUP_: 'Toggle on “Same Day” to have funds deposited for outstanding payments made by credit card (typically within 30 minutes of client payment).',
                _INSTANT_DEPOSIT_POPUP_TITLE_: 'Opt in to Instant Deposit',
                _INSTANT_DEPOSIT_TOGGLE_: 'Same day',
                _INSTANT_DEPOSIT_NOW_: 'Deposit now',
                _INSTANT_DEPOSIT_TOOLTIP_: 'There is a 1% charge for Instant Deposit ($5 minimum). This charge does not include processing fees.',
                _INSTANT_DEPOSIT_LEARN_MORE_: 'Learn more.',
                _DUE_: 'Due',
                _AMOUNT_: 'Amount',
                _FILE_NAME_: 'File name:',
                _FLOW_NAME_: 'Smart File name:',
                _FILE_TYPE_: 'File type:',
                _FLOW_TYPE_: 'Smart File type:',
                _FILE_STATUS_: 'File status',
                _FILE_OWNER_: 'File owner',
                _DESCRIPTION_: 'Description:',
                _CHARGE_DATE_: 'Charge date',
                _CHARGE_: 'Charge',
                _CHARGED_: 'Charged',
                _LAST_SENT_REMINDER_: 'Last Sent Reminder',
                _LAST_REMINDER_: 'Last reminder:',
                _OWNER_: 'Owner:',
                _NO_SENT_REMINDER_: 'No Sent Reminder',
                _SUBTOTAL_: 'Subtotal:',
                _ISSUE_REFUND_: 'ISSUE REFUND',
                _TOTAL_CHARGE_: 'Total charged:',
                _TOTAL_: 'Total:',
                _METHOD_: 'Payment method:',
                _PAID_IN_PERSON_: 'Paid In Person',
                _REFUND_DATE_: 'Refund Date',
                _REFUNDED_AMOUNT_: 'Refunded Amount',
                _TIP_: 'Gratuity:',
                _LATE_FEE_: 'Late fee:',
                _WAIVE_LATE_FEE_: 'Cancel late fee',
                WAIVE_LATE_FEE_MODAL: {
                    _TITLE_: 'Cancel late fee?',
                    _BODY_: 'If you cancel, the client will not incur a late fee\nfor payments overdue by 30 or more days.',
                    _DISMISS_MODAL_CTA_: 'KEEP FEE',
                    _WAIVE_FEE_CTA_: 'CANCEL FEE',
                    _DISMISS_MODAL_MWEB_CTA_: 'KEEP LATE FEE',
                    _WAIVE_FEE_MWEB_CTA_: 'CANCEL LATE FEE'
                },
                _PAID_BY_: 'Paid by',
                _PAYMENT_METHOD_: 'Payment method',
                _NOTE_: 'Notes',
                _DATE_CHANGED_: 'Payment Date Changed',
                _INVOICE_: 'Invoice',
                _REMIND_: 'Remind',
                _REFUND_: 'Refund',
                _REFUND_NO_PAYMENT_ERROR_: 'Could not find a payment to refund. Please contact us to take care of this refund.',
                _REFUND_EDITABLE_DRAFT_ERROR_: 'You can not refund a payment on an editable draft. Please delete the current draft or send the file and try again.',
                _REFUND_NOT_OWNER_ERROR_: 'Only the owner of the file can refund this payment',
                _REFUND_CANCELED_ERROR_: 'Payments of expired files can not be refunded. First un-expire the file then try again',
                _REFUND_WORKSPACE_ARCHIVED_ERROR_: 'Payments of archived workspaces can not be refunded. First un-archive the workspace and then try again',
                _REFUND_NO_CC_ERROR_: 'We need your credit card in order to refund your client, but we do not have one on file. Please contact us to take care of this refund.',
                _REFUND_CHARGE_CC_MESSAGE_: 'We will charge your credit card ending in {{ lastFourCc }} the original payment of {{ refundAmount }}',
                _REFUND_CHARGE_MANAGED_ACCOUNT_MESSAGE_: 'We will charge your Stripe account available balance for the original payment of {{ refundAmount }}',
                _REFUND_DISABLED_FOR_MARK_AS_PAID_PAYMENT_: 'Oops! You can only issue a refund for a payment that was made through HoneyBook. To mark this payment as unpaid, please contact us',
                _REFUND_NON_NUMERIC_AMOUNT_: 'Refund amount can only contain digits and dot',
                _REFUND_ACH_REFUND_PERIOD_OVERDUE_: 'Oops! This bank transfer payment was made more than 90 days ago. We cannot issue a refund past this time period',
                _REFUND_DISABLED_: 'This payment cannot be refunded. Please contact us for more info',
                _HAVE_QUESTIONS_: 'Have any questions on transfer times, refunds, or anything else about getting paid on HoneyBook? Check out our',
                _PAYMENTS_FAQ_: 'Payments FAQ.',
                _VIEW_ALL_OUTSTANDING_PAYMENTS_: 'View all outstanding payments',
                _CLIENT_PAYMENT_PLAN_DISCLAIMER_: '* Some payments may change based on client selections.',
                _CLIENT_PAYMENT_PLAN_DISCLAIMER_MOBILE_: '* Some payments may change based on client selections.',
                _VIEW_ALL_PAYMENTS_: 'View all payments',

                _SYNC_TO_QB_: 'Sync to Quickbooks',
                _ALL_FILES_ARE_SYNCED_WARNING_: 'Any other paid payments on this file will automatically sync to QuickBooks',
                _TOOLTIP_AUTOPAY_NOT_PAID_: '<b>Autopay: On </b> <br/> your client will be charged on the due date.',
                _TOOLTIP_AUTOPAY_PAID_: '<b>Paid using Autopay</b> <br/> was last charged on {{chargedDate}}',

                FILTERS: {
                    _FILTER_BY_: 'Filter By:',
                    _OVERDUE_: 'Filter by: Overdue',
                    _NEXT_WEEK_: 'Filter by: Next Week',
                    _NEXT_MONTH_: 'Filter by: Next Month',
                    _NEXT_YEAR_: 'Filter by: Next Year',
                    _ALL_: 'Filter by: All',
                    _RECENTLY_: 'Filter by: Recently',
                    _LAST_SIX_MONTHS_: 'Filter by: Last Six Months',
                    _PAST_YEAR_: 'Filter by: Past Year'
                }
            },

            CALENDAR: {
                _INDEX_: 'Index',
                _PROJECTS_: 'Projects',
                _PAST_PROJECTS_: 'Past Projects',
                _BOOKED_PROJECTS_: 'Booked Projects',
                _TENTATIVE_PROJECTS_: 'Tentative Projects',
                _TEAM_PROJECTS_: 'Team Projects',
                _MEETING_PROJECTS_: 'Meetings',
                _CALENDLY_MEETING_: 'Calendly Meetings',
                _PAYMENTS_: 'Payments',
                _PAYMENT_PAID_: 'Payment Paid',
                _FUTURE_PAYMENTS_: 'Future Payment Due',
                _PAYMENTS_WEEK_BEFORE_: 'Payment Week Before',
                _PAYMENT_OVERDUE_: 'Payment Overdue',
                _CREATE_MEETING_: 'Create meeting',
                _CALENDARS_: 'Calendars',

                IMPORTING: {
                    _ERROR_IMPORTING_: 'There was an error connecting to your calendar on your account. Please try again'
                },
                _CONNECT_TO_PROJECT_: 'Connect to project',
                _CREATE_PROJECT_: 'Create a project',

                POPOVER: {
                    _MILESTONE_PAYMENT_REQUESTED_ON_: 'Milestone payment requested on ',
                },

                MEETING_MODAL: {
                    _LOCATION_: 'Location',
                    _SEARCH_WORKSPACE_PLACEHOLDER_: 'Start typing to search for your project',
                    _NO_MEETING_TITLE_DEFAULT_: '(No title)',
                    _MEETING_: 'Meeting',
                    _CREATE_: 'Create',
                    _CREATE_TITLE_: 'Create Meeting',
                    _UPDATE_: 'Update',
                    _UPDATE_TITLE_: 'Edit Meeting',
                    _ADD_PROJECT_: 'Add Project',

                    ERRORS: {
                        _COULD_NOT_FIND_THIS_MEETING_: 'Oops, we can’t find this meeting.',
                        _COULD_NOT_CREATE_THIS_MEETING_: 'Oops, we could not create this meeting.',
                        _MISSING_DATE_FIELD_: 'Please choose a date and try again'
                    }
                },

                SYNC: {
                    _CONNECT_: 'Google Sign in',
                    _NYLAS_CONNECT_: 'Connect Calendar',
                    _ZOOM_CONNECT_: 'Sign in',
                    _MODAL_TITLE_CONNECT_: 'Connect your calendar',
                    _MODAL_TITLE_MANAGE_: 'Calendar settings',
                    _CONNECT_MESSAGE_: 'Track all your meetings in one place! Sync your calendars to save time, stay organized, and avoid the dreaded double-book.',
                    _CONNECT_BUTTON_TITLE_: 'Connect Calendar',
                    _GOOGLE_CALENDAR_: 'Google calendar',
                    _NYLAS_CALENDAR_: 'Connected Calendar',
                    _RECONNECT_: 'RECONNECT',
                    _RECONNECT_MESSAGE_: 'It seems we have issues with your calendar integration. Please reconnect your calendar.',
                    _DISCONNECT_: 'DISCONNECT',
                    _SYNC_WITH_GOOGLE_DESCRIPTION_: 'Share your HoneyBook calendar events to other Google calendars',
                    _SYNC_WITH_NYLAS_DESCRIPTION_: 'All HoneyBook events will appear on your synced calendar.',
                    _ADD_ANOTHER_: 'Add another Google email address',

                    _SHOW_GOOGLE_PREFS_: 'Show Google calendar events in my HoneyBook calendar',
                    _SHOW_NYLAS_PREFS_: 'Show connected calendar events in my HoneyBook Calendar',
                    _INVALID_EMAIL_: 'Please enter a valid email address',
                    _ERROR_SHARING_: 'Oops! There seems to be a problem syncing the calendars. Please try again.',
                    _ERROR_PERMISION_REVOKED_: "You've recently revoked HoneyBook's permission to access your calendar. To reconnect your Google calendar, click the gear icon.",

                    _IMPORT_FROM_INTEGRATED_CALENDAR_: 'The status of imported calendar events (busy/free) stays the same.',

                    _SELECT_CALENDARS_: 'Select calendars you’d like to import into HoneyBook:',
                    _AVAIL_TOOLTIP_: 'Ex. If an imported calendar event is marked as busy (or free), it’ll be set as unavailable (or available) in HoneyBook.',

                    _TOOLTIP_: 'Now you can see all your Google Calendar projects in your HoneyBook Calendar',

                    _CONNECT_GOOGLE_: 'Connect your calendar to Google',
                    _ERROR_GETTING_DATA_: "Oops. We encountered a problem getting your calendar information. Please close this dialog and try again,",

                    _SHOW_HB_PREFS_: 'Share HoneyBook calendar events',
                }
            },

            FEES: {
                _MONTH_: 'Month',
                _INVOICE_: '# Invoice',
                _NET_: 'Net',
                _TRANSACTION_FEE_: 'Transaction Fee',
                _GROSS_: 'Gross',
                _FILE_NAME_: 'File Name',
                _DESCRIPTION_: 'Description',
                _CHARGE_DATE_: 'Charge Date',
                _PAID_: 'Paid'
            }
        },

        CALENDAR: {
            LABELS: {
                _GOOGLE_: 'Google',
                _CONNECTED_CALENDAR_: 'Connected Calendar',
                _SESSIONS_: 'Sessions',
                _SPACES_: 'Spaces',
                _HB_: 'HoneyBook',
                _SESSION_CONFIGS_: "Session Type",
                _ALL_DAY_: 'All day',
                _GO_TO_PROJECT_: 'GO TO PROJECT',
                _VIEW_PAYMENT_: 'VIEW PAYMENT',
                _VIEW_ON_GOOGLE_: 'VIEW ON GOOGLE',
                _TEAM_MEMBERS_: 'Team Members'
            },
            VIEWS_MENU: {
                _DAY_VIEW_: 'Day',
                _WEEK_VIEW_: 'Week',
                _MONTH_VIEW_: 'Month',
                _YEAR_VIEW_: 'Year',
                _SCHEDULE_: 'Schedule',
                _TODAY_: 'Today'
            },
            SCHEDULE_MEETING_MODAL: {
                _TITLE_: 'Create new meeting',
                _TITLE_UPDATE_: 'Update meeting',
                _CREATE_: 'schedule',
                _UPDATE_: 'update',
                _MEETING_TITLE_LABEL_: 'Title',
                _MEETING_TITLE_PLACEHOLDER_: 'E.g. Brainstorming with Kate',
                _MEETING_TITLE_ERROR_: 'Please enter a title',
                _MEETING_DATE_ERROR_: 'Choose a start date',
                _MEETING_TYPE_ERROR_: 'Please select a type',
                _VIDEO_TYPE_ERROR_: 'Please choose video call type',
                _GOOGLE_NOT_CONNECTED_: 'Please sign in',
                _ZOOM_LINK_ERROR_: 'Failed to create a Zoom meeting',
                _DATE_: 'date',
                _TIMEZONE_: 'Timezone',
                _MARK_AS_BUSY_: 'Mark as busy',
                _ALL_DAY_: 'All day',
                _START_DATE_LABEL_: 'Start date',
                _START_TIME_LABEL_: 'Start time',
                _END_TIME_LABEL_: 'End time',
                _END_DATE_LABEL_: 'End date',
                _DETAILS_: 'details',
                _MEETING_TYPE_LABEL_: 'Type',
                _MEETING_TYPE_PLACEHOLDER_: 'How will it take place?',
                _MEETING_PROJECT_LABEL_: 'Project',
                _MEETING_INVITEES_LABEL_: 'Invitees',
                _MEETING_PROJECT_PLACEHOLDER_: 'Connect to a project',
                _MEETING_LOCATION_LABEL_: 'Location (optional)',
                _MEETING_LOCATION_PLACEHOLDER_: 'Where will it take place?',
                _MEETING_INSTRUCTIONS_LABEL_: 'Description',
                _MEETING_INSTRUCTIONS_PLACEHOLDER_: 'Add instructions, an agenda, etc.',
                _MEETING_INSTRUCTIONS_HELPER_TEXT_: 'This will be visible to all invitees.',
                _VIDEO_CONFERENCE_LABEL_: 'Video call type',
                _VIDEO_LINK_LABEL_: 'Video call link (optional)',
                _GOOGLE_VIDEO_LINK_LABEL_: 'Google meeting link',
                _ZOOM_VIDEO_LINK_LABEL_: 'Enter a Zoom Meeting link',
                _CONNECT_GOOGLE_CALENDAR_: 'Sign in with Google to access Google Meet',
                _CONNECT_ZOOM_: 'You must sign in with your Zoom account to use this video conferencing option',
                _PHONE_CALL_NUMBER_LABEL_: 'Phone call number',
                _MEETING_CREATED_FROM_FILE_TOOLTIP_: 'This project cannot be replaced as the meeting was scheduled using a file.',
                _GOOGLE_VIDEO_LINK_: "Once scheduled, a Google Meet/Hangouts link will be added to the meeting invite in all attendee calendars."
            },

            CONNECT_PROJECT_MODAL: {
                _TITLE_: 'Stay organized',
                _HELPER_TEXT_: 'Connect this meeting to a project for improved tracking and effortless organization.',
                _PROJECT_SEARCH_PLACEHOLDER_: 'Select project',
                _MWEB_PROJECT_SEARCH_PLACEHOLDER_: 'Type to search existing projects…',
                _ERROR_: 'Please select a project',
                _APPROVE_: 'CONNECT TO PROJECT',
                _DECLINE_: 'NO RELATED PROJECT',
                _CREATE_NEW_PROJECT_: 'Create new project'
            },

            _DELETE_MEETING_CONFIRMATION_: 'Deleting this will remove it from your calendar and from any connected projects. This cannot be undone.',

            NOTIFY_INVITEES_POPUP: {
                _TITLE_: 'Notify invitees?',
                _CANCEL_BODY_: 'Send an email to let invitees know you are canceling this session.',
                _CANCEL_CTA_: 'CANCEL WITHOUT NOTIFYING',
                _RESCHEDULE_BODY_: 'Send an email to let invitees know you are rescheduling this session.',
                _RESCHEDULE_CTA_: 'RESCHEDULE WITHOUT NOTIFYING',
                _SCHEDULE_BODY_: 'Send an email to let invitees know you are scheduling this session.',
                _SCHEDULE_CTA_: 'SCHEDULE WITHOUT NOTIFYING',
                _REVIEW_EMAIL_: 'NOTIFY BY EMAIL',
            }
        },

        SCHEDULING: {
            _BANNER_TITLE_: 'Now you can combine scheduling with payments!',
            _BANNER_TEXT_: 'Add a Scheduler session into any smart file template, send off to a client, and get instantly booked and paid for your time. ',
            _BANNER_LINK_: 'Learn more about using the Scheduler block in smart files.',
            _BANNER_BUTTON_: 'TRY IT OUT',
            _HERO_TITLE_: 'Schedule with less back and forth',
            _HERO_TEXT_: 'Create a session, set your availability and let clients select a time that works for them.',
            _HERO_TEXT_OTHER_USER_: 'No sessions for this team member',
            _HERO_CTA_: 'CREATE MY FIRST SESSION',
            _SESSIONS_TITLE_: 'Manage your sessions',
            _SESSIONS_SUB_TITLE_: 'Edit the details of your sessions, copy the links to send to clients, and preview their experience.',
            _AVAILABILITY_TITLE_: 'Availability Overview',
            _AVAILABILITY_SUB_TITLE_: 'This overview shows your availability for all sessions. Events from your calendars (including synced) will override this and show you as unavailable.',
            _VIEW_ALL_SESSIONS_: 'View all sessions',
            _AVAILABILITY_EMPTY_STATE_TEXT_: 'Once you’ve created your sessions, you’ll be able to view your overall availability across all sessions here.',
            _AVAILABILITY_EMPTY_STATE_TEXT_OTHER_USER_: 'No sessions for this team member',
            SESSION: {
                _ENABLED_: 'ACTIVE',
                _DISABLED_: 'INACTIVE',
                DISABLED_TOAST: {
                    _TITLE_: 'Session was disabled: {{session_name}}',
                    _CONTENT_: 'This session no longer appears in files & templates—clients cannot schedule this session.'
                },
                _CREATE_NEW_: 'NEW SESSION TYPE',
                _EDIT_: 'Edit',
                SESSION_TYPES: {
                    _MEETING_: 'Meeting',
                    _PHONE_CALL_: 'Phone Call',
                    _VIDEO_CALL_: 'Video Call',
                    _OTHER_: 'Other',
                },
                WINDOW_TYPES: {
                    _INDEFINITELY_: 'Indefinitely',
                    _ROLLING_WINDOW_: 'Rolling Window',
                    _FIXED_DATE_RANGE_: 'Fixed Date Range'
                },
                POPOVER: {
                    _AVAILABILITY_POPOVER_TITLE_: 'Add availability for'
                }
            },
            EDIT_DAY_MODAL: {
                _UNAVAILABLE_: 'Unavailable',
                _TO_: 'to',
                _ADD_: ' Add time',
                _ADD_ANOTHER_: ' Add another time',
                _REMOVE_AVAILABILITY_: 'Remove Availability',
                _APPLY_ALL_: 'Apply to all {{dayOfWeek}}s',
                _SAVE_: 'SAVE',
                _OVERLAP_ERROR_: 'Times cannot overlap',
                _INVALID_ERROR_: 'Invalid time range',

            },
            CONFIGURE_SESSION: {
                _NEW_TITLE_: 'New Session Type',
                _EDIT_TITLE_: 'Edit Session Type',
                _WORKFLOW_TOOLTIP: 'Session is used in an automation.',
                _SAVE_: 'Save And Finish',
                _DELETE_SESSION_: 'DELETE SESSION',
                _DETAILS_TITLE_: 'These details will be visible to invitees when scheduling.',
                _ALLOW_RESCHEDULING_: 'Allow rescheduling',
                _RESCHEDULING_DESCRIPTION_: 'Reschedule option will appear upon confirmation and in all reminders.',
                _ALLOW_CANCELLATIONS_: 'Allow cancellations',
                _CANCELLATIONS_DESCRIPTION_: 'Cancel option will appear upon confirmation and in all reminders.',
                _SEND_CONFIRMATION_EMAIL_: 'Send confirmation email',
                _CONFIRMATION_EMAIL_DESCRIPTION_: 'Invitee will receive an email confirming details of the scheduled session.',
                _CONFIRMATION_MESSAGE_TITLE_: 'CONFIRMATION PAGE',
                _CONFIRMATION_MESSAGE_DESCRIPTION_: 'The message below will appear on confirmation page after invitee schedules.',
                _PREFERENCES_TITLE_: 'ALLOW INVITEE:',
                _EMAILS_TITLE_: 'EMAILS',
                _PHONE_CALL_LABEL_: 'Who should make the call?',
                _PHONE_CALL_INFO_: 'Phone number will be collected at time of scheduling',
                _BUFFERS_TITLE_: 'Buffers',
                _BUFFERS_SUBTITLE_: 'Set an amount of time to block before and/or after a client schedules.',
                _BUFFERS_BEFORE_: 'Before',
                _ADD_BUFFER_BEFORE_: 'Add buffer before',
                _BUFFERS_AFTER_: 'After',
                _ADD_BUFFER_AFTER_: 'Add buffer after',
                _NOTICE_TITLE_: 'Minimum notice',
                _NOTICE_SUBTITLE_: 'Set a minimum amount of time that you require from when this session is scheduled and when it will occur.',
                _AVAILABILITY_INCREMENTS_TITLE_: 'Custom increments',
                _AVAILABILITY_INCREMENTS_SUBTITLE_: 'Select the increments of time in which your availability will appear.',
                _AVAILABILITY_INCREMENTS_LABEL_: 'minutes',
                _SHOW_MORE_: 'More options',
                _NEXT_AVAILABILITY_: 'NEXT: AVAILABILITY',
                _NEXT_CONFIRMATION_: 'NEXT: CONFIRMATION',
                _ADD_REMINDER_: ' Add Reminder',
                _INSTRUCTIONS_TITLE_: 'INSTRUCTIONS',
                _INSTRUCTIONS_SUBTITLE_: 'Provide any information that will help invitees be prepared for this session.',
                _REMINDERS_TITLE_: 'SEND INVITEE REMINDERS',
                _COLORS_TITLE_: 'SESSION COLOR',
                _REMINDERS_EMPTY_STATE_: 'No reminders',
                _SET_PROJECT_DATE_TITLE_: 'Use meeting date as the project date',
                _SET_PROJECT_DATE_TIPPY_: 'When there isn’t a project date, a client’s first scheduled meeting date is used as the project date.',
                _SET_PROJECT_DATE_TITLE_SUBTITLE_: 'This lets you benefit from automations, interactive fields, reports, and more.',
                _ME_: 'Me',
                _INVITEE_: 'Invitee',
                _CREATED_SESSION_TITLE_: 'Session created!',
                _CREATED_SESSION_MESSAGE_: 'Your session is <strong>now live</strong> and clients can schedule with you through this link:',
                _CREATED_SESSION_COPY_LINK_: 'COPY',
                _CREATED_SESSION_COPIED_LINK_: 'COPIED!',
                _CREATED_SESSION_HELPER_: 'You can copy and add this link into your templates, including files and email signature.',
                _REMINDER_BEFORE_: 'before session',
                _REMINDER_SEND_ME_: 'Also send to me',
                _SMS_DISCLAIMER_: "Note: Contacts must have a valid phone number and consent to receive SMS.",
                _SMS_LEARN_LINK_: 'Learn about SMS notifications',
                _SEND_: 'Send',
                _REMINDER_CUSTOMIZE_: 'Customize message',
                _DURATION_: 'Duration',
                _DURATION_ERROR_: 'Please set a duration',
                _SESSION_TIMEZONE_ERROR_: 'Please set a timezone for this session',
                _SESSION_TYPE_ERROR_: 'Please choose session type',
                _SESSION_NAME_ERROR_: 'Please name this session',
                _VIDEO_TYPE_ERROR_: 'Please choose video call type',
                _PHONE_NUMBER_ERROR_: 'Phone number is invalid',
                _FIXED_START_DATE_ERROR_: 'Start date has to be after today',
                _FIXED_END_DATE_ERROR_: 'End date has to be after start date',
                _TEAM_MEMBER_MISSING_ERROR_: 'You have to choose at least one team member',
                _DELETE_SESSION_MESSAGE_: 'You will lose all details of this session and clients will no longer be able to schedule any future sessions of this type with you.',
                _DELETE_SESSION_MESSAGE_WITH_FILES_: 'The session <b>will be removed from all files & templates</b>—clients won\'t be able to schedule this session, but any existing scheduled sessions will take place as planned. <b>Be sure to remove this session from template used in automations.</b>',
                _SESSION_DELETE_DISABLED: 'Session cannot be deleted',
                _SESSION_LINK_URL_INVALID:'Invalid url (should start with "http://" or "https://")',
                SESSION_USED_IN_WORKFLOW_MESSAGE:{
                    _SINGULAR: 'This session cannot be deleted as it’s included in an automation, <strong>{{ workflowTitle }}</strong>. Update all action steps, then try again.',
                    _PLURAL: 'This session cannot be deleted as it’s included in {{ workflowCount }} automation templates, including <strong>{{ workflowTitle }}</strong>. Update all action steps before deleting the session.'
                },
                _TEAM_MEMBERS_TITLE_: 'Team members',
                _TEAM_MEMBERS_SUBTITLE_: 'Invitees will be able to schedule this session with you and one or more members of your team.',
                _ADD_TEAM_MEMBER_: 'ADD TEAM MEMBER',
                _CONNECT_GOOGLE_CALENDAR_: 'Sign in with Google to access Google Meet, also available through your calendar.',
                _CONNECT_ZOOM_: 'You must sign in with your Zoom account to use this video conferencing option',
                _GOOGLE_CONNECTED_: 'A Google Meet link will be generated after session is scheduled.',
                _ZOOM_CONNECTED_: 'A Zoom Meeting link will be automatically generated when this session is scheduled.',
                _GOOGLE_NOT_CONNECTED_ERROR_: 'Sign in with Google.',
                _WINDOW_: 'When can this session be scheduled?*',
                _ZOOM_NOT_CONNECTED_ERROR_: 'Sign in with Zoom.',
                _ROUND_ROBIN_: 'Round robin',
                _ROUND_ROBIN_SUBTITLE_: 'Select team members that you’d like to cycle this session between.'
            },
            TOOLTIP: {
                _TIME_ZONE_DESC_: 'Set the timezone for this session to be scheduled in.',
                _WHEN_CAN_SESSION_BE_SCHEDULE_: 'When can this session be scheduled?',
                _INDEFINITELY_: 'Indefinitely',
                _INDEFINITELY_DESC_: 'Scheduling can occur at any point in the future.',
                _ROLLING_PERIOD_: 'Rolling period',
                _ROLLING_PERIOD_DESC_: 'Scheduling can occur during a “rolling” period relative to the current date. ',
                _FIXED_DATE_RANGE_: 'Fixed date range',
                _FIXED_DATE_RANGE_DESC_: 'Scheduling can only occur within this fixed range of dates.',
                _LEARN_MORE_: 'LEARN MORE'
            },
            FEATURE_RESTRICTION_BANNER: {
                _TITLE_: 'Unlimited sessions, unlimited potential ',
                _DESKTOP_:{
                    _BENEFIT_1_: 'Create different types sessions for the various services or meetings you offer',
                    _BENEFIT_2_: 'Let clients book what’s right for them (and works with your availability)'},
                _MWEB_:{
                    _BENEFIT_1_: 'Create different sessions for different services',
                    _BENEFIT_2_: 'Let clients book what’s right for them'
                }
            },
        },

        RESCHEDULE_PROJECT: {
            CHOOSE_DATE: {
                _TITLE_: 'Reschedule project',
                _CURRENT_PROJECT_DATE_: 'The current project date is <strong>{{projectDate}}</strong>',
                _CURRENT_PROJECT_END_DATE_: ' and ends on <strong>{{date}}</strong>',
                _AT_PROJECT_END_TIME_: 'at <strong>{{time}}</strong>',
                _AT_PROJECT_TIME_: ' at <strong>{{projectTime}}</strong>',
                _SELECT_NEW_DATE_: 'Select a new date for this project:',
                _UNPAUSE_TEXT_: 'You’ll be able to modify any payment schedules, contracts or workflows beforehand.',
                _UNPAUSE_TITLE_: 'Unpause project',
                _PAUSE_TOOLTIP_: '<b>Pause project</b> <br/> Pausing this project will move it to a “Paused” stage of your Pipeline, stop all workflows or emails from being sent and all payments from processing.',
                _ACTIVE_WORKFLOW_: 'Date related workflow steps will be updated according to your new date',
            },
            WORKFLOW: {
                _TITLE_: 'Update workflows',
                _UPDATE_PAYMENT_: 'Next: Update Payments',
                _DESCRIPTION_: 'The workflow below is currently applied to this project and will be reactivated. You can also choose to remove the workflow now or you can edit it from within the project later.',
                _TBD_DATE_DESCRIPTION_: 'Changing your date to TBD impacts date related step in {{workflowName}} Workflow. You can either remove the workflow now or edit it from the project workspace later by choosing “Skip”.',
                _STEPS_COMPLETED_: 'steps completed.',
                _WORKFLOW_REMOVED_: 'The workflow {{workflowName}} will be removed from this project',
                _REAPPLY_WORKFLOW_: 'Reapply Workflow'
            },
            UPDATE_PAYMENT: {
                _TITLE_: 'Update payment schedule',
                _DESCRIPTION_: 'We’ve automatically updated any dates based on project date. Select the files you’d like to resend to your client and make any necessary updates. To update and resend an expired file, you must unexpire it first. ',
                _REVERT_TO_ORIGINAL_: 'Revert to original schedule',
                _NO_UPDATES_: 'No updates will be sent',
                _UPDATE_CONTRACT_: 'Next: Update Contract'
            },
            CHOOSE_CONTRACT: {
                _TITLE_: 'Choose contract',
                _DESCRIPTION_: 'Select an option below for updating your contract then click to continue and edit it, send updates to your client, and complete the rescheduling process.',
                OPTIONS: {
                    _UPDATE_EXISTING_CONTRACT_TITLE_: 'Update existing contract',
                    _UPDATE_EXITING_CONTRACT_DESCRIPTION_: 'Edit and resend your existing contract. Your client will need to accept any changes.',
                    _CHOOSE_CONTRACT_: 'Choose contract',
                    _CONTRACT_NAME_: 'Contract name:',
                    _SEND_AMENDMENTS_TITLE_: 'Send amendment contract (drafted by our legal experts) ',
                    _SEND_AMENDMENTS_DESCRIPTION_: 'Your original contract will remain binding. This amendment will be sent as a separate contract where you can outline the amended details (e.g. project date and payments).',
                    _CREATE_NEW_CONTRACT_TITLE_: 'Create and send a brand new contract',
                    _CREATE_NEW_CONTRACT_DESCRIPTION_: 'Create and send an entirely new contract. Make sure you expire your old contract.',
                    _NO_CHANGES_TITLE_: 'No changes to contract',
                    _NO_CHANGES_DESCRIPTION_: 'Your existing contract will remain binding. Your client will not be receive any updates.'
                }
            },
            EDIT_CONTRACT: {
                _TITLE_: 'Edit contract'
            },
            SEND_NOTIFICATION: {
                _TITLE_: 'Send notification',
                _DESCRIPTION_: 'Would you like to send an email to notify your clients of the updates you’ve made to this project?',
                _REVIEW_EMAIL_CTA_: 'YES, REVIEW EMAIL'
            },
            NEXT_CTA: {
                _WORKFLOW_: 'NEXT',
                _PAYMENTS_: 'NEXT: UPDATE PAYMENTS',
                _CONTRACT_: 'NEXT: UPDATE CONTRACT',
                _EDIT_CONTRACT_: 'NEXT: EDIT CONTRACT',
                _SEND_NOTIFICATION_: 'NEXT',
            },
            SUCCESS_TOAST: {
                _TEXT_: '"{{eventName}}" has been updated{{emailSentText}}. To make additional changes, visit the',
                _EMAIL_SENT_TEXT_: ' and your client has been notified via email',
                _PROJECT_WORKSPACE_: 'project workspace'
            },
            _SEND_EMAIL_LABEL_: 'Sending updates...'
        },

        PAUSE_PROJECT: {
            _HEADER_: 'PAUSE PROJECT',
            _DETAILS_: 'Pausing this project will move it to a “Paused” stage of your Pipeline, stop all workflows or emails from being sent and all payments from processing.',
            _TITLE_: 'Would you like to send an amendment contract?',
            _SUBTITLE_: 'This will specify the new terms of the project and require signatures from both parties. You will be able to edit before sending.',
            _CHECKBOX_TEXT_: 'Send amendment contract to client',
            _CTA_: 'PAUSE PROJECT'
        },

        ARCHIVE_PROJECT: {
            _HEADER_: 'Archive project',
            _DETAILS_: 'This project will be removed from your active projects, all files will be expired, and all payments, emails, workflows, etc. will be cancelled.',
            _TITLE_: 'Would you like to send a cancellation amendment?',
            _SUBTITLE_: 'This terminates all obligations between both parties and assumes a force majeure event. You will be able to edit this contract before sending.',
            _CHECKBOX_TEXT_: 'Send cancellation amendment contract to client',
            _CTA_: 'NEXT',
            REASON: {
                _TITLE_: 'Select a reason for archiving this project:',
                _SUBTITLE_: 'If you change your mind later, you can un-archive this project at any time. Did you know you could also pause or reschedule this project?',
                _CHECKBOX_TEXT_: 'Send amendment contract to client',
                _CTA_: 'ARCHIVE PROJECT'
            },
            _LEARN_MORE_: 'If you change your mind later, you can un-archive this project at any time.'
        },


        WORKSPACE: {
            ATWS: {
                _TITLE_: 'Hi {{name}}, <br/> Welcome to the workspace.',
                _CONTENT_: 'This is where you can send messages and files. <br/> Want to explore more tools made for',
                _CTA_: 'LET\'S EXPLORE',
            },
            TABS: {
                _FILES_: 'Files',
                _FEED_: 'Activity',
                _PAYMENTS_: 'Payments',
                _TASKS_: 'Tasks'
            },
            ACTIONS: {
                _EDIT_PROJECT_: 'Edit Project',
                _DELETE_WORKSPACE_: 'Delete Workspace',
                _ADD_WORKSPACE_: 'Add Workspace',
                _PAUSE_PROJECT_: 'Pause project',
                _UNPAUSE_PROJECT: 'Unpause project',
                _RESCHEDULE_PROJECT: 'Reschedule Project',
                _ARCHIVE_WORKSPACE_: 'Archive Workspace',
                _CANCEL_WORKSPACE_: 'SHOULDN\'T BE USED',
                _BLOCK_WORKSPACE_: 'Block Workspace',
                _SHOW_ARCHIVED_FILES_: 'Show Archived Files',
                _HIDE_ARCHIVED_FILES_: 'Hide Archived Files',
                _CREATE_FILE_: 'Create File',
                _CREATE_: 'Create File',
                _NEW_FILE_: 'Create new',
                _CREATE_PROPOSAL_: 'Proposal',
                _CREATE_INVOICE_: 'Invoice',
                _CREATE_RECURRING_INVOICE_: 'Recurring invoice',
                _CREATE_AGREEMENT_: 'Contract',
                _CREATE_REQUEST_PROPOSAL_: 'Request Proposal',
                _CREATE_QUESTIONNAIRE_: 'Questionnaire',
                _CREATE_CUSTOM_FLOW_: 'Custom Smart File',
                _CREATE_FLOW_MWEB_: 'Smart Files',
                _CREATE_FLOW_APP_AVAILABLE_: '(available in the app)',
                _CREATE_TIMELINE_: 'Timeline',
                _CREATE_BROCHURE_: 'Brochure',
                _CREATE_SERVICES_: 'Services',
                _CREATE_SCHEDULER_: 'Scheduler',
                _CREATE_FLOW_: 'Smart Files',
                _CREATE_DIRECT_PAYMENT_: 'Direct Payment',
                _REFER_WORKSPACE_: 'Refer to another vendor',
                _REFER_WORKSPACE_CONSULTANT_: 'Refer project',
                _ASSIGN_TO_TEAM_MEMBER_: 'Assign to team member',
                _ADD_USER_: 'Add/Remove Participants',
                _ADD_TEAM_MEMBER_: 'Add/Remove Team Members',
                _COMING_SOON_: 'Soon!',
                _NEW_: 'NEW',
                _MORE_: 'Show More',
                _UPLOAD_FILE_: 'Upload',
                _SCHEDULE_: 'Schedule',
                _CALENDAR_MEETING_: 'Calendar Meeting',
                _NEW_CALENDAR_EVENT_: 'New calendar event',
                _GO_TO_CALENDAR_: 'Go to calendar',
                _SAVED_SESSIONS_: 'Your saved sessions',
                _FILES_CREATE_MENU_HEADER_: 'FILES (OLD BUILDER)',
                _FLOWS_CREATE_MENU_HEADER_: 'SMART FILES',
                _OTAM_MIGRATION_: 'Get the new Smart Files builder',

                SECOND_BATCH: {
                    _FILES_CREATE_MENU_HEADER_: 'LEGACY FILES',
                    _FLOWS_CREATE_MENU_HEADER_: 'SMART FILES'
                },

                LABELS: {
                    DESC: {
                        _PROPOSAL_: 'This is an invoice and contract<br/> in one. Clients can easily sign<br/> and pay you right away.',
                        _INVOICE_: 'Need to bill a client? Our<br/> invoice allows you to send and<br/> your client to pay - all digitally.',
                        _AGREEMENT_: 'Make it official. Create and<br/> send a contract that your<br/> client can sign online.',
                        _BROCHURE_: 'Showcase the services you<br/> offer along with your pricing<br/> to nab that next client.',
                        _BROCHURE_NEW_: 'More fonts, more images, more<br/> layouts. Showcase your brand and get booked fast.',
                        _QUESTIONNAIRE_: 'Want more info from a client<br/> before starting their project?<br/> Create a questionnaire now.',
                        _TIMELINE_: 'No more missed deadlines.<br/> Create milestones so you and<br/> your client can stay on track.',
                        _ATTACHMENT_: 'Upload images or attachments.<br/> All project participants will<br/> be able to view them.',
                        _FLOW_: 'Create whatever you need to send your client, from mood boards to quotes, and more.',
                        _SCHEDULER_: 'Arrange meetings quickly—your client picks a session date & time, without any back-and-forth.',
                    },
                    DESC_MOBILE: {
                        _PROPOSAL_: 'Invoice and contract in one. Clients can easily sign and pay right away.',
                        _AGREEMENT_: 'Create and send a contract that your client can sign online.',
                        _INVOICE_: 'Our invoice allows you to send and your client to pay - all digitally.',
                        _BROCHURE_: 'Showcase the services you offer with your pricing.',
                        _QUESTIONNAIRE_: 'Use a questionnaire to gather additional information from clients.',
                        _TIMELINE_: 'Create milestones so you and your client can stay on track.',
                        _SCHEDULER_: 'Arrange meetings quickly—your client picks a session date & time, without any back-and-forth.',
                        _FLOW_: 'Smart files are the new way to streamline outstanding communications with your clients',
                    }
                }
            },
            CREATE_WORKSPACE: {
                _HEADER_: "Choose a new workspace type",
                _HEADER_A: "Choose a new workspace type",
                _CLIENT_WORKSPACE_: "Client",
                _CLIENT_WORKSPACE_TWO_: "Workspace",
                _DESIGN_WORKSPACE_: "Design Brainstorming",
                _TEAM_WORKSPACE_: "Team Members / Subcontractors",
                _ALL_VENDORS_WORKSPACE_: "Vendor Collaboration",
                _CLIENT_WORKSPACE_A_: "Client Workspace",
                _CLIENT_WORKSPACE_A_desc_: "A place to collaborate and send files to clients",
                _VENDOR_WORKSPACE_A_: "Vendor Collaboration",
                _VENDOR_WORKSPACE_A_desc_: "A place to collaborate with other vendors in this project",
                _DESIGN_WORKSPACE_A_: "Design Brainstorming",
                _DESIGN_WORKSPACE_A_desc_: "A place to figure out the design details for this project",
                _TEAM_WORKSPACE_A_: "Team Members / Subcontractors",
                _TEAM_WORKSPACE_A_desc_: "A place to collaborate with your team or subcontractors",
                _VENDOR_WORKSPACE_: 'Vendor Workspace',
                _TEAM_COLLABORATION_: 'Team Collaboration'
            },
            PAYMENTS: {
                _PAYMENTS_COUNT_: 'Payments',
                _EMPTY_STATE_TITLE_: 'No Payments',
                _NO_PAYMENTS_SENT_: 'Looks like you haven\'t sent any payment requests.',
                _WHEN_PAYMENT_SENT_: 'When you send a proposal or invoice, you will see the payments outlined here.',
                _DRAFT_EDITABLE_: 'You have an unsent draft. Showing Payments based on last sent file.',
                _VIEW_PAYMENTS_: 'To view payments, go to the <a class="workspace-payments__flow-message__link" ng-click="workspacePaymentsVm.goToFlow($event, currFile)">smart file</a>',
                _CLIENT_VIEW_PAYMENTS_: 'To view payments, <a class="workspace-payments__flow-message__link__client" ng-click="workspacePaymentsVm.goToFlow($event, currFile)">go to your file</a>',
                PAYMENT_AWARENESS_EMPTY_STATE: {
                    _TITLE_: "Get paid on time, all the time",
                    _DESC_: "Send an invoice with a do-able payment schedule. <br />Then, track payments with the help of reminders so <br /> everyone’s always on the same page.",
                    _DESC_FLOWS_: "Send an invoice with a do-able payment schedule. <br />Then, track payments with the help of reminders so <br /> everyone’s always on the same page.",
                    _CREATE_INVOICE_: "CREATE INVOICE"
                }
            },
            EMAILS: {
                _EMAILS_COUNT_SINGLE_: 'Email',
                _EMAILS_COUNT_: 'Emails',
                _NO_EMAILS_SENT_: 'Looks like you haven\'t sent any emails.',
                _WHEN_EMAIL_SENT_: 'After you send a file, you\'ll see a copy of the email here.',
                _EMAIL_VIEWED_: 'Message seen {{date}}',
            },
            BUTTONS: {
                _CREATE_FILE_: 'Create File'
            },
            PIPELINE: {
                _VENDOR_: 'Vendor',
                _WIDGET_: 'Contact Form',
                _VENDOR_REFERRAL_: 'Referral',
                _REFERRED_: 'Referred',
                _RECEIVED_FILES_: 'Received Files',
            },
            FILES: {
                _NO_FILES_: 'Looks like you don\'t have any files',
                _CREATE_PROJECT_: 'Start by creating a project',
                _CREATE_LEAD_: 'Bring a lead and create a new project to get started',
                _CREATE_PROJECT_TWO_: 'Create a new project to get started',
                _EMPTY_STATE_HEADER_: 'Create a File',
                _EMPTY_STATE_HEADER_TRIALER_: 'Create Your First Proposal',
                _EMPTY_STATE_TEXT_: 'Customize and send files directly from here!',
                _EMPTY_STATE_TEXT_TRIALER_: 'Set up your services and contracts so clients will be able to review, sign, book, and pay.', // -- all in one place. Seamless for them, simple for you.'
                _FILES_TITLE_: "HoneyBook Files",
                _FILES_GENERAL_TITLE_: "Files",
                _LEGACY_FILES_TITLE_: "Legacy Files",
                _FLOWS_TITLE_: "Smart Files",
                _GALLERIES_TITLE_: "GALLERIES",
                _ATTACHMENTS_TITLE_: "Attachments",
                _IMAGES_TITLE_: "Images",
                _GALLERIES_EMPTY_: "You haven’t displayed any galleries in this project",
                _ATTACHMENTS_EMPTY_: "You haven’t sent any attachments to participants in this project",
                _FILES_EMPTY_: "No files were shared in this project",
                _IMAGES_EMPTY_: "You haven’t sent any images to participants in this project",
                _ATTACHMENTS_LOADING_: "Loading attachments...",
                _IMAGES_LOADING_: "Loading images...",
                _ATTACHMENTS_LIMIT_REACHED_: 'You have reached the limit of {{limit}} attachments and images',
                _CONFIRM_DELETE_: 'Are you sure you want to delete this file?',
                _DISABLED_DELETE_ATTACHMENT_TOOLTIP_: 'Belongs to a submitted file and cannot be deleted.',
                _MARK_AS_COMPLETED_TOAST_: 'File marked as completed. Automation steps that follow file completion will be triggered.'
            },
            FEED: {
                _WORKSPACE_CREATED_: 'New workspace created by {{creator}} on {{created_at | date:\'EEE, MMM d, yyyy\' }}',
                _WORKSPACE_CREATED_BY_REFERRAL_: '{{referred_by}} referred this workspace to {{creator}} on {{created_at | date }}',
                _NEW_MESSAGES_NOTIFICATION_: 'new messages above this line',
                _LOAD_MORE_: 'Load more',
                _ALL_MESSAGES_: 'You can respond to messages here or via email',
                _ITEM_SEEN_: 'read',
                _ITEM_READ_: 'Read',
                _ITEM_UNREAD_: 'UnRead',
                _ITEM_UNTRACK_: 'N/A',
                _ITEM_ONLY_VISIBLE_TO_YOU_: 'Only visible to you',
                _ACTIVITY_TITLE_MEMBER_: 'Active Smart Files',
                _ACTIVITY_TITLE_CLIENT_: 'Action Required',
                _ACTIVITY_SUBTITLE_: 'Track the actions and questions your client still needs to complete.',
                FLOW: {
                    MENU: {
                        _PREVIEW_: 'Preview',
                        _RENAME_: 'Rename',
                        _CONFIGURE_: 'Configure',
                        _COPY_VIEW_ONLY_: 'Copy view-only link',
                        _GENERATE_VIEW_ONLY_: 'Generate view-only link',
                        _MARK_FLOW_AS_COMPLETED_: 'Mark as completed',
                        _SAVE_AS_TEMPLATE_: 'Save as template',
                        _REMOVE_SCHEDULE_SEND_: 'Cancel scheduled send',
                        _PRINT_RESPONSES_: 'Print responses',
                        _COPT_LINK_TO_FLOW_: 'Copy link to smart file',
                        _DELETE_: 'Delete',
                    },
                    MODALS: {
                        _DELETE_CONFIRMATION_TITLE_: 'Delete file',
                        _DELETE_CONFIRMATION_: '<strong>{{flowTitle}}</strong> will be permanently deleted for everyone.',
                        _SCHEDULED_DELETE_CONFIRMATION_: '<strong>{{flowTitle}}</strong> will be permanently deleted for everyone and it will not be sent on <strong>{{scheduledDate}}</strong>.',
                        _SESSION_WILL_BE_CANCELED_NOTE_: '<br><br>Your session ' + '<strong>{{sessionName}}</strong>' +
                            ' will be canceled and your client will be notified by email.',
                        _SESSION_WILL_BE_DELETED_NOTE_: '<br><br>Your past session ' + '<strong>{{sessionName}}</strong>'+
                            ' will be deleted from your calendar.',
                        _SAVE_AS_TEMPLATE_: 'Save as template',
                        _RENAME_FLOW_: 'Rename smart file',
                        _FLOW_NAME_: 'Smart File name',
                        _TEMPALTE_NAME_: 'Smart File name',
                        _FLOW_PLACEHOLDER_: 'Corporate price guide, Design proposal, etc...'
                    }
                },
                ACTIVITY: {
                    FIRST_PAYMENT_PAID: {
                        _HEADER_: 'Booked',
                        _BODY_: '{{user.full_name | hbmarker}} booked and paid a retainer of {{payment.amount | hblocaleamount | currency:"$"}} on {{workspace_file.file_title | hbmarker:"file-link"}}',
                        _FOOTER_: '{{payment.count_description}}'
                    },
                    SOME_PAYMENT_PAID: {
                        _HEADER_: 'Payment paid',
                        _BODY_: '{{user.full_name | hbmarker}} paid {{payment.amount | hblocaleamount | currency:"$"}} on {{workspace_file.file_title | hbmarker:"file-link"}}',
                        _FOOTER_: '{{payment.count_description}}'
                    },
                    FIRST_PAYMENT_PENDING: {
                        _HEADER_: 'Payment pending',
                        _BODY_: '{{user.full_name | hbmarker}} booked and paid a retainer of {{payment.amount | hblocaleamount | currency:"$"}} on {{workspace_file.file_title | hbmarker:"file-link"}} via bank transfer.<br/>You will be notified when the payment is approved.',
                        _FOOTER_: '{{payment.count_description}}'
                    },
                    SOME_PAYMENT_PENDING: {
                        _HEADER_: 'Payment pending',
                        _BODY_: '{{user.full_name | hbmarker}} paid {{payment.amount | hblocaleamount | currency:"$"}} on {{workspace_file.file_title | hbmarker:"file-link"}} via bank transfer. Payment is currently pending.',
                        _FOOTER_: '{{payment.count_description}}'
                    },
                    FIRST_PAYMENT_MAP: {
                        _HEADER_: 'Booked',
                        _BODY_: '{{"Client" | hbmarker}} booked and paid a retainer of {{payment.amount | hblocaleamount | currency:"$"}} on {{workspace_file.file_title | hbmarker:"file-link"}}',
                        _FOOTER_: '{{payment.count_description}}'
                    },
                    SOME_PAYMENT_MAP: {
                        _HEADER_: 'Payment paid',
                        _BODY_: '{{"Client" | hbmarker}} paid {{payment.amount | hblocaleamount | currency:"$"}} on {{workspace_file.file_title | hbmarker:"file-link"}}',
                        _FOOTER_: '{{payment.count_description}}'
                    },
                    REFUND_PAYMENT_MAP: {
                        _HEADER_: 'Payment Refunded',
                        _BODY_: '{{user.full_name | hbmarker}} was refunded {{payment.amount | hblocaleamount | currency:"$"}} on {{workspace_file.file_title | hbmarker:"file-link"}}',
                        _FOOTER_: ''
                    },
                    PARTIAL_REFUND_PAYMENT_MAP: {
                        _HEADER_: 'Payment Partially Refunded',
                        _BODY_: '{{user.full_name | hbmarker}} was partially refunded {{payment.refund_data.refunded_amount | hblocaleamount | currency:"$"}} on {{workspace_file.file_title | hbmarker:"file-link"}}',
                        _FOOTER_: ''
                    },
                    ADDED_USER: {
                        _BODY_: '{{user.full_name | hbmarker}} added {{target.full_name | hbmarker}} to this workspace',
                        _FOOTER_: '',
                        _HEADER_: ''
                    },
                    ADDED_PICTIME_GALLERY: {
                        _BODY_: '{{user.full_name | hbmarker}} added a gallery to this workspace. {{"View gallery" | hbmarker:"workspace-pictime-gallery-link"}}',
                        _FOOTER_: '',
                        _HEADER_: ''
                    },
                    ASSIGNED_USER: {
                        _BODY_: '{{user.full_name | hbmarker}} assigned this workspace to {{target.full_name | hbmarker}}',
                        _FOOTER_: '',
                        _HEADER_: ''
                    },
                    SELECTED_BROCHURE_PROPOSAL: {
                        _HEADER_: 'A Brochure was submitted!',
                        _BODY_: '{{user.full_name | hbmarker}} has selected from {{selected_brochure_proposal.file_title | hbmarker}}. See it {{"here" | hbmarker:"workspace-files-tab-link"}}!',
                        _FOOTER_: ''
                    },
                    SELECTED_BROCHURE_PROPOSAL_BOT: {
                        _HEADER_: 'A Brochure was submitted!',
                        _BODY_: '{{user.full_name | hbmarker}} has selected from {{selected_brochure_proposal.file_title | hbmarker}}.',
                        _FOOTER_: ''
                    },
                    QUESTIONNAIRE_SUBMITTED: {
                        _HEADER_: 'A Questionnaire was submitted!',
                        _BODY_: '{{user.full_name | hbmarker}} has submitted {{questionnaire.file_title | hbmarker}}. See it {{"here" | hbmarker:"workspace-files-tab-link"}}!',
                        _FOOTER_: ''
                    },
                    ADDED_COMMENT: {
                        _FOOTER_: 'comment added to ',
                        _BODY_: '',
                        _HEADER_: ''
                    },
                    AGREEMENT_SIGNED: {
                        _HEADER_: 'SIGNED',
                        _BODY_: '{{user.full_name | hbmarker}} signed {{workspace_file.file_title | hbmarker:"file-link"}}',
                        _FOOTER_: ''
                    },
                    REMINDER_SENT: {
                        _BODY_: '{{user.full_name | hbmarker}} sent a payment reminder',
                        _FOOTER_: '',
                        _HEADER_: ''
                    },
                    AUTO_REMINDER_SENT: {
                        _BODY_: 'Auto payment reminder was sent',
                        _FOOTER_: '',
                        _HEADER_: ''
                    },
                    PAYMENT_REQUESTED: {
                        _BODY_: '{{user.full_name | hbmarker}} requested a payment on {{workspace_file.file_title | hbmarker:"file-link"}}',
                        _FOOTER_: '',
                        _HEADER_: ''

                    },
                    ALL_DAY_MEETING_SCHEDULED: {
                        _HEADER_: 'MEETING SCHEDULED',
                        _BODY_: '{{meeting_owner_full_name | hbmarker: "bold"}} scheduled {{calendar_item.title | hbmarker:"meeting-title"}} for {{calendar_item.date_start | date}}',
                        _FOOTER_: ''
                    },
                    MEETING_SCHEDULED: {
                        _HEADER_: 'MEETING SCHEDULED',
                        _BODY_: '{{meeting_owner_full_name | hbmarker: "bold"}} scheduled {{calendar_item.title | hbmarker:"meeting-title"}} for {{calendar_item.date_time_start | date}} at {{calendar_item.date_time_start | date:"shortTime"}}',
                        _FOOTER_: ''
                    },
                    ALL_DAY_MEETING_UPDATED: {
                        _HEADER_: 'MEETING UPDATED',
                        _BODY_: '{{meeting_owner_full_name | hbmarker: "bold"}} updated {{calendar_item.title | hbmarker:"meeting-title"}} for {{calendar_item.date_start | date}}',
                        _FOOTER_: ''
                    },
                    MEETING_UPDATED: {
                        _HEADER_: 'MEETING UPDATED',
                        _BODY_: '{{meeting_owner_full_name | hbmarker: "bold"}} updated {{calendar_item.title | hbmarker:"meeting-title"}} for {{calendar_item.date_time_start | date}} at {{calendar_item.date_time_start | date:"shortTime"}}',
                        _FOOTER_: ''
                    },
                    PVL_REQUESTED: {
                        _HEADER_: 'Recommendations requested',
                        _BODY_: '{{user.full_name | hbmarker: "bold"}} requested recommendations',
                        _FOOTER_: ''
                    },
                    SYS_GEN: {
                        _BODY_: '',
                        _FOOTER_: '',
                        _HEADER_: '',
                        _PRO_TIP_: 'PRO TIP: ',
                        _PREFERENCES_AUTOMATION: 'This message was auto-sent according to your preferences. You can customize these at any time in your',
                        _COMPANY_SETTINGS_PAGE_: 'company settings page.'
                    },
                    SESSIONS: {
                        _BODY_: '',
                        _FOOTER_: '',
                        _HEADER_: '',
                        _ON_: 'on ',
                        _YOU_: 'You',
                        _CANCELED_: '{{user}} canceled: {{title}} ',
                        _RESCHEDULED_: '{{user}} rescheduled: {{title}}',
                        _SCHEDULED_FOR_: ' scheduled for ',
                        _NEW_TIME_: 'New time:',
                        _PREVIOUS_TIME_: 'Previous date and time:',
                        _VIEW_IN_CALENDAR_: 'VIEW IN CALENDAR',
                        _MATCH_DATE_FEED_SET_: 'Project date is now set to this date.',
                        _MATACH_DATE_TIPPY_: 'Session was set to apply first meeting date as the project date.',
                        _MATCH_DATE_FEED_RESCHEDULE_: 'Project date was updated to the new date and time.',
                        _MATACH_DATE_RESCHEDULE_TIPPY_: 'Session was set to apply first meeting date as the project date.',
                        _MATCH_DATE_FEED_CANCEL_: 'Project date removed.',
                        _MATACH_DATE_CANCEL_TIPPY_: 'Session was set to apply first meeting date as the project date. Since this session was canceled project date is now empty.'
                    },
                    GIFT_CARD: {
                        _BODY_: '',
                        _FOOTER_: '',
                        _HEADER_: '',
                        _PROMOTION_PURCHASED_: 'Gift card purchased',
                        _REDEEMED_: "Discount code redeemed",
                        _DISCOUNT_MESSAGE_: ' for {{amountPaid}} (a {{discountAmount}} discount)',
                        _PURCHASED_MESSAGE_: '{{full_name}} just bought a {{value|hbcurrency: currency}} gift card{{discountText}}! When you\'re both ready, the gift card can be redeemed using the following code: {{voucher_code  | hbmarker}}',
                        _REDEEMED_MESSAGE_: '{{redeemedValue}} from the gift card code: {{voucher_code}} has been redeemed!<br/>{{paymentStatus}}',
                        _REDEEMED_MESSAGE_REMAINING_: 'The gift card has a remaining balance of {{remaining_value | hbcurrency: currency}} remaining.',
                        _REDEEMED_MESSAGE_DONE_: 'The full value of this gift card has now been used, so there is no remaining balance.',
                        _SEE_INVOICE_: "SEE INVOICE"
                    },
                    FLOW: {
                        _BODY_: '',
                        _FOOTER_: '',
                        _HEADER_: '',
                        _COMPLETED_: '{{full_name | hbmarker: "bold"}} completed the file: {{title | hbmarker: "bold"}}',
                        _RE_COMPLETED_: '{{full_name | hbmarker: "bold"}} recompleted the file: {{title | hbmarker: "bold"}}',
                        _SUBMITTED_: '{{full_name | hbmarker: "bold"}} submitted {{title | hbmarker: "bold"}}',
                        _RE_SUBMITTED_: '{{full_name | hbmarker: "bold"}} resubmitted {{title | hbmarker: "bold"}}',
                        _VIEWED_: '{{full_name | hbmarker: "bold"}} viewed {{title | hbmarker: "bold"}}',
                        _DELETED_: '{{full_name | hbmarker: "bold"}} deleted {{title | hbmarker: "bold"}}',
                        _PUBLISHED_: '{{full_name | hbmarker: "bold"}} published some changes to {{title | hbmarker: "bold"}}',
                        _DRAFT_: '{{full_name | hbmarker: "bold"}} created a draft: {{title | hbmarker: "bold"}}',
                        PUBLISHED_WITHOUT_SHARING: {
                            _TITLE_: '{{full_name | hbmarker: "bold"}} published without sharing to your clients {{title | hbmarker: "bold"}}',
                            _DESCRIPTION_: 'The file is accessible to your clients through the client portal. <a target="_blank" href="https://help.honeybook.com/en/articles/2621043-honeybook-client-portal">Learn about the client portal.</a>',
                            _CX_TITLE_: '{{full_name | hbmarker: "bold"}} published {{title | hbmarker: "bold"}}'
                        },

                        SIGNATURE: {
                            _COLLECTED_TITLE_CURRENT_CLIENT_: 'You signed a contract in {{flowTitle | hbmarker: "bold"}}.',
                            _COLLECTED_TITLE_: '{{ clientName | hbmarker: "bold" }} signed a contract in {{flowTitle | hbmarker: "bold"}}.',
                            _COLLECTED_VENDOR_DESCRIPTION_SINGULAR_: ' There is now 1 remaining required signature.',
                            _COLLECTED_VENDOR_DESCRIPTION_PLURAL_: ' {{ clientName | hbmarker: "bold" }} signed a contract. There are now {{ signaturesLeftCount  }} required signatures remaining in {{flowTitle | hbmarker: "bold"}}.',
                            _COUNTERSIGN_READY_TITLE_: 'Final signature collected from {{ clientName | hbmarker: "bold" }}. Your countersignature is now required for {{flowTitle | hbmarker: "bold"}}.',
                            _COUNTERSIGN_READY_DESCRIPTION_: 'You can now countersign.',
                            _NOT_COUNTERSIGNING_MEMBER_COUNTERSIGN_READY_DESCRIPTION_: 'Now awaiting a countersignature from {{ membersNames | hbmarker: "bold" }}.',
                            _AWAITING_COUNTERSIGN_: 'You still need to sign a contract in {{flowTitle | hbmarker: "bold"}} - {{eventName | hbmarker: "bold"}}. Countersign now.',
                            _CONTRACT_COMPLETED_: 'All required signatures have been collected for a contract in {{flowTitle | hbmarker: "bold"}}'
                        },

                        SERVICE_SELECTION: {
                            _FLOW_SERVICE_SELECTED_: '{{clientName | hbmarker: "bold"}} made service selections valued at a total of ${{price | hblocaleamount }} in {{flowTitle | hbmarker: "bold"}}.',
                            _AUTOMATIC_FLOW_CREATED_BY_SELECTED_SERVICE_: '{{clientName | hbmarker: "bold"}} just made service selections valued at ${{price | hblocaleamount }} in {{flowTitle | hbmarker: "bold"}}. Based on the selections, we automatically created an invoice Smart File.',
                            _SERVICE_SELECTION_CHANGED_: '{{clientName | hbmarker: "bold"}} just made some changes to their service selections in {{flowTitle | hbmarker: "bold"}}. They are now valued at ${{price | hblocaleamount }}.',
                            _FLOW_SERVICE_SELECTED_NO_PRICE_: '{{clientName | hbmarker: "bold"}} just made service selections in {{flowTitle | hbmarker: "bold"}}.',
                            _AUTOMATIC_FLOW_CREATED_BY_SELECTED_SERVICE_NO_PRICE_: '{{clientName | hbmarker: "bold"}} just made service selections in {{flowTitle | hbmarker: "bold"}}. Based on the selections, we automatically created an invoice Smart File.',
                            _SERVICE_SELECTION_CHANGED_NO_PRICE_: '{{clientName | hbmarker: "bold"}} just made some changes to their service selections in {{flowTitle | hbmarker: "bold"}}.',
                            _FLOW_SERVICE_SELECTED_CLIENT_: '{{clientName | hbmarker: "bold"}} just made service selections valued at ${{price | hblocaleamount}} in {{flowTitle | hbmarker: "bold"}}.',
                            _FLOW_SERVICE_SELECTED_CLIENT_NO_PRICE_: '{{clientName | hbmarker: "bold"}} just made service selections in  {{flowTitle | hbmarker: "bold"}}.',
                            _SERVICE_SELECTION_CHANGED_CLIENT_: '{{clientName | hbmarker: "bold"}} just made some changes to their service selections in {{flowTitle | hbmarker: "bold"}}. They are now valued at ${{price | hblocaleamount }}.',
                            _SERVICE_SELECTION_CHANGED_CLIENT_NO_PRICE_: '{{clientName | hbmarker: "bold"}} just made some changes to their service selections in {{flowTitle | hbmarker: "bold"}}.',
                            _SERVICE_SELECTION_DESCRIPTION_: '{{item_title}} {{count}} selected',
                            _INVOICE_FLOW_UPDATED_: ' We’ve updated your connected invoice Smart File.'
                        },

                        PAYMENT: {
                            _PAYMENT_REMINDER_: '{{full_name | hbmarker: "bold"}} sent a payment reminder to {{client | hbmarker: "bold"}} regarding {{title | hbmarker: "bold"}}',
                            _AUTO_PAYMENT_REMINDER_: 'An automated payment reminder was sent to {{client | hbmarker: "bold"}} regarding {{title | hbmarker: "bold"}}',
                            _MARK_AS_PAID_: '{{full_name | hbmarker: "bold"}} marked ${{amount | hblocaleamount}} as paid in {{title | hbmarker: "bold"}}',
                            _MARK_AS_UNPAID_: '{{full_name | hbmarker: "bold"}} marked ${{amount | hblocaleamount}} as unpaid in {{title | hbmarker: "bold"}}',
                            _MARK_AS_REFUND_: '{{full_name | hbmarker: "bold"}} marked ${{amount | hblocaleamount}} as refunded from {{title | hbmarker: "bold"}}',
                            _REFUND_: '{{full_name | hbmarker: "bold"}} refunded ${{amount | hblocaleamount}} to {{client | hbmarker: "bold"}} from {{title | hbmarker: "bold"}}',
                            _PAID_: '{{client | hbmarker: "bold"}} paid ${{amount | hblocaleamount}} via {{paymentMethod}} for {{title | hbmarker: "bold"}}',
                            _PAID_WITH_GRATUITY_: '{{client | hbmarker: "bold"}} paid ${{amount | hblocaleamount}} and added ${{gratuity}} gratuity via {{paymentMethod}} for {{title | hbmarker: "bold"}}',
                            _PAYMENT_PENDING_: '{{client | hbmarker: "bold"}} booked and paid a retainer of ${{amount | hblocaleamount}} on {{title | hbmarker: "bold"}} via bank transfer.<br/>You will be notified when the payment is approved.',
                            _PAYMENT_REQUESTED_: '{{full_name | hbmarker}} requested a payment on {{title | hbmarker: "bold"}}'
                        },
                    },
                    LEAD_FORM: {
                        _COMPLETED_: '{{full_name | hbmarker: "bold"}} submitted a lead form: {{title | hbmarker: "bold"}}',
                    },
                },
                REQUESTED_PVL: {
                    _TITLE_: 'VENDOR RECOMMENDATIONS',
                    _DESCRIPTION_: '{{client ? client : "Client" | hbmarker}} asked for vendor recommendations',
                    _DESCRIPTION_WITH_TYPES_: '{{client ? client : "Client" | hbmarker}} asked for vendor recommendations for:',
                    _ACTION_: 'SHARE RECOMMENDATIONS...',
                    _DISMISS_: 'SKIP',
                    _INQUIRE_: 'INQUIRE',
                    _INQUIRY_SENT_: 'INQUIRY SENT',
                    _INQUIRY_SENT_ERROR_: 'Error. please try again',
                    _DISMISS_OPTIONS_TITLE_: 'Choose a reason',
                    DISMISS_OPTIONS: {
                        _ALREADY_DONE_: "I've already done this",
                        _LATER_: "I’ll do this later",
                        _NO_RECOMMENDATIONS_: "I don’t have any recommendations",
                        _NOT_RELEVANT_: "It’s not relevant",
                        _OTHER_: "Other"
                    }
                },
                CLIENT_REQUEST_PVL: {
                    _TITLE_: 'NEED RECOMMENDATIONS?',
                    _CONTENT_TEXT_: 'Ask {{vendor_full_name | hbmarker:"bold"}} for their vendor recommendations.',
                    _DISMISS_: 'SKIP',
                    _REQUEST_: 'REQUEST'
                },
                PVL_ITEM: {
                    _HEADER_: '{{sender.full_name | hbmarker}} shared their',
                    _VENDOR_RECOMMENDATIONS_LINK_: 'Vendor Recommendations',
                    _DESCRIPTION_: '{{sender_first_name}} thinks these vendors would be a great fit for you.'
                },
                LEAD_CONFLICT: {
                    _HEADER_: 'It looks like you have a conflict',
                    _BOOKED_: 'It looks like you\'re already booked on this date:',
                    _LEADS_: 'It looks like you have {{plural ? "conflicts with other leads" : "a conflict with another lead"}} on this date:',
                    _ONE_: 'There is a conflict on this date:',
                    _MULTIPLE_: 'There are {{numOfConflictingProjects > 2 ? "multiple" : "2"}} conflicts on this date:',
                    _ACTION_: 'OK, GOT IT',
                    _STATUS_BOOKED_: 'Booked',
                    _STATUS_TENTATIVE_: 'Tentative',
                    _AND_MORE_: 'And {{numberOfChoppedProjects}} more {{type === "TENTATIVE" ? "tentative" : "booked"}} {{ numberOfChoppedProjects == 1 ? "project" : "projects"}}',
                    _GO_TO_SETTINGS_: 'You can turn off date conflict notifications from <a class="feed-conflict-banner__link-to-settings" href="/app/settings/company/preferences">Preferences</a>'
                },
                ADD_TEAM_MEMBER_BANNER: {
                    _ONE_MEMBER_PREFIX_: 'ATTENTION: {{teamMember}}',
                    _TWO_MEMBERS_PREFIX_: 'ATTENTION: {{teamMember}} and {{secondTeamMember}}',
                    _AND_: ' and ',
                    _MORE_THAN_TWO_MEMBERS_PREFIX_: 'ATTENTION:', // need to calc this one
                    _TEXT_: 'has been invited to the scheduled {{numOfSessions > 1 ? "sessions" : "session"}} listed below. However, they are not a participant in the project. Add them to grant access to files and communications.',
                    _TEAM_MEMBER_TITLE_: '<strong> Team member: </strong>',
                    _SESSION_TITLE_: '<strong> Session: </strong>',
                    _DATE_TITLE_: '<strong> Date & time: </strong>',
                    _CTA_TEXT_: 'Add team member to project',
                    _CTA_TEXT_PLURAL_: 'Add all team members to project'
                }
            },
            ONBOARDING: {
                VENDORS: {
                    _HEADER_: 'This is a private place where vendors can collaborate',
                    _SUBTITLE_: 'Here\'s what you can do to get started',
                    _STEP_ONE_: 'Iron out the "behind the scenes" details with a private conversation',
                    _STEP_TWO_: 'Share photos, design boards, ideas and inspiration',
                    _STEP_THREE_: 'Make sure everyone\'s on track with a shared project timeline'
                },
                TEAM: {
                    _HEADER_: 'This is a private place to collaborate with your team',
                    _SUBTITLE_: 'Here\'s what you can do to get started',
                    _STEP_ONE_: 'Iron out the "behind the scenes" details with a private conversation',
                    _STEP_TWO_: 'Share photos, design boards, ideas and inspiration',
                    _STEP_THREE_: 'Figure out team responsibilities and make sure everyone\'s on track'
                },
                DESIGN: {
                    _HEADER_: 'Welcome to your design workspace',
                    _SUBTITLE_: 'Here\'s what you can do to get started',
                    _STEP_ONE_: 'Make sure everyone\'s in the loop about the design details',
                    _STEP_TWO_: 'Share photos, design boards, links, ideas and inspiration',
                    _STEP_THREE_: 'Figure out team responsibilities and make sure everyone\'s on track'
                }
            },
            _UNABLE_TO_LOAD_MESSAGE_: 'Oops, we\'re unable to find the project you\'re looking for. Perhaps it is not shared with you or it has been deleted.',
            LABELS: {
                _PROJECT_NAME_: 'Project Name',
                _OWNER_NAME_: 'Creator'
            },
            CANCEL: {
                _MESSAGE_: 'Blocking will lock this workspace and all the files and outstanding payments associated with it.',
                _ALERT_: 'Are you sure you want to block "{{ workspace_name }}" ?',
                _FULL_MESSAGE_: 'Canceling will PERMANENTLY lock this workspace and all the files and outstanding payments associated with it. Are you sure you want to cancel "{{ workspace_name }}" ?',
                _ERROR_: 'Error blocking workspace',
                _YES_: 'YES',
                _NO_: 'NO'
            },
            UNDO:{
                _MESSAGE_: 'Message sending cancelled successfully.'
            },
            DELETE: {
                _MESSAGE_: 'This will PERMANENTLY remove all data associated with this workspace, including all the files within it.',
                _ALERT_: 'Are you sure you want to delete "{{ workspace_name }}" ?',
                _FULL_MESSAGE_: 'This project and all its content will be permanently deleted.\n' +
                    '\n' +
                    '<b> Project name: </b> "{{ workspace_name }}" ',
                _FULL_MESSAGE_PLURAL_WORKSPACES_: 'This workspace and all its content will be permanently deleted.\n' +
                    '\n' +
                    '<b> Workspace name: </b> "{{ workspace_name }}" ',
                _TITLE_: 'Permanently delete project?',
                _TITLE_PLURAL_WORKSPACES_: 'Permanently delete workspace?',
                _YES_: 'DELETE PROJECT',
                _YES_PLURAL_WORKSPACES_: 'DELETE WORKSPACE',
                _NO_: 'DO NOT DELETE',
                _ERROR_: 'Oops, there was a problem deleting the workspace. Please try again.',
                _SUCCESS_: 'Workspace deleted successfully',
                _CONFIRM_BULK_DELETE_: 'Delete Projects',
                _BULK_FULL_MESSAGE_: 'Are you sure you want to delete {{ workspaces_count }} projects?\nYou will not be able to undo this action.',
                _PENDING_TITLE_: 'Deleting projects',
                _BULK_FAILED_TITLE_: 'Couldn\'t delete {{ failed_count }} of the selected projects',
                _CANCEL_: 'Cancel',
                _TOOLTIP_: '{{project_name}} in {{stage_name}} was deleted'
            },
            ARCHIVE: {
                _ERROR_: 'Oops, there was a problem archiving the workspace. Please try again.',
                _BULK_PENDING_TITLE: 'Archiving projects',
                _BULK_FAILED_TITLE_: 'Couldn\'t archive {{ failed_count }} of the selected projects',
                _FULL_MESSAGE_: 'These projects have related payments or a signed contract so they can only be archived. ',
                _YES_: 'ARCHIVE PROJECT',
                _NO_: 'LEAVE IN PIPELINE',
                _CANCEL_: 'CANCEL',
                _TITLE_: 'Project cannot be deleted',
                _TOOLTIP_: '{{project_name}} archived successfully'
            },
            MOVE: {
                _BULK_ERROR_: 'Oops, there was a problem moving the workspaces. Please try again.',
                _BULK_PENDING_TITLE_: 'Moving projects',
                _BULK_FAILED_TITLE_: 'Couldn\'t move {{ failed_count }} of the selected projects',
            },
            BULK_GENERAL: {
                _FAILED_MSG_: 'This might happen due to several reasons - <a ng-if="pendingActionModalVm.failedLink" target="_blank" href="{{ failed_link }}">click here</a> to learn more',
            },
            _TBD_: 'TBD',
            ADD_MEMBER: {
                _ACTIVE_: 'Active',
                _HIDE_: 'hide',
                _SHOW_: 'show',
                _YOU_: '(You)',
                REINVITE: {
                    _REINVITE_: 'Re-invite',
                    _SENDING_: 'Sending',
                    _SENT_: 'Sent',
                    _ERROR_: "Error resending the invite. Please try again later."
                },
                _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added to the workspace. Are you sure you\'re done editing?',
                _CANNOT_ADD_ADDED_USER_: 'Hmm, it looks like this email address has already been added to this project.',
                _CANNOT_ADD_CLIENT_: 'Oops! You can only request a proposal from a vendor.',
                _CANNOT_ADD_ME_: 'Hmm, you cannot add yourself.',
                _ADD_TEAM_MEMBER_DIALOG_TITLE_: 'Select Team Member',
                _ADD_TEAM_MEMBER_DIALOG_SUBTITLE_: '(You can always add more later)'
            },
            REMOVE_MEMBER: {
                _IS_MEMBER_SIGNATORY_: "This user is a signatory on a file. They will no longer have access once removed. Are you sure you want to continue?",
            },
            REQUEST_PROPOSAL: {
                _NO_VENDOR_: 'Requesting a proposal is an opportunity to work with another vendor. To add a new workspace, open the Project Details, click on \'Workspaces\' and choose \'Add Workspace\'',
                _HEADER_TITLE_: 'Which vendor would you like to request a proposal from?'
            },
            DIRECT_PAYMENT: {
                _NO_VENDOR_OR_TO_MANY_MEMBERS_: 'To set payment to another vendor, you have to have a vendor and just one vendor in the workspace.',
                _HEADER_TITLE_: 'Which vendor would you like to request a proposal from?'
            },
            ASSIGN_TEAM_MEMBERS: {
                _HEADER_TITLE_: 'Select team member',
                _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added to your team. Are you sure you\'re done editing?',
                _NOT_REMOVED_SUCCESS_TEXT_: 'Success! We’ve assigned the workspace to your team member. If you were a part of the workspace - you are still part of it, but most notifications will be muted and the workspace will be removed from your pipeline.',
                _REMOVED_SUCCESS_TEXT_: 'Success! We assigned the workspace to your team member and if you were a part of it we removed you from it.',
                _ERROR_: 'You can\'t assign a workspace after a file has been sent',
                MOVE_SESSIONS: {
                    _TEXT_: 'You have scheduled meetings or sessions connected to this project. Would you like to assign these as well?',
                    _YES_ASSIGN_: 'Yes, assign',
                    _NO_KEEP_: 'No, keep'
                }
            },
            WORKSPACE_TYPE_INTRO: {
                _FEED_MESSAGE_BODY_: '<p>Hi friend! This is a private workspace to collaborate with each other on project details. We can share files, kick around ideas, and keep all of the important information in one place. No more scanning months of e-mails to find details or checking dates to find up-to-date information. Let\'s get started!</p>',
                _FEED_MESSAGE_TOOLTIP_: 'Great! Now let\'s reach out and send a quick intro message to the rest of the workspace members',
                TEAM: {
                    _HEADER_: 'Welcome to your team\'s safe place',
                    _BODY_: 'This is a private workspace for you and your team to collaborate on the project. By default, your client won\'t have access to this workspace.',
                    _ACTION_BUTTON_: 'Let\'s Get Started'
                },
                DESIGN: {
                    _HEADER_: 'Craft a vision together',
                    _BODY_: 'Use this private workspace to kick around design ideas with clients and other vendors. Share design boards, links and other inspiration all in one place.',
                    _ACTION_BUTTON_: 'Let\'s Get Started'
                },
                ALL_VENDORS: {
                    _HEADER_: 'Collaborate with other vendors on this project',
                    _BODY_: 'This is a private workspace to collaborate with other vendors on project details. Share files, collaborate on a timeline, and kick around ideas.',
                    _ACTION_BUTTON_: 'Let\'s Get Started'
                },
                TIMELINE: {
                    _HEADER_: 'Create a timeline for the project',
                    _BODY_: 'Create a timeline with the client and other vendors',
                    _ACTION_BUTTON_: 'Let\'s Get Started'
                }
            },
            WORKFLOW: {
                LABELS: {
                    _ADD_WORKFLOW_TEMPLATE_: 'Apply automation',
                    _CHOOSE_WORKFLOW_: 'Select an automation to use in',
                    _STEP_1_WILL_BE_TRIGGER_: 'Step 1 will trigger based on the date the automation is applied.',
                    _APPLY_: 'Apply',
                    _STEPS_: 'Steps',
                    _WANT_TO_EDIT_: 'Want to create or edit an automation template?',
                    _DUPLICATE_WORKFLOW_LABEL_: 'Please name your automation template',
                    _ACTIVE_WORKFLOW_: 'Active Automation'
                },

                POPUPS: {
                    _CONFIRM_PROJECT_TYPE_SELECTION_: 'Any contact form inquiries with the following project types will automatically have this workflow template applied: {{projectTypes}}',
                    _PROJECT_TYPE_CONTACT_FORM_HELP_: 'Select the project types that you want  to apply this automation to when they come in via the contact form',
                    _NO_TEMPLATES_AVAILABLE_: 'Please setup a {{templateType}} template in order to create this step',
                    _CREATE_TEMPLATE_: 'Create template',
                    _GOT_IT_: 'OK, GOT IT'
                }
            },

            TOOLS: {

                _HEADER_: 'Private place for you and your internal team to manage this project.',

                NOTES: {
                    _TITLE_: 'Notes',
                    _PLACEHOLDER_: 'Add your private project notes here',
                    _VIEW_: 'View'
                },

                SESSIONS: {
                    _TITLE_: 'Scheduled sessions',
                    _FILE_LINK_: 'File:',
                    TOOLTIPS: {
                        RELATED_TO_WS: 'this session is connected to a future step in this project’s automation'
                    }
                }
            },

            PARTICIPANTS: {
                MODAL: {
                    _TITLE_: 'Participant Info',
                    _EDIT_: 'Edit',
                },
                LABELS: {
                    _PARTICIPANT_: 'Participant',
                    _NOTES_: 'Notes',
                    _VISIBLE_TO_: 'Visible to you',
                    _EMAIL_: 'Email',
                    _PHONE_: 'Phone',
                    _WEBSITE_: 'Website',
                    _MAILING_ADDRESS_: 'Mailing Address',
                    _REMOVE_FROM_PROJECT_: 'Remove from Project',
                    _VIEW_CLIENT_: 'see more details',
                    _PRIVATE_NOTE_: 'Private Note',
                    _NAME_: 'Name',
                    _COMPANY_NAME_: 'Company Name',
                    _JOB_TITLE_: 'Job Title',
                    _MORE_PARTICIPANTS_: 'More Participants'
                }

            },
            TIPPY: {
                _TAB_FILE_: 'You can find files associated with a project here in the Files tab of your workspace.'
            },

            PROJECT_DETAILS: {
                _PROJECT_NAME_: 'Project Name',
                _PROJECT_TYPE_: 'Project Type',
                _PROJECT_DATE_: 'Project Date',
                _START_DATE_: 'Start Date',
                _RESCHEDULE_TOOLTIP_: 'Reschedule project',
                _PROJECT_LOCATION_: 'Project Location',
                _IS_BUSY_AVAILABLE_: 'Availability',
                _ADD_LOCATION_: 'Add location',
                _GUESTS_: 'Guests',
                _BUDGET_: 'Budget',
                _DATE_: 'Date',
                _DATE_N_LOCATION_: 'Date & Location',
                _DATE_TITLE_: 'Date title',
                _LOCATION_TITLE_: 'Location title',
                _LABEL_ERROR_: 'A {{labelType}} with this title already exists.',
                _SPACES_: 'Spaces',
                _TIME_: 'Time',
                _LOCATION_: 'Location',
                _ADD_DATE_: 'Add date',
                _TIMEZONE_: 'Timezone:',
                _ADDRESS_: 'Address',
                _PARTIAL_DATE_: '{{start}}',
                _FULL_DATE_: '{{start}} at {{end}}',
                _TO_: 'to',
                _FROM_: 'from ',
                _NOTES_: 'Notes',
                _PRIVATE_DETAILS_: 'PRIVATE DETAILS',
                _NOTES_PLACEHOLDER_: 'Add notes...',
                _NOTES_VISIBILITY_: 'The following information is only visible to you.',
                _ADD_END_DATE_: 'Add end date',
                _GO_FULL_SCREEN_: 'Expand view',
                _EXIT_: 'EXIT FULLSCREEN',
                _NOT_VISIBLE_TO_CLIENTS_: 'Not visible to clients',
                _ADD_NEW_NOTE_: 'Add new note',
                _YES_DELETE_: 'Yes, Delete',
                _ARE_YOU_SURE_: 'Are you sure?',
                _WILL_BE_DELETED_: 'will be deleted. This cannot be undone.',
                _YOUR_NOTE_ON_: 'Your note on',
                _at_: 'at',
                _DELETE_THIS_NOTE_: 'Delete this note',
                _ABOUT_THE_PROJECT_: 'About the project',
                _TEXT_PLACEHOLDER_: 'Your text here',
                _PLEASE_CHOOSE_A_START_DATE_: 'Please choose start date',
                _PLEASE_CHOOSE_A_START_TIME_: 'Please choose start time',
                _ADD_SPACE_: 'Looks like you haven\'t added any spaces to your calendar',
                _LINK_TO_CUSTOMIZE_: 'Want to edit or customize your project details? You can do this at any time from the "Preferences" tab in ',
                _SETTINGS_: 'Settings',
                _PROJECT_AVAIL_TOOLTIP_: "<b>Busy</b>: When you book a project and set availability to <i>busy</i>, clients & leads cannot schedule sessions on the project date/s."
            },

            MWEB_APP_REFER_MODAL: {
                _TITLE_: 'Send smart files from the app',
                _DESCRIPTION_: 'Create amazing client experiences and send smart files from the mobile app.',
                _GO_TO_APP_: 'CONTINUE TO APP',
                _AVAILABILITY_: 'Smart Files are available on iOS, Android, and desktop.'
            }
        },

        GOOGLE_INETGRAION_CALLBACK_PAGE: {
            _MESSAGE_: 'Connecting to your Google account.</br>Almost there...'
        },

        ZOOM_INTEGRATION_CALLBACK_PAGE: {
            _MESSAGE_: 'Connecting to your Zoom account...</br>'
        },

        NYLAS_INTEGRATION_CALLBACK_PAGE: {
            _MESSAGE_: 'Connecting to your account...</br>'
        },

        YAHOO_INTEGRATION_CALLBACK_PAGE: {
            _MESSAGE_: 'Connecting to your Yahoo account...</br>'
        },

        CANVA_INTEGRATION_CALLBACK_PAGE: {
            _MESSAGE_: 'Connecting to your Canva account...</br>'
        },

        CALENDLY_INTEGRATION_CALLBACK_PAGE: {
            _MESSAGE_: 'Connecting to your Calendly account...</br>'
        },

        INTELLUM_INTEGRATION_SSO_CALLBACK_PAGE: {
            _MESSAGE_: 'Connecting you to the Academy...</br>'
        },

        QUICKBOOKS_INTEGRATION_CALLBACK_PAGE: {
            _MESSAGE_: 'Connecting to your QuickBooks account...</br>'
        },

        ACCOUNT: {
            _TITLE_: 'ACCOUNT',

            ADD_SECURITY_PHONE_NUMBER: {
                MODAL: {
                    _TITLE_: 'Verify your account',
                    _NOTE_: 'Note:',
                    _NOTE_TEXT_: 'Before editing your security phone number, you need to re-login to your account',
                    _CONTINUE_: 'Continue',
                    _ENTER_YOUR_CODE_: 'Enter your code',
                    _WE_NEED_TO_VERIFY_: 'We need to verify it’s you. Enter below the 6 digits verification code we sent to your email:',
                    _ERROR_NO_CODE_: 'Please enter code',
                    _ERROR_CODE_: 'Wrong code',
                    _TOO_MANY_ATTEMPTS_: 'Too many attempts. Please try again later.',
                }
            },

            LOGIN_TWO_FACTOR_AUTH: {
                MODAL: {
                    _TITLE_: 'Add 2-step verification',
                    _REVIEW_YOUR_PHONE_NUMBER_: 'Review your security phone number',
                    _WHEN_SIGNING_IN_: 'When signing in, we will send a code to this number. You will need to enter it to access your account.',
                    _RATES_MAY_APPLY_: 'Note: Messages and data rates may apply',
                    _CHANGE_SECURITY_NUMBER_: 'The security number can be changed in the account settings page',
                    _HOW_TO_RECEIVE_: 'How would you like to receive your code?',
                    _SEND_CODE_: 'SEND CODE',
                    _CONFIRM_SECURITY_PHONE_NUMBER_: 'Confirm your security phone number',
                    _WE_SENT_YOU_VERIFICATION_CODE_: 'We sent a 6 digit verification code to the number (•••) •••-{{number}}. Enter the code below:',
                    _DIDNT_GET_THE_CODE_: 'Didn’t get the code? Resend or contact support',
                    _ERROR_NO_CODE_: 'Please enter code',
                    _ERROR_CODE_: 'Wrong code',
                    _TOO_MANY_ATTEMPTS_: 'Too many attempts. Please try again later.',
                    _DONE_: 'DONE',
                    _RESEND_: 'Resend',
                    _CONTACT_SUPPORT_: 'contact support',
                    _SMS_: 'Text Message',
                    _PHONE_CALL_: 'Phone Call',
                    _NO_CODE_ERROR_: 'Please enter code',
                    _COMPLETE_CTA_: 'SUPER, THANKS!',
                    _COMPLETE_TITLE_: 'You just helped us protect your account, you’re a true hero!',
                    _COMPLETE_SUBTITLE_: '2-step verification is now active on your account.  Each time you log in you’ll be prompted to enter a code, as an extra layer of security.'

                }
            },

            EMAIL_INTEGRATION: {
                _TITLE_: 'Email Integration',
                _SUB_TITLE_: 'Your Clients will receive emails from this address:',
                _GOOGLE_INTEGRATION_TEXT_: 'Connect your Google account to have HoneyBook files and messages come directly from your Gmail address.',
                _SELECTED_VALUE_LABEL_: 'Messages will be sent from',
                _ADD_GOOGLE_INTEGRATION_: 'Connect Google account',
                _EMAIL_INTEGRATION_TEXT_1_: "<b>Everything in one place.</b> All your email communication with clients will be synced to HoneyBook, making sure you stay organized.",
                _EMAIL_INTEGRATION_TEXT_2_: "<b>Keep it professional & branded.</b> Files and messages will be sent directly from your business email with your company name.",
                _EMAIL_INTEGRATION_TEXT_3_: "<b>Keep it professional & branded.</b> Smart Files and messages will be sent directly from your business email with your company name.",
                _NYLAS_SELECTED_VALUE_LABEL_: 'YOUR CLIENTS ARE RECEIVING EMAILS FROM THIS ADDRESS:',
                _ADD_EMAIL_INTEGRATION_: 'Connect Email account',
                _ADD_NYLAS_INTEGRATION_: 'connect other email provider',
                _NYLAS_ACTIVE_EMAIL_TEXT_: 'is connected to your HoneyBook account. Messages and files will be sent from this email. Incoming emails will automatically be imported into the client\’s project',
                _NYLAS_ACTIVE_EMAIL_TEXT_FLOWS_: 'is connected to your HoneyBook account. Messages and Smart Files will be sent from this email. Incoming emails will automatically be imported into the client\’s project',
                _DISCONNECT_BUTTON_: 'Disconnect',
                _DISCONNECT_CONFIRM_: "Are you sure you want to disconnect your email integration?",

                ERRORS: {
                    _GETTING_AVAILABLE_EMAIL_PROVIDERS_: 'Oops, we couldn\’t get the email providers. Please refresh the page.',
                    _PROVIDER_ERROR_: 'Oops, we failed to identify your email provider. Please contact us for more info'
                },

                _REVOKED_BANNER_: 'There was an issue connecting to your Google account. Emails and files will be sent from HoneyBook\'s mail server until you reconnect below.',

                REVOKED_POPUP: {
                    _TITLE_: 'Google account not connected',
                    _SKIP_: 'SKIP AND DO THIS LATER',
                    _GO_: 'GO TO SETTINGS',
                    _CONTENT_: 'There’s an issue connecting to your Google account. Please go to settings and reconnect. Until then, all emails and files will be delivered from HoneyBook\'s mail server.'
                }
            },

            LABELS: {
                _SOCIAL_ACCOUNT_DETAILS_FACEBOOK: 'Connecting to a Facebook account enables you to log in with it. Don\'t worry, we won\'t post anything on your behalf.',
                _SOCIAL_ACCOUNT_DETAILS_INSTAGRAM: 'Connecting to an Instagram account. Don\'t worry, we won\'t post anything on your behalf.',
                _NAME_: 'Full Name',
                _USER_INFO_TITLE_: 'Account Info',
                _JOB_: 'Job Title',
                _EMAIL_: 'Email Address',
                _PERSONAL_ADDRESS_: 'Personal Address',
                _PHONE_: 'Phone Number',
                _OLD_PW_: 'Current Password',
                _NEW_PW_: 'New Password',
                _CONFIRM_NEW_PW_: 'Confirm New Password',
                _CHANGE_PW_: 'Change Password',
                _CREATE_PW_: 'Create Password',
                _SECURITY_2FA_: 'Login 2-Step Verification',
                _ZAPIER_API_KEY_: 'Zapier API Key:',
                _QUESTIONS_: 'Questions? Check out our',
                _FAQ_: 'FAQ',
                _START_TRIAL_: '<i>{{ company_name }}</i> uses HoneyBook to manage their business. Do you have a business too?',
                _START_TRIAL_CTA_: '<a class="settings-account__start-trial-link branded-link">Try HoneyBook for free</a>'
            },
            PLACEHOLDERS: {
                _NAME_: 'Full Name',
                _JOB_: 'Project Planner',
                _PHONE_: '(123) 456-7890',
                _OLD_PW_: 'Current Password',
                _NEW_PW_: 'At Least 8 Characters',
                _PERSONAL_ADDRESS_: 'Personal Address',
                _FIVE_CHARS_: '8 Characters',
                _CONFIRM_NEW_PW_: 'Retype New Password'
            },
            TOOLTIPS: {
                _EMAIL_: 'In order to change your email, you will be required to complete two factor authentication process',
            },
            BUTTONS: {
                _CHANGE_PIC_: 'Change Photo',
                _UPDATE_: 'Update',
                _CHANGE_PW_: 'Change Password',
                _SECURE_ACCOUNT_: 'Secure account with Password',
                _LINK_SENT_: 'Check your {{email}} inbox for a reset password link (make sure to check your spam)',
                _RESEND_EMAIL_: 'Resend email',
                _CONNECT_: 'Connect',
                _DISCONNECT_: 'Disconnect',
                _HIDE_: 'Hide',
                _COPY_: 'Copy',
                _SHOW_: 'Show',
                _ADD_2FA_: 'Add'
            },
            RESULT: {
                _CHANGE_SUCCESSFUL_: 'Password Changed',
                _CHANGE_UNSUCCESSFUL_: 'Wrong Password'
            },
            ERRORS: {
                _FIELD_IS_TOO_SHORT_: 'Your password must be at least 8 characters.',
                _FIELD_SHOULD_MATCH_PREVIOUS_: 'These passwords don\'t match.',
                _FIELD_IS_REQUIRED_: 'This field is required.',
                _CONNECTING_SOCIAL_ACCOUNT_: 'There was a problem connecting to your {{socialAccountType}} account',
                _DISCONNECTING_SOCIAL_ACCOUNT_: 'There was a problem disconnecting your {{socialAccountType}} account',
                _NEED_TO_BE_SELECTED_: 'Value needs to be selected from suggested list',
                _FAILED_COPY_TO_CLIPBOARD_: 'Could not copy to clipboard automatically, you can copy iy manually'
            }
        },

        COMMON_FEED_COMPONENTS: {
            EDITOR: {
                TOOLTIPS: {
                    _SHARE_VENDOR_RECOMMENDATIONS_: 'Share vendor recommendations',
                    _EMAIL_TEMPLATES_: 'Email templates',
                    _ATTACH_FILE_: 'Attach',
                    _ADD_EMAIL_SIGNATURE_: 'Add your email signature',
                    _ADD_CLIENT_NAME_: 'Add client name',
                    _ADD_CALENDLY_LINK_: 'Add Calendly link',
                    _WARN_CALENDLY_EXPER_: 'Calendly made changes. Update your integration under Company settings.',
                    _ADD_CLIENT_NAME_DISABLED_: 'Add a client to the project first',
                    _DELETE_DRAFT_: 'Delete draft',
                    _SET_SCHEDULED_MESSAGE_: 'Schedule send',
                    _CANCEL_SCHEDULER_: 'Cancel schedule'
                }
            },
            EMAIL_SEND_ERROR: {
                MODAL: {
                    _TITLE_: 'Failed sending email',
                    _HELPER_TEXT_: '{{ ( numValidEmails === 1) ? \'One\' : numValidEmails }} email{{ ( numValidEmails === 1) ? \' is valid\' : \'s are valid\'}} and will be sent once you review and edit the {{ (numInvalidEmails === 1) ? \'\' : numInvalidEmails }} problematic email{{ (numInvalidEmails === 1) ? \'\' : \'s\' }} below:',
                    _HELPER_TEXT_NO_VALID_EMAILS_: 'Please review and edit the invalid email{{ (numInvalidEmails === 1) ? \'\' : \'s\' }} below:',
                    _SEND_: 'Send',
                    _SEND_ANYWAY_: 'Send Anyway',
                    _SCHEDULE_: 'Schedule',
                    _SCHEDULE_ANYWAY_: 'Schedule Anyway',
                    _EDIT_: 'Edit Email Address',
                    _EDIT_MOBILE_: 'Edit',
                    _EDITED_: 'Email updated',
                    _DO_NOT_SEND_: 'Don’t {{ isSchedule ? \'Schedule\' : \'Send\' }} email'
                }
            },
            SCHEDULING_EXPOSURE_POC: {
                _YOUR_SESSIONS_: 'YOUR SESSIONS',
                _CREATE_A_SESSION_: 'CREATE A SESSION',
                _SETTING_UP_TIME_TO_MEET_TITLE_: 'Setting up time to meet?',
                _GET_SCHEDULED_QUICKER_: 'Get scheduled quicker using one of your existing sessions below.',
                _GET_MEETING_SCHEDULED_FASTER_: 'Get meetings scheduled faster by creating sessions and allowing clients to schedule time with you based on your availability.',
                _ADD_LINK_: 'Add a link to your new session',
                _YOU_CAN_ADD_LINKS_: 'You can add links to any email for all sessions you create from here.'
            },
            SCHEDULED_MESSAGE: {
                TOOLTIPS: {
                    _DELETE_SCHEDULED_: 'Cancel scheduled send'
                }
            },
            EDITOR_TABS: {
                _POST_MESSAGE_: 'Post a message',
                _SHARE_LEAD_: 'Share a lead'
            },
            MESSAGE_EDITOR: {
                _SENDING_FAILED_: 'Oops! There was a problem sending your message. Please try again.',
                _SENDING_WITH_FILES_FAILED_: 'Oops! There was a problem sending your message with the attached files. Please refresh the page and try again',
                _PLACEHOLDER_: 'Start your client conversation',
                _SUBJECT_: 'Subject: ',
                _PVL_INQUIRY_PLACEHOLDER_: 'Honey Tip: Reply now while this lead is hot!',
                _USERS_VENDOR_RECOMMENDATIONS_: '{{first_name}}\'s Vendor Recommendations',
                _ARCHIVE_WORKSPACE_FILE_POPUP_: 'This project is currently in Archive! We\'ve sent your file but make sure to move it to an active pipeline stage so that you don\'t miss a response.',
                _CREATE_NEW_SESSION_TYPE_: 'Create new session type'

            },
            COMMENTS: {
                _CONFIRM_DELETE_MESSAGE_: 'Are you sure you want to delete this comment?',
                _SHOW_COMMENTS_: "{{nComments-1}} more comments",
                _HIDE_COMMENTS_: "Hide comments"
            },
            LEAD: {
                HEADER: {
                    _INQUIRY_ADDED_: 'New Inquiry',
                    _INQUIRY_REFERRED_: 'New Inquiry Referred by {{sender_name | hbmarker}}',
                    _INTERESTED_: 'You\'re {{"interested" | hbmarker}} in this project',
                    _NOT_INTERESTED_: 'You\'re {{"not interested" | hbmarker}} in this project',
                    _SHOW_DETAILS_: 'More Info',
                    _HIDE_DETAILS_: 'Hide Info',
                    _SUBMITTED_: 'Submitted',
                    _RESPONDED_: 'Responded'
                },
                DETAILS: {
                    _PROJECT_TITLE_: '{{client}}\'s Project',
                    _LOCATION_: 'Location',
                    _LOOKING_FOR_: 'Looking For',
                    _ZERO_REPLIES_: '0 replies',
                    _ONE_REPLY_: '1 member replied',
                    _REPLIES_: '{{count}} members replied',
                    _EMAIL_: 'Client Email',
                    _PHONE_: 'Phone Number',
                    _GUESTS_: 'Guest Count',
                    _BUDGET_: 'Budget',
                    _ABOUT_: 'Project Details',
                    _PROJECT_TYPE_: 'Project Type',
                    _REFERRAL_SOURCE_: 'Referral Source',
                    _RESPONSE_SENT_TO_: 'Lead response sent to',
                    _RESPONSE_SENT_ON_: 'You replied on',
                    _SOURCE_: 'Source:'
                },
                RECORD: {
                    _INTERESTED_: 'Reply to {{name}}',
                    _INTERESTED_V2_: 'I\'m Interested',
                    _NOT_INTERESTED_: 'Not interested',
                    _RESPONSE_DATE_: 'You responded on {{resDate | date:"MMM d y"}}'
                },
                _SENT_SUCCESSFULLY_: 'Successfully sent'
            },
            LABELS: {
                _SHOW_DETAILS_: 'Not Interested',
                _HIDE_DETAILS_: 'Not Interested',
                _RESPONDED_: 'RESPONDED',
                _ATTACHMENTS_: 'ATTACHMENTS',
                _ADD_PVL_VENDORS_: 'Recommend Vendors',
                _GROUP_: 'GROUP',
                _INDIVIDUAL_: 'INDIVIDUAL',
                _TEAM_MEMBERS_: 'Team members:'
            },
            TOOLTIPS: {
                _AUTOMATION_INDICATION: 'This message was sent by a project automation: {{title}}',
                _PREFERENCES_AUTOMATION_INDICATION: 'This message was auto-sent according to your preferences. You can customize these at any time in your company settings page.',
                _OOO_AUTO_REPLY: 'This message was sent automatically during your OOO. Update your OOO settings under  “My account”. This is only visible to you.'
            }
        },

        CONTACT_FORM_WEBSITE_BUIDLER: {
            _USING_SQUARESPACE_: 'Using Squarespace? Sweet!',
            _CHECK_OUT_THE_: 'Check out the ',
            _EMBEDDING_INSTRUCTIONS_: 'embedding instructions',

            _EMAIL_US_: 'email us',

            _USING_: 'Using {{builder_type}}? Sweet!',
            _JUST_: 'just',
            _LINK_TEXT: 'read the instructions on embedding',
            _FIRST_: 'first!',

            _OTHER_GUIDES_LINK_: 'Not using {{builder_type}}? [LINK]',
            _OTHER_GUIDES_LINK_TEXT_: 'Check out our other guides',

        },

        LEAD: {
            FORM: {
                _PROJECT_DETAILS_: 'Project Details',
                _ADD_PROJECT_DETAILS_: 'Add More Project Details',
                _PROJECT_DATE_: 'MM/DD/YY',
                _PROJECT_LOCATION_: 'Location',
                _PROJECT_ABOUT_: 'Project Details',
                _GUESTS_: 'Estimated Guests',
                _BUDGET_: 'Estimated Budget',
                _SERVICES_: 'Services Needed',
                _CLIENT_INFORMATION_: 'Client Information',
                _FULL_NAME_: 'Full Name',
                _EMAIL_: 'Email',
                _PHONE_: 'Phone Number',
                _HIDE_CLIENT_DETAILS_: 'Only to me (we\'ll hide the client contact details)',
                _SHOW_CLIENT_DETAILS_: 'Directly to this client (client contact details are shown)',
                _ADDITIONAL_INFO_: 'Anything else your vendors should know about this lead?',
                _SEND_BUTTON_: 'Share Lead'
            },
            LABELS: {
                _PROJECT_LOCATION_: 'Location',
                _PROJECT_ABOUT_: 'Tell us about your project'
            }
        },

        PROJECT: {
            CREATE_PROJECT_MODAL: {
                _TITLE_: 'Create project',
                _NAME_ERROR_: 'This field is mandatory',
                _PRO_TIP_BOLD_TEXT_: 'PRO TIP: ',
                _PRO_TIP_TEXT_: 'Add lead source to your project to get more from your reports'
            },
            BUTTONS: {
                _ADD_END_DATE_: 'Add End Date',
                _ALL_PROJECTS_: 'Projects',
                _PROJECT_DETAILS_: 'Project details',
                _CREATE_PROJECT_: 'Create Project'
            },
            TABS: {
                _OVERVIEW_: 'Project Details',
                _WORKSPACES_: 'Project Workspaces'
            },
            DETAILS: {
                _GUESTS_: 'Guest Count',
                _BUDGET_: 'Budget',
                _LOCATION_: 'Where',
                _ABOUT_: 'About the Project',
                _WHEN_: 'When',
                _TYPE_: 'Project Type'
            },
            CONFLICTS: {
                _HEADER_: "You have {{events_count}} conflicting project{{plural}}"
            },
            FEED: {
                _NEW_MESSAGES_NOTIFICATION_: 'new messages above this line'
            },
            TYPES: {
                _TYPE_WEDDING_: 'Wedding',
                _TYPE_CORPORATE_: 'Corporate',
                _TYPE_SOCIAL_: 'Social',
                _TYPE_OTHER_: 'Other',
                _TYPE_FAMILY_: 'Family',
                _TYPE_DESIGN_: 'Design'
            },
            _PROJECT_INFO_: 'Project Info',
            _DATE_CREATED_: 'Date Created:',
            _SHOW_MORE_: 'Show More',
            _SHOW_LESS_: 'Show Less',
            _MORE_DETAILS_: 'Additional details',

            PLACEHOLDERS: {
                _PROJECT_NAME_: 'John & Lisa\'s Wedding',
                _LOCATION_: 'Venue or Location Address',
                _DATE_: 'MM/DD/YYYY',
                _START_TIME_: '00:00 pm',
                _END_TIME_: '00:00 am',
                _ESTIMATED_BUDGET_: '$0',
                _NUMBER_OF_GUESTS_: '210',
                _ABOUT_: 'Tell us a bit more about your project...'
            },

            MEMBERS: {
                _VIEW_ALL_: 'View all',
                _MEMBERS_: 'members'
            },

            ADD_MEMBER: {
                _HEADER_TITLE_: 'Add Vendors or Clients to this Project',
                _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added to your project. Are you sure you\'re done editing?',
                _CANNOT_ADD_ADDED_USER_: 'Hmm, it looks like this user is already on the project'
            },

            LABELS: {
                _ABOUT_PROJECT_: 'About the Project',
                _ADD_MEMBERS_: 'ADD MEMBERS',
                _ADD_PRIVATE_NOTE_: 'Private Comments',
                _END_DATE_: 'End Date',
                _END_TIME_: 'End Time',
                _END_TIMEZONE_: 'Time Zone',
                _ESTIMATED_BUDGET_: 'Estimated Budget',
                _PROJECT_TEAM_: 'Project Team',
                _KEEP_TRACK_MSG_: 'Let\'s keep track of the project details',
                _KEEP_TRACK_MSG_SUBHEAD_: 'Flesh out the project information and keep all of your project vendors on the same page.',
                _LOCATION_: 'Location',
                _DATE_: 'DATE',
                _TIMEZONE_: 'Timezone',
                _NUMBER_OF_GUESTS_: 'Number of Guests',
                _PRIVATE_NOTE_: 'Private note about this client or this project',
                _START_DATE_: 'Start Date',
                _START_TIME_: 'Start Time',
                _TBD_: 'TBD',
                _TITLE_: 'Project Title',
                _NAME_: 'Project name',
                _TYPE_: 'Project type',
                _LEAD_SOURCE_: 'Lead source',
                _LEAD_SOURCE_REFERRAL_: "Who's your referral?",
                _LEAD_SOURCE_OTHER_: "Other lead source",
                _LEAD_SOURCE_SAVE_: 'Save source for future use?',

                _WORKFLOW_: 'Workflow'
            },
            WORKSPACES: {
                _TITLE_: 'WORKSPACE',
                _SUB_TITLE_1_: 'Create a private workspace to send messages, share files and collaborate with others in this project.',
                _SUB_TITLE_2_: '',
                _NEW_WORKSPACE_: 'New Workspace',
                _CHOOSE_: 'Choose a new workspace type',
                _CLIENT_TITLE_: 'Client Workspace',
                _TEAM_TITLE_: 'Team Workspace',
                _VENDOR_TITLE_: 'Vendor Workspace',
                _GROUP_TITLE_: 'Group Workspace',
                _CLIENT_COPY_: 'Create a new workspace to collaborate with other your clients',
                _TEAM_COPY_: 'Create a new workspace to collaborate between team members',
                _VENDOR_COPY_: 'Create a new workspace to collaborate with other vendors in this project',
                _GROUP_COPY_: 'Create a new workspace to collaborate between vendors and clients',
                _ARCHIVED_: 'Archived'
            },

            LEADSOURCE: {
                _VENDOR_REFERRAL_: 'Vendor Referral',
                _INDUSTRY_REFERRAL_: 'Industry Referral',
                _CLIENT_REFERRAL_: 'Client Referral',
                _PERSONAL_WEBSITE_: 'Personal Website',
                _GOOGLE_: 'Google',
                _FACEBOOK_: 'Facebook',
                _INSTAGRAM_: 'Instagram',
                _WEDDING_SPOT_: 'Wedding Spot',
                _THE_KNOT_: 'The Knot',
                _WEDDING_WIRE_: 'Wedding Wire',
                _YELP_: 'Yelp',
                _OTHER_: 'Other',
                _UNKNOWN_: 'Unknown',

                LABELS: {
                    _MODAL_EDIT_LEAD_SOURCE_: 'Edit lead sources',
                    _MODAL_EDIT_LEAD_SOURCE_DESCRIPTION_: 'Update the lead source for each of your projects to get better insights on your booking process',
                    _MODAL_EDIT_SPACES_: 'Edit spaces'
                }
            },

            ERRORS: {
                _INVALID_DATE_: 'Invalid project date',
                _INVALID_DATE_END_: 'Invalid project end date',
                _INVALID_DATE_RANGE_: 'Start date or time cannot be after end date or time',
                _REQUIRED_FIELD_: 'Error message'
            },

            EMPTY_PROJECT: {
                _HEADER_: 'Looks like you don\'t have any projects',
                _SUB_HEADER_: 'Create a new one now',
                _CREATE_PROJECT_: 'Create Project'
            },

            SPACES: {
                _SPACES_HEADER_: "Spaces",
                _ALL_: "All",
                _CONFIRM_DELETE_: "Are you sure you want to delete {{space_name}}?",
                _NO_SPACES_: 'I don\'t have multiple spaces',
                _SKIP_: 'Skip for Now',
                _PROJECT_COUNT_: '{{event_count}} project{{plural}}',
                _ADD_SPACE_: 'Add Space',
                _ADD_SPACES_: 'ADD SPACES',
                _SPACES_COUNT_: '{{spaces_count}} Space{{plural}}'
            }
        },

        SETTINGS: {
            SUBSCRIPTION: {
                _HEADER_: 'Membership Plan',
                _MAIN_CTA_: 'Continue',
                _MONTH_: 'month',
                _BILLED_ANNUALLY_: 'Billed annually',
                _WHITE_GLOVE_: 'White-glove account setup',
                _CREDIT_CARD_FEES_: 'Credit Card fees',
                _FREE_PAYMENTS_: 'Free payments',
                _DISCOUNTED_PAYMENTS_: "Discounted payment{{ ( numberOfPayments === 1) ? '' : 's'}}",
                _BANK_TRANSFER_FEES_: 'Bank Transfer fees',
                _RECOMMENTED_: 'Popular',
                _HEADLINE_: 'one-time',
                _LIFETIME_: 'Lifetime',
                _NEXT_BILLING_: 'Next billing date:',
                _ENTER_PROMO_CODE_: 'Enter promo code',
                _APPLY_PROMO_CODE_: 'Apply',
                _APPLIED_PROMO_CODE_: 'Applied',
                _CUSTOM_PLAN_TITLE: 'Professional',
                _YOU_GOT_A_DISCOUNT_: 'Sweet! You got a discount!',
                _CLEAR_DISCOUNT_: 'Enter a different promo code',
                _PROMOCODE_LABEL_: 'Do you have a promo code? Awesome!',
                _MISS_YOU_: 'We’ll miss you! Keep an eye out for a confirmation email with the details regarding your cancellation.',
                _CANCEL_ANYTIME_: 'Cancel anytime',
                _CANCEL_IN_APP_PURCHASE_: 'We’re sad to see you go. Since you signed up via our mobile app, please visit the AppStore to complete your cancellation.',

                CANCELED_PLAN: {
                    _VALID_UNTIL_: 'Your current subscription is valid through {{date}}',
                    _EXPLAIN_: 'At that point you will be able to resubscribe to any of the plans below.'
                },

                PAUSED_PLAN: {
                    _VALID_UNTIL_: 'Your current subscription is valid through {{date}}',
                    _VALID_EXPLAIN_: 'At that point, your membership will be paused, to unpause it contact',
                    _NOT_VALID_: 'Your current subscription is Paused',
                    _NOT_VALID_EXPLAIN_: 'To unpause it contact'
                },

                FOUNDING: {
                    _HEADLINE_: 'one-time',
                    _TITLE_: 'FOUNDING MEMBERSHIP',
                    _TITLE_2_: 'Hurry! Ends 12/31',
                    _CTA_: 'BUY FOUNDING MEMBERSHIP'
                },

                MONTHLY: {
                    _HEADLINE_: 'per month',
                    _TITLE_: 'MONTHLY MEMBERSHIP',
                    _FREE_MONTH_INVITE_: 'Your friend invited you to enjoy a FREE month. You will be charged 30 days after submitting the info below.',
                    _CTA_: 'CHOOSE MONTHLY MEMBERSHIP',
                },

                TIERS: {
                    _BASIC_: 'Entrepreneur',
                    _MEDIUM_: 'Professional',
                    _TOP_: 'Established',
                    _NO_TIER_: 'Entrepreneur',
                    _VENUES_: 'Enterprise'
                },

                PLAN_TYPE: {
                    _MONTLY_: 'monthly',
                    _ANNUAL_: 'annual',
                    _PER_MONTH_: 'month',
                    _PER_MO_: '/mo',
                    _PER_MONTHS_: 'months',
                    _PER_YEARS_: 'years',
                    _PER_YEAR_: 'year',
                    _BILLED_ANNUAL_: 'Billed annually at ${{price}}'
                },

                AUTO_COUPON_APPLIED: {
                    _TITLE_: 'You almost missed out on a great deal!',
                    _BODY_: "We're running a promotion right now and don't want you to miss it. Instead of charging you full price, we've applied the promotion price for you:",
                    _CTA_: "ok, great"
                },

                OFFER_APPLIED: {
                    _TITLE_: "Let's do this thing\n" +
                        'Your plan has been updated',
                    _BODY_: "Your Membership: ${{price}}/mo\n" +
                        "until you’ve earned $10K through HoneyBook",
                    _CTA_: "Thanks"
                },

                FEATURES_BLOCK: {
                    _BATCH_EMAIL_TITLE_: 'Upgrade your plan\nto send batch emails',
                    _BATCH_EMAIL_BODY_: 'Batch emails are only available for paying members. Upgrade your plan now for more efficient email communication.',
                    _BATCH_EMAIL_CTA_: 'Upgrade my plan'
                },

                MEMBERSHIP: {
                    _YOUR_MEMBERSHIP_: 'Your Membership',
                    _PLAN_: 'Plan',
                    _US_: 'US$',
                    _DOLLAR_SIGN_: '&',
                    _EDIT_PAYMENT_METHOD_: 'EDIT PAYMENT METHOD',
                    _CANCEL_MEMBERSHIP_: 'Cancel Membership',
                    _WE_LOVE_OUR_MEMBERS_: 'We love our members!',
                    _HB_PERKS_: 'HoneyBook membership has its perks:',
                    _FREE_SETUP_: 'Free Account setup + training',
                    _CUSTOMER_SUPPORT_: 'Concierge customer support',
                    _ALL_HB_FEATURES_: 'All HoneyBook features',
                    _YOUR_BRAND_: 'Your brand everywhere',
                    _CUSTOMIZED_WORKFLOW_: 'Customized workflow',
                    _FRAUD_PROTECTION_: 'Fraud Protection',
                    _IOS_APP_: 'Powerful mobile app',
                    _FAQ_: 'FAQ',
                    _ONLY_VISIBLE_TO_OWNER_: 'Only visible to company owner'
                },

                PAUSED: {
                    _TITLE_PAUSED_: 'Your membership is',
                    _TITLE_SCHEDULED_: 'Your membership is scheduled to pause in',
                    _DESCRIPTION_SCHEDULED_: 'Your subscription will pause on <br /> {{date}}',
                    _DESCRIPTION_: 'Your subscription will resume on <br /> {{date}}',
                    _RESUME_MEMBERSHIP_: 'RESUME MEMBERSHIP',
                    _CANCEL_MEMBERSHIP_: 'Cancel membership',
                    _MEMBERSHIP_STATUS_: 'Your membership is',
                    _PAUSED_: 'PAUSED',
                    _UNPAUSED_: 'Your membership is Unpaused',
                    _RESUME_PAUSED_MEMBERSHIP_: 'You can choose to resume your subscription by clicking below.',

                },

                PAUSED_OFFER_MODAL: {
                    _TITLE_: 'Hold that thought…',
                    _DESCRIPTION_: 'Your membership is paused <br /> Unpause your membership now to get back to business.',
                    _CTA_: 'UNPAUSE MEMBERSHIP'
                },

                PASSED_10K: {
                    _TITLE_: 'You’ve reached your plan’s limit, <br /> upgrade now',
                    _DESCRIPTION_: 'You’ve reached $10K in total transactions. Keep earning on an upgraded plan.',
                    _CTA_: 'Upgrade My Plan',

                },

                CANCELLATION: {
                    _TITLE_: 'Your opinion means a lot',
                    _SUB_TITLE_: 'We want to answer your business needs, that’s our goal. Please tell us why you’re canceling your plan.',
                    _WERE_SAD_TITLE_: 'We’re sad to see you go, <br /> but come back any time!',
                    _ONCE_YOU_CANCEL_: 'Once you cancel your membership:',
                    _NO_LONGER_CHARGED_: 'You will no longer be charged the monthly or annual membership fee.',
                    _ACCESS_FILES_: 'You will still have access to all existing projects and files.',
                    _EFFECTIVE_DATE_: 'Effective on {{date | hbmarker}}, you will no longer be able to create new projects.',
                    _EFFECTIVE_NOW_: 'Effective now, you will no longer be able to create new projects.',
                    _ACCESS_DESCRIPTION_: 'You will still have access to all existing projects and files. <br /> Effective on {{date | hbmarker}}, you will no longer be able to <br /> create new projects.',
                    _KEEP_PLAN_: 'Keep my plan',
                    _NOTES_: 'Notes',
                    _EXPLAIN_: 'Please explain "other"',
                    _CANCEL_MEMBERSHIP_: 'Cancel membership',
                    _CANCEL_INFO_: '<span class="hb-marker">Important:</span> All automated reminders and notifications within projects will continue to be sent to clients unless you archive those projects. If you no longer want these sent, please ensure that you archive all of your projects after cancellation.',
                    _HOW_TO_ARCHIVE_: 'How to archive',
                    REASONS: {
                        _COST_: 'Subscription cost',
                        _MISSING_FEATURES_: 'Missing features',
                        _SHUTTING_DOWN_: 'Shutting down my business/downsizing',
                        _ONLINE_PAYMENTS_: 'No longer accepting online payments',
                        _CHANGING_PLATFORM_: 'Moving to another platform/software',
                        _DIFFICULT_FOR_ME_: 'Difficult for me to use—I never got the hang of it',
                        _DIFFICULT_FOR_CLIENTS_: 'Difficult for my clients to use',
                        _TAKING_A_BREAK_: 'Taking a break — I\'ll be back later!',
                        _MERGE_ACCOUNTS_: 'Accounts are being merged',
                        _TRANSACTION_FEES_: 'Transaction fees',
                        _COVID_19_: 'COVID-19',
                        _UNFIT_FOR_CLIENTS_: 'HoneyBook doesn’t fit the way I work with my clients',
                        _HAVE_NOT_EXPLORE_: 'I haven’t had the chance to explore and set up my account',
                        _DISPUTED_MEMBERSHIP_PAYMENT_: 'Disputed membership payment',
                        _OTHER_: 'Other'
                    },

                    STEP_PAUSE: {
                        _TITLE_: ' Just hit pause',
                        _BODY_: 'We’ll be here when you’re ready to come back. Your plan will pause at the end of this billing cycle.',
                        _SECONADRY_CTA_: 'continue to cancel',
                        _PRIMARY_CTA_: 'Pause plan',
                        _DROPDOWN_TITLE_: 'Pause for'
                    },

                    STEP_CONFIRMATION_PAUSE: {
                        _TITLE_: 'Pause now, come back when you’re ready',
                        _SUB_TITLE_: 'Once you pause your membership:',
                        _SECONADRY_CTA_: 'keep my plan',
                        _PRIMARY_CTA_: 'pause membership'
                    },

                    STEP_PRICE_OFFER: {
                        _TITLE_: "It's been a wild year, and we're here for you.",
                        _SUB_TITLE_: 'We want to make it a little easier to invest in yourself',
                        _DESCRIPTION_TITLE_: "Don't pay full price until you're up and running.",
                        _DESCRIPTION_SUB_TITLE_: 'Stay on board for just ${{price}}/month and get your business running smoothly in 2021. <br />The price will be updated on the next billing cycle.<br />',
                        _DESCRIPTION_ACCESS_FEATURES_: "Access to all features.",
                        _DESCRIPTION_SUPPORT_TEAM_: "No additional team members.",
                        _DESCRIPTION_TRANSACT_: "Limited to $10K in total transactions.",
                        _PRICING_EARNINGS_: 'Until you’ve earned $10k',
                        _PRICING_FINAL_PRICE_: 'And then $39/mo',
                        _PRICING_CTA_: 'SELECT',
                        _SECONADRY_CTA_: 'keep my plan',
                        _PRIMARY_CTA_: 'continue canceling'
                    },

                    STEP_FEES_EXPLANATION: {
                        _TITLE_: "Fees power your online payments",
                        _DESCRIPTION_: 'Fees cover payment services you may not be aware of—offsetting risk & the cost of processing payments, as well as finding faster and more secure ways for you to get paid.',
                        _SEE_OUR_FEES_LINK_: 'Check how our fees compare',
                        _ILLUSTRATION_TITLE_: "Benefits of online payments...",
                        _ILLUSTRATION_BENEFIT_1_: "Track payments, effortlessly",
                        _ILLUSTRATION_BENEFIT_2_: "Get automated payment reminders & notifications",
                        _ILLUSTRATION_BENEFIT_3_: "Enjoy fast payments with auto-pay, direct deposit, & instant deposits*",
                        _INSTANT_DEPOSITS_CONDITION_: "*Based on eligibility"
                    },

                    FEES_COMPARISON_MODAL: {
                        _TITLE_: "How our credit card rates stack up",
                        _CARDHOLDER_ENTERED_: "Cardholder-entered details",
                        _CARDHOLDER_ENTERED_DESCRIPTION_: "Your client enters their payment info manually.",
                        _CARD_ON_FILE_: "Card on file—securely",
                        _CARD_ON_FILE_DESCRIPTION_: "Client’s card is ready for repeat payments.",
                        _CONDITIONS_: "*Rates are accurate to 19 Oct 2022 and are based on publicly-available information."
                    },

                    OFFER: {
                        _TITLE_: 'We hope this helps...',
                        _SUB_TITLE_: 'We want to make this work for you. And we\'ll do everything we can to support you ',

                        CTA_1: {
                            _TITLE_: 'We want you to get paid fast',
                            _SUB_TITLE_: 'Before you stop accepting online payments, here’s some food for thought:',
                            _LINK_TEXT_: 'See how online payments will make your life way easier'
                        },

                        CTA_2: {
                            _TITLE_: 'We’ve got your back',
                            _SUB_TITLE_: 'Whatever business challenges you’re facing right now, we’re here for you. Keep us posted, so we can do our best to help.',
                            _LINK_TEXT_: '',
                        },

                        CTA_3: {
                            _TITLE_: 'Your client’s struggles are our struggles',
                            _SUB_TITLE_: 'We’re sorry if your clients have had difficulties using HoneyBook. We’re here to answer any questions and clear up any confusion.',
                            _LINK_TEXT_: 'Take me to Help Center',
                        },

                        CTA_4: {
                            _TITLE_: 'Your struggle is our struggle',
                            _SUB_TITLE_: 'We’re sorry about any difficulties you’ve had using HoneyBook. We’re here to answer any questions and clear up any confusion.',
                            _LINK_TEXT_: 'Take me to Help Center',
                        },

                        CTA_5: {
                            _TITLE_: 'Are you sure you\'re using HoneyBook\'s full potential?',
                            _SUB_TITLE_: 'We have training videos and live webinars you can use to start making the most of our amazing features ASAP.\n',
                            _LINK_TEXT_: 'Take me to HoneyBook\'s training videos and webinars',
                            _FEED_BACK_: 'What features are missing for you?',
                        },

                        CTA_6: {
                            _TITLE_: 'Are you sure you\'re using HoneyBook\'s full potential?',
                            _SUB_TITLE_: 'We are constantly improving our platform to suit your needs by adding new and exciting features! You can get updates from our Facebook Groups or our Product Updates page.',
                            _LINK_TEXT_: 'Take me to HoneyBook\'s Product Updates',
                            _FEED_BACK_: 'Where are you headed?',
                        },

                        CTA_7: {
                            _TITLE_: 'a system that does it all',
                            _SUB_TITLE_: 'Way less than a coffee, HoneyBook helps you manage projects, book clients and get paid. Are you sure you want to cancel?'
                        },

                        CTA_8: {
                            _TITLE_: 'HoneyBook is here for you.',
                            _SUB_TITLE_: 'Not sure how HoneyBook can help your business grow? Check out our video resources and Getting Started Guide to learn the basics today.',
                            _LINK_TEXT_: 'Take me to the getting started guide',
                            _FEED_BACK_: 'What wasn\'t working for you?',
                        },
                        CTA_9: {
                            _TITLE_: 'We want you to communicate easily with your clients.',
                            _SUB_TITLE_: 'Learn how to communicate with your clients through HoneyBook, create and send files and what your clients experience.',
                            _LINK_TEXT_: 'Take me to “Boost your branding” free webinar',
                            _FEED_BACK_: 'How is HoneyBook different from the way you work with your clients?',
                        },
                        CTA_10: {
                            _TITLE_: 'We want to set up your account for you.',
                            _SUB_TITLE_: 'Here at HoneyBook we have a team dedicated to transferring your existing materials into HoneyBook on your behalf as a complimentary service!',
                            _LINK_TEXT_: 'How do I use HoneyBook\'s complimentary account setup service?',
                            _FEED_BACK_: 'How can we make it easier for our members to set up their accounts?'
                        },
                        CTA_11: {
                            _TITLE_: 'We want you to get paid fast.',
                            _SUB_TITLE_: 'HoneyBook\'s rates are competitive with the industry standard, but we understand there is a lot to consider when taking online payments.',
                            _LINK_TEXT_: 'Here\'s a breakdown of the best processors for Small Businesses',
                        },

                    },

                    FEEDBACK: {
                        _TITLE_: 'Your feedback means the world to us',
                        _TITLE_FOR_TRANSACTION_FEES_: 'Have questions about fees?',
                        _SUB_TITLE_: 'Your comments and ideas let us know what you need for your business.',
                        _SUB_TITLE_FOR_TRANSACTION_FEES_: 'Your customer success manager is waiting to talk with you. Simply schedule a 15-minute call to discover the best way to approach fees for your specific business.',
                    },
                    CANCEL: {
                        _TITLE_: 'We\'re sad to see you go...',
                        _SUB_TITLE_: 'but you can come back any time!'
                    },

                    LABELS: {
                        _HOW_CAN_WE_IMPROVE_: 'How can we improve?',
                        _YOUR_FEED_BACK_: 'Your comments make a difference.',
                        _REASON_FOR_CANCELLING_: 'Reason for canceling plan:',
                        _WHAT_CHANGES_DO_YOU_WANT_: 'What changes do you want to see?'
                    }
                },
            },


            HEADER: {
                LABELS: {
                    _SETTINGS_: 'Settings'
                }
            },

            PROJECT_DETAILS: {
                MODAL: {
                    _TITLE_: 'Customize project details',
                    _BANNER_: 'These details will be displayed in every new project you create. You\'ll be able to automatically pull the populated information into files and more.',
                    _DETAIL_: 'Detail',
                    _VISIBLE_TO_CLIENT_: 'Visible to client?',

                    FIELDS: {
                        _PROJECT_DETAILS_: 'Project details',
                        _DETAIL_: 'Detail',
                        _VISIBLE_TO_CLIENT_: 'Visible to client?',
                        _VISIBLE_: 'Visible',
                        _NOT_VISIBLE_: 'Not Visible',
                        _CANNOT_EDIT_: 'This is a required field that you cannot edit',
                        _ADD_: 'Add',
                        _EMPTY_FIELD_ERROR_: 'Label name is required.',
                        _DUPLICATE_NAME_ERROR_: 'A project detail with this name already exists. Please select another.',
                        _SHORT_TEXT_: 'Short text',
                        _PROJECT_UPDATED_TITLE_: 'Project details updated',
                        _PROJECT_UPDATED_MSG_: 'These updates will only apply to new projects that you create moving forward. Past projects will not be affected.',
                        _PRIVATE_NOTES_: 'Notes (private)'
                    }

                }
            },
        },

        BROCHURE: {
            _BROCHURE_INTRO_MESSAGE_PLACEHOLDER_: 'Write an introduction or welcome message here.',
            _NO_COLLECTION_MESSAGE_: 'Hmm, please add a collection before sending.',
            _NO_PACKAGES_MESSAGE_: 'Hmm, one of the collections is empty, please adjust before sending.',
            _INTRO_1_: 'Hello!',
            _INTRO_2_: 'I’m looking forward to working with you! Below is a list of my packages. Please let me know which one you’re interested in, and we’ll take it from there.',
            _INTRO_3_: 'Talk soon!',
            _ADD_PACKAGE_: 'Add Package',
            _CHOOSE_IT_: 'Choose it',
            _CHOSEN_: 'Chosen',
            _YOU_CHOSE_: 'You\'ve selected  {{title}}',
            _MOVE_FORWARD_: 'Click \'OK\' below to let {{vendor_name}} know. ',
            _IM_INTERESTED_: 'I\'m interested',
            _CANNOT_DELETE_LAST_COLLECTION_: 'The brochure must contain at least one collection',
            _ADD_SECTION_: 'Add section',
            _NEXT_REVIEW_: 'NEXT: REVIEW',
            _SUBMIT_BROCHURE_DESCRIPTION_: 'Review your selections and click \'Submit\' to notify {{file_owner}} of your interest in the above services',
            _YOU_ARE_PREVIEWING_AS_CLIENT_: 'You are previewing this file as a client.',
            _YOU_MUST_SELECT_SERVICE_: 'You must select from these services in order to continue',
            _REVIEW_: 'Review',
            _IM_INTERESTED_WILL_NOTIFY_VENDOR_: 'Clicking "I\'m interested" will notify {{file_owner}} that you are interested in their services.',
            _BACK_: 'Back',
            _COMMENTS_: 'Comments',
            _COMMENTS_OPTIONAL_: 'Comments (optional)',
            _CLIENT_REVIEW_SECTION_TITLE_: 'Review your selections',
            _VENDOR_REVIEW_SECTION_TITLE_: 'Selection summary',
            _CLIENT_REVIEW_SECTION_EMPTY_MESSAGE_: 'You haven\'t selected any service or package.',
            _VENDOR_REVIEW_SECTION_EMPTY_MESSAGE_: 'No selection was made.',
            _CONVERT_OLD_BROCHURE_BANNER_TITLE_: 'Convert your old brochures with one click now',
            _CONVERT_OLD_BROCHURE_BANNER_TEXT_: 'You have {{days}} days to copy your old brochures to the new format',
            _CONVERT_OLD_BROCHURE_BANNER_CREATE_: 'CONVERT THIS BROCHURE',
            _CONVERT_OLD_BROCHURE_BANNER_IN_PROGRESS_: 'Converting brochure...',
            _SCROLL_TO_SEE_MORE_SECTIONS_: 'Scroll to see more sections',
            _DELETE_SECTION_CONFIRM_MSG_: 'Are you sure you want to delete this brochure section?',
            TOAST: {
                _MOBILE_CUSTOMIZE_ON_DESKTOP_: 'You are previewing this file as a client. You can send it now or log in on desktop to edit it.'
            },
            MOBILE_VIEW: {
                _TITLE_: 'Showcase your services-your way.',
                _SUBTITLE_: 'Log into your account on desktop to harness the power of complete customization, or view and send from',
                _CTA_: 'your saved templates'
            },
            SETTINGS: {
                _CLIENT_CAN_SUBMIT_QUESTIONS_: 'Client can submit questions or comments',
                _CLIENT_CAN_SUBMIT_QUESTIONS_SUB_: 'Reduce the back and forth by allowing clients to submit their questions/comments.'
            },
            ADD_BLOCK: {
                _TITLE_: 'Select the type of block you\'d like to add:',
                _FULL_WIDTH_TEXT_: 'Full width text',
                _2_COLUMN_TEXT_: '2 column text',
                _2_IMAGES_: '2 images',
                _COVER_IMAGE_: 'Cover image',
                _TEXT_ON_IMAGE_: 'Text on image',
                _LEFT_ALIGNED_: 'Left-aligned',
                _CHOOSE_IMAGE_: 'CHOOSE IMAGE',
                _EDIT_IMAGE_: 'EDIT IMAGE',
                _TEXT_PLACEHOLDER_: 'Your text here',
                _ADD_BLOCK_TO_SECTION_: 'Add block to this section'
            },
            SERVICES: {
                SETTINGS: {
                    _SEE_PRICING_: 'See pricing',
                    _ALLOW_SELECTION_: 'Allow client to select items',
                    _CHOOSE_QUANTITY_: 'Allow client to choose quantity',
                    _ALLOW_MULTIPLE_SELECTION_: 'Allow more than one selection',
                    _REQUIRED_SELECTION_: 'At least one selection required',
                    _LEARN_MORE_: 'To learn more about these settings visit our '
                },
                _SELECT_AT_LEAST_ONE_FROM_THE_FOLLOWING_: 'Select at least one from the following:',
                _SELECT_ONLY_ONE_FROM_THE_FOLLOWING_: 'Select only one from the following:',
                _SELECT_ANY_OF_THE_FOLLOWING_: 'Select any of the following:'
            },
            TOOLTIP: {
                TITLE: {
                    _HEADER_: 'Give your first section a title',
                    _TEXT_ONE_: 'Use sections to structure the content of your document and create a completely tailored experience for your client.',
                    _TIP_: 'PRO-TIP: An example of a section structure might look like this: <br> <b>About us, Our process, Our Services, Terms</b>',
                    _TIP_A_: 'About us, Our process, Our Services, Terms',
                },
                CONTENT: {
                    _TITLE_: 'It\'s time to get creative!',
                    _TEXT_: 'Select a content type to begin customizing your document. You can also click services (and allow clients to make selections directly from your brochure).',
                    _CTA_: 'Learn more about building brochures'
                }
            },
            SECTIONS_MODAL: {
                _TITLE_: 'Create a new brochure',
                _SUBTITLE_: 'Start with one of our pre-made layouts and edit how you’d like, or start with a blank slate to have creative freedom from the start.',
                _STANDARD_: 'Standard',
                _PROPOSAL_: 'Proposal',
                _COMPREHENSIVE_: 'Comprehensive',
                _BLANK_: 'Blank',
                _SELECT_: 'Select',
                _CREATE_YOUR_OWN_: 'Create your own'
            }
        },

        DYNAMIC_CONTENT: {
            ADD_BLOCK: {
                _CONTENT_: 'CONTENT',
                _QUESTIONS_: 'QUESTIONS',
                _SERVICES_: 'SERVICES',
                _CUSTOM_: 'Custom',
                _SUGGESTED_: 'Suggested',
                _SETS_: 'Sets'
            },
            FORM_COMPONENT: {
                _UPDATE_: 'Update',
                _UPDATE_LIST_: 'Update list in account settings',
                _LINKED_TO_: 'Answer linked to',
                _LINKED_TO_TITLE_: 'Client response will connect to the corresponding field on their project details and files',
                _FIELD_: 'field',
                _IS_REQUIRED_: 'Is Required',
                _CHANGE_QUESTION_TYPE_: 'Change question type:',
                _MANDATORY_: 'This is a mandatory question and cannot be removed or changed',
                _DISABLED_ITEM_TEXT_: 'This question was already added',
                _CONNECTED_QUESTION_REPLACE_WARNING_: 'This question will replace the current {{question_type}} question.',
            },
            QUESTIONS: {
                CUSTOM: {
                    _TWO_FIELDS_: 'Two-fields',
                    _SHORT_TEXT_: 'Short text',
                    _LONG_TEXT_: 'Long text',
                    _DROPDOWN_: 'Dropdown',
                    _SINGLE_CHOICE_: 'Single choice',
                    _MULTIPLE_CHOICE_: 'Multiple choice',
                    _DATE_PICKER_: 'Date picker',
                    _FILE_UPLOAD_: 'File upload',
                    _PICTURE_CHOICE_: 'Picture choice'
                },
                SUGGESTED: {
                    _FULL_NAME_: 'Full Name',
                    _EMAIL_: 'Email',
                    _PHONE_NUMBER_: 'Phone Number',
                    _ABOUT_: 'About',
                    _LEAD_SOURCE_: 'Lead Source',
                    _PROJECT_TYPE_: 'Project Type',
                    _LOCATION_: 'Location',
                    _BUDGET_: 'Budget',
                    _PROJECT_DATE_: 'Project Date',
                    _GUESTS_: 'Guests',
                },
                SETS: {
                    _BASIC_: 'Basic',
                    _PROJECT_DETAILS_: 'Project details',
                    _COMPANY_TYPE_: '[Company type]',
                    _PROJECT_TYPE_: 'Project Type',
                    _PROJECT_DATE_: 'Project Date',
                    _LOCATION_: 'Location',
                    _BUDGET_: 'Budget',
                    _GET_COUNT_: 'Guest Count',
                    _QUESTIONS_INCLUDED_: 'Questions Included',

                },
                SETTINGS: {
                    _LEFT_FIELD_: 'Left field',
                    _RIGHT_FIELD_: 'Right field',
                    _CHANGE_LEFT_FIELD_: 'Change left field question type',
                    _CHANGE_RIGHT_FIELD_: 'Change right field question type',
                },
                _REQUIRED_QUESTION_: '* This field is required',
                _OPTION_ALREADY_EXIST_: 'Whoops—options can’t be repeated'
            },

            GIFT_CARD: {
                SETTINGS: {
                    _TITLE_: 'Title',
                    _AMOUNT_: 'Amount',
                    _DEFAULT_AMOUNT_: 'Default Amount',
                    _CUSTOMIZE_TOGGLE_: 'Client can customize amount',
                    _LIMIT_TOGGLE_: 'Limit custom amount',
                    _MIN_VALUE_: 'Min value',
                    _MAX_VALUE_: 'Max value',
                    _TEXT_COLOR_: 'Text color',
                    _DISCOUNT_: 'Discount',
                    _DIRECT_LINK_TEXT_: 'Share in ads, social media <br /> or directly to clients.',
                    _DIRECT_LINK_INACTIVE_TEXT_: 'Your gift card is inactive and <br /> cannot be accessed by clients.',
                    ERRORS: {
                        _AMOUNT_: 'Amount must be above 0',
                        _AMOUNT_NOT_IN_RANGE_: 'Amount is not in limit range',
                        _LIMIT_MIN_: 'Min must be above 0',
                        _LIMIT_MAX_: 'Max must be above min',
                        _DISCOUNT_: 'Discount must be above 0',
                        _RELATIVE_DISCOUNT_: '% discount must be between 1 to 100',
                        _ABSOLUTE_DISCOUNT_: '$ discount must be lower than the amount',
                        _LIMIT_MIN_AND_AMOUNT_: 'Min must be below amount',
                        _LIMIT_MAX_AND_AMOUNT_: 'Max must be above amount',
                    }
                }
            },

            _USE_QUESTIONNAIRE_TEMPLATE_: 'Use questionnaire template'

        },

        TIMELINE: {
            _ADD_ITEM_: 'Add Item',
            _FILMSTRIP_PLACEHOLDER_: 'Section Title',
            _FILMSTRIP_BUTTON_: 'Add',
            _SECTION_TITLE_PLACEHOLDER_: 'Untitled Section',
            _SECTION_DATE_PLACEHOLDER_: 'Section Date',
            _TIME_PLACEHOLDER_: 'TBD',
            _TITLE_PLACEHOLDER_: 'Project Title',
            _DESCRIPTION_PLACEHOLDER_: 'Description',
            _ADD_ITEM_TOOLTIP_: 'Let\'s add a timeline item',
            _ADD_TIME_: 'Add time',

            ALERT: {
                _CONFIRM_DELETE_SECTION_: 'Are you sure you want to delete this?'
            }
        },

        PROPOSAL: {
            BUTTONS: {
                _ADD_SERVICE_: 'Custom Item',
                _ADD_PACKAGE_: 'Add Package or Item',
                _LOAD_FROM_FILE_: 'Use previous invoice',
                _LOAD_FROM_FILE_THANK_YOU_: 'We\'re still developing this! Stay tuned for an update.',
                _ADD_ITEM_: 'Add Another Item to this Package',
                _SET_ALL_TAXABLE_: 'Add Tax to All',
                _SET_ALL_NOT_TAXABLE_: 'Remove Tax From All'
            },
            LABELS: {
                _TOTAL_AMOUNT_: 'Total Amount',
                _GRAND_TOTAL_: 'Grand Total',
                _MONTHLY_TOTAL_: 'Monthly Total',
                _DISCOUNT_: 'Discount',
                _ADD_DISCOUNT_: 'Add discount',
                _ADD_TAX_: 'Add tax',
                _ADD_TAX_2_: 'Add tax 2',
                _ADD_SERVICE_CHARGE_: 'Add service charge',
                _GIFT_CARD_: 'Gift Card',
                _TAX_: 'Tax',
                _SERVICE_CHARGE_: 'Service Charge',
                _TAX_SERVICE_CHARGE_: 'Tax Service Charge',
                _SUBTOTAL_: 'Subtotal',
                _NO_TAXABLE_: '* No taxable items selected',
                _NO_SVCABLE_: '* No service charge items selected',
                _PROJECT_DATE_CHANGED_: 'Project Date Updated',
                _ADD_ITEM_: 'Add item',
                _NO_ITEMS_: 'No items'
            },
            HEADER_LABELS: {
                _ITEMS_: 'ITEMS',
                _QTY_: 'QTY',
                _UNIT_: 'UNIT',
                _PRICE_: 'PRICE',
                _TAX_: 'TAX',
                _SVC_: 'SVC',
                _TOTAL_: 'TOTAL'
            },
            MORE_TEMPLATES_MENU: {
                _SAVE_TEMPLATE_: 'Templates',
                _MENU_SECTIONS_: 'Menu',
                _ACTIONS_: 'Actions',
                _PROPOSAL_TEMPLATE_: 'Proposal Template'
            },
            NEW_PACKAGE: {
                _TITLE_PLACEHOLDER_: 'Package Name - Click to edit',
                _PACKAGE_DESCRIPTION_: 'Package Description - Click to edit'
            },
            NEW_SERVICE: {
                _LABEL_: '',
                _LABEL_IN_PACKAGE_: '',
                _PLACEHOLDER_: 'Description - Click to edit',
                _TITLE_PLACEHOLDER_: 'Service/Item Name - Click to edit',
                _QUANTITY_: 'QTY',
                _PRICE_: 'Price',
                _ADD_PACKAGE_: 'Package:',
                _PRE_MADE_PACKAGE_: '',
                _WHICH_PACKAGE_: '',

                DELETE_IMAGE: {
                    _TITLE_: 'Delete Image',
                    _BODY_: 'Are you sure you’d like to delete the image from this invoice?'
                },

                UNIT: {
                    _NONE_: 'none',
                    _ITEM_: 'item',
                    _HOUR_: 'hour',
                    _DAY_: 'day',
                    _WEEK_: 'week',
                    _MONTH_: 'month'
                },

                AUTO_COMPLETE: {
                    LABELS: {
                        _FREQUENT_: 'FREQUENT',
                        _PACKAGES_: 'PACKAGES',
                        _ITEMS_: 'ITEMS',
                        _RESULTS_: 'RESULTS',
                        _CREATE_ITEM_: 'CREATE ITEM',
                        _SEARCH_PLACEHOLDER_: 'Search by name, date or email…'
                    },
                    _EMPTY_STATE_: 'You currently have no saved items. <br /> Start typing to create your first item.',
                    _NO_DESCRIPTION_: 'No description',
                    _ADD_ITEM_TITLE_: 'Add item to invoice'
                }
            },
            ADD_NEW_SERVICE: {
                _BUTTON_CTA_: 'ADD NEW ITEM',
            },
            TOOLTIP: {
                _ADD_PACKAGE_: 'Let\'s add a package or item',
                _MENU_: 'Menu',
                _CLOSE_: 'Close',
                _DELETE_: 'Delete',
                _SAVE_: 'Save',
                _SAVE_AS_: 'Save as Package'
            },
            PACKAGES: {
                SHOW_LIST: {
                    _LESS_: 'Show Less',
                    _MORE_: 'Show More'
                },
                ALERT: {
                    _CONFIRM_DELETE_: 'Are you sure you want to delete this?'
                },
                PLACEHOLDERS: {
                    _TITLE_: 'Package name',
                    _DESCRIPTION_: 'Package description',
                    _QUANTITY_: 'Qty',
                    _UNIT_: 'Unit',
                    _PRICE_: '$0',
                    _TAX_: "Tax",
                    _SVC_: "Svc",
                },
                LABELS: {
                    _QUANTITY_: 'Qty',
                    _UNIT_: 'Unit',
                    _PRICE_: 'Price'
                }
            },
            SERVICES: {
                _QUANTITY_: 'Qty',
                _UNIT_: 'Unit',
                _PRICE_: 'Price',
                _DESCRIPTION_: 'Service Description - Click to edit',
                ALERT: {
                    _CONFIRM_DELETE_: 'Are you sure you want to delete this?'
                },
                PLACEHOLDERS: {
                    _TITLE_: 'Item name',
                    _DESCRIPTION_: 'Item description',
                    _QUANTITY_: 'Qty',
                    _UNIT_: 'Unit',
                    _PRICE_: '$0',
                    _TOTAL_: '$0',
                    _TAX_: "Tax",
                    _SVC_: "Svc",
                },
                LABELS: {
                    _QUANTITY_: 'Qty',
                    _UNIT_: 'Unit',
                    _PRICE_: 'Price'
                },
                _DELETE_: 'Delete',
                _DELETE_IMAGE_: 'Delete',
                _EDIT_IMAGE_: 'Edit',
            },
            TAX: {
                _AMOUNT_: 'Amount',
                _TYPE_: 'Type',
                _ADD_TAX_: 'Add another tax',
                _SECOND_TAX_: 'SECOND TAX',
                _EDIT_TAX_: 'Edit tax',
                _TAX_: 'Tax',
                _SERVICE_CHARGE_: 'Service Charge',
                _REMOVE_TAX_: 'Remove Tax',
                _REMOVE_SVC_: 'Remove Service Charge',
                _TAX_TYPE_: 'Tax type'
            },

            SVC: {
                _EDIT_SVC_: 'Edit Service Charge'
            },

            VOUCHER: {
                ERRORS: {
                    _INVALID_: 'Code is not valid',
                    _ALREADY_USED_: 'Code already redeemed'
                }
            },

            _ZERO_TOTAL_MESSAGE_WARNING_: 'The proposal total is currently $0. Are you sure you want to continue?',
            _ZERO_TOTAL_MESSAGE_ERROR_: 'Hmm, the proposal total is currently $0. Before sending the proposal, please adjust your value to something higher than $0.',
            _SERVICE_REORDER_ERROR_: 'Hmm, looks like we couldn\'t update file. Please try again.',
            _UNSELECTED_PAYMENT_TYPE_MESSAGE: 'You haven’t defined a payment schedule. Please select scheduled or recurring to continue.',
            _UNSELECTED_PAYMENT_TYPE_: 'EDIT PAYMENT SCHEDULE'
        },

        PAYMENTS: {
            LABELS: {
                _STATUS_: 'Status',
                _DUE_DATE_: 'Due Date',
                _UNPAID_: 'Unpaid',
                _RECURRING_DATE_: 'Recurring Date',
                _DUE_: 'due ',
                _AMOUNT_: 'Amount',
                _ADD_PAYMENT_: 'Add Payment',
                _ADD_ANOTHER_PAYMENT_: 'ADD ANOTHER PAYMENT',
                _TOTAL_: 'Total',
                _MONTHLY_TOTAL_: 'Monthly Total',
                _PAYMENT_METHOD_: 'Paid Via',
                _CHARGE_DATE_: 'Charge Date',
                _PAID_BY_: 'Paid By',
                _INVOICE_: 'Invoice',
                _NET_: 'Net',
                _SUBTOTAL_: 'Subtotal',
                _REFUNDED_AMOUNT_: 'Refunded Amount',
                _REFUNDED_DATE_: 'Refund Date',
                _TIP_: 'Gratuity',
                _MANUAL_: 'Manual',
                _PAID_: 'Paid',
                _DUE_TODAY_: 'Due Today',
                _PROCESSING_: 'Processing',
                _UPCOMING_: 'Upcoming',
                _OVERDUE_: 'Overdue',
                _REFUNDED_: 'Full Refund',
                _PARTIALLY_REFUNDED_: 'Partial Refund',
                _REFUND_IN_PROCESS_: 'Refund In Process',
                _CANCELED_: 'Canceled',
                _TIP_LABEL_: '(+${{value}} Gratuity)',
                _ALLOW_GRATUITY_: 'Allow Gratuity',
                _SEND_PAYMENT_REMINDER_: 'Send payment reminder',
                _EDIT_PAYMENT_METHOD_: 'Edit payment method',
                _DELETE_: 'Delete',
                _END_PAYMENT_: 'END PAYMENT',
                _VIEW_ALL_PAYMENTS_: 'View all payments',
                _HELD_FOR_DISPUTE_: 'Held For Dispute',
                _DISPUTE_RESERVED_LOST_: 'Paid',
                _DISPUTED_IN_PROCESS_: 'Dispute In Process',
                _DISPUTED_WON_: 'Dispute Won',
                _DISPUTED_PARTIALLY_WON_: 'Dispute Partially Won',
                _DISPUTED_LOST_: 'Dispute Lost',
            },

            HEADER_LABELS: {
                _SCHEDULED_TITLE_: 'Payment Schedule',
                _RECURRING_TITLE_: 'Recurring Payment',
                _PAYMENT_SCHEDULE_: 'Payment Schedule',
                _RECURRING_SUB_TITLE_: 'This is an ongoing payment that will occur repeatedly as defined below:',
                _RECURRING_INFORMATION_: 'This is an ongoing payment occurring on the <span class="hb-marker">{{day}} of every month</span>.',
                _RECURRING_INFORMATION_WITH_END_: 'This is an ongoing payment occurring on the <span class="hb-marker">{{day}} of every month</span> through {{end | hbmarker}}.',
                _AMOUNT_: 'AMOUNT',
                _DATE_: 'WHEN',
                _INVOICE_: 'INVOICE #',
                _STATUS_: 'STATUS',
                _OPTIONS_: 'OPTIONS'
            },

            MILESTONES: {
                _REQUEST_PAYMENT_: 'Request Payment',
                _MILESTONE_COMPLETED_: 'MILESTONE COMPLETED',
                _REMINDERS_SENT_: 'REMINDERS SENT',
                _SEND_REMINDER_: 'Send reminder',
                _REMINDER_SENT: 'Reminder sent',
                _SEND_ANOTHER_REMINDER_: 'Send another reminder'
            },

            STATUS: {
                _PAID_: 'Paid',
                _UNPAID_: 'Unpaid',
                _OVERDUE_: 'Overdue',
                _UPCOMING_: 'Upcoming',
                _PAY_NOW_: 'Pay'
            },

            TOOLTIPS: {
                _EDIT_: 'Edit',
                _DELETE_: 'Delete',
                _RECEIPT_: 'Receipt',
                _PRINT_: 'Print',
                _REFUND_: 'Full / Partial Refund',
                _MAP_REFUND_: 'Undo mark as paid',
                _SEND_: 'Send Reminder',
                _RECURRING_DISABLED_TOOL_TIP_: 'To send a recurring legacy invoice, you must enable card on file payments in settings. To send a recurring invoice without Autopay, try sending a smart file.'
            },

            POPUPS: {
                _DELETE_PAYMENT_MESSAGE_: 'Are you sure you want to delete this payment?',
                END_RECURRING_PAYMENT: {
                    _TITLE_: 'Stop recurring payments?',
                    _BODY_: 'You are about to end payments on a recurring invoice. You will still be able to access this file, but no future payments will process.',
                }
            },

            REMINDER: {
                _REMINDER_SENT_: 'Reminder Sent',
                _REMINDER_SENT_RECENTLY_: 'Reminder Recently Sent',
                _REMINDER_SENT_AND_SEEN_: 'Reminder Seen',
                _REMINDER_SENDING_: 'Sending...',
                _REMINDER_ERROR_: 'Failed'
            },

            INTRO: {
                _TITLE_: 'What type of payment is this?',
                _SCHEDULED_DESCRIPTION_: 'Charge your client a single payment or multiple payments of differing amounts.',
                _RECURRING_DESCRIPTION_: 'Charge your client a repeat fixed amount for a set period (or ongoing).   ',
                _SELECT_: 'SELECT'
            },

            RECURRING: {
                END_DATE_TYPE: {
                    _NEVER_: 'Never',
                    _ON_DATE_: 'On (selected date)',
                    _ON_: 'On'
                }
            },

            SCHEDULE: {

                _PROJECT_DATE_TBD_: 'Project Date (TBD)',
                _MIDWAY_DATE_TBD_: 'Midway (TBD)',
                _AFTER_INVOICE_TBD_: 'after invoice (TBD)',
                _AFTER_PROJECT_TBD_: 'after project (TBD)',
                _BEFORE_PROJECT_TBD_: 'before project (TBD)',


                LABELS: {
                    _INVOICE_DATE_: 'Upon Receipt',
                    _PROJECT_DATE_: 'Project Date',
                    _FIXED_DATE_: 'Custom Date',
                    _MIDWAY_DATE_: 'Mid Project',
                    _CUSTOM_DATE_: 'Smart Date',
                    _MILESTONE_: 'Milestone',
                },

                SECONDARY_LABELS: {
                    _INVOICE_DATE_: 'Payment due on the date invoice is sent',
                    _PROJECT_DATE_: 'Payment due on project date',
                    _FIXED_DATE_: 'Select a fixed due date on the calendar',
                    _MIDWAY_DATE_: 'Payment due halfway between date invoice is sent and project date',
                    _CUSTOM_DATE_: 'Due date based on a variable (e.g. project or invoice date)',
                    _MILESTONE_: 'Request payment manually once a milestone is complete',
                },

                CUSTOM_DATE_OPTIONS: {
                    UNIT_TYPES: {
                        _DAY_: 'day(s)',
                        _WEEK_: 'week(s)',
                        _MONTH_: 'month(s)'
                    },

                    OPERATORS: {
                        _BEFORE_PROJECT_: 'before project date',
                        _AFTER_PROJECT_: 'after project date',
                        _AFTER_INVOICE_: 'after invoice has been sent'
                    }
                },
                TOOLTIP: {
                    _INVOICE_DATE_: 'This payment will be due at the moment you send this invoice.',
                    _PROJECT_DATE_: 'Payment will be due on the date of the project. If the date is TBD, then this payment will automatically set itself when a date is chosen.',
                    _FIXED_DATE_: 'Choose a specific date that works for you and your client.',
                    _MIDWAY_DATE_: 'Payment will be due midway between the time you send the invoice and the project date.',
                    _CUSTOM_DATE_: 'Choose a highly customized payment schedule.'
                }
            },

            AMOUNT: {
                LABELS: {
                    _DIVIDE_EQUALLY_: 'Divide Equally',
                    _DIVIDE_EQUALLY_FROM_THIS_DATE_FORWARD_: 'Divide all payments equally from this date forward.',
                    _FIXED_AMOUNT_: 'Fixed Amount',
                    _CUSTOM_AMOUNT_: 'Custom Amount',
                    _PERCENTAGE_: 'Percentage',
                    _PERCENTAGE_ERROR_: 'Please enter a percentage value between 0 and 100',
                },
                TOOLTIP: {
                    _DIVIDE_EQUALLY_: 'Divide all of your payments equally from this point forward.',
                    _FIXED_AMOUNT_: 'Choose an exact amount due to this payment.',
                    _PERCENTAGE_: 'Assign a percentage of the total cost of services.'
                }
            },

            MARK_AS_PAID: {
                _TITLE_: 'Please indicate how your client paid',
                _SUB_TITLE_: 'Note: We highly recommend that clients pay online through HoneyBook so that we can offer you smart-tracking and analytics.',
                _CHOOSE_PAYMENT_METHOD_: 'Choose Payment Method',
                _SET_IT_: 'Set it!',
                _SEND_AND_SET_: 'Set it and resend {{fileType}}',
                _BLOCKED_DISCARD_DRAFT_: 'Please discard your draft, or resend it, in order to mark the payment as paid.',
                _ALLOW_PAYMENTS_UNCHANGED_: 'Hey! Just so you know, no need to click Edit if all you want is to mark a payment as paid.',
                _ORIGINAL_AMOUNT_: 'Original payment amount:',
                _SUM_TO_MAP_: 'Sum to mark as paid:',
                _CONTACT_CONCIERGE_: 'The amount you entered is different than the amount due. To mark this payment as paid, follow <a target="_blank" href="https://intercom.help/honeybook-5891e2a409ff/payments-and-bank-info/marking-payment-as-paid-payment-received-differs-from-payment-schedule">these instructions</a>.',
                _NUMBERS_ONLY_ERROR_: 'Input can only be a number',
                _CONFIRM_DRAFT_: "You've made changes to this payment. In order to mark it as paid, you'll have to send this draft. Click continue to enter this payment's details.",
                _CONFIRM_DRAFT_AND_SEND_: "You've made changes to this payment. In order to mark it as paid, you'll have to send this draft. Click continue to send this file.",
                LABELS: {
                    _CASH_: 'Cash',
                    _CHECK_: 'Check',
                    _OTHER_: 'Other',
                    _METHOD_: 'Method',
                    _PAID_ON_: 'Paid On',
                    _OTHER_REASON_: 'Fill out the reason why you set this payment as paid',
                    _OTHER_REASON_EMPTY_MESSAGE_: 'Please fill out the reason why you set this payment as paid'
                }
            },

            _NO_PAYMENTS_MESSAGE_: 'Hmm, it looks like you didn’t add any payments. Please add at least one payment to your payment schedule before sending your proposal.',
            _PAYMENTS_DO_NOT_ADD_UP_TO_PROPOSAL_SUM_: 'Hmmm. These payments don\'t quite add up to the total. Adjust the payment schedule to continue.',
            _ACCEPT_CHANGES_POPUP_: 'Some changes were made. Please review and click accept at the top of the file page to agree upon the changes.',

            INSTANT_DEPOSIT_WEEKEND_MODAL: {
                _TITLE_: 'Don’t keep your money waiting all weekend.',
                _SUBTITLE_: 'The following payment(s) are coming in over the weekend! Choose “Same day” to get them deposited within hours.',
                _DESCRIPTION_: 'There is a 1% charge for Instant Deposit ($5 minimum). This charge does not include processing fees.',
                _CONFIRM_: 'USE INSTANT DEPOSIT',
                _DISMISS_: 'KEEP STANDARD DEPOSIT',
                _DONT_SHOW_AGAIN_: 'SKIP AND DON’T SHOW AGAIN',
                _LEARN_MORE_: 'Learn more.'
            },

            REFUNDS: {
                _REFUND_: 'Please enter in the full dollar amount that you want refunded to your client: $',
                _REFUND2_: 'Do not subtract processing fees from the amount you’re refunding.',
                _REFUND_HELP_1_: 'HoneyBook will reimburse you for any original transaction fees you incurred. Therefore, you should refund your client the entirety of the service they paid for.',
                _CREDIT_CARD_: 'Credit Card',
                _BILLING_ADDRESS_: 'Billing Address',
                _ENTER_CREDIT_CARD1_: 'Please enter',
                _ENTER_CREDIT_CARD2_: 'your',
                _ENTER_CREDIT_CARD3_: 'credit card below for us to recollect the funds.',
                _ENTER_CREDIT_CARD4_: 'We will not charge you for the transaction fee.',
                _REVERT_MARK_AS_PAID_: 'Revert mark as paid payment',
                _CONFIRM_REFUND_: 'You are about to refund',
                _REFUND_CONFIRM_ALREADY_TRANSFERRED_: 'Since you have already received this payment, HoneyBook will need to recollect the funds <b>from you</b> (by credit card) to process the refund. We are not able to debit your bank account.',
                _REFUND_FINAL_TRANSFERRED_: 'We will issue your client a refund in the amount of: ',
                _REFUND_FINAL_TRANSFERRED2_: 'using your credit card',
                _REFUND_FINAL_TRANSFERRED3_: 'ending in',
                _REFUND_FINAL_TRANSFERRED4_: 'The amount billed to your credit card will be',
                _REFUND_FINAL_TRANSFERRED5_: 'minus any transaction fees you paid on the original payment.',
                _REFUND_FINAL_TRANSFERRED6_: 'Refunds take up to 3-10 business days to be reflected back onto your client’s card.',

                _CONFIRM_CREDIT_CARD1_: 'Please confirm',
                _CONFIRM_CREDIT_CARD2_: 'your',
                _CONFIRM_CREDIT_CARD3_: 'credit card below for us to recollect the funds. We will not charge you for the transaction fee.',
                _CONFIRM_CREDIT_CARD4_: 'ending in',

                _REFUND_FINAL_NOT_TRANSFERRED_: 'We will refund',
                _REFUND_FINAL_NOT_TRANSFERRED2_: 'to your client’s card',
                _REFUND_FINAL_NOT_TRANSFERRED3_: 'ending in',
                _REFUND_FINAL_NOT_TRANSFERRED4_: 'Refunds take up to 3-10 business days to be reflected on your client’s credit card statement.',

                _REFUND_FINAL_PLEASE_CONFIRM_: 'Please confirm below.',

                _REFUND_MARK_AS_PAID_CONFIRM_: 'Are you sure you want to undo the $',
                _REFUND_MARK_AS_PAID_CONFIRM2_: 'payment you manually marked as paid?',

                _REFUND_CUSTOM_AMOUNT_: 'Refund',
                _REFUND_MAP_: 'Refund manual payment',
                _UNDO_MAP_: 'Undo manual payment',
                _AMOUNT_: 'Amount',
                _ENTER_REFUND_AMOUNT_: 'Please enter in the full dollar amount that you want refunded to your client.',
                _DONT_SUBTRACT_FEES_: 'Do not subtract processing fees',
                _REFUND_NOT_TRANSFERRED_: 'We will refund <b>${{refund_amount}}</b> to your client’s card. Refunds take up to 3-10 business days to be reflected on your client’s credit card statement.',
                _USE_THIS_CARD_: 'Confirm your credit card',
                _CHANGE_: 'Change',
                _REFUND_FAILED_: 'Refund failed',
                _REFUND_TEXT_: 'Refund',
                _REFUNDED_TEXT_: 'Refunded',
                _REFUNDING_TEXT_: 'Refunding',
                _CONNECTING_ACH_: 'Connecting',

                _UNDO_TEXT_: 'Undo',
                _UNDOING_TEXT_: 'Undoing',
                _UNDONE_TEXT_: 'Undone',
                _UNDO_FAILED_: 'Undo failed',
                _REFUND_AMOUNT_: 'Refund amount',
                _ORIGINAL_PAYMENT_: 'Original payment of ',
                _MADE_ON_: ' made on ',
                _WE_DO_NOT_SUBTRACT_PROCESSING_FEES_: 'Do not subtract processing fees from the refund amount as we will reimburse you for these.',
                _WE_CANNOT_DEBIT_REFUNDS_: 'We cannot debit refunds directly from your bank account, therefore your credit card will have to be used to issue this refund.',
                _LEARN_MORE_: 'Learn more',
                _FROM_: 'From: ',
                _YOU_: 'You ',
                _TO_: 'To: ',
                _CARD_ENDING_IN_: 'Card ending in ',
                _BANK_ACCOUNT_ENDING_IN_: 'Bank account ending in ',
                _USE_CARD_ENDING_IN_: 'Use card ending in ',
                _USE_BANK_ACCOUNT_ENDING_IN_: 'Bank account ending in ',
                _USE_DIFFERENT_CARD_: 'Use a different card ',
                _USE_DIFFERENT_BANK_ACCOUNT_: 'Use a different bank account',
                _ENTER_YOUR_CARD_INFO_: 'Enter your credit card information to issue this refund.',
                _WHY_DO_YOU_NEED_MY_CARD_: 'Why do you need my credit card?',
                _NOW_: 'NOW',
                _DISCLAIMER_TOOLTIP_: 'We cannot debit refunds directly from your bank account, therefore your credit card will have to be used to issue this refund. ',
                _REFUNDED_MSG_: 'You cannot refund this payment through HoneyBook since it was transacted outside of the system. You will need to refund your client using another method.',
                _WOULD_YOU_LIKE_TO_MARK_AS_REFUNDED_: 'Would you like to mark this payment as refunded?',
                _MARK_AS_REFUNDED_: 'Mark as refunded',
                _CONNECT_BANK_ACCOUNT_: 'Connect bank account',
                _CONNECT_BANK_ACCOUNT_DESC_: 'Refunds are issued from your bank to your client’s original payment method. Easily connect to the account you’d like to use for refunds.',
                _CONNECT_TO_DIFFERENT_ACCOUNT_: 'Connect to different account',
                _TRY_LATER_: 'Try later',
                _ACCOUNT_BALANCE_ERROR_: 'You currently don’t have sufficient funds in this account to cover the refund amount. Please connect to a different account or try again later.',
                _CONNECTION_ERROR_: 'Connection failed. Please connect to a different account or try again.',
                _CONFIRMATION_MESSAGE_: 'Please contact us if there are any issues.',
                _REFUND_INITIATED_: 'Your refund has been initiated.',
                _CONFIRM_: 'ok'
            }
        },

        AGREEMENT: {
            POPUP: {
                _TITLE_: 'Customize field',
                _FIELD_NAME_LABEL_: 'Field name',
                _FIELD_NAME_PLACEHOLDER_: 'Client name, location, etc.',
                _FIELD_NAME_TOOLTIP_: 'This will show as placeholder text for the field if there is no value assigned.',
                _FIELD_VALUE_LABEL_: 'Field value',
                _FIELD_VALUE_TOOLTIP_: 'The contract recipient will see this as the default value.',
                _FIELD_SETTINGS_LABEL_: 'Client...',
                _SAVE_FIELD_: 'SAVE FIELD',
                _CUSTOM_FIELD_TITLE_: 'CUSTOM FIELD',
                _PRESET_FIELDS_TITLE_: 'PULL FIELD INFO FROM',
                _CHOOSE_CATEGORY_: 'Choose Category',
                _CHOOSE_FIELD_: 'Choose Field Subject',
                _FIELD_PLACEHOLDER_: 'Enter custom field',
                _FIELD_PLACEHOLDER_HELP_: 'Ex. Rehearsal Date, Mother Name, etc...',
                _CLIENT_FIELD_CHECKBOX_: 'Enable client to fill out',
                _REQUIRED_FIELD_CHECKBOX_: 'Required field',
                _CLIENT_FIELD_WARNING_: '*Checking this box grants your client access to enter info.',
                _ADD_BUTTON_TEXT_: 'ADD',
                SETTINGS: {
                    _CAN_EDIT_: 'can edit',
                    _MUST_FILL_: 'must fill',
                    _CANT_EDIT_: 'can\'t edit',
                }
            },
            LABELS: {
                _NUMBER_OF_FIELDS_LEFT_LEFT_: 'You have ',
                _NUMBER_OF_FIELDS_LEFT_RIGHT_: ' that must be filled before signing.',
                _NUMBER_OF_FIELDS_LEFT_RIGHT_VENDOR_: ' that must be filled before you can send.',

                _ONE_FIELD_LEFT_: 'There is 1 empty field highlighted. Please complete this field.',
                _ALL_FIELDS_FILLED_: 'You\'re all set! ',
                _ALL_FIELDS_FILLED_HIGHLIGHTED_: ' Time to sign the contract.',
                _NOT_THE_SIGNATORY_: 'You are not the signatory for this contract. You will not be able to sign.',
                _SIGNED_READY_TO_PAY_: 'Now that you’ve signed, ',
                _SIGNED_READY_TO_PAY_HIGHLIGHTED_: 'you’re ready to pay!',
                _NO_FIELDS_READ_AND_SIGN_: 'Once you’ve read the contract, ',
                _NO_FIELDS_READ_AND_SIGN_HIGHLIGHTED_: 'sign at the bottom!',
                _CONTRACT_SIGNED_: 'Excellent! You\'ve signed the contract.',

                _ALL_FIELDS_FILLED_SEND_: 'Excellent! All fields are filled! You may send the contract now.',
                _CLIENT_HAS_SIGNED_AGREEMENT_: 'Your client has signed the contract. ',
                _CLIENT_HAS_SIGNED_AGREEMENT_HIGHLIGHTED_: 'It\'s time to countersign!'
            },
            SIGNATURE: {
                _DRAW_MESSAGE_: 'Draw your signature',
                _CLEAR_: 'Clear',
                _NAME_PLACEHOLDER_: 'Enter your full name',
                _AGREE_: 'Agree',
                _VENDOR_SIGNATURE_LOCKED_: 'You will be able to sign once your client fills out their assigned fields.',
                _VENDOR_SIGNATURE_NO_SIGNEES_: 'You can only change to an admin or owner that is a member in the workspace',
                _CLIENT_SIGNATURE_LOCKED_: 'Hmm, it looks like there are empty fields. You cannot sign the contract until all fields are filled.',
                _CLIENT_VERSION_ACCEPT_LOCKED_: 'Hmm, it looks like there are empty fields. You cannot accept the contract until all fields are filled.',
                _ADD_SIGNATURE_: 'Add Signature',
                _CONFIRMATION_TEXT_: "By signing, I understand that this is a legally binding contract.",
                _PLEASE_SIGN_FIRST_: "Please enter your name and signature before submitting",
                _SIGNED_ON_: 'Signed: ',
                _CHANGE_: 'Change',
                _USE_DEFAULT_: 'Use default',
                _SAVE_AS_DEFAULT_: 'Save as default',
                _ACCEPT_: 'Accept',
                _TYPE_SIGNATURE_: 'Type your signature',
                _TYPE_NAME_: 'Type your name here',
                _CHOOSE_A_SIGNEE_: 'Choose a signee',
                _CLICK_TO_SIGN_: 'Click to sign',
                _SHOW_DONT_SIGN_HERE_ERROR_: 'Only {{userName}} should sign here',
                _FILE_EXPIRED_ERROR_: 'This {{fileType}} is expired and can not be signed',
                _SIGN_ERROR_CLIENT_FIELDS_: 'You can only sign once your client fills out their assigned fields',
                _SHOW_SIGN_ACTIVE_VERSION_ERROR_: 'You can only sign the most updated version',
                _ADD_USER_MODAL_SUBTEXT_: 'You must add your client(s) to the workspace to request their signature'
            },
            MESSAGES: {
                _HAVE_MANDATORY_FIELDS_: 'Oops, please make sure to fill out the required contract fields before sending the proposal.',
                _HAVE_MANDATORY_FIELDS_AGREEMENT_: 'Oops, please make sure to fill out the required contract fields before sending the contract.',
                _ADD_SIGNATURE_ERROR_: 'Oops, there was an error adding the signature. Please try again.'
            }
        },

        CREATE_FILE_WIZARD: {
            ERROR: {
                _ERROR_CREATING_A_FILE_: 'Oops, please try again.'
            },

            RECENT_FILES: {
                _CREATE_FILE_WIZARD_TITLE_: "Where do you want to start?",
                _CREATE_FILE_WIZARD_SUBTITLE_: "Select from the list below",
                _SENT_ON_: "Last used on ",
                _CREATED_ON_: " - Created on ",
                _RECENT_: "Start from a recent {{ fileType }}",
                _BLANK_: "Blank ",
                _EMPTY_MESSAGE_: "No recently created ",
                _LOADING_FILES_: "Loading recent files ",
                _LOADING_TEMPLATES_: "Loading templates"
            },

            TEMPLATES: {
                _TITLE_: "Start from a template",
                _EMPTY_MESSAGE_: "No templates yet. You can create a new one in your ",
                _TEMPLATES_PAGE_LINK_: "Templates page",
                _SENT_TEST_TEXT_: "A project lets you work, communicate, and track client activity—all in one organized place."
            },

            SELECT_PROJECT: {
                _NEW_PROJECT_EXPLAIN_: "This file will be created in a new project.",
                _NEW_FLOW_EXPLAIN_: "This smart file will be created in a new project.",
                _NEW_PROJECT_CONTROL_: "New project",
                _NEW_PROJECT_LABEL_: "Project name",
                _NEW_PROJECT_ERROR_: "Please give the new project a name",
                _NEW_PROJECT_PLACEHOLDER_: "My New Project",
                _EXISTING_PROJECT_EXPLAIN_: "This smart file will be created in an existing project.",
                _EXISTING_PROJECT_EXPLAIN_WITH_CONTACT: "This smart file will be created in an existing project with this contact",
                _EXISTING_PROJECT_CONTROL_: "Existing project",
                _EXISTING_PROJECT_LABEL_: "Type to search",
                _EXISTING_PROJECT_ERROR_: "Please select an existing project",
                _CTA_LEGACY_: "NEXT",
                _CREATE_FILE_CTA_: "Create file",
                _CREATE_FLOW_CTA_: "Create smart file",
                _SENT_TEST_FROM_TEMPLATE_CTA_: "Continue",
                _SAVE_FILE_CTA_: "Save file",
            }
        },

        SAVING_MODEL_MESSAGE: {
            _SAVING_: 'Saving...',
            _SAVED_FULL_: 'All changes saved!',
            _SAVED_: 'Saved!',
            _ERROR_: 'Oops, looks like we had a glitch. Try again'
        },

        ADMIN: {
            LOGIN: {
                _SEARCH_USER_: 'Search For a User',
                _OR_: '-OR-',
                _EMAIL: 'Enter User Email',
                _ENTER_EMAIL: 'Email',
                _LOGIN_AS_: 'Log In As',
                _GO_TO_MALKUT_: 'Go to Malkut Page'
            }
        },

        ADD_USER_DIALOG: {
            _HEADER_TITLE_: 'Add a member to the workspace',
            _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added to the workspace. Are you sure you\'re done editing?',
            _SUBTITLE_: 'Search by name or email or add someone new by entering an email address.',
            _NO_MEMBERS_SUBTITLE_: '(You can always add more later)',
            _NO_MEMBERS_TOOLTIP_TITLE_: 'Add Anyone -',
            _NO_MEMBERS_TOOLTIP_: 'Inside or outside of HoneyBook',
            _ADD_NEW_: 'Add New',
            _EMAIL_: 'Email',
            _EMAIL_ADDRESS_: 'Email Address',
            _FULL_NAME_: 'Full Name',
            _PHONE_NUMBER_: 'Phone Number [Optional]',
            _MAILING_ADDRESS_: 'Mailing Address [Optional]',
            _COMPANY_TYPE_: 'Company Type',
            _COMPANY_NAME_: 'Company Name',
            _COMPANY_WEBSITE_: 'Company Website [Optional]',
            _SELECT_USER_TYPE_: 'Choose a role',
            _ADD_USER_: 'Add Participant',
            _ADD_USER_CLIENT_FLOW_: 'Add Client',
            _ADD_USER_TRIALER_: 'Add Client',
            _EMPTY_STATE_HEADING_: 'It\'s lonely in here!',
            _EMPTY_STATE_TEXT_: 'Add a participant — in or out of HoneyBook — to share files and begin collaborating.',
            _ADD_CLIENT_SUBTITLE_:'Add details about the person who’ll be receiving your service.',
            _ADD_COLLABORATOR_SUBTITLE_:'Add details about the person helping you provide your service. Part of your company? You can add them as a team member.',
            _ADD_TEAM_MEMBER_SUBTITLE_: 'Add team members to this project.',
            _MEMBER_NOT_ADDED_HEADER_: 'The participant has not been added.',
            _MEMBER_NOT_ADDED_MSG_: 'To keep editing, continue by clicking “Keep editing” If you don’t wish to add a participant, click “Discard”. Make sure to click Save when you’re done.',
            _DISCARD_CHANGES_: 'Discard',
            _KEEP_EDITING_: 'Keep Editing',
            _REFER_LABEL: 'Spread the love! Invite them to join HoneyBook to get $100 when they subscribe',
            _ADD_USER_FROM_FILE_EDITOR_TITLE_: 'Add member to this project',
            _ADD_USER_FROM_FILE_EDITOR_SUBTITLE_: 'Members added will see all activity on this project',
            _SKIP_: 'SKIP',
            _ERROR_CLIENT_ALREADY_EXISTS_: 'Client already exists in project.',

            NEW_ADD_USER_MODAL: {
                _TITLE_: "Add New Contact",
                _SUBTITLE_: "Keep track of your contacts here.  You’ll be able to access their info, send them files, store notes on them and more.",
                _SAVE_: "Save",
                _INVITE_: "Invite",
                _ADD_: "Add",
                _WILL_INVITE_: "",
                _REFER_: "Refer",
                _MOVE_: "Move"
            },

            INVITE_CONNECTION_MODAL: {
                _TITLE_: "Invite Creative",
                _SUBTITLE_: "Keep track of your contacts here. You’ll be able to access their info, send them files, store notes on them and more.",
                _INVITE_: "Invite",
            },

            WORKSPACE_TYPE: {
                TEAM: {
                    _HEADER_TITLE_: 'Add Vendors or Clients to this Workspace'
                },
                DESIGN: {
                    _HEADER_TITLE_: 'Add people to collaborate with'
                },
                ALL_VENDORS: {
                    _HEADER_TITLE_: 'Add people to collaborate with'
                },
                TIMELINE: {
                    _HEADER_TITLE_: 'Add people to share the timeline with'
                }
            }
        },

        VENDOR_REFERRAL_DIALOG: {
            _HEADER_TITLE_: 'Refer to Vendor',
            _HEADER_TITLE_CONSULTANT_: 'Refer Project',
            _SUB_TITLE_: 'Which vendor would you like to refer this project to?',
            _SUB_TITLE_CONSULTANT_: 'Who would you like to refer this project to?',
            _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added. Are you sure you\'re done editing?',
            _HEADER_TITLE_TWO_: 'We\'ll pass on the project information and keep track of your referral',
            _CANNOT_ADD_CLIENT_: 'Oops, Cannot refer a project to a client'
        },


        VENDOR_ADMIN_MOVE_DIALOG: {
            _HEADER_TITLE_: 'This is an admin tool that will move select workspace/all files to another vendor. Please select a new vendor',
            _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added. Are you sure you\'re done editing?',
            _HEADER_TITLE_TWO_: 'We\'ll pass on the project information and keep track of your move',
            _CANNOT_ADD_CLIENT_: 'Oops, Cannot move a project to a client'
        },


        VENDOR_REFERRAL_EMAIL_EDITOR: {
            _HEADER_TITLE_: 'We\'ll include these project details in the email',
            _HEADER_SUB_TITLE_: 'We\'ll include these project details in the email',
            _INFO_MSG_: 'Project and clients\' details will automatically populate in the email'
        },

        VENDOR_REFERRAL_KEEP_SESSIONS: {
            _TEXT_: 'You have scheduled meetings or sessions connected to this project. Would you like to keep these sessions?',
            _YES_KEEP_: 'Yes, keep',
            _NO_CANCEL_: 'No, cancel'
        },

        BULK_INTRO_MODAL: {
            _HEADER_: 'Send one email to multiple clients',
            _CONTENT_: 'With bulk emailing, you can send one message to multiple clients at once. Other important details about bulk emails:',
            _ITEM1_: 'The email will be sent to all participants in the selected projects',
            _ITEM2_: 'You can manage follow ups within each project workspace',
            _ITEM1_CONTACTS_: 'The email will be sent to all of the selected contacts',
            _ITEM2_CONTACTS_: 'You can manage follow ups on the “Batch emails” tool',
            _CTA_: 'send bulk email'
        },

        EMAIL_TEMPLATES_MODAL: {
            _BLANK_: 'start from blank',
            _TEMPLATE_: 'Start from template'
        },

        BULK_EMAILS: {
            EMAIL_SUCCESS_PRIMARY: 'Bulk email initiated',
            EMAIL_SUCCESS_SECONDARY: 'This could take a minute or two.\n We\'ll notify you when all sends are complete.',
            TOAST_SUCCESS: 'Bulk email sent successfully',
            BULK_EMAIL: 'Bulk email',
            FEED_TEXT: 'This message was sent as part of a bulk email',
            ONLY_VISIBLE_TO_YOU: 'Only visible to you',
        },

        BULK_EMAILS_ERRORS: {
            _NOT_ALLOWED_: "You're not allowed to send bulk emails. Questions? Email concierge@honeybook.com",
            _MAX_DAILY_: "You’ve reached the maximum number of bulk emails today, please try again tomorrow.",
            _RUNNING_TASK_: "We’re still working on sending your previous bulk email. Try again in a couple minutes.",
            _MAX_PROJECTS_PER_BATCH_EXCEEDED_: 'Bulk emails are limited to {{limit}} projects at a time. Please deselect {{delta}} projects.',
            _MAX_CONTACTS_PER_BATCH_EXCEEDED_: 'Bulk emails are limited to {{limit}} contacts at a time. Please deselect {{delta}} contact{{plural}}.',
            _PREMIUM_: "Bulk Email is only available for HoneyBook members. For access, please upgrade to a monthly or annual plan.",
            _GMAIL_: "Our Bulk Email BETA is currently enabled for users with an integrated Email account. Please connect your Email or contact us to request access.",
            _GMAIL_CTA_POSITIVE_: 'Integrate Email',
            _GMAIL_CTA_NEGATIVE_: 'Not Now',
        },

        EMAIL_EDITOR: {
            _FROM_: 'From:',
            _TO_: 'To:',
            _BCC_MYSELF_: 'Bcc myself',
            _SUBJECT_: 'Subject:',
            _TEMPLATES_: 'Templates',
            _SAVE_AS_: 'SAVE AS',
            _SUBJECT_PLACEHOLDER_: 'New subject',
            _ALL_PARTICIPANTS_: 'All participants in {{count}} project',
            _ALL_PARTICIPANTS_PLURAL_: 'All participants in {{count}} projects',
            _SELECTED_CONTACTS_: '{{count}} contact',
            _SELECTED_CONTACTS_PLURAL_: '{{count}} contacts',
            _GENERAL_ERROR_: 'Oops! There is an error sending this email, please try again later.',
            _MANDRILL_ERROR_: 'Oops! There is an error sending this email, please try again later.',
            _SEND_MYSELF_: 'Send to myself',
            SUCCESS: {
                _SUCCESS_COPY_: 'Your message was successfully sent!',
                _SUCCESS_SCHEDULED_: 'Your message was successfully scheduled!',
                _FILE_PREVIEW_SUCCESS_HEADER_: 'Test {{ fileType }}',
                _FILE_PREVIEW_SUCCESS_HEADLINE_: 'Test {{ fileType }} successfully sent to',
                _FILE_PREVIEW_SUCCESS_STEP_1_: 'Check your inbox on desktop or mobile',
                _FILE_PREVIEW_SUCCESS_STEP_2_: 'Click the email to view exactly like a client',
                _FILE_PREVIEW_SUCCESS_STEP_SUBTEXT_: 'you will view the test as a client and be unable to edit the file',
                _FILE_PREVIEW_SUCCESS_CTA_: 'OK',
            },
            ERRORS: {
                _INVALID_EMAIL_: 'The message is undeliverable to {{email}}. <br /> Try verifying the email address with the recipient.'
            },
            PROMPT_USER_SAVE_ATTACHMENTS: {
                _MESSAGE_PLURAL_: 'This email includes {{count}} attachments. Would you like to include them in your new template?',
                _MESSAGE_SINGLE_: 'This email includes an attachment. Would you like to include it in your new template?',
                _POSITIVE_: 'save with attachments',
                _NEGATIVE_: 'save message only',
                _TITLE_: 'Save with attachments?',
                _TOAST_TEXT_: 'Email template saved successfully'
            },
            UPLOADING_FILES: {
                _CANCEL_UPLOAD_TITLE_: 'Cancel Upload?',
                _UPLOADING_FILE_: 'You are uploading a file.',
                _UPLOADING_FILES_: 'You are uploading files.',
                _CONTINURE_UPLOAD_: 'CONTINUE UPLOAD'
            }

        },

        SENDING_DIALOG: {
            _SENDING_: 'Sending...',
            _SENDING_TO_: 'Sending to {{userName}}'
        },

        MALKUT: {
            MAIN_NAVIGATION: {
                _LOGOUT_: 'Log Out',
                _DASHBOARD_: 'Dashboard',
                _SALES_: 'Sales',
                _CS_: 'CS',
                _CU_: 'Client Users',
                _LOANS_: 'Advanced Withdrawal',
                _USER_DETAILS_: 'User Details',
                _TRENDS_: 'Trends',
                _DESIGN_: 'Product Design',
                _ECOSYSTEM_OKR_: 'Ecosystem',
                _GRAPH_: 'User graphs',
                _FAILED_TRANSFERS_NEW_: 'Pending Payouts',
                _UNVERIFIED_OBJECTS_: 'Blocked Users',
                _TRIALERS_: 'Trialers',
                _UNVERIFIED_RECIPIENTS_: 'Unverified Recipients',
                _MANAGED_ACCOUNTS: 'Managed Accounts',
                _PRODEV_: 'ProDev',
                _PRICING_: 'Pricing',
                _VENUE_ONBOARDING_: 'Venue Onboarding',
                _REFERRALS_: 'Referrals',
                _PAYMENT_DISPUTES_: 'Disputed Payments',
                _ADMINISTRATION_: 'Administration',
                _RTS_: 'RTS',
                _PRICING_TIERS_: 'Pricing Tiers',
                _EMAIL_STATUSES_: 'Email Status',
                _PUBLIC_PAYMENTS_VIOLATIONS_: 'Public Payments Violations',
                _RESTORE_ITEMS_: 'Restore items'
            },

            PRICING: {
                ERRORS: {
                    PROMO_CODE_NAME_ALREADY_EXISTS: 'This promo code already exists. rename it or delete that other one.'
                },
                RECURLY: {
                    _RECURLY_ACTIONS_: 'This action now happens in Recurly. Please reach out to Steph L. or Yona with questions.'
                }
            },

            RTS: {
                _DELETE_CHAPTER_: 'Are you sure you want to delete this chapter?.'
            },

            PRODEV: {
                _PRODEV_TITLE_: 'ProDev Rules!',
                _GROWTH_TAB_: 'Growth',
                _ACTIVE_TAB_: 'Active',
                _DESIGN_TAB_: 'Design',
                _ICONS_TAB_: 'Icons',
                _SURVEYS_TAB_: 'Surveys',

                GROWTH: {
                    _PROJECTS_WITH_MULTIPLE_INSTANCES_: 'Projects With Multiple Instances',
                    _TOTAL_PROJECTS_: 'Total Projects',
                    _TOTAL_DUPLICATE_PROJECT_INSTANCES_: 'Number of Duplicate Project Instances'
                },
                _MOBILE_TAB_: 'Mobile'

            },
            STRIPE: {
                _EMAIL_: 'email',
                _NAME_: 'name'
            },
            STATISTICS: {
                _WEEKLY_SUMMARY_: 'Weekly Summary:',
                _FILES_CREATED_: 'Files Created',
                _FILES_SENT_: 'Files Sent',
                _FILES_VIEWED_: 'Files Viewed',
                _FILES_BOOKED_: 'Files Booked',
                _FILES_PAID_IN_FULL_: 'Files Paid in Full',
                _TOTAL_PAYMENTS_PROCESSED_: 'Total Payments Processed',
                _VALUE_OF_ALL_HONEYBOOK_PROJECTS_: 'Value of all HoneyBook Projects',
                _VALUE_OF_ALL_SENT_FILES_: 'Value of all Sent Files',

                _BOOKED_: 'GSV',
                _PAID_THROUGH_HB_: 'Paid Through HB',
                _REVENUE_: 'HB Rev',
                _SENT_TO_BOOKED_: 'Sent to Booked (Days)',
                _OZ_JUST_FOR_YOU_: 'Oz, just for you',

                _WEEK_: 'Week',
                _CREATED_: 'Created',
                _SENT_: 'Sent',
                _VIEWED_: 'Viewed',
                _SIGNED_: 'Signed',
                _FIRST_PAYMENT_: 'First Payment',
                _MARKED_AS_PAID_: 'Marked As Paid (1st / All)',
                _TIP_: 'Tip',
                _PAYMENTS_THROUGH_HB_: 'Payments Through HB',
                _HB_FEES_REVENUE_: 'HB Fees Revenue',
                _SEND_TO_BOOKED_: 'Sent to Booked (days)',
                _TOTAL_PAYMENTS_: 'Total Payments',
                _AVERAGE_: 'Average',
                _MAX_: 'Max',
                _ERROR_GETTING_STATISTICS_: 'There was an error in getting the statistics',
                _REFRESH_: 'Refresh'

            },

            GLOBAL_SEARCH: {
                GLOBAL_SEARCH_BLOCK: {
                    _GLOBAL_SEARCH_HINT_: 'Search for file, project, happiness',
                    _FIND_: "Search",
                    _HINT_: 'Feel lucky?',

                },
            },

            INJECT_TEMPLATES: {
                SEARCH_BLOCK: {
                    _SEARCH_TITLE_: 'Search a user to fetch templates',
                    _HINT_: 'Search for a user name or email',
                    _OR_: 'Or',
                    _ENTER_EMAIL_: 'Enter Email',
                    _EMAIL_: 'Email',
                    _FETCH_USER_TEMPLATES_: 'Load Templates'
                },
            },

            USER_DETAILS: {
                SEARCH_BLOCK: {
                    _SEARCH_TITLE_: 'Search a user to load it\'s details',
                    _HINT_: 'Search for a user name or email',
                    _OR_: 'Or',
                    _ENTER_EMAIL_: 'Enter Email',
                    _EMAIL_: 'Email',
                    _OPEN_USER_DETAIL_: 'Open User Details'
                },
                _COMPANY_NAME_: 'Company Name:',
                _COMPANY_TYPE_: 'Company Type:',
                _DAYS_TILL_TRIAL_ENDS_: 'Days till trial ends:',
                _ACCOUNT_TYPE_: 'Account Type',
                _VENDOR_INFO_: 'Vendor Info',
                _CLIENT_INFO_: 'Client Info',
                _EMAIL_: 'Email:',
                _TEL_: 'Phone Number:',
                _COMPANY_INFO_: 'Company Info',
                _URL_: 'URL:',
                _CLIENT_PORTAL_URL_: 'Client Portal URL:',
                _TAX_1099_ELIGIBLE_: '1099-K Eligible:',
                _ACCOUNT_INFO_: 'Account Info',
                _INTEGRATION_STATUS: 'Integration Status',
                _ACCOUNT_CREATED_: 'Account Created:',
                _INTEGRATED_EMAIL_STATUS_: 'Integration Status:',
                _EMAIL_PROVIDER_: 'Selected Provider:',
                _EMAIL_CREATED_: 'Email Creation:',
                _EMAIL_ADDRESS_: 'Integrated Email',
                _CREATED_BY_: 'Created By:',
                _STATUS_: 'Status:',
                _ADDRESS_: 'Address:',
                _ACTIVE_: 'Active',
                _INACTIVE_: 'Not Active',
                _CANCEL_PRICING_PLAN: 'Cancel Pricing Plan',
                _REVOKE_PRICING_PLAN: 'Revoke Pricing Plan',
                _EXTEND_TRIAL_PERIOD_: 'Extend trial period',
                _FORCE_OTAM_ON_: 'Enable otam on account',
                _GIVE_DISCOUNTED_FEE_: 'Give Discounted Fees',
                _GIVE_DISCOUNTED_FEE_DEC_2022_: 'Give Discounted Fees (December 2022)',
                _REVOKE_DISCOUNTED_FEE_: 'Revoke Discounted Fees',
                _MALKUT_APPLY_PROMO_CODE_: 'Apply promo code',
                _MALKUT_EXTEND_MEMBERSHIP_PERIOD_: 'Extend Membership Period',
                _TRIAL_ENDED_: "Trial Ended",
                _PROMO_CODE_: 'Promo Code:',
                _CANCEL_REASON_: 'Cancel Reason',
                _CANCEL_DATE_: 'Cancel Date',
                _SEND_RECEIPT_: 'Send Payment Receipt to Owner',
                _SYNC_SUBSCRIPTION_: 'Sync Subscription Data',
                _IS_EMAIL_TESTER_OPEN_: 'Is email tester open?'
            },
            TRENDS: {
                _MONTHLY_: 'Monthly',
                _WEEKLY_: 'Weekly',
                _CUSTOMERS_: 'Customers',
                _SALES_: 'Sales',
                _FILES_COUNT_: 'Files Count',
                _FILES_CREATED_: 'Files Created',
                _FILES_SENT_: 'Files Sent',
                _FILES_BOOKED_: 'Files Booked',
                _FILES_VALUE_: 'Files Value',
                _PAYMENTS_: 'Payments',
                _PAID_WITH_HB_: 'Paid With HB',
                _PAID_OUTSIDE_HB_: 'Paid Outside HB',
                _FUTURE_PAYMENTS_: 'Future Payments',
                _ACTIVE_USERS_: 'Active Users',
                _VENDORS_WHO_CREATED_A_FILE_: 'Vendors Who Created a File',
                _VENDORS_WHO_SENT_A_FILE_: 'Vendors Who Sent a File',
                _VENDORS_WHO_GOT_PAID_: 'Vendors Who Received a Retainer',
                _USERS_DISTRIBUTION_: 'Users Distribution',
                _VENUES_WHO_GOT_PAID_: 'HB Payments / Active / 10',
                _TOTAL_VENUES_: 'Total Venues'

            },

            TRANSFERS: {
                _RESEARCH_STAGE_: 'RESEARCH STAGE',
                _PROJECT_: 'Project',
                _PAYMENT_DESC_: 'Payment Desc.',
                _WORKSPACEFILE_ID_: 'Workspace File ID',
                _SMART_FILE_ID_: 'Smart File ID',
                _PAYMENT_ID_: 'Payment ID',
                _VENDOR_: 'Vendor',
                _PAYER_LINK_TO_STRIPE: 'Link to Stripe payer',
                _LINK_TO_STRIPE: 'Link to Stripe charge',
                _COMPANY_OWNER_EMAIL_: 'Company Owner Email',
                _FILE_OWNER_EMAIL_: 'File Owner Email',
                _FRAUD_STATUS_: 'Fraud Status',
                _WEBSITE_URL: 'Website URL',
                _TYPE_: 'Type',
                _TRANSFER_PROCESSING_START_: 'Transfer Processing Start',
                _TRANSFER_PROCESSING_END_: 'Transfer Processing End',
                _TRANSFER_LAST_ATTEMPT_: 'Last Transfer Attempt',
                _ACCOUNT_INTERNAL_NOTES_: 'Account Internal Notes',
                _TRANSFER_ID_: 'Transfer ID',
                _STRIPE_TRANSFER_ERROR_: 'Stripe Transfer Error',
                _HONEYBOOK_TRANSFER_ERROR_: 'HoneyBook Transfer Error',
                _HB_FORTER_DECISION_: 'HB Decision',
                _TIP_: 'Tip',
                _HB_FEE_TO_COLLECT_: 'HB Fee to Collect',
                _TRANSFER_AMOUNT_: 'Transfer Amount',
                _CLIENTS_: 'Clients',
                _REMOVE_: 'Remove',
                _TRY_TRANSFER_AGAIN_: 'Try Transfer Again',
                _SHOW_VENDOR_INFO_: 'Vendor Info',
                _DETAILS_: 'Details',
                _AMOUNT_DUE_: 'Amount Due',
                _PAYMENT_DUE_DATE_: 'Payment Due Date',
                _CHARGED_DATE_: 'Charged Date',
                _CLIENTS_AND_VENDOR_: 'Clients/Vendor',
                _SET_AS_TRANSFERRED_: 'Set as Transferred',
                _SHOW_VENDOR_INFO: 'Show Vendor Info',
                _RETRY_TRANSFER_MESSAGE_: 'This will try to transfer the payment into the vendor\'s bank account.',
                _REMOVE_RECIPIENT_MESSAGE_: 'This will set the the recipient as irrelevant and remove it from this list.',
                _RETRY_TRANSFER_SUCCESS_MESSAGE_: 'Transfer Successfully Made',
                _RELOAD_: 'Reload'
            },
            GRAPH: {
                _USERNAME_: 'vendor',
                _SCORE_: 'score'
            },
            VENUE_ONBOARDING: {
                SEARCH_BLOCK: {
                    _SEARCH_TITLE_: 'Enter the name or email of a Wedding Spot venue.',
                    _HINT_: 'Search for a venue name or email',
                    _SEARCH_TERM_PLACEHOLDER_: 'Venue name or email'
                }
            }
        },
        PIPELINE: {
            STAGES: {
                _INQUIRY_: 'Inquiry',
                _FOLLOW_UP_: 'Follow Up',
                _MEETING_: 'Meeting',
                _PLANNING_: 'Planning',
                _PROPOSAL_SENT_: 'Proposal Sent',
                _PROPOSAL_SIGNED_: 'Proposal Signed',
                _CONTRACT_SIGNED_: 'Contract Signed',
                _RETAINER_PAID_: 'Retainer Paid',
                _CLOSED_: 'Completed',
                _ARCHIVED_: 'Archived',
                _ALL_ACTIVE_: 'Active Projects',
                _UNTRACKED_PROJECTS_: 'Untracked Projects',
                _DO_NOT_TRACK_: 'NOT TRACKED',
                _DISCOVERY_CALL_: 'Discovery Call',
                _PROPOSAL_: 'Proposal',
                _PAYMENT_RECEIVED_: 'Payment Received',
                _IN_PROGRESS_: 'In Progress',
                _DELIVERY_: 'Delivery',
                _ONBOARDING_: 'Onboarding',
                _KICK_OFF_: 'Kick off 🎉',
                _LOST_: 'Lost',
                _WON_: 'Won 🎉',
                _NEGOTIATION_: 'Negotiation',
                _DISCOVERY_: 'Discovery',
                _NEW_: 'New'
            },
            TOOLTIPS: {
                _HEADER_TITLE_: 'How the Pipeline Works',
                _SUBHEADER_TITLE_: 'The pipeline is your place to keep track of all your projects. These are all the stages that are un-editable because certain things trigger movement between these stages',
                _ALL_LEADS_: 'ALL Leads',
                _ALL_BOOKED_: 'ALL Booked',
                _INQUIRY_: 'An inquiry is a lead that has contacted you. No other information has been exchanged.',
                _FOLLOW_UP_: 'A follow-up is a lead with whom you have an active dialogue, i.e., continuing questions about the details of the project.',
                _PLANNING_: 'All booked projects, after their first payment but before the project date, are considered in progress.',
                _MEETING_: 'The meeting stage is designated for those leads with whom you’re meeting to see if he or she is a fit. ',
                _PROPOSAL_SENT_: 'HoneyBook automatically moves your leads into this stage when you email a proposal.',
                _PROPOSAL_SIGNED_: 'HoneyBook automatically moves your leads into this stage once they’ve signed a contract. At this point, your lead is also considered booked.',
                _RETAINER_PAID_: 'HoneyBook automatically moves your booked projects into this stage once all or part of the invoice amount has been paid.',
                _COMPLETED_: 'The total number of projects you’ve booked that have reached their project date.',
                _ALL_ACTIVE_: 'The number of all your active leads and all of your booked jobs.',
                _CUSTOMIZE_: 'Customize your project stages to fit your process. To get you started, we added a few stages most {{industryPlural}} use.'
            },
            BLOCK_REASONS: {
                _DUPLICATE_: 'Duplicate project',
                _NO_RESPONSE_: 'No response from lead',
                _OUTSIDE_BUDGET_: 'Outside lead\'s budget',
                _UNAVAILABLE_: 'I\'m not available',
                _LOST_TO_COMPETITOR_: 'Client chose an alternative',
                _CORONA_: 'COVID-19',
                _NOT_A_GOOD_FIT_: 'Not a good fit',
                _LACKING_SERVICES_: 'Lacking services/product',
                _PROPOSAL_EXPIRED_: 'Proposal expired',
                _PROJECT_CANCELED_: 'Project was cancelled',
                _ARCHIVED_: 'Archived project',
                _CANCELED_: 'Canceled project',
                _SPAM_: 'Spam',
                _OTHER_: 'Other',
                _COMPLETED_: 'Completed project'
            },
            LABELS: {
                _AMOUNT_: 'Amount',
                _WORKSPACE_RECENT_: 'PROJECT',
                _PROJECT_CREATED_: 'Project Date',
                _TAGS_ALL_: 'Tags',
                _STAGE_: 'Stage',
                _MOVED_: 'Moved',
                _TEAM_: 'Team',
                _ACTIVE_: 'Active',
                _PIPELINE_: 'PIPELINE',
                _NOT_TRACKING_: 'Not Tracking',
                _ALL_LEAD_: 'All Leads',
                _ALL_BOOKED_: 'All Booked',
                _BOOKED_: 'Booked',
                _INACTIVE_: 'Archive',
                _ARCHIVED_: 'Archived',
                _ERROR_: 'Oops there was an error loading the page please try to refresh the page',
                _BLOCK_: 'Archive',
                _UNBLOCK_: 'Archived',
                _LOADING_: 'Loading...',
                _RECENT_ACTIVITY_: 'Recent Activity',
                _SWITCH_VIEW_: 'Switch Pipeline View',
                _ME_: 'me',
                _TEAM_MEMBER_WITHOUT_WORKSPACE_TITLE_ONE_: 'Send',
                _TEAM_MEMBER_WITHOUT_WORKSPACE_TITLE_TWO_: 'some love',
                _TEAM_MEMBER_WITHOUT_WORKSPACE_: 'doesn\'t have any workspaces yet. Invite them to a workspace or go to your pipeline and assign one.',
                _VIEW_ALL_TEAM_MEMBERS_WITHOUT_WORKSPACE_TITLE_: 'Your team doesn’t have any projects at this stage',
                _VIEW_ALL_TEAM_MEMBERS_WITHOUT_WORKSPACE_SUB_TITLE_: 'Select a different stage to view your team’s projects.',
                _DISABLE_TRACKING_: 'Do not track on pipeline',
                _SEND_BROCHURE_: 'Send Brochure',
                _SEND_FLOW_: 'Send Smart File',
                _PVL_FIRST_INQUIRY_: 'Welcome to HoneyBook! Your first lead awaits. Click above to see the details.',
                _PVL_FIRST_INQUIRY_MOBILE_: 'Welcome to HoneyBook! Your first lead awaits. Tab above to see the details.',
                _LEARN_MORE_: 'Learn More',
                _BULK_ACTIONS_: 'Bulk actions',
                _DOWNLOAD_CSV_: 'CSV',

                _COPY_: 'Copy',
                _COPIED_: 'Copied!',
                _ARCHIVE_REASON_: "Archive Reason",
                _PROJECT_TYPE_: "Project Type",
                _LEAD_SOURCE_: "Lead Source"
            },
            SORT: {
                _SORT_BY_: 'Sort By:',
                _ALPHABETICAL_: 'A-Z Name',
                _PROJECT_DATE_: 'Project Date',
                _CREATE_DATE_: 'Created Date'

            },
            BULK_ACTIONS: {
                _MOVE_TO_: 'move to...',
                _ARCHIVE_: 'archive',
                _DELETE_: 'Delete'
            },
            REFER: {
                _ERROR_NO_CLIENT_: 'You may only refer workspaces which have at least one client. Add members to your workspace in order to refer it.',
                _BLOCK_SUCCESSFUL_: 'Successfully archived',
                _BLOCK_WORKSPACE_REFER_ANOTHER_VENDOR_: 'Refer this lead to another vendor?',
                _BLOCK_WORKSPACE_REFER_ANOTHER_VENDOR_SUB_: '(We\'ll archive this workspace afterwards)',
                _TOOL_TIP_: 'Here\'s a chance to brighten up someone\'s day!',
                _YES_BUTTON_: 'YES',
                _SEND_REFERAL_: 'YES, send referral',
                _NO_REFERRAL_: 'NO, just archive',
                _VIEW_MY_REFERRALS_: 'VIEW MY REFERRALS',
                _MY_REFERRALS_: 'MY REFERRALS',
                _NO_REFERRALS_: 'It looks like you don’t have any referrals yet.',
                _NO_REFERRALS_1_: 'Start sending and tracking your referrals.',
                _NO_REFERRALS_2_: 'Click on the ',
                _NO_REFERRALS_3_: 'refer ',
                _NO_REFERRALS_4_: 'button in the pipeline and we’ll keep track of everyone you send a referral to.',
                _HAS_REFERRALS_1_: 'We’re busy working on a place to view and manage your referrals.',
                _HAS_REFERRALS_2_: 'In the meantime we’ll still keep track of all your referrals so keep sharing the love!'
            },
            SETTINGS: {
                _TITLE_: 'Customize Your Project Pipeline',
                _SUBTITLE_: 'Add, edit, or remove stages to make the Pipeline fit your process.',
                _EXPLANATION_: 'Add up to 10 custom stages. Drag and drop to create your preferred order.',
                _ADD_STAGE_TITLE_: 'Add a new stage below by clicking “ADD STAGE”',
                _STAGE_COMPLETE_: 'That\'s a great place for your new stage!',
                _DRAG_STAGE_: 'Give your stage a name, and drag to desired position',
                _ADD_STAGE_: 'Add Stage',
                _ERROR_UPDATING_NEW_PIPELINE_STAGES_: 'Oops, something went wrong. Please try again',
                _SURE_HEADER_: 'Are you sure?',
                _SURE_BLURB_: 'If you delete this stage, all workspaces within this stage will be bumped to',
                _NO_MORE_STAGES_: 'Good job. You\'ve added all of your custom stages.',
                _INTRO_TITLE_: 'Edit, add and rename your Pipeline stages',
                _INTRO_BLURB_: 'Access your stages in your pipeline by clicking the "settings" icon.',
                _INTRO_BLURB_TWO_: 'Drag, edit and rename as you please.',
                _UNTITLED_STAGE_NAME_: 'UNTITLED',
                TOOLTIP_COPY: {
                    _INQUIRY_: "New inquiries from your contact form will automatically enter this stage. Projects that you add yourself will start here as well. Locked because a smart automation is involved.",
                    _FOLLOW_UP_: "Once you have engaged with a client’s inquiry, the Project automatically enters this stage. Locked because a smart automation is involved.",
                    _PROPOSAL_SENT_: "When you send an Invoice or Proposal to a client, the project will automatically move to this stage. Locked because a smart automation is involved.",
                    _PROPOSAL_SIGNED_: "Projects will automatically move to this stage when your client signs a contract, but hasn’t paid yet.  Locked because a smart automation is involved.",
                    _PROPOSAL_: "When you send an Invoice or Proposal to a client, the project will automatically move to this stage. Locked because a smart automation is involved.",
                    _RETAINER_PAID_: "Projects will automatically move to this stage once you've received your first payment. Locked because a smart automation is involved.",
                    _PAYMENT_RECEIVED_: "Projects will automatically move to this stage once you've received your first payment. Locked because a smart automation is involved.",
                    _PLANNING_: "Projects will automatically move to this stage once clients makes the second payment and stay here until the Project date. Locked because a smart automation is involved.",
                    _IN_PROGRESS_: "Projects will automatically move to this stage once clients makes the second payment and stay here until the Project date. Locked because a smart automation is involved.",
                    _COMPLETED_: "Projects move here when you manually update their status. This is a good stage for projects that are done, but you’re not ready to archive.",
                    _DEFAULT_: "Projects enter this stage when you manually update their status.This is a custom stage: edit name, re-order, or delete."
                },

            },

            BANNER: {
                _SCHEDULE_DEMO_: 'Schedule a walkthrough',
                _WITH_PRODUCT_SPECIALIST_: 'with a HoneyBook Product Specialist'
            },
            STRIPE_BANNER: {
                _FIRST_NOTICE_: 'Keep your money safe: please <a href="https://www.honeybook.com/app/settings/company/bank" target="_blank">update your bank information</a> before December 31st, 2021 in order to continue to receive deposits to your bank account as usual.<br>Reach out to us if you have any questions.',
                _SECOND_NOTICE_: 'Staying up to date with the latest financial regulations is one of the ways HoneyBook helps you protect yourself. To continue to receive deposits to your bank account, please <a href="https://www.honeybook.com/app/settings/company/bank" target="_blank">update your bank information</a> before December 31st, 2021. Reach out to us if you have any questions.',
                _LAST_CALL_: 'Act now! Update your <a href="https://www.honeybook.com/app/settings/company/bank" target="_blank">bank account</a> information. <strong>Deposits to your bank account will be paused soon</strong> and until all account information is up to date.'
            },

            //            _BLOCK_WORKSPACE_EMAIL_MESSAGE_: 'Would you like to notify the workspace member(s) that all shared files are now "read-only"?'
            EMPTY_STATES: {
                INQUIRY: {
                    _INFO_: 'Easily keep track of any new inquiries you receive from your contact form.',
                    _HEADER_: 'Track your leads',
                    _TEXT_: 'Know where your leads are at all times, so you can keep them moving through your process and get them booked. ✌️',
                    _PRIMARY_CTA_: 'ADD NEW INQUIRY',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                FOLLOW_UP: {
                    _INFO_: 'Once you have engaged with your client you can manage your early communications and discover your client needs.',
                    _HEADER_: 'Send the ultimate follow-up',
                    _TEXT_: 'Send potential clients a beautifully branded Brochure that breaks down your services and pricing. 📌',
                    _PRIMARY_CTA_: 'SEND YOUR PRICING & SERVICES',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                PROPOSAL_SENT: {
                    _INFO_: 'Once you have sent your proposal your client will be moved to this stage. We\'ll let you know as soon as they open and sign it.',
                    _HEADER_: 'Book faster with a Proposal',
                    _TEXT_: 'Our Proposal is a magical combo of invoice and contract. That means no back-and-forth to make it official. 👌',
                    _PRIMARY_CTA_: 'SEND A PROPOSAL',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',

                },
                PROPOSAL_SIGNED: {
                    _INFO_: 'Once your proposal has been signed, it will hold in this stage until you have received your first payment.',
                    _HEADER_: 'Make it official with a Contract',
                    _TEXT_: 'Autofill fields for fast creation and e-signatures make it easy for everyone to seal the deal. 🤝',
                    _PRIMARY_CTA_: 'SEND A CONTRACT',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                CONTRACT_SIGNED: {
                    _INFO_: 'Once your proposal has been signed, it will hold in this stage until you have received your first payment.',
                    _HEADER_: 'Make it official with a contract',
                    _TEXT_: 'Autofill fields for fast creation and e-signatures make it easy for everyone to seal the deal. 🤝',
                    _PRIMARY_CTA_: 'SEND A CONTRACT',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                RETAINER_PAID: {
                    _INFO_: 'You\'ve got paid! HoneyBook automatically moves your booked projects into this stage once you\'ve received your first payment.',
                    _HEADER_: 'Happiness is getting paid',
                    _TEXT_: 'When your client pays, your project moves here—the happy place. 🤑 If they lag, we automatically remind them. ',
                    _PRIMARY_CTA_: 'SET PAYMENT PREFERENCES',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                PLANNING: {
                    _INFO_: 'You\'re all set! Your client is booked. You can invite other vendors and collaborate with them.',
                    _HEADER_: 'Let the planning begin',
                    _TEXT_: 'Once your client is booked, it’s time to get to work and focus on what you love. Set up your tasks to keep on track. 👊',
                    _PRIMARY_CTA_: 'CREATE A TASK',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                CLOSED: {
                    _INFO_: 'Congrats! Another success in the books. This represents the total number of projects you\'ve booked that have reached their project date.',
                    _HEADER_: 'Mission accomplished',
                    _TEXT_: 'Pat yourself on the back and see how your latest project impacts your business in reports. 👏',
                    _PRIMARY_CTA_: 'VISIT REPORTS',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                ARCHIVED: {
                    _INFO_: 'All archived projects are here. They will be hidden from your main view but you will always have access to them here.',
                    _HEADER_: 'Safe, sound and archived',
                    _TEXT_: 'We never delete projects, so keep them safe and sound in our digital filing cabinet.  🤓',
                    _PRIMARY_CTA_: 'CREATE YOUR NEXT PROJECT',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                ALL: {
                    _INFO_: 'Track all your clients here — keep up with notifications and statuses from inquiry to booked.',
                    _HEADER_: 'Control your Projects',
                    _TEXT_: 'The Pipeline breaks down your projects into digestible steps. It tracks what’s happening, so nothing falls through the cracks. 😎',
                    _PRIMARY_CTA_: 'CREATE A NEW PROJECT',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                DEFAULT: {
                    _HEADER_: 'Build your Pipeline your way',
                    _TEXT_: 'The Pipeline works the way you work with your clients. Customize it according to the specific needs of each project. 🙌',
                    _PRIMARY_CTA_: 'CUSTOMIZE YOUR PIPELINE',
                    _SECONDARY_CTA_: 'SEE WHAT IT\'S LIKE',
                },
                ERROR: {
                    _HEADER_: 'Error loading the pipeline',
                    _TEXT_: 'A thousand apologies.\nPlease try to reload or contact support.',
                    _PRIMARY_CTA_: 'RELOAD',
                },
                SECOND_EVENT: {
                    _CTA_: 'CREATE YOUR NEXT PROJECT',
                }
            },
            // Contact form awareness
            // TODO: remove when this feature is removed
            CF_AWARENESS: {
                _PROTIP_TEXT_: "Connect the Contact Form with your website to",
                _PROTIP_LINK_TEXT_: "Capture your Leads",
                _EMPTY_STATE_HEADER_: "Organize your leads",
                _EMPTY_STATE_TEXT_: "Easily keep track of Inquiries! Add them manually, or take advantage of our new Contact Form to funnel leads from your website & social channels right into HoneyBook.",
                _EMPTY_STATE_SECONDARY_CTA_: "SETUP YOUR CONTACT FORM",
            },
            ACTIONS_POPOVER: {
                _LEARN_MORE_: "Learn more",
                STAGE: {
                    _TITLE_: "Move project down the pipeline",
                    _TITLE_BULK_: "Move projects down the pipeline",
                    _SUBTITLE_: "Select pipeline stage:",
                    _CTA_: "MOVE",
                    _TOOLTIP_TITLE_: "Move Stage",
                },
                ARCHIVE: {
                    _TITLE_: "Archive this project",
                    _TITLE_BULK_: "Archive these projects",
                    _SUBTITLE_: "Archive reason:",
                    _EXPLANATION_: "Clicking Archive will move the project{{plural}} to the archived pipeline stage",
                    _CTA_: "ARCHIVE",
                    _TOOLTIP_TITLE_: "Archive"
                },
                EMAIL: {
                    _TOOLTIP_TITLE_: "Send email"
                }
            },
            POPUPS: {
                ARCHIVE: {
                    _MESSAGE_: "Are you sure you want to archive {{project_count}} project{{plural}}?",
                    _CONFIRM_: "YES, ARCHIVE",
                    _CANCEL_: "CANCEL"
                },
                MOVE: {
                    _MESSAGE_: "Are you sure you want to move {{project_count}} project{{plural}} to the {{stageName}} stage?",
                    _CONFIRM_: "YES, MOVE",
                    _CANCEL_: "CANCEL"
                }
            },
            TOASTS: {
                _ARCHIVE_: "Project successfully archived",
                _DELETE_: "Project successfully deleted",
                _DELETE_PROJECT_: '{{project_name}} deleted successfully',
                _MOVE_STAGE_: "Project succesfully moved",
                _BULK_ARCHIVE_: "{{count}} project{{plural}} successfully archived",
                _BULK_DELETE_: "{{count}} project{{plural}} successfully deleted",
                _BULK_MOVE_: "{{count}} project{{plural}} successfully moved to {{stageName}} stage"
            },
            INVOICE_BANNER: {
                _TITLE_: 'Get paid 3x faster—send an invoice with your contract',
                _TEXT_: "Dramatically reduce the back-and-forth. Send your client an invoice and they'll get automatic payment reminders.\nYou're left free to focus on your work, without the need for awkward conversations.",
                _PRIMARY_CTA_: 'Create an Invoice'
            }
        },
        BROCHURE_QUICKSEND: {
            _WELCOME_: 'Welcome to Quick Send!',
            _CHOOSE_BROCHURE_TEMPLATE_: 'Choose your Brochure Template',
            _CHOOSE_EMAIL_TEMPLATE_: 'Choose your Email Template',
            _PREVIEW_FIRST_: 'Preview First',
            _CREATE_A_BROCHURE_MSG_: 'Create a Brochure template to take advantage of Quick Send!',
            _CREATE_A_BROCHURE_BTN_: 'Create a Brochure',
            _NO_USERS_TO_SEND_TO_ERROR: 'In order to send a brochure you must have at least one other participant on that workspace'


        },
        PREFERRED_VENDOR: {
            SMOKE_TEST_MODAL: {
                _HEADER_WITH_PREFERRED_VENDOR_LIST_: 'We\'re busy working on a way to share your preferred vendors',
                _SUBHEADER_WITH_PREFERRED_VENDOR_LIST_: 'In the meantime feel free to continue adding your preferred vendors in the',
                _SUBHEADER_WITH_PREFERRED_VENDOR_LIST_ONE_: 'and we\'ll let you know when you can start sharing them with your clients.',
                _HEADER_WITHOUT_PREFERRED_VENDOR_LIST_: 'Create a Preferred Vendor List',
                _SUBHEADER_WITHOUT_PREFERRED_VENDOR_LIST_: 'Keep your favorite vendors organized and quickly share them with your clients.',
                _SUBHEADER_WITHOUT_PREFERRED_VENDOR_LIST_ONE_: 'to go to your preferred vendors page to get started.',
                _CREATE_MY_LIST_: 'create my list',
                _CLICK_TO_CREATE_MY_LIST_: 'Click on Create my list',
                _CONNECTIONS_PAGE_: 'Connections Page'
            }
        },
        ONBOARDING: {
            PROGRESS_BAR: {
                _EXIT_: 'Exit intro tour',
                _ABOUT_: 'create account',
                _FIRST_PROJECT_: 'create project',
                _GET_PAID_: 'send proposal'
            },
            WELCOME_MODAL: {
                _HEADER_: 'Enhance your client experience',
                _BLURB_: 'Let me show you how to easily send an invoice and get paid faster.',
                _BLURB_TWO_: 'Let me show you how to easily send a proposal (contract + invoice) and get paid faster. Watch the 40 second clip below to see how HoneyBook will enhance your clients\' experience.',
                _BLURB_TWO_INVOICE_: 'Watch the 40 second clip below to see how HoneyBook will enhance your clients\' experience.',
                _BLURB_PROPOSAL_: 'Hi, I\'m Alexis! Let me show you how to easily send a proposal (contract + invoice) and get paid faster.',
                _BLURB_INVOICE_: 'Hi, I\'m Alexis! Let me show you how to easily send an invoice and get paid faster.',
                _INFO_FIRST_FILE_: 'Ready to edit your branded invoice?',
                _ACTION_BUTTON_FIRST_FILE_: 'Edit my First Invoice',
                _EXPLORE_: 'Explore HoneyBook on your own',
                _EXPLORE_BUTTON_TEXT_: 'Explore HoneyBook'
            },
            ADD_USER_MODAL: {
                _NO_EMAILS_: 'Don\'t worry, no emails will be sent at this point.',
                _ADD_CLIENT_TITLE_: 'Add the client to this project.',
                _ADD_CLIENT_SUBTITLE_: 'Enter the client\'s email and click "ADD"',
                _EMAIL_PLACEHOLDER_: 'Enter client email address'
            },
            PAYMENT: {
                _HEADER_: 'Select a Membership Plan',
                _HEADER_WITHOUT_FM_: 'Become a Member',
                _SUBTITLE_: 'Choose a membership and enjoy all of HoneyBook with a Money Back Guarantee',
                _DISCLAIMER_: 'If you\'re not completely happy with HoneyBook, we\'ll refund your money within 60 days of sign-up',
                _LIFE_TIME_: '${{coastInDollars}} one-time',
                _LIFE_TIME_SUBTITLE_: 'founding membership',
                _LIFE_TIME_DETAILS_: 'Pay once for a lifetime of benefits',
                _SUBSCRIPTION_: '${{coastInDollars}} per month',
                _SUBSCRIPTION_SUBTITLE_: 'monthly membership',
                _SUBSCRIPTION_DETAILS_: 'Pay as you go and cancel anytime',
                _DELAYED_PAYMENT_PREFIX_: 'you get ',
                _DELAYED_PAYMENT_SUFFIX_: 'month free',
                _PAYMENT_INFO_: 'payment information',
                _EDIT_PAYMENT_INFO_: 'edit payment information',
                _SUBMIT_PAYMENT_: 'Pay ${{ amount }}',
                _SAVE_PAYMENT_: 'SAVE PAYMENT METHOD',
                _SAVE_AND_PAY_: 'SAVE CARD AND PAY',
                _FOUNDING_MEMBERSHIP_: 'Founding Membership',
                _MONTHLY_MEMBERSHIP_: 'Monthly Membership',
                _MEMBER_SINCE_: 'You\'ve been a member since',
                _NEXT_PAYMENT_: 'Your subscription will automatically renew on:',
                _NEXT_CHARGE_GENERIC_: 'You\'ll be charged ${{costInDollars}}',
                _NEXT_BILLING_AMOUNT_: '${{costInDollars}}/{{recurrency}}',
                _DOWNLOAD_BILLING_: 'download billing history',
                _LOVING_HB_: 'Loving HoneyBook',
                _RENEWAL_ONE_GENERAL_: 'Pay a one time ${{costInDollars}} upgrade,',
                _RENEWAL_TWO_: 'and never have to pay a monthly fee again!',
                _UPGRADE_: 'Upgrade today'
            },
            PROFILE_DATA: {
                _DIGIT_LENGTH_: 'Oops, looks like you may have entered an invalid phone number.'
            },

            SENT_FIRST_FILE: {
                _TITLE_: 'Way to go!',
                _TEXT_: '',
                _ACTION_: 'Go to workspace'
            },

            OPTIONS_POPOVER: {
                _PROJECT_: 'Creating your first project',
                _LEAD_: 'Create a new project',
                _PROPOSAL_: 'Creating your first proposal',
                _CUSTOMIZE_: 'Customizing your account',
                _BRING_: 'Bring in existing lead',
                _GUIDE_: 'setup guide',
                _WATCH_VIDEO_: 'Watch intro clip',
                _EXAMPLE_PROPOSAL_: 'Experience the client side',
                _WEBINAR_: 'Join a HoneyBook Webinar',
                _WALKTHROUGH_: 'Join a Free Walkthrough',
                _BROCHURE_VIDEO_TITLE_: 'Create your brochure',
                _SETUP_ACCOUNT_: 'Upload your materials',
                _AVATAR_: 'Upload your Avatar',
                _BRAND_: 'Set up your brand',
                _INVOICE_: 'Create first invoice',
                _CUSTOMIZE_TEMPLATES_: 'Customize templates',
            },
            CHANGE_PLAN: {
                TEXT: {
                    _ONE_TIME_REGULAR_: 'One-time payment of ',
                    _ONE_TIME_VENUE_: 'One-time payment of $1500, ',
                    _NEVER_: 'never',
                    _MONTHLY_FEE_: ' pay a monthly fee!',
                    _FOUNDING_MEMBERSHIP_: 'Founding Membership includes:',
                    _SETUP_CUSTOMIZING_: 'Account setup and full customization',
                    _TRAINING_: '1:1 training anytime',
                    _PERKS_: 'Exclusive HoneyPerks and offers from partners',
                    _CONCIERGE_: 'Your own dedicated concierge',
                    _FEE_AMOUNT_: 'you will be charged $500',
                    _REDUCED_LIFETIME_MEMBERSHIP_: 'we\'ll apply your monthly payments toward your membership,<br>and you will only be charged ${{amount}}'
                },
                BUTTON: {
                    _UPGRADE_PLAN_: 'Upgrade Membership'
                }
            },
            GUIDANCE: {
                _HEADER_: 'Customize Your Brand',
                _SUB_HEADER_: 'Our members love our beautiful digital files and custom branding. Let\'s start with an invoice &mdash; upload some images to make the invoices you send to your clients reflect your brand.',
                _IMG_SPECS_: 'For best results, upload a png or jpg 1200px wide.',
                _IMG_PROFILE_SPECS_: 'For best results, upload a png or jpg at least 180px square.',
                _UPLOADING_: 'Uploading',
                ERRORS: {
                    _ERROR_UPLOADING_FILE_: 'Oops, there seems to be a problem with the file upload. Please try again.',
                }
            },

            ONBOARDING_BANNER: {
                _VIEW_EXAMPLE_PROPOSAL_: {
                    _COPY_: "Welcome to business management, reinvented. First, check out our example proposal and get a feel for how clients will see you.",
                    _CTA_: "See the client experience"
                },
                _VISIT_BRANDING_PAGE_: {
                    _COPY_: "Now, let’s customize HoneyBook to your brand, so that every email, invoice, and proposal showcases your style and values.",
                    _CTA_: "Add my branding"
                },
                _CREATE_EXAMPLE_PROPOSAL_: {
                    _COPY_: "Looking good! We’re focused on getting you booked more, and with less legwork. To get started, simply send your first proposal.",
                    _CTA_: "Create a proposal"
                },
                _COMPLETE_: {
                    _COPY_: "You’re set up for success{{ firstName }}! Get ready to hit all of your business goals.",
                    _CTA_: "Upgrade Now"
                },
            },

            SETUP_GUIDE_STREAMLINED: {
                HEADER: {
                    _TITLE_: '{{ firstNamePossessive }} Setup Guide',
                    _SUBTITLE_: 'Let’s get {{ companyName ? companyName : "you" }} set up for success.',
                },

                TRIAL_TEXT: {
                    _EXPIRED_: 'Trial expired',
                    _X_DAYS_LEFT_: "{{remainingTrialDays}} day{{ ( remainingTrialDays !== 1) ? 's' : ''}} left in trial",
                    _CHECK_OUT_: "Check out our",
                    _OUR_PRICING_: 'pricing',
                    _YOU_GOT_: "You've got a",
                    _X_DISCOUNT_: "{{discount}}% discount",
                    _FROM_REFERRER_: "from {{referrer}}",
                    _GET_STARTED_: "Time to get the party started,",
                    _UPGRADE_NOW_: "upgrade now."
                },

                TOURS: {
                    _TITLE_: 'Product tours for you'
                },

                MAIN_CONTENT: {
                    BECOME_MEMBER: {
                        _HEADLINE_: 'Almost there! Join today.',
                        _BODY_COPY_: 'Take your business to the next level.',
                        _CTA_: 'View pricing',
                        _SECONDARY_CTA_: 'Get free account setup',
                    },
                    CREATE_PROJECT: {
                        _HEADLINE_: 'Way to go! You’re {{ progressPercentage }}% set up.',
                        _BODY_COPY_: 'Create a project — this is where you’ll work with each client.',
                        _CTA_: 'Create new project',
                        _SECONDARY_CTA_: '',
                    },
                    COMPANY_INFO: {
                        _HEADLINE_: 'Create an account',
                        _BODY_COPY_: '🙌 Done! Let’s start streamlining your business.',
                        _CTA_: '',
                        _SECONDARY_CTA_: '',
                    },
                    CUSTOMIZE_BRAND: {
                        _HEADLINE_: 'Set up your brand',
                        _BODY_COPY_: 'Logo, color and imagery will appear on all templates and client communications.',
                        _CTA_: 'Add my brand',
                        _HOVER_CTA_: 'Visit brand',
                        _SECONDARY_CTA_: 'Watch video',
                    },
                    INVOICE_TEMPLATE: {
                        _HEADLINE_: 'Try out an invoice',
                        _BODY_COPY_: 'Add your main services to see what your invoice could look like.',
                        _CTA_: 'Go to invoice',
                        _HOVER_CTA_: 'Template',
                        _SECONDARY_CTA_: 'Watch video',
                    },
                    CLIENT_EXPERIENCE: {
                        _HEADLINE_: 'Preview client experience',
                        _BODY_COPY_PROPOSAL_: 'See what your invoice (plus a contract) would look like from a client’s perspective.',
                        _BODY_COPY_INVOICE_: 'See what your invoice would look like from a client’s perspective.',
                        _CTA_: 'Enter client view',
                        _HOVER_CTA_: 'Enter client view',
                        _SECONDARY_CTA_: 'Watch video',
                    },
                },

                SECONDARY_CONTENT: {
                    _TITLE_: 'Product tours for you:',
                    _PIPELINE_: 'Learn about project tracking',
                    _BUILD_OUT_ACCOUNT_: 'View a built out account',
                    _ACCOUNT_SETUP_: 'Free account migration',
                    _CLIENTBOT_: 'Dive into a sample project',
                },

                HOME_TITLES: {
                    _CUSTOMIZE_BRAND_: 'Customize company brand',
                    _PIPELINE_: 'Learn to track leads and projects',
                    _CLIENT_BOT_: 'Dive into a client project'
                }

            },

            UPGRADE_TEXT: {
                _DISCOUNT_AMOUNT_: "You've got a <a href='/app/settings/company/subscription'> discount </a> from {{ from_name }}",
                _DISCOUNT_PERCENT_: "You've got a <a href='/app/settings/company/subscription'> {{ discount_percent }}% discount </a> from {{ from_name }}",
                _CHECK_PRICING_: "Check out our <a href='/app/settings/company/subscription'> pricing </a>",
                _UPGRADE_NOW_: "Time to get the party started, <a href='/app/settings/company/subscription'> upgrade now. </a>",
            },

            SETUP_GUIDE_MODAL: {
                HEADER: {
                    _TITLE_: 'The Setup Guide',
                    _SUBTITLE_: 'Everything you need to get started',
                    _TITLE_LAST_STEP_: 'One last step!',
                    _SUBTITLE_LAST_STEP_: 'Your business is about to take off',
                    _CTA_LAST_STEP_: 'Upgrade Now',
                    _SEE_PRICING_: 'see pricing',
                    _ALL_DONE_: 'Hooray! All done!',
                },

                TRIAL_TEXT: {
                    _EXPIRED_: 'Trial expired',
                    _X_DAYS_LEFT_: "{{remainingTrialDays}} day{{ ( remainingTrialDays !== 1) ? 's' : ''}} left in trial",
                    _CHECK_OUT_: "Check out our",
                    _OUR_PRICING_: 'pricing',
                    _YOU_GOT_: "You've got a",
                    _X_DISCOUNT_: "{{discount}}% discount",
                    _FROM_REFERRER_: "from {{referrer}}",
                    _GET_STARTED_: "Time to get the party started,",
                    _UPGRADE_NOW_: "upgrade now."
                },
                EXPLORE: {
                    _TITLE_: 'Explore',
                    ITEMS: {
                        INVOICES: {
                            _TITLE_: 'Invoices',
                            _SUBTITLE_: 'Learn how to book\nclients faster',
                        },
                        CONTRACTS: {
                            _TITLE_: 'Contracts',
                            _SUBTITLE_: 'Check out our\nbulletproof contracts',
                        },
                        ALLINONE: {
                            _TITLE_: 'All-in-one projects',
                            _SUBTITLE_: 'Manage your project\nfrom one place',
                        },
                        BOOKING: {
                            _TITLE_: 'Professional booking',
                            _SUBTITLE_: 'Experience a streamlined\nclient process',
                        },
                        CLIENTSIDE: {
                            _TITLE_: 'The client side',
                            _SUBTITLE_: 'View a superb client\nexperience',
                        },
                        BUILTOUT: {
                            _TITLE_: 'A built-out account',
                            _SUBTITLE_: 'See what your account\ncould be',
                        }
                    }
                },

                CUSTOMIZE: {
                    _TITLE_: 'Customize',
                    ITEMS: {
                        _BRAND_: 'Customize company brand',
                        _SERVICES_: 'Add your services & pricing',
                        _PROCESS_: 'Personalize your client process',
                        _GOOGLE_: 'Connect Google Calendar & Gmail',
                        _SETTINGS_: 'Update settings & preferences',
                        _TEMPLATES_: 'Set up templates',
                    }
                },

                BOTTOM_LINKS: {
                    _WEBINAR_: 'Join a webinar',
                    _HELP_CENTER_: 'Visit our Help Center',
                    _VIDEOS_: 'Watch intro videos',
                },

                ACCOUNT_SETUP_TILE_PROMPT: {
                    SUBSCRIPTION: {
                        _TITLE_: '🎁 Limited Time Offer: Free Account Migration',
                        _BODY_: 'Let us do the heavy lifting for you. We will build out your account using your personal pricing, templates, and booking materials when you upgrade right now!'
                    }
                }


            },

            MOBILE_GUIDE: {
                ACCOUNT_SETUP: {
                    COME_AS_YOU_ARE: {
                        _SUBTITLE_: "",
                        _TITLE_: "Your brand, your magic",
                        _BODY_: "Your brand, process, and style are part of who you are. On HoneyBook we work the magic and let your brand and business shine through.",
                        _CTA_: "NEXT"
                    },
                    CONQUER_THE_CHAOS: {
                        _SUBTITLE_: "Hate repetitive work?",
                        _TITLE_: "Automate!",
                        _BODY_: "Meet your new superpower — automation. We’re talking immediate client responses, automatic payment reminders, and all the things you would do if there were 10 of you.",
                        _CTA_: "NEXT"
                    },
                    ULTIMATE_CLIENT_EXPERIENCE: {
                        _SUBTITLE_: "Your clients",
                        _TITLE_: "Will love you",
                        _BODY_: "Easily build branded, personalized experiences for each of your clients. Then give them the flexibility to review docs, sign, and pay from their computer, phone, or in person.",
                        _CTA_: "NEXT"
                    },
                    SET_UP_FAST: {
                        _SUBTITLE_: "Let’s get you",
                        _TITLE_: "Set up fast!",
                        _BODY_: "Setting up your business requires much less time because:",
                        _SUB_HEADER_: "We offer a free service to build your account",
                        _CTA_: "NEXT"
                    },
                    FINISH_ACCOUNT_SETUP: {
                        _SUBTITLE_: "",
                        _TITLE_: "Great!",
                        _BODY_: "Check your inbox for further instructions on how we can do the hard work for you! In the meantime, how about exploring our system?",
                        _CTA_: "DONE"
                    },
                },
            },

            SETUP_GUIDE: {
                _MOBILE_WARNING_MESSAGE_: "This option is available only on a desktop computer.\nDon’t forget to try HoneyBook on desktop: www.honeybook.com",
            },
            BOT: {
                ACTIONS: {
                    SEND_BROCHURE: {
                        _TITLE_: "Start booking Eliot McBot",
                        _DESCRIPTION_: "Imagine Eliot just checked out your site, fell in love and made an inquiry. Now it’s time to respond with your services and pricing.",
                        _PRIMARY_CTA_: "Reply with brochure",
                        _SECONDARY_CTA_: "Learn more about inquiries"
                    },
                    SELECT_BROCHURE_PACKAGE: {
                        _TITLE_: "Brochure sent",
                        _DESCRIPTION_: "Eliot was impressed by your super-slick, branded Brochure. Now he’s going to choose one of your services.",
                        _PRIMARY_CTA_: "See what eliot picks",
                        _SECONDARY_CTA_: "Learn more about Brochures"
                    },
                    SEND_PROPOSAL: {
                        _TITLE_: "Eliot picked a package",
                        _DESCRIPTION_: "Sweet! Eliot wants to work with you. Now it’s time to send him a Proposal, which is a combo of invoice and contract.",
                        _PRIMARY_CTA_: "Send proposal",
                        _SECONDARY_CTA_: "How did Eliot pick the package?"
                    },
                    CLIENT_SIGN_AND_PAY: {
                        _TITLE_: "Proposal sent 👍",
                        _DESCRIPTION_: "Done! Now Eliot just e-signs and pays. FYI: We accept digital payments, so getting paid is super easy. ",
                        _PRIMARY_CTA_: "Show me the money",
                        _SECONDARY_CTA_: "Learn more about Proposals"
                    },
                    WHATS_NEXT: {
                        _TITLE_: "You’re booked💰",
                        _DESCRIPTION_: "High five! You’re booked and you made Eliot McBot shed a tear of joy with a beautiful client experience. ",
                        _PRIMARY_CTA_: "What's next?",
                        _SECONDARY_CTA_: "Learn more about getting paid"
                    }
                },
                RECAP: {
                    _TITLE_: 'And now, some action items for you.',
                    _CREATE_PROJECT_: 'create project',
                    _GO_TO_SETUP_GUIDE_: 'go to setup guide',
                    _CONTACT_FORM_: 'Set up your Contact Form',
                    _BROCHURE_: 'Create your first Brochure',
                    _TEMPLATES_: 'Explore our ready-to-send templates',
                    _BANK_ACCOUNT_: 'Add your Bank Account'
                },
                VIDEO_MODAL: {
                    _CTA_NEXT_: 'next',
                    _CTA_DONE_: 'got it',
                    _CTA_BACK_: 'back',
                    INQUIRIES: {
                        _TITLE_: "How do inquiries work?",
                        _VIDEO_1_TITLE_: 'Inquiries are captured by a customizable contact form.',
                        _VIDEO_2_TITLE_: 'Easily add your contact form to your website.',
                        _VIDEO_3_TITLE_: 'Inquiries automatically go from your website into HoneyBook.'
                    },
                    BROCHURES: {
                        _TITLE_: "How do brochures work?",
                        _VIDEO_1_TITLE_: 'Create a brochure from scratch or start from a template.',
                        _VIDEO_2_TITLE_: 'Upload your brand assets, add your services and pricing.',
                        _VIDEO_3_TITLE_: 'Send a message to your client and attach your brochure as a file.'
                    },
                    PACKAGES: {
                        _TITLE_: "How does your client pick a package?",
                        _VIDEO_1_TITLE_: 'Your client will get an email with a link that takes them to the brochure file.',
                        _VIDEO_2_TITLE_: 'They click into the brochure to browse services and pick their package.'
                    },
                    PROPOSALS: {
                        _TITLE_: "How do proposals work?",
                        _VIDEO_1_TITLE_: 'The proposal combines your contract and payment.',
                        _VIDEO_2_TITLE_: 'Clients can review and sign from one place.',
                        _VIDEO_3_TITLE_: 'And clients can pay with one click from one place.'
                    },
                    GET_PAID: {
                        _TITLE_: "How to get paid?",
                        _VIDEO_1_TITLE_: 'Add bank account info, to receive payments into your HoneyBook account.',
                        _VIDEO_2_TITLE_: 'Your clients can choose to pay by credit card or bank transfer',
                        _VIDEO_3_TITLE_: 'Cha-ching! You’ll find out in real time when payments are made.'
                    }
                }
            }
        },

        VENDOR_RECOMMENDATIONS: {
            _TITLE_: 'Vendor Recommendations',
            EDIT: {
                _GET_STARTED_: 'GET STARTED',
                _INSTRUCTIONS_: 'Your Preferred Vendors are shown below. Select vendors to recommend to your client.',
                _PROMO_HEADER1_: "Because your clients deserve to",
                _PROMO_HEADER2_: "work with the best",
                _PROMO_BODY_: "Beautiful customized recommendations for every client",
                _ADD_VENDORS_: 'add vendors',
                _UPLOAD_FILE_: 'upload file',
                _USE_EXISTING_: 'Use an existing preferred vendor list',
                _CREATE_NEW_LIST_: 'Create new list of preferred vendors',
                _INSTRUCTION_DETAILS_: 'All vendors are selected by default. Adding vendors here also adds them to your list of Preferred Vendors (manage them in Connections).',
                _ADD_VENDOR_: 'Add Vendor',
                _PVL_FILES_UPLOADED_: 'Thanks! We\'re processing your file. We\'ll let you know when it\'s ready (typically within 24 hours).',
                _COMPANY_LOGO_: 'Company Logo',
                _ERROR_FETCHING_PVL_: 'Oops, that\'s not right. We failed to fetch the preferred vendors.',
                EMPTY: {
                    _NO_PVL_YET_: 'You don\'t have any Preferred Vendors yet!',
                    _INSTRUCTION_: 'click {{addVendorButton | translate | uppercase}} to get started'
                },
                _UNSAVED_CHANGES_: 'You have unsaved changes in your list. Would you like to save and attach your changes?',
                _NOTIFY_VENDORS_: 'Notify vendors when I refer them',
                _NOTIFY_VENDORS_DESCRIPTION_: 'Notify the vendors on this list they\'ve been referred to a client. This will not share the client name nor project details.'
            },
            ADD: {
                _CANT_ADD_CLIENT_: 'The user {{email}} is defined as a client in the system and thus cannot be marked as a preferred vendor.'
            },
            SHARE: {
                _GIVE_ACCESS_: 'Recommend vendors <strong>after</strong> client books'
            }
        },

        VENDOR_RECOMMENDATIONS_GUIDANCE: {
            _TITLE_: 'Recommended Vendors',
            _LOOKING_FOR_TITLE_: 'I am looking for {{vendorList | hbmarker:"bold"}} for {{evnetName| hbmarker:"bold"}}',
            _REQUEST_SENT_: 'Request sent: {{timeAgo}}',
            _HAVE_A_SAY_: 'Have a say on who you work with',
            _ADD_VENDOR_: 'Add vendor',
            _NEXT_PREVIEW_: 'Next: Preview'
        },

        COMMON_DIRECTIVES: {
            COPY_TO_CLIPBOARD: {
                _SUCCESS_: 'Copied!',
                _ERROR_WIN_: 'Press ctrl+c to copy',
                _ERROR_MAC_: 'Press cmd+c to copy'
            },

            CAROUSEL: {
                _CHANGE_PHOTO_: 'Change photo',
                _CUSTOMIZE_PHOTO_: 'Customize photo'
            }
        },

        ACTIVITY_NOTIFICATIONS: {
            HOT_LEAD: {
                _CVR_: 'Get back to {{clientFullName}}—there\'s a really high chance they\'ll book.',
                _CVR_DATE_: 'Get back to {{clientFullName}}—there\'s a really high chance they\'ll book for {{eventDate}}.',
                _CVR_REFERRAL_: 'Get back to {{clientFullName}}—they\'re a referral with a really high chance of booking.',
                _CVR_REFERRAL_DATE_: 'Get back to {{clientFullName}}—they\'re a referral with a really high chance of booking for {{eventDate}}.',
                _REVENUE_: 'Get back to {{clientFullName}}—they\'re interested in booking a high-value project.',
                _REVENUE_DATE_: 'Get back to {{clientFullName}}—they\'re interested in booking a high-value project for {{eventDate}}.',
                _REVENUE_BUDGET_: 'Get back to {{clientFullName}}—they\'re interested in booking a high-value project budgeted at up to ${{budget}}.',
                _REVENUE_BUDGET_DATE_: 'Get back to {{clientFullName}}—they\'re interested in booking a high-value project budgeted at up to ${{budget}} for {{eventDate}}.',
                _BOTH_: 'Get back to {{clientFullName}}—there\'s a strong chance they\'ll book a high-value project.',
                _BOTH_DATE_: 'Get back to {{clientFullName}}—there\'s a strong chance of a high-value project for {{eventDate}}.',
                _WITH_AI_RESPONSE_: 'A personalized AI reply is ready and waiting to be sent to {{clientFullName}}. There’s a really strong chance they\'ll book.',
                _WITH_AI_RESPONSE_DATE_: 'A personalized AI reply is ready and waiting to be sent to {{clientFullName}}. There’s a really strong chance they\'ll book for {{eventDate}}.'
            },
            _HEADER_TITLE_: 'notifications',
            _CONVERSATIONS_TITLE_: 'conversations',
            _CONVERSATION_TITLE_: '{{conversationTitle}} chat',
            _FEED_MESSAGE_: '{{senderFirstName}}: {{initialText}}',
            _CONVERSATION_MESSAGE_: '{{unreadCount}} unread',
            _CONVERSATION_MESSAGE_EMPTY_: 'no new messages',
            _MARK_ALL_AS_READ_: "MARK ALL AS READ",
            _SEE_ALL_: "ALL",
            _SEE_ALL_camelcase_: "ALL",
            _SEE_UNREAD_: "UNREAD",
            _EMPTY_STATE_ALL_: "You're up to date! You have no unread notifications.",
            _EMPTY_STATE_MARK_AS_READ_: "Your read notifications can be found under",

            _NEW_INQUIRY_WITH_DATE_: 'New inquiry from {{clientName}} for {{eventDate}}',
            _NEW_INQUIRY_WITHOUT_DATE_: 'New inquiry from {{clientName}}',
            _NEW_INQUIRY_WITH_AI_RESPONSE_: 'A personalized AI reply is ready and waiting to be sent to {{clientName}}.',
            _NEW_INQUIRY_WITH_AI_RESPONSE_WITH_DATE_: 'A personalized AI reply is ready and waiting to be sent to {{clientName}} for {{eventDate}}.',
            _NEW_PROMOTION_INQUIRY_: '{{clientFullName}} is interested in purchasing a {{productType}}',
            _PROMOTION_PURCHASED_: 'New {{productType}} has been purchased by {{clientFullName}}',
            _PAYMENT_RECEIVED_WITHOUT_GRATUITY_: '${{paymentAmount}} received on {{fileName}}{{allowInstantDeposit ? ".' +
                ' Instant Deposit eligible!" : ""}}<br>({{paymentCountDescription}})',
            _PAYMENT_RECEIVED_WITH_GRATUITY_: '${{paymentAmount}} (+ ${{paymentGratuity}} gratuity) received on {{fileName}}{{allowInstantDeposit ? ". Instant Deposit eligible!" : ""}}<br>({{paymentCountDescription}})',
            _PAYMENT_PENDING_WITHOUT_GRATUITY_: '${{paymentAmount}} received on {{fileName}} via bank transfer. Payment is currently pending<br>({{paymentCountDescription}})',
            _PAYMENT_PENDING_WITH_GRATUITY_: '${{paymentAmount}} (+ ${{paymentGratuity}} gratuity) received on {{fileName}} via bank transfer. Payment is currently pending<br>({{paymentCountDescription}})',
            _FLOW_PAYMENT_PAID_: '{{payerName}} paid--{{isAch ? (isBankAccountConnected ? "you’ll get the bank transfer within 8 business days." : "add bank info to get your bank transfer within 8 business days.") : (isBankAccountConnected ? (allowInstantDeposit ? "you’ll see the money within 2-3 business days or today with Instant Deposit" : "you\'ll see the money within 2-3 business days.") : "add bank info to get your money within 2-3 business days.")}}<br>' +
                '<strong>Payment {{paymentIndex}} of {{totalPaymentsCount}}: ${{paymentAmount | hblocaleamount}}</strong>',
            _PROJECT_NAME_SUB_TEXT_: 'Project name: {{flowName}}',
            _PROJECT_AND_FLOW_NAME_SUB_TEXT_: '{{projectName}}: {{flowName}}',
            _ACH_SUCCESS_: '{{payerName}}’s transfer was cleared by the bank—you’ll see the money within 2-3 business days.<br>' +
                '<strong>Payment {{paymentIndex}} of {{totalPaymentsCount}}: ${{paymentAmount | hblocaleamount}}</strong>',
            _FLOW_AUTOMATED_PAYMENT_REMINDER_: 'An automated payment reminder was sent to {{clientName}} regarding {{flowName}}',
            _BROCHURE_PACKAGE_SELECTED_: '{{clientFirstName}} selected from {{brochureFileTitle}}',
            _AGREEMENT_SIGNED_: '{{clientFirstName}} signed {{fileName}}. Countersign now.',
            _QUESTIONNAIRE_SUBMITTED_: '{{clientFirstName}} filled {{fileName}}',
            _PROJECT_COMPLETE: 'Enjoyed working with other vendors on {{eventName}}? {{"Invite them"}} to collaborate with you on HoneyBook!',
            _NEW_TRIALER: 'Hooray! {{trialerName}} just joined HoneyBook! {{"Say hello!"}}',
            _EMAIL_MARKED_UNREAD_: 'The email sent on {{dateFileEmailWasSent}} has not been opened. Follow up!',
            _MEETING_TODAY_: 'You have a meeting at {{meetingDateTimeStart}} ({{meetingTitle}})',
            _COUNTERSIGN_AGREEMENT_: 'You have not countersigned {{fileName}}. Countersign now.',
            _NOT_FULLY_SIGNED_AFTER_3_DAYS_: 'Not all parties signed {{fileName}}. Follow up soon.',
            _NOT_FULLY_SIGNED_AFTER_1_WEEK_: 'Not all parties signed {{fileName}}. Follow up now.',
            _PVL_REQUESTED_: '{{clientFirstName}} asked for vendor recommendations. Send them now.',
            _PVL_REQUESTED_REMINDER_: 'Friendly reminder: {{clientFirstName}} asked for vendor recommendations. Send them now.',
            _AUTOMATED_AUTOMATION_INQUIRY_ARRIVED_WITH_DATE_TBD_: 'You currently have an automated automation in place with a date TBD. Any steps that are dependent on a project date will not occur unless a project date has been added.',
            _HYPER_RELEVANT_OPPORTUNITY_: 'New {{opp_type}} near {{location}} for {{vendorType}}',
            _HYPER_RELEVANT_OPPORTUNITY_AGGREGATED_: '{{nOpps}} new opportunities near {{location}} for {{vendorTypes}}',
            _READER_COMMENT_ON_OPPORTUNITY_: '{{firstName}} just commented: "{{comment}}"',
            _READER_COMMENT_ON_OPPORTUNITY_DESCRIPTION_: '{{lookingFor ? lookingFor : "New comment on opportunity you posted" | hbTrunc:70:"ellipsis"}}',
            _READER_COMMENT_ON_OPPORTUNITY_AGGREGATED_: '{{nComments}} new comments for your opportunity',
            _READER_COMMENT_ON_OPPORTUNITY_AGGREGATED_DESCRIPTION_: '{{lookingFor | hbTrunc:60:"ellipsis"}}',
            _AUTHOR_COMMENT_ON_OPPORTUNITY_: '{{lookingFor ? lookingFor : "New comment by author on opportunity" | hbTrunc:70:"ellipsis"}}',
            _AUTHOR_COMMENT_ON_OPPORTUNITY_DESCRIPTION_: 'New comment by author, {{firstName}}: {{comment}}',
            _NETWORK_CONNECTION_REQUEST_: '{{fullName}} wants to connect with you',
            _NETWORK_CONNECTION_APPROVAL_: 'Yay, {{fullName}} just accepted your connection request!',
            _NEW_CALENDLY_MEETING_: "{{clientName}} has scheduled a meeting for {{meetingDate}} through Calendly. Go to meeting.",
            _REFERRAL_AWARENESS_: "Did you know? You can earn extra cash just by sharing HoneyBook with friends!",
            _PREMIUM_REFERRAL_CAMPAIGN_: "You’ve been upgraded to Premium Referral! Give HoneyBook for {{discountAmount}}, earn ${{maxCommissionAmount}}.",
            _REFERRAL_STARTED_TRIAL_: "{{referredUserName}} just started a trial from your referral. Be sure to share your expertise as they get up and running.",
            _REFERRAL_SUBSCRIBED_: "Your referral {{referredUserName}} just subscribed! We’ll send ${{commission_amount}} your way as soon as they activate.",
            _REFERRAL_COMMISSION_PAYOUT_: "Extra cash alert! Your referral earnings for {{referredUserName}} have just been released. Who’s next?",
            _REFERRAL_NOTIFICATIONS_DESCRIPTION_: "HONEYBOOK TEAM",
            _CONNECTION_POSTED_OPPORTUNITY_: 'Hooray! Your connection, {{connectionFullName}}, just posted a new {{oppType}}. Is it your turn to post one?',
            _CLIENT_UPLOAD_ATTACHMENT_: '{{clientFirstName}} has just uploaded an attachment. Go to their project.',
            _AUTO_PAYMENT_UNACCEPTED_CHANGES_: 'Changes in your {{fileType}} need approval by your client or auto-pay will not be processed. Resend the {{fileType}} and notify them to ensure payment',
            _GMAIL_UNAUTHORIZED_: 'There’s an issue connecting with your Gmail account. Go to your settings now to reconnect.',
            _GCAL_REVOKED_: 'There’s an issue connecting with your Google calendar. Go to your calendar settings to reconnect.',
            _NYLAS_CALENDAR_REVOKED_: 'There’s an issue connecting with your integrated calendar. Go to your calendar settings to reconnect.',
            _EMAIL_UNAUTHORIZED_: 'There’s an issue connecting with your integrated email account. Go to your settings now to reconnect.',
            _FB_LEADS_UNAUTHORIZED_: 'There’s an issue connecting with your Facebook Leads integration. Go to your settings now to reconnect.',
            _SESSION_SCHEDULED_: '{{client}} scheduled a session with you.',
            _SESSION_SCHEDULED_WITH_NON_WS_MEMBER_: '{{client}} scheduled a session with a team member, add them to the project to give them access.',
            _SESSION_RESCHEDULED_: '{{client}} has rescheduled their session with you.',
            _SESSION_CANCELED_: '{{client}} has canceled their session with you.',
            _FLOW_VIEWED_: '{{client}} viewed {{flowName}}',
            _FLOW_SUBMITTED_: '{{client}} submitted {{flowName}}',
            _LEAD_FORM_SUBMITTED_: '{{client}} submitted a lead form: {{flowName}}',
            _FLOW_RESUBMITTED_: '{{client}} resubmitted {{flowName}}',
            _FLOW_COMPLETED_: '{{client}} completed {{flowName}}',
            _FLOW_RECOMPLETED_: '{{client}} recompleted {{flowName}}',
            _ADD_YOUR_BANK_ACCOUNT_: 'You need to connect your bank account in order to receive incoming payments',
            _ASK_EMAIL_VERIFICATION_: 'Click this notification and a verification email—valid for 24 hours—will be sent to you.',
            _EXPIRED_CREDIT_CARD_: 'Your account is overdue due to a billing issue. Please update your billing information to avoid cancellation.',
            _ACCOUNT_SETUP_COMPLETED_: 'Your templates have been built and are ready for you! Check them out now.',
            _PAYMENT_WITHOUT_BANK_: 'Cha-Ching! You received your first payment on HoneyBook. Connect your bank account now in order to get paid.',
            _FIRST_FLOW_SENT_HAS_LEGACY_AUTOMATIONS_: 'Replace the templates in your automations with your new smart files templates',
            _FIRST_FLOW_SENT_HAS_LEGACY_AUTOMATIONS_TITLE_: 'Update your Automations',
            _SHARED_FLOW_TEMPLATE_: '{{sender_full_name}} shared a template with you.',
            _SAVED_SHARED_TEMPLATE_: 'Someone just saved your shared template - {{template title}}',
            _EMAIL_DELIVERABILITY_ISSUE_FOUND: 'Email deliverability issues were found, follow the instructions to solve them',
            _EMAIL_DELIVERABILITY_ISSUE_FOUND_SUB_TEXT: 'Company settings',
            _JOHN_BRANCH_TEMPLATES_IMPORTED_: 'Click to access your new templates. You’ll find them in the John Branch folder. Enjoy!',
            _JOHN_BRANCH_TEMPLATES_IMPORTED_SUB_TEXT_: 'Templates',
            _FREELANCERS_UNION_TEMPLATE_IMPORTED_: "<strong>Click to access your contract template</strong>\nGo to My Templates and look in the Favorites folder, or the Contracts folder.",
            _FREELANCERS_UNION_TEMPLATE_IMPORTED_SUB_TEXT_: "Templates"
        },

        OVERVIEW: {
            COMMON: {
                _BUTTON_TEXT_DEFAULT_: 'Start Now',
                _BUTTON_TEXT_COMPLETE_: 'Completed',
                _BUTTON_EXIT_TEXT_: 'Exit Setup Guide'
            },
            TITLE_CARD: {
                _TITLE_: 'SETUP GUIDE',
                _DESCRIPTION_1_OF_2_: 'Welcome to HoneyBook. Here\'s a series of walkthroughs to get you started. If you need further help, click the',
                _DESCRIPTION_2_OF_2_: 'icon at the bottom right corner of your screen and we will answer your questions.'
            },
            CREATE_PROJECT: {
                _TITLE_: 'Create a new project',
                _DESCRIPTION_: 'Have a project or project you are working on? Start here.'
            },
            CUSTOMIZE_ACCOUNT: {
                _TITLE_: 'Customize your account',
                _DESCRIPTION_: 'Create reusable templates, set automation preferences, and update your company information.'
            },
            BRING_IN_LEAD: {
                _TITLE_: 'Bring in an existing lead',
                _DESCRIPTION_: 'Have a client you are already working with? Begin here.'
            },
            CLIENT_SIDE: {
                _TITLE_: 'Experience the client side',
                _DESCRIPTION_: 'See what your client will see on their end.'
            },
            WEBINAR: {
                _TITLE_: 'Join a HoneyBook webinar',
                _DESCRIPTION_: 'Learn everything you can do with HoneyBook in a quick, engaging webinar.'
            },
            INTRO_CLIP: {
                _TITLE_: 'Watch intro clip',
                _DESCRIPTION_: 'Like doing things on your own? Watch the intro clip to get started.'
            }
        },
        NETWORK: {
            _PROJECTS_: 'Projects',
            _FIND_CREATIVES_: 'Find Creatives',
            _CREATIVES_: 'Creatives',
            _CONNECTIONS_: 'Connections',
            _UNABLE_TO_LOAD_MESSAGE_: 'Oops, we\'ve encountered an error. Please try again later.',
            _PROFILE_: 'Profile',
            _ADD_PROJECT_: 'Add Project',
            _LOG_OUT_: 'Log Out',
            _GO_TO_HONEYBOOK_: 'Go to HoneyBook',

            INTRO_MODAL: {
                _WELCOME_: 'Find, connect, and keep in touch with other creatives on the HoneyBook',
                _DESCRIPTION_: 'It looks like you\'re new around here.<br>Let\'s get you quickly set-up.',
                _BUTTON_GO_TO_SETUP_: 'GO TO QUICK SETUP',

                PROFILE: {
                    _WELCOME_: 'Welcome to the new HoneyBook Community!',
                    _DESCRIPTION_: 'This is a private place for creative professionals to connect, reconnect, and collaborate.',
                    _BEFORE_PROFILE_: 'Before we go to {{name | hbmarker:"bold"}}{{"\'s" | hbmarker:"bold"}} profile let\'s get you quickly setup.'
                },
                SELF: {
                    _WELCOME_: 'Welcome to your new profile on the HoneyBook community',
                    _DESCRIPTION_: 'This is a private place for creative professionals to connect, reconnect, and collaborate.',
                    _BEFORE_PROFILE_: 'It looks like you\'re new around here.<br>Let\'s get you quickly set-up.'
                }
            },

            PROFILE: {
                _EDIT_PROFILE_: 'EDIT PROFILE',
                _CONNECT_: 'CONNECT',
                _ACCEPT_: 'ACCEPT',
                _VIEW_WEBSITE_: 'View Website',
                _MESSAGE_: 'MESSAGE',
                _LOOKING_FOR_: 'Looking For:',
                _NEW_PROJECT_BUTTON_: '+ ADD PROJECT',
                _ADD_PROJECT_: 'ADD PROJECT',
                _PUBLISH_PROJECT_BUTTON_: '+ PUBLISH PROJECT',
                _PUBLISH_PROJECT_: 'PUBLISH PROJECT',
                _CHAT_REQUEST_: 'Chat is enabled only for connected users. Please send {{firstName}} a connection request, so you can start chatting.',
                _SEND_REQUEST_: 'SEND REQUEST',
                _CHAT_CONFIRM_REQUEST_: 'Chat is enabled only for connected users. Please accept their connection request, so you can start chatting.',
                _CHAT_PENDING_REQUEST_: 'Chat is enabled only for connected users. You\'ll be able to chat after {{firstName}} accepts your connection request.',

                PROJECT_BLANK_STATE: {
                    _TITLE_: 'Publish your first project',
                    _EXPLANATION_1_: 'Add a project so that others can find your work, show their appreciation, and give you feedback.',
                    _EXPLANATION_2_: 'HINT: Adding a project also makes your profile more discoverable in the HoneyBook Community ;)',
                    _EXPLANATION_3_: 'See an example project from Natalie Franke'
                },
                IMPROVE_BANNER: {
                    _HEADER_: 'You\'re Getting Noticed!',
                    _TEXT_: 'Congratulations! Your profile has gotten {{number_of_views}}',
                    _BUTTON_: 'GET MORE ATTENTION'
                }
            },
            EDIT_PROFILE: {
                _TITLE_: 'Edit Public Profile',
                _BASICS_: 'BASICS',
                _SOCIAL_LINKS_: 'SOCIAL LINKS',
                _YOUR_NAME_: 'Your Name',
                _YOUR_NAME_PLACEHOLDER_: 'e.g. Jane Smith',
                _COMPANY_NAME_: 'Company Name',
                _COMPANY_NAME_PLACEHOLDER_: 'e.g. Acme Photography',
                _COMPANY_WEBSITE_: 'Company Website',
                _COMPANY_WEBSITE_PLACEHOLDER_: 'e.g. http://www.mywebsite.com',
                _LOCATION_: 'Location',
                _VENDOR_TYPE_: 'Industry Type',
                _INSTAGRAM_USERNAME_: 'Instagram Profile URL',
                _INSTAGRAM_USERNAME_PLACEHOLDER_: 'Your Instagram Username',
                _SNAPCHAT_USERNAME_: 'Snapchat Username',
                _SNAPCHAT_USERNAME_PLACEHOLDER_: 'Your Snapchat Username',
                _PINTEREST_USERNAME_: 'Pinterest Profile URL',
                _PINTEREST_USERNAME_PLACEHOLDER_: 'Your Pinterest Username',
                _FACEBOOK_PAGE_: 'Facebook Page URL',
                _FACEBOOK_PAGE_PLACEHOLDER_: 'Facebook Page URL',
                _DESCRIPTION_: 'About Yourself',
                _DESCRIPTION_PLACEHOLDER_: 'Tell Others About Yourself in 1000 characters or less',
                _LOOKING_FOR_: 'Looking For',
                _LOOKING_FOR_PLACEHOLDER_: 'Tell us about opportunities you would be interested in having other creative vendors contact you about (i.e. Styled Shoots, Weddings, Special Projects, etc...)',
                _AFFILIATIONS_: 'AFFILIATIONS',
                _MY_SOCIAL_ACCOUNTS_: 'MY SOCIAL ACCOUNTS',
                _MY_SOCIAL_ACCOUNTS_DESCRIPTION_: 'Connect to your social accounts to more easily find creatives you already know, see opportunities they post, and share your work with',
                _CHAPTER_: 'CHAPTER',
                _CHAPTER_PLACEHOLDER_: 'E.g. San Francisco',

                CONFIRM: {
                    _TITLE_: 'Confirm your info',
                    _DESCRIPTION_: 'Fill out some basic information so that other creative pros can find and connect with you',
                    _SAVE_AND_CONTINUE_: 'SAVE & CONTINUE',
                    _PHOTO_HINT_: 'Hint: Choose a recent photo so that people can recognize you'
                }
            },
            PROJECT: {
                _EDIT_PROJECT_: 'Edit Project',
                _DELETE_PROJECT_: 'Delete Project',
                _CONFIRM_DELETE_: 'Are you sure you want to delete this project? This will also remove it for any tagged collaborators.',
                _CONFIRM_CANCEL_DELETE_: 'Are you sure you want to cancel this project? This will delete all changes made so far.',
                _LIKE_THIS_PROJECT_: 'Like this project',
                _CONTRIBUTORS_: 'Collaborators',
                _DRAFT_: 'DRAFT',
                _WRITE_COMMENT_: 'Add comment ...',
                _COMMENTS_: 'COMMENTS',
                _GO_TO_ADD_COMMENT_: 'Add comment'
            },
            EDIT_PROJECT: {
                _CREATE_PROJECT_: 'Publish Project',
                _EDIT_PROJECT_: 'Edit Project',
                _SUBTITLE_: 'Showcase your work to win clients and trust from other creatives in the Community.<br><br><b>Don\'t  worry you can always edit later.</b>',
                _PROJECT_NAME_: 'PROJECT NAME',
                _PROJECT_NAME_PLACEHOLDER_: '40 Characters',
                _PROJECT_TYPE_: 'PROJECT TYPE',
                _PROJECT_DATE_: 'Date',
                _PROJECT_LOCATION_: 'Location',
                _PROJECT_LOCATION_PLACEHOLDER_: 'Add venue or location address',
                _STYLE_TAGS_: 'Style Tags',
                _STYLE_TAGS_PLACEHOLDER_: 'Separate tags with commas',
                _DESCRIPTION_: 'Description',
                _DESCRIPTION_PLACEHOLDER_: 'Short description about what makes this project unique',
                _PROJECT_PHOTOS_: 'Project Photos',
                _TAG_VENDORS_: 'Tag Collaborators',
                _TAG_VENDOR_: 'Add Collaborator',
                _TAG_VENDORS_INFO_: 'Get discovered on other people\'s profiles. Tag the people you worked with.',
                _DELETE_PROJECT_: 'Delete Project',
                _GUESTS_: 'Guests',
                _GUESTS_PLACEHOLDER_: 'Guest count',
                _PROJECT_IMAGES_: 'Project Photos'
            },
            DIRECTORY: {
                _CREATIVE_: 'Creative',
                _FILTER_INDUSTRY_TYPE_: 'Filter industry type',
                _FILTER_LOCATION_: 'Filter location',
                _FILTER_CONNECTIONS_: 'Filter collaborators',
                _FILTER_CONNECTIONS_BUTTON_: 'My Connections',
                _DONT_SEE_USERS_: 'Can\'t find someone?',
                _INVITE_ANY_CREATIVE_: 'Invite any creative',
                _CONFIRM_CONNECTION_DELETE_: 'Are you sure? Click Confirm to delete this connection.',

                LISTING: {
                    _PROJECTS_: 'PROJECTS',
                    _CONNECTIONS_: 'CONNECTIONS',
                    _CONNECT_: 'CONNECT',
                    _REQUEST_SENT_: 'REQUEST SENT',
                    _PENDING_: 'PENDING',
                    _CONNECTED_: 'CONNECTED',
                    _IGNORE_: 'IGNORE',
                    _HIDE_: 'HIDE',
                    _ACCEPT_: 'ACCEPT',
                    _CLIENT_ADDED_: 'CLIENT ADDED'
                }
            },
            PROJECT_TYPES: {
                _NONE_: 'Choose type',
                _WEDDING_: 'Wedding',
                _CORPORATE_: 'Corporate',
                _SOCIAL_: 'Social',
                _PROJECT_: 'Project',
                _PARTY_: 'Party',
                _FAMILY_: 'Family',
                _DESIGN_: 'Design',
                _OTHER_: 'Other'
            },
            TAG_VENDOR: {
                _HEADER_TITLE_: 'Add collaborators to your project',
                _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been added to the project. Are you sure you\'re done editing?',
                _CANNOT_ADD_ADDED_USER_: 'Hmm, it looks like this user is already on the project',
                _CANNOT_ADD_CLIENT_USER_: 'Oopsies... The email address you entered was marked as a client by a different user. The Creative Community is currently ONLY for creative professionals. If this email address belongs to a creative please write to concierge@honeybook.com'
            },
            INVITE_USER: {
                _HEADER_TITLE_: 'Invite creative pros to join the HoneyBook Community',
                _HEADER_SUBTEXT_: 'They don\'t have to be HoneyBook members',
                _USER_NOT_ADDED_: 'Hang on! {{email}} hasn\'t been invited. Are you sure you\'re done editing?',
                _CANNOT_ADD_ADDED_USER_: 'Hmm, it looks like you\'ve already added this user',
                _CANNOT_ADD_CLIENT_USER_: 'Sorry, cannot add a client as a connection. Please go to the Clients tab to do so',
                _INVITE_: 'Invite'
            },
            FEED: {
                _LOOKING_FOR_PLACEHOLDER_: 'E.g. Looking for a second shooter on {{date}}',
                _DESCRIPTION_PLACEHOLDER_: 'E.g. Other relevant info, such as location, style or client details',
                _ACTIVITY_: 'All Activity',
                _OPPORTUNITIES_IVE_SHARED_: 'Opportunities I\'ve Shared',
                _OPPORTUNITIES_: 'Opportunities',
                _PROJECTS_: 'Projects',
                _PUBLIC_PROJECTS_: 'Public Projects',
                _MY_POSTS_: 'My Posts',
                _MY_OPPORTUNITIES_: 'My Opportunities',

                ERRORS: {
                    _INPUT_LOOKING_FOR_: 'In order to post, please specify what your looking for',
                    _SELECT_VENDOR_TYPES_: 'Select up to 5 creative types to share this opportunity with'
                }
            },
            OPPORTUNITIES: {
                _EVERYWHERE_: 'All Locations'
            },
            ERRORS: {
                _FIELD_MAX_LENGTH_: 'Text is too long'
            },
            REFERRAL: {
                _REPLY_: 'Reply here...'
            },
            EMAIL_SETTINGS: {
                _UNSUBSCRIBE_TITLE_: 'What would you prefer to hear about?',
                _UNSUBSCRIBE_SUBTITLE_: 'We want to stay in touch, but only in ways that work for you. You will always receive emails relevant to your clients and projects.',
                _UPDATE_MY_PREFERENCES_: 'UPDATE MY PREFERENCES',
                _UNSUBSCRIBE_ALL_: 'UNSUBSCRIBE FROM ALL',
                _UNSUBSCRIBE_CONNECTIONS_TITLE_: 'Connection requests', // please make sure to update description to enum.rb!!!!
                _UNSUBSCRIBE_CONNECTIONS_DESC_: 'When other community members want to connect to you',
                _UNSUBSCRIBE_PROJECTS_TITLE_: 'Project comments and tags',
                _UNSUBSCRIBE_PROJECTS_DESC_: 'Get notified when someone tags you in a project or comments on a project you are tagged in',
                _UNSUBSCRIBE_OPPORTUNITIES_TITLE_: 'Opportunities near you',
                _UNSUBSCRIBE_OPPORTUNITIES_DESC_: 'Creatives in your area may periodically post about leads, collaborations, or client referrals',
                _UNSUBSCRIBE_GENERAL_TITLE_: 'General updates',
                _UNSUBSCRIBE_GENERAL_DESC_: 'New features, updates and knowledge for our business tools',
                _UNSUBSCRIBE_OPPORTUNITIES_DIGEST_TITLE_: 'Weekly opportunities email digest',
                _UNSUBSCRIBE_OPPORTUNITIES_DIGEST_DESC_: 'Aggregated updates about opportunities near you from the last week'
            }
        },

        REGISTRATION: {
            ERRORS: {
                _API_UPDATE_USER_: 'There was an error saving your information',
            }
        },

        ATTACH_ASSETS_MODAL: {
            _CROP_TITLE_: 'Crop Your Image'
        },

        AVATAR_DROPDOWN: {
            _BANNER_TEXT_: 'Partner with the team that will never let you down.',
            _UPGRADE_NOW_: 'Upgrade Now',
            _SHARE_YOUR_LOVE: 'Share The Love',
            _COMPANY_SETTINGS_: 'Company Settings',
            _INTEGRATIONS_: 'Integrations',
            _COMPANY_SETTINGS_AS_PRO_: "{{name}}'s Company Settings",
            _ACCOUNT_: 'My Account',
            _ACCOUNT_AS_PRO_: "{{name}}'s Account",
            _LOGOUT_: 'Log out',
            _PRO_LOGOUT_AS_VENDOR_: 'Log into your own account',
            _HELP_: "Help Center",
            _SETUP_GUIDE_: '{{percentage}}% Set up',
            _ADD_COMPANY_: 'Add a new company',
            _SWITCH_COMPANY_: 'Switch company',
            _HONEYBOOK_FINANCE_: 'HoneyBook Balance',
            _PUBLIC_PROFILE_: 'Public Profile',
            _BACK_TO_ACCOUNT_: 'Go to my account >',
            ALERT: {
                _MESSAGE_: 'To access Balance, setup a 2-step verification login.',
                _CTA_: 'SETUP 2FA'
            },
            _CLIENT_ACCOUNTS_: 'Client accounts',
            _BOOKKEEPER_ACCESS_: 'Bookkeeper Access',
            _BOOKKEEPER_ALERT_: 'To access other information as Bookkeeper ,setup a 2-step verification login.',
        },

        MOBILE_SETTINGS: {
            _UPGRADE_NOW_: 'Upgrade Now',
            _LOGOUT_: 'Log out',
            _HELP_: "Help Center",
            _SETUP_GUIDE_: '{{percentage}}% set up',
        },

        TASKS: {
            LABELS: {
                _ADD_TASK_: 'Add task',
                _FILTERS_TITLE_: 'Filters',
                _VIEW_AND_EDIT_: 'View/Edit',
                FILTERS: {
                    _ALL_: 'All',
                    _TODAY_: 'Today',
                    _THIS_WEEK_: 'This Week',
                    _OVERDUE_: 'Overdue',
                    _COMPLETED_: 'Completed',
                    _TASKS_: 'Tasks',
                    _APPROVALS_: 'Approvals'
                }
            },
            HEADERS: {
                _DESCRIPTION_: 'DESCRIPTION',
                _PROJECT_: 'PROJECT',
                _DUE_DATE_: 'DUE DATE',
                _WORKFLOW_: 'AUTOMATION'
            },
            ALERT: {
                _CONFIRM_DELETE_: 'Are you sure you want to remove this task?'
            },
            EMPTY_STATE: {
                _TITLE_: 'Enjoy your day!',
                _YOU_DONT_HAVE_TASKS_: 'You don\'t have tasks for today! Make sure you don\'t miss out on anything and',
                _ADD_TASKS_: 'Add a Task',
                _ADD_TASKS_REMINDER_: 'now. We\'ll remind you when your task\'s time has come. ;)'
            }
        },

        EXPENSES: {
            FILTER: {
                _FILTER_BY_: 'Filter by',
                _30_DAYS_: 'Last 30 days',
                _90_DAYS_: 'Last 90 days',
                _CUSTOM_: 'Custom'
            }
        },

        FACEBOOK_LEADS: {
            _DELETE_CONFIRM_MESSAGE_: 'You are about to disconnect this Facebook page Lead Ads from HoneyBook. New leads from this page won’t appear in your HoneyBook pipeline.',
            _APPROVE_: 'REMOVE PAGE',
            _NO_PAGES_TO_ADD_: 'No available pages to add',
            _BANNER_CTA_: 'LEARN MORE',
            _BANNER_TITLE_: 'FACEBOOK BUSINESS LEADS',
            _BANNER_TEXT_: 'Streamline Facebook business leads into HoneyBook in real time',
            _ADD_FACEBOOK_PAGE_TITLE_: 'Add Facebook Page',
            _ADD_FACEBOOK_PAGE_DESCRIPTION_: 'Choose a Facebook page that has active Lead Ads. Once connected, any new lead from these ads will be show up in your HoneyBook pipeline as a new inquiry.',
            _ADD_FACEBOOK_PAGE_CTA_: 'CONNECT PAGE',
            _INTRO_MODAL_TITLE_: 'Easily streamline Facebook and Instagram leads into HoneyBook',
            _INTRO_MODAL_DESCRIPTION_: 'Integrate Facebook and Instagram Lead Ads with HoneyBook’s pipeline. Once linked, any new business lead created on Facebook or Instagram lead ads will instantly turn into a new HoneyBook inquiry and will instantly show up in your pipeline!',
            _INTRO_MODAL_CTA_: 'Connect With Facebook',
            _INTRO_MODAL_LEARN_MORE_: 'What are Facebook/Instagram Lead Ads?'
        },



        WORKFLOW: {
            WORKFLOWS: {
                _TITLE_: 'Your workflow templates',
                _INTRO_TEXT_: 'Why spend another minute wondering what email to send or task to accomplish? Customize your own workflow templates and apply them when creating a new project or automate through the contact form.',
                _CREATE_WORKFLOW_BUTTON_: 'Create workflow',
                _CREATE_AUTOMATION_BUTTON_: 'New automation',
                _CREATE_WORKFLOW_ROW_: 'New workflow template',
                _CREATE_AUTOMATION_ROW_: 'New automation template',
                _UPGRADE_FEATURE_TEXT_: 'Upgrade to connect automation to contact form',
                _DEFAULT_WORKFLOW_: 'Set as default',
                _AUTOMATIONS_TOGGLE_TEXT_: 'NEW! Activate the toggle and test drive the new, enhanced Automation builder. Only available in Beta',
                _AUTOMATIONS_TOGGLE_SUBTEXT_: '(Toggle back any time to use the old builder).',
                _STEPS_: 'Steps',
                _TABLE_TITLE: {
                    _WORKFLOWS_: 'Workflows',
                    _AUTOMATIONS_: 'Automation name',
                    _ATOMATE_: 'Automate via contact form',
                    _PUBLIC_AUTOMATE_ATOMATE_: 'Activate via lead form',
                    _TOOLTIP_: 'Add a project type question to your contact form and apply it to an automation sequence here to send inquiries of different types down separate paths.',
                    _PUBLIC_FILE_AUTOMATION_TOOLTIP_: 'You can select a lead form to trigger an\n automation either from the drop-down list\n provided or from the form settings.'
                },

                PROJECT_TYPE_SELECTOR: {
                    _DEFAULT_WORKFLOW_: 'Default for contact form',
                    _DROP_DOWN_TITLE_: 'Trigger automation based on project type',
                    _NOT_ASSIGNED_: 'Not Assigned',
                    _NO_FORMS_SET_: 'No forms set'
                },

                PUBLIC_FILE_SELECTOR: {
                    _PUBLIC_FILE_DROP_DOWN_TITLE_: 'Trigger automation form a lead form.',
                    _PUBLIC_FILE_HELP_: 'Select the specific lead forms that you want to trigger this automation.',
                    _PUBLIC_FILE_EMPTY_ARRAY_TITLE_: 'No lead forms created yet.',
                    _PUBLIC_FILE_EMPTY_ARRAY_TITLE_HELP_: 'Please <a className="referrals-content__go-to-resources-text__link" href="/app/my-templates?section=Lead_forms"">create a lead form</a> and assign it to trigger an automation.',
                    _PUBLIC_FILE_DISABLED_TOOLTIP_: 'This lead form is assigned to another automation. To use it, remove from current automation.',
                    _SELECT_PUBLIC_FILE_TO_TRIGGER_AUTOMATION_TOOLTIP_: 'Select a lead form that will trigger this automation.'
                },

                FOOTER: {
                    _TITLE_: 'Focus on your clients, let automation do the rest',
                    _FIRST_STAGE_TITLE_: 'Create your workflows',
                    _FIRST_STAGE_TEXT_: 'Set up customized step-by-step workflows to ensure every client gets the same amazing experience.',
                    _SECOND_STAGE_TITLE_: 'Choose which workflows to automate',
                    _SECOND_STAGE_TEXT_: 'You can manually apply any workflow to a new project, or choose to automate a workflow template for inquries that come through the contact form.',
                    _THIRD_STAGE_TITLE_: 'Stay on top of tasks',
                    _THIRD_STAGE_TEXT_: 'We’ll keep you on track and notify you of new workflow tasks or emails to approve, so you can rest easy.'
                }
            },
            WORKFLOW_STEPS_EDITOR: {
                DROPDOWNS_DEFAULT: {
                    SELECT_TEMPLATE: 'Select Template',
                },
                _TASK_NAME_LABEL_: 'Add a task',
                _WORKFLOW_TEMPLATE_: 'Workflow Template',
                _YOU_HAVE_UNSAVED_CHANGES_: 'You have unsaved changes',
                _YOU_HAVE_SAVED_CHANGES_: 'Your changes have been saved',
                _TASK_: 'Create Task',
                _TASK_DESCRIPTION_: 'Write tasks for yourself so you don\'t miss anything throughout the project lifecycle.',
                _QUEST_: 'Send Questionnaire',
                _QUEST_DESCRIPTION_: 'Automatically send questionnaires so that you are gathering all the information you need at the right time.',
                _EMAIL_: 'Send Email',
                _EMAIL_DESCRIPTION_: 'Set up emails to automatically send to your leads or clients.',
                _BROCHURE_: 'Send Brochure',
                _BROCHURE_DESCRIPTION_: 'Immediately showcase your work by sending a brochure as soon as you receive a lead.',
                _FLOW_: 'Send an interactive smart file',
                _FLOW_DESCRIPTION_: 'Automatically send a smart file to clients so you can present information and collect responses to questions. Use for portfolios, briefs, questionnaire, mood boards etc...',
                _DAYS_: 'Days',
                DO_NOT_SHOW_AGAIN: {
                    _DONT_SHOW_: 'Don\'t show me this again',
                    _OK_: 'Ok, got it',
                    _MSG_COPY_: 'Checking off this task ahead of time will trigger any steps dependent on this. Are you sure you want to continue?'
                }
            },
            APPROVAL: {
                _APPROVE_BEFORE_SENDING_: 'Approve before sending',
                _SEND_AUTOMATICALLY_: 'Send automatically',
                _APPROVE_AND_SEND_NOW_: 'APPROVE & SEND NOW',
                _GO_BACK_: 'GO BACK'
            },
            TRIGGERS: {
                _AFTER_ACTIVATING_WORKFLOW_: 'after automation is activated',
                _BEFORE_PROJECT_DATE_: 'before project date ',
                _AFTER_PROJECT_DATE_: 'after project date',
                _BEFORE_PROJECT_END_DATE_: 'before project end date ',
                _AFTER_PROJECT_END_DATE_: 'after project end date',
                _AFTER_PREVIOUS_STEP_IS_COMPLETE_: 'after previous step is complete',
                _AFTER_CONTRACT_SIGNED_: 'after contract is signed',
                _AFTER_ALL_REQUIRED_SIGNATURES_SIGNED_: 'after all required signatures signed',
                _AFTER_QUESTIONNAIRE_SUBMITTED_: 'after questionnaire submitted',
                _AFTER_INVOICE_PAID_IN_FULL_: 'after invoice paid in full',
                _AFTER_FIRST_PAYMENT_MADE_: 'after first payment is made',
                _AFTER_PROJECT_MOVE_TO_COMPLETED_: 'after project move to completed',
                _AFTER_SCHEDULED_SESSION_ENDED_: 'after scheduled session has ended',
                _AFTER_BROCHURE_SUBMITTED_: 'after brochure is submitted',
                _AFTER_FLOW_COMPLETED_: 'after smart file is completed'
            },
            TRIGGER_TOOLTIPS: {
                _AFTER_ACTIVATING_WORKFLOW_: 'Workflows can be activated automatically when a Contact Form is submitted, or when manually applied to a Project.',
                _BEFORE_PROJECT_DATE_: 'The “Project date” will either be the single date associated with the Project, or the start date (if an end date is set).',
                _AFTER_PROJECT_DATE_: 'The “Project date” will either be the single date associated with the Project, or the start date (if an end date is set).',
                _BEFORE_PROJECT_END_DATE_: 'The “end date” will need to be specified in your Project Details. If no end date is set, steps with this trigger will be skipped.',
                _AFTER_PROJECT_END_DATE_: 'The “end date” will need to be specified in your Project Details. If no end date is set, steps with this trigger will be skipped.',
                _AFTER_PREVIOUS_STEP_IS_COMPLETE_: 'Action will trigger a set amount of time after the previous step in the Workflow has completed.',
                _AFTER_CONTRACT_SIGNED_: 'Action will trigger a set amount of time after a Contract has been signed by a client.',
                _AFTER_ALL_REQUIRED_SIGNATURES_SIGNED_: 'Action will trigger a set amount of time after all the required signatures has been signed.',
                _AFTER_QUESTIONNAIRE_SUBMITTED_: 'Action will trigger a set amount of time after the first Questionnaire in a Project has been submitted by a client.',
                _AFTER_INVOICE_PAID_IN_FULL_: 'Action will trigger after final payment has been made on an Invoice or a Proposal (credit card & ACH payments only).',
                _AFTER_FIRST_PAYMENT_MADE_: 'Action will trigger after the first payment has been made in an Invoice or a Proposal (credit card & ACH payments only).',
                _AFTER_PROJECT_MOVE_TO_COMPLETED_: 'Moving Projects to “Completed” will need to be done manually using the Pipeline stage dropdown.',
                _AFTER_BROCHURE_SUBMITTED_: 'Action will trigger a set amount of time after the first Brochure in a Project has been submitted by a client.',
                _AFTER_FLOW_COMPLETED_: 'Action will trigger a set amount of time after the first Smart File in a Project has been completed by a client.'
            },
            LABELS: {
                _ACTION_: 'Action',
                _APPROVAL_: 'Approval',
                _BROCHURE_TEMPLATE_: 'Brochure Template',
                _QUEST_TEMPLATE_: 'Questionnaire Template',
                _FLOW_TEMPLATE_: 'Smart File Template',
                _SEND_EMAIL_WITH_THIS_MESSAGE_: 'Send email with this message',
                _WHEN_: 'WHEN?',
                _ADD_STEP_: 'Add Step',
                _SEND_BROCHURE_: 'Send Brochure',
                _SEND_EMAIL_: 'Send Email',
                _SEND_QUEST_: 'Send Questionnaire',
                _TASK_: 'Task',
                _SEND_FLOW_: 'Send Smart File',
                _TEMPLATE_: 'Template',
                _WORKSPACE_WORKFLOW_TITLE_: 'Workflow',
                _WORKSPACE_AUTOMATION_TITLE_: 'Automation',
                _WORKSPACE_AUTOMATION_UPCOMING_STEPS: 'UPCOMING STEPS',
                _WORKSPACE_AUTOMATION_VIEW_ALL: 'View all',
                _WORKSPACE_AUTOMATION_VIEW_MORE: 'View , more step,s'
            },
            BANNER: {
                _FULL_VIEW_MSG_: 'Steps within this automation are triggered according to how they were set in templates and may be out of order. You can remove any steps that are no longer relevant or in the incorrect order.'
            },
            DELAY_UNIT_OPTIONS: {
                _MINUTES_: 'Minutes',
                _HOURS_: 'Hours',
                _DAYS_: 'Days',
                _WEEKS_: 'Weeks',
                _MONTHS_: 'Months'
            },
            TITLES: {
                _START_BUILDING_A_WORKFLOW_: 'Let’s build your new Automation sequence!',
                _WHAT_DO_YOU_WANT_TO_DO_FIRST_: 'Define the steps that will occur in your Automation and when each of those actions should be triggered.'
            },
            TOOLTIPS: {
                _WORKFLOW_FILE_ATTACHED_: 'This email contains an attachment',
                _WORKFLOW_FILE_ATTACHED_PLURAL_: 'This email contains attachments',
                _WORKFLOW_ACTIVATION_: 'Workflows can be activated manually on the date you apply it to a project, or automatically via the contact form when an inquiry is received.',
                _APPROVE_BEFORE_SENDING_: 'If you choose "approve before sending", you will need to manually approve on the day the step is due for it to send.'
            },
            ALERTS: {
                _REMOVE_WORKFLOW_FROM_WORKSPACE_: 'Removing this automation will stop any future steps from occurring. Are you sure you want to remove?',
                _ADD_WORKFLOW_DATE_TBD_: 'Any steps that are dependent on a project date will not occur unless a project date has been added',
                _ADD_WORKFLOW_OVERDUO_STEPS: 'Due to the project date, there are steps that would have occurred in the past. Any past due steps will need your approval before sending',
                _ADD_WORKFLOW_MEMBERLESS_: 'You must add a participant to this workspace before applying an automation.',
                _DELETING_CURRENT_OR_FUTURE_APPROVAL_: 'Deleting this step will still trigger any following steps that are dependent on it. Are you sure you want to delete?',
                _UNSAVED_CHANGES_: 'You have unsaved changes. Are you sure you want to leave this page?',
                _SKIP_WORKFLOW_TASK: 'Removing this task will still activate any steps that are following in this automation',
                _DELETE_WORKFLOW_TEMPLATE_: 'Any projects that currently have this automation active will continue to run.\n \n If you have a lead form that triggers this automation, it will be removed.',
                _TEMPLATE_IS_ALREADY_ACTIVE_: 'Any edits made to this automation template will not reflect in active projects that already have this automation attached',
                _STAY_: 'Stay',
                _LEAVE_: 'Leave'

            }
        },

        CONFIRM_MODALS: {
            PREMIUM_FEATURE: {
                _TITLE_: 'Membership Required',
                _TEXT_: 'In order to use this feature, please sign up for a monthly or annual membership. We’re excited to have you join our family!',
                _OPEN_SUBSCRIPTION_PAGE_: 'Join HoneyBook'
            },

            GMAIL_INTEGRATION_NEEDED: {
                _TEXT_: 'In order to send batch emails you\'ll need to integrate your Email account.',
                _OPEN_ACCOUNT_SETTINGS_PAGE_: 'integrate email'
            }
        },

        TRIAL_EXTENDED_MODAL: {
            _TITLE_: 'Congratulations!',
            _SUBTITLE_: 'We\'ve given you {{nDays}} more days to experience how HoneyBook can help you manage your business and book more clients!',
            _CTA_: 'LET\'S GO'
        },

        EMAIL_VERIFICATION: {
            TOAST: {
                _EMAIL_WAS_VERIFIED_TITLE_: 'Your email was confirmed',
                _EMAIL_WAS_VERIFIED_SUB_TITLE_: 'You’re all set for a secure HoneyBook experience.',
                _EMAIL_VERIFICATION_FAILED_TITLE_: 'Verification email expired',
                _EMAIL_VERIFICATION_FAILED_SUB_TITLE_: 'Another one was sent to your inbox. Email is valid for 24 hours.'
            }
        },

        BATCH_EMAILS: {
            LAST_SENT: {
                _TITLE_: 'Batch email',
                _SUB_TITLE_ONE_SENT_: 'You have sent 1 Batch Email',
                _SUB_TITLE_MULTIPLE_SENT_: 'You have sent {{emails_count}} Batch Emails',
                _DESCRIPTION_: 'Send a single email to a select group of your contacts with just a couple of clicks.',
                _CREATE_BUTTON_: 'Create new batch',
                _START_HERE_: 'Start here',
                _NO_GMAIL_INTEGRATION_ERROR_: 'no gmail integration',
                _CANNOT_SEND_MORE_THEN_XXX_A_DAY_: 'You have reached the maximum number of bulk emails for today. Please try again tomorrow',
                _STATUS_: 'Status',
                _OPEN_RATE_: 'Open rate',
                _SUBJECT_: 'Subject',
                _DATE_SENT_: 'Date Sent',
                _RECIPIENTS_: 'Recipients',
                _OPENED_LIST_TITLE_: 'Opened ({{count}})',
                _NOT_OPENED_LIST_TITLE_: 'Not opened ({{count}})',
                PRO_TIP: {
                    _BOLD_: "Pro Tip:",
                    _TEXT_: "Batch emails do not appear in Project Activity. if you want your email to show up in each client's project, use the bulk email action from the Project Pipeline.",
                    _LINK_TITLE_: "Learn more"
                },
                _PERCENT_OPENED_: "{{rate}} Opened",
                _SENDING_: "Sending",
                _PEOPLE_: "{{count}} People",
                _PERSON_: "1 Person",
                _SUCCESS_TOAST_: "Batch emails sent successfully",

            },

            SEND_BATCH_MODAL: {
                _SELECTED_: 'Selected',
                _BACK_: 'Back',
                _PROGRESS_TITLE_: 'Send a batch email',
                _CREATE_: 'Create email',
                _SELECT_RECIPIENTS_: 'Select Recipients',
                _RECIPIENTS_: 'Recipients',
                _SEND_TEST_EMAIL_BUTTON_: 'Send myself a test email',
                _DONE_: 'Done',
                _ERROR_: 'You cannot send an empty email',
                _ON_USER_SELECTED_ERROR_: 'You must select users before sending',
                EDIT_EMAIL_SCREEN: {
                    _NEXT_BUTTON_: 'Next',
                    _TIPS_: 'TIP: Achieve better open and click rates by being as personal as possible.',
                    _TOP_TEXT_: 'Craft your batch email:',
                    _SELECT_ALL_: 'SELECT ALL'
                },
                SELECT_VENDORS_SCREEN: {
                    _CLIENTS_: 'Clients',
                    _VENDORS_: 'Vendors',
                    _CREATIVES_: 'Creatives',
                    _NEXT_BUTTON_: 'Send Email',
                    _SEARCH_PLACE_HOLDER_: 'Search by name or email',
                    _TOP_TEXT_: 'Select the recipients to receive this email'
                },
                SENDING_SCREEN: {
                    _NEXT_BUTTON_: 'Done',
                    _SENDING_EMAILS_: 'Batch emails initiated',
                    _YOUR_EMAIL_: 'This could take a minute or two.\n We\'ll notify you when all sends are complete.'
                }
            },
            TOAST_SUCCESS: 'Batch emails sent successfully',
        },

        EXTENDED_CREATIVE_TYPES: {
            _EVENT_PLANNER_: 'Planning',
            _VENUE_: 'Venue',
            _PHOTOGRAPHER_: 'Photographer',
            _RENTAL_: 'Rentals',
            _FLORIST_: 'Floral Design',
            _STATIONERY_: 'Stationery',
            _OTHER_: 'Other',
            _FASHION_: 'Fashion / Accessories',
            _PHOTO_BOOTH_: 'Photo Booth',
            _CINEMATOGRAPHY_: 'Videography',
            _EVENT_LIGHTING_: 'Lighting / AV',
            _OFFICIANT_: 'Officiant',
            _MARKETING_: 'Marketing / PR',
            _VISUAL_ARTIST_: 'Visual Art',
            _BLOGGER_: 'Blogging',
            _BUSINESS_CONSULTANT_: 'Business Consulting',
            _CALLIGRAPHER_: 'Calligraphy',
            _INTERIOR_DESIGNER_: 'Interior Design',
            _GRAPHIC_DESIGNER_: 'Graphic Design',
            _MAKER_ARTISAN_: 'Maker / Artisan',
            _WEB_DESIGNER_: 'Web Design',
            _MUSICIAN_: 'Musician',
            _HAIR_DESIGN_: 'Hair Design',
            _MAKEUP_ARTIST_: 'Makeup Artist',
            _ENTERTAINMENT_: 'Entertainment',
            _DJ_: 'DJ',
            _BAR_SERVICES_: 'Bar Services',
            _CATERING_: 'Catering Services',
            _BAKERY_: 'Bakery',
            _DESSERTS_: 'Desserts / Cakes',
            _FIREWORKS_: 'Fireworks',
            _ARCHITECTURE_: 'Architecture',
            _ATTIRE_RENTALS_: 'Attire Rentals',
            _AUTHOR_WRITER_: 'Author / Writer',
            _COPYWRITER_: 'Copywriter',
            _CRAFTING_: 'Crafting',
            _CERAMICS_: 'Ceramics',
            _BRANDING_: 'Branding',
            _HAND_LETTERING_: 'Hand Lettering',
            _FIBER_ART_: 'Fiber Art',
            _JEWELRY_: 'Jewelry',
            _MERCHANDISER_: 'Merchandiser / Boutique Owner',
            _PHOTO_EDITING_: 'Photo Editing',
            _PRODUCT_DESIGNER_: 'Product Design',
            _PUBLISHING_: 'Publishing',
            _STYLING_: 'Styling',
            _STRATEGY_: 'Strategy',
            _VIRTUAL_ASSISTANT_: 'Virtual Assistant',
            _WEB_DEVELOPER_: 'Web Developer',
            _ILLUSTRATION_: 'Illustration',
            _CAKE_: 'Bakery / Dessert',
            _HAIR_MAKEUP_: 'Hair / Makeup',
            _DJ_MUSICIAN_: 'DJ / Entertainment',
            _CATERER_: 'Food & Drinks',
            _TRANSPORTATION_: 'Transportation'
        },

        PROJECT_ACTION_DROPDOWN: {
            _TAKE_ACTION_: 'TAKE ACTION',
            _ARCHIVED_PROJECT_RESCHEDULE_TOOLTIP_: 'You must unarchive this project to reschedule. To do this, move it to an active stage in your pipeline.',
            ACTION_NAME: {
                _SEND_EMAIL_: 'Send them an email',
                _PAUSE_PROJECT_: 'Pause Project',
                _ARCHIVE_PROJECT: 'Archive Project',
                _RESCHEDULE_PROJECT: 'Reschedule Project',
                _UNPAUSE_PROJECT: 'Unpause Project',
                _SCHEDULE_SESSION_: 'Schedule Session',
                _RESCHEDULE_PROJECT_: 'Reschedule Project',
                _GO_TO_PROJECT_: 'Go to project',
                _MOVE_STAGE_: 'Move stage',
                _ASSIGN_TO_TEAM_MEMBER_: 'Assign to team member',
                _REFER_: 'Refer',
                _ARCHIVE_: 'Archive',
                _DELETE_: 'Delete'
            },
            TOOLTIPS: {
                _NOT_DELETABLE_ITEM_: 'You can archive this project.<br>Projects that have related<br>payments or a signed contract<br>cannot be deleted.',
                _NOT_DELETABLE_ARCHIVED_ITEM_: 'Projects that have related<br>payments or a signed contract<br>cannot be deleted.'
            }
        },

        PROJECTS_DRAWER: {
            _NO_PROJECTS_FOUND_: 'No projects found',
            _SHOW_MORE_: 'Show more',
            _FILTER_BY_STAGE_: 'Filter by stage',
            _BOOKED_: 'booked',
            _SEARCH_PLACEHOLDER_: 'Projects',

            SORT: {
                ACTIVITY: {
                    _TITLE_: 'Activity',
                    _MENU_TITLE_: 'Project Activity'
                },
                DATE: {
                    _TITLE_: 'Date',
                    _MENU_TITLE_: 'Project Date'
                },
                NAME: {
                    _TITLE_: 'Name',
                    _MENU_TITLE_: 'Project Name'
                },
                SEARCH: {
                    _TITLE_: 'Search Results'
                }
            }
        },

        TEMPLATES_PAGE: {
            _PAGE_TITLE_: "Ready-to-send templates.",
            _NO_TEMPLATES_: "Add template",
            _TEMPLATE_NO_TITLE_: "(No title)",
            _HONEYBOOK_TEMPLATE_: "HoneyBook Template",
            _TEMPLATE_HAS_ATTACHMENTS_: "This template has attachments",
            EMPTY: {
                _HEADLINE_: "Flexible, time-saving templates",
                _COPY_: "Start from a template to make client communication easy. You can always make edits on the fly."
            },
            ALL_BANNER: {
                _TITLE_: 'Flexible, time saving templates',
                _DESCRIPTION_: 'Start from a template to make client communication easy. You can always make edits on the fly.',
            },
            LIST_VIEW_EMPTY: {
                _TITLE_: 'There are no templates here',
                _SUBTITLE_: 'Create your own to get started'
            },
            INVOICE: {
                _TITLE_SINGULAR_: "Invoice",
                _TITLE_PLURAL_: "Invoices",
                _DESCRIPTION_: "Customize and send invoices from anywhere.",
                _HEADLINE_: "Get paid",
                _SUBHEADLINE_: "Invoice features:",
                BANNER: {
                    _SUBTITLE_: 'Save time, get paid',
                    _DESCRIPTION_: 'Accept payments easily! Save commonly-sent sets of items or services, their prices, and a payment schedule as an Invoice template. Then, quickly send it to a client so they can review their statement and easily make payments from any device.'
                },
                FEATURES: {
                    _EASY_: "Easy drag-and-drop creation from a package template",
                    _CLIENTS_: "Clients can make secure and easy digital payments",
                    _MAKE_: "Customize it and make it pro with your branding",
                    _SET_: "Set up payment schedules and reminders",
                    _AUTOMATICALLY_: "Automatically calculate taxes and totals"
                },
            },
            AGREEMENT: {
                _TITLE_SINGULAR_: "Contract",
                _TITLE_PLURAL_: "Contracts",
                _DESCRIPTION_: "Get the signatures you need on the go.",
                _HEADLINE_: "Make it official",
                _SUBHEADLINE_: "Contract features:",
                BANNER: {
                    _SUBTITLE_: 'Copy & paste less, confirm clients more',
                    _DESCRIPTION_: 'Never write a contract from scratch again. When you need to collect a legally binding signature, simply start from a contract template, tweak for the client, send it off, and allow your client to securely review and sign from any device.'
                },
                FEATURES: {
                    _SMART_: "Smart fields automatically pull in essential details",
                    _CLIENT_: "Client can sign from anywhere and you get notified",
                    _UPDATE_: "Update as needed and store all versions",
                    _PROFESSIONAL_: "Professional and designed for all business types",
                    _GET_: "Get notifications when client reviews and signs",
                    _SMART_BAR_: "Smart bar highlights where to sign for no misses"
                },
            },
            PROPOSAL: {
                _TITLE_SINGULAR_: "Proposal",
                _TITLE_PLURAL_: "Proposals",
                _DESCRIPTION_: "Clients review, sign and pay all in one place.",
                _HEADLINE_: "Get booked",
                _SUBHEADLINE_: "Proposal features:",
                BANNER: {
                    _SUBTITLE_: 'Less busywork, more bookings',
                    _DESCRIPTION_: 'This isn’t your average “proposal”... instead, collect payments and e-signatures through this all-in-one booking document by combining an Invoice (a set of services, prices, and a payment schedule) with the associated Contract. '
                },
                FEATURES: {
                    _INVOICE_: "Invoice, payment and contract together",
                    _CLIENTS_: "Clients review, sign and pay all in one place",
                    _EASY_: "Easy drag-and-drop creation from a package",
                    _TOTALLY_: "Customize it and make it pro with your branding",
                    _SECURE_: "Secure payments with 24/7 fraud protection and easy refunds"
                },
            },
            QUESTIONNAIRE: {
                _TITLE_SINGULAR_: "Questionnaire",
                _TITLE_PLURAL_: "Questionnaires",
                _DESCRIPTION_: "Customized questions to understand your client.",
                _HEADLINE_: "Understand your client",
                _SUBHEADLINE_: "Questionnaire features:",
                BANNER: {
                    _TITLE_: 'Introducing: Questionnaire Smart Files',
                    _TITLE_STYLE_: 'custom',
                    _SUBTITLE_: 'Ask the right questions every time',
                    _DESCRIPTION_: 'You might know questionnaires as event planning forms; name, song, or preference requests; surveys; and more. No matter the context, they make it easy to collect the project & client information you need throughout the process.',
                    _PROMOTE_DESCRIPTION_: 'A better way to get responses from your clients.',
                    _DESCRIPTION_STYLE_: 'normal',
                    _LINK_: 'Visit Smart Files Template Gallery',
                    _LINK_EVENT_: 'go to smart file gallery'
                },
                FEATURES: {
                    _COLLECT_: "Collect key client information",
                    _PREMADE_: "Pre-made questions to start the client relationship",
                    _CUSTOMIZE_: "Customize questions and response type",
                    _USE_: "Use automation and send to client immediately with Workflows",
                    _GATHER_: "Gather information post booking to learn more"
                },
            },
            BROCHURE: {
                _TITLE_SINGULAR_: "Brochure",
                _TITLE_PLURAL_: "Brochures",
                _DESCRIPTION_: "Send out a branded presentation of your services.",
                _HEADLINE_: "Your brand, your brochure",
                _SUBHEADLINE_: "Brochure features:",
                BANNER: {
                    _SUBTITLE_: 'Showcase your brand without the busywork',
                    _DESCRIPTION_: 'Don’t let the name fool you—a brochure can be many things: a quote, mood board, pricing guide, proofing doc, company overview, or traditional “brochure”. Combine images, questions, text, and services to showcase your work and win over clients.'
                },
                FEATURES: {
                    _CUSTOMIZABLE_: "Customizable fonts, colors and images",
                    _LAYOUTS_: "Pre-made layouts to guide you and ensure you always look your best",
                    _AUTOMATION_: "Selected services automatically create proposals"
                },
            },
            PACKAGE: {
                _TITLE_SINGULAR_: "Package",
                _TITLE_PLURAL_: "Packages",
                _DESCRIPTION_: "Organize your services just the way you want.",
                _HEADLINE_: "Organize your services",
                _SUBHEADLINE_: "Package features:",
                BANNER: {
                    _SUBTITLE_: 'Organized pricing = organized business',
                    _DESCRIPTION_: 'Packages are groupings of services or items commonly offered together, along with their prices. Package templates are like building blocks and allow you to easily plug your services into other types of files like brochures, invoices, and proposals.'
                },
                FEATURES: {
                    _SET_: "Set up packages for easy drag-and-drop creation of brochures, proposals and invoices",
                    _PROFESSIONAL_: "Professional presentation of services",
                    _CUSTOMIZE_: "Customize packages with individual items",
                    _PRESENT_: "Present flexible pricing by customizing packages",
                    _ADD_: "Add images to showcase your services"
                },
            },
            EMAIL: {
                _TITLE_SINGULAR_: "Email",
                _TITLE_PLURAL_: "Emails",
                _DESCRIPTION_: "Templatize go-to messages, send, repeat.",
                _HEADLINE_: "Communicate with your clients",
                _SUBHEADLINE_: "Email features:",
                BANNER: {
                    _SUBTITLE_: 'Less typing, more communicating',
                    _DESCRIPTION_: 'Find yourself saying the same thing to clients over and over again? Save it as a template! You can create email templates for any language you use regularly, throughout your booking and project management process.'
                },
                FEATURES: {
                    _STRENGTHEN_: "Strengthen your brand through communications",
                    _SAVE_: "Save heaps of time with templated messages",
                    _EASY_: "Easy access to email templates from anywhere",
                    _INCLUDE_: "Include a branded email signature across all communications",
                    _ADD_: "Add Calendly link to emails and easily schedule meetings"
                },
            },
            EMAIL_SIGNATURE: {
                _TITLE_SINGULAR_: "Email Signature",
                _TITLE_PLURAL_: "Email Signatures",
                _DESCRIPTION_: "Customize your signature once, use forever.",
                _HEADLINE_: "Make it true to you and your clients",
                _SUBHEADLINE_: "Email signature features:",
                BANNER: {
                    _SUBTITLE_: 'Identify as a professional',
                    _DESCRIPTION_: 'Look professional in every email, every time by creating a personalized email signature. Customize to suit your brand’s logo and colors, add your contact information, and even include links!'
                },
                FEATURES: {
                    _CUSTOMIZE_: "Customize your signature to make it yours",
                    _LOOK_: "Look professional across all communications",
                    _ADD_: "Add your logo to make it all about your brand",
                    _STAND_: "Stand out from the crowd with your own logo"
                },
            },
            CONTACT_FORM: {
                _TITLE_SINGULAR_: "Contact Form",
                _TITLE_PLURAL_: "Contact Forms",
                _DESCRIPTION_: "Streamline your leads into one place.",
                _HEADLINE_: "Streamline your leads into one place",
                _SUBHEADLINE_: "Contact form features:",
                BANNER: {
                    _SUBTITLE_: 'Capturing your leads just got better!',
                    _DESCRIPTION_: 'Get booked faster! Create Contact Forms to make it easy for clients to get in touch. Personalize forms with your brand’s fonts & colors, and customize questions. Submissions will funnel right into HoneyBook, so all your leads are in one place.'
                },
                FEATURES: {
                    _CUSTOMIZABLE_: "Customize to sync up with multiple brands",
                    _GENERATED_: "Automatically create new leads or projects from contact form",
                    _AUTO_RESPONSE_: "Trigger auto-responses or custom workflows"
                },
            },
            GIFT_CARD: {
                _TITLE_: "Gift Cards",
                _TITLE_PLURAL_: "Gift Cards",
                _TITLE_SINGULAR_: "Gift Card",
                _DESCRIPTION_: "Increase your cash smart file",
                _HEADLINE_: "Increase your cash smart file",
                _SUBHEADLINE_: "Gift card features:",
                BANNER: {
                    _SUBTITLE_: 'Give the gift of you',
                    _DESCRIPTION_: 'Gift cards help increase your cash smart file by letting clients buy now & redeem later. Offer gift cards for specific services, or simply allow clients to purchase a certain amount to spend later—at full price or at a discount!'
                },
                FEATURES: {
                    _OFFER_: "Offer gift cards for specific services or allow clients purchase a certain amount.",
                    _DISCOUNT_: "Add discounts to any gift card promotion.",
                    _REDEEM_: "Let your clients redeem them in the future through your invoices."
                },
                _ACTIVATION_HELP_: 'Your Gift Card will default to "Active" when you click Publish for the first time. If you toggle to "Inactive", your clients will not be able to access the page.',
                _ACTIVATION_HELP_INACTIVE_: 'This gift card is set to "Inactive", which prevents clients from viewing or purchasing it. Instead, they’ll be prompted to contact you or visit your website.',
                _WHAT_WILL_CLIENTS_SEE_: "WHAT WILL CLIENTS SEE?",
                _ACTIVE_: 'Active',
                _INACTIVE_: 'Inactive'
            },
            FLOW: {
                _TITLE_: "Smart File",
                _TITLE_PLURAL_: "Smart Files",
                _TITLE_SINGULAR_: "Smart File",
                _DESCRIPTION_: "Smart files are the new way to streamline outstanding communications with your clients",
                _HEADLINE_: "Book clients faster and better",
                _SUBHEADLINE_: "Smart File features:",
                BANNER: {
                    _TITLE_: 'Introducing our new Smart Files',
                    _TITLE_STYLE_: 'custom',
                    _DESCRIPTION_STYLE_: 'normal',
                    _LINK_: 'Visit our Smart Files Template Gallery to get started',
                    _LINK_EVENT_: 'go to smart file gallery'
                },
                FEATURES: {
                    _OFFER_: "Offer gift cards for specific services or allow clients purchase a certain amount.",
                    _DISCOUNT_: "Add discounts to any gift card promotion.",
                    _REDEEM_: "Let your clients redeem them in the future through your invoices."
                },
                _ACTIVATION_HELP_: 'Your Gift Card will default to "Active" when you click Publish for the first time. If you toggle to "Inactive", your clients will not be able to access the page.',
                _ACTIVATION_HELP_INACTIVE_: 'This gift card is set to "Inactive", which prevents clients from viewing or purchasing it. Instead, they’ll be prompted to contact you or visit your website.',
                _WHAT_WILL_CLIENTS_SEE_: "WHAT WILL CLIENTS SEE?",
                _ACTIVE_: 'Active',
                _INACTIVE_: 'Inactive'
            },
            BLOCK: {
                _TITLE_: "Block",
                _TITLE_PLURAL_: "Blocks",
                _TITLE_SINGULAR_: "Block",
                _DESCRIPTION_: "Block are the new way to streamline outstanding communications with your clients",
                _HEADLINE_: "Book clients faster and better",
                _SUBHEADLINE_: "Smart File features:",
                BANNER: {
                    _SUBTITLE_: 'Take your booking process to the next level',
                    _DESCRIPTION_: 'Bring all of your booking actions into one streamlined smart file to allow a better and faster booking experience for your clients.'
                },
                FEATURES: {
                    _OFFER_: "Offer gift cards for specific services or allow clients purchase a certain amount.",
                    _DISCOUNT_: "Add discounts to any gift card promotion.",
                    _REDEEM_: "Let your clients redeem them in the future through your invoices."
                },
                _ACTIVATION_HELP_: 'Your Gift Card will default to "Active" when you click Publish for the first time. If you toggle to "Inactive", your clients will not be able to access the page.',
                _ACTIVATION_HELP_INACTIVE_: 'This gift card is set to "Inactive", which prevents clients from viewing or purchasing it. Instead, they’ll be prompted to contact you or visit your website.',
                _WHAT_WILL_CLIENTS_SEE_: "WHAT WILL CLIENTS SEE?",
                _ACTIVE_: 'Active',
                _INACTIVE_: 'Inactive'
            },
            _DIVE_IN_: 'Dive in',

            _CREATE_NEW_TYPE_: 'Create a new {{ typeTitle }}',
            _FILE_TEMPLATES_: 'File templates',
            _OTHER_TEMPLATES_: 'Other templates',
            _ALL_: 'All',
            _CREATE_CTA_: 'Create new',
            _TEMPLATE_: 'Template',
            _TEMPLATE_CREATED_AT_: 'Created at: {{ createdAt }}',
            ACTIONS: {
                _DELETE_: 'Delete',
                _DUPLICATE_: 'Duplicate',
                _DEFAULT_: 'Set as default',
                _PUBLISH_: 'Publish',
            },
            SORT: {
                _LABEL_: 'Sort by:',
                _DATE_CREATED_ASC_: 'Date created (newest)',
                _DATE_CREATED_DES_: 'Date created (oldest)',
                _ALPHABETICAL_: 'Name (A-Z)',
                _HEADER_TYPE_: 'Type',
                _HEADER_TITLE_: 'Template name',
            },

            EXPLAIN_MODAL: {
                FIRST_TIME: {
                    _TITLE_: 'Templates',
                    DESCRIPTION: {
                        _P1_: 'One of the great efficiencies that HoneyBook has to offer is templates. Save loads of time by reusing templates. You can save your commonly-used emails, pricing, contracts and more, so that you can easily use them with any future client.',
                        _P2_: 'Oh and the best thing is you personalize your template for an individual client without affecting your original template.'
                    },
                },
                RETURN_USER: {
                    _TITLE_: 'Welcome to the new template library!',
                    DESCRIPTION: {
                        _P1_: 'We’ve heard your feedback, and now, we’re delighted to present your new templates library—now easier to navigate and easier to use!',
                        _P2_: 'Don’t worry: any templates you’ve already created are still here. The new layout just means you can find those existing templates quicker (and create new ones with just a few clicks)!'
                    },
                    _LABEL_OLD_: 'The Old...',
                    _LABEL_NEW_: 'The New!',
                },
                _UPDATE_TAG_: 'Update',
                CTA: {
                    _NEXT_: 'Next',
                    _OK_: 'OK',
                }
            },
            TEMPLATE_IMPORT_REDIRECT_ALERT: {
                DESCRIPTION: 'The file you tried to load was created on a legacy format, explore our template gallery to find the most updated HoneyBook templates.'
            },
            FLOW_TEMPLATE_IMPORT_REDIRECT_ALERT: {
                DESCRIPTION: 'The file you tried to load was created on a new format, please contact support.'
            },
            TEMPLATE_MIGRATION_BANNER: {
                _BANNER_TEXT_: 'You’re currently using the old legacy builder, which will soon be deprecated.',
                _CTA_TEXT_: 'CONVERT TEMPLATE TO NEW BUILDER',
                _RECURRING_ERROR_TEXT_: 'Smart Files does not support recurring payments, therefore this template can\'t be converted into smart file.',
                SECOND_BATCH: {
                    _BANNER_TEXT_: 'You’re currently using the old legacy builder, try the new builder for more customization',
                }
            }
        },

        TIME_TRACKER: {
            WARNING: {
                _MSG_: 'Are you sure you want to delete this time entry?',
                _YES_DELETE_: 'YES, DELETE',
            },
            NEW_ENTRY_MODAL: {
                LABELS: {
                    _ADD_NEW_ENTRY_FOR_: 'Add new time entry for',
                    _WHAT_DID_YOU_WORKED_ON_: 'What did you work on?',
                    _NUMBER_OF_HOURS_: 'Number of hours',
                    _HOURS_: 'Hours',
                    _HOURLY_RATE_: 'Hourly rate',
                    _RATE_: 'Rate',
                    _DATE_COMPLETED_: 'Date completed',
                    _TOTAL_: 'Total',
                    _BILLABLE_: 'Billable',
                    _DELETE_: 'DELETE',
                    _SAVE_: 'SAVE CHANGES'
                }
            },
            LABELS: {
                _HOURS_LOGGED_: 'Hours Logged',
                _TOTAL_: 'TOTAL',
                _LOG_NEW_: 'Log new',
                _TIME_TRACKER_: 'Time Tracker',
                _VIEW_ALL_: 'View all entries',
                _LOG_NEW_TIME_: 'LOG NEW TIME',
                _TOTAL_HOURS_: 'Total hours:',
                _DATE_: 'DATE',
                _DESCRIPTION_: 'DESCRIPTION',
                _PROJECT_: 'PROJECT',
                _BILLABLE_: 'BILLABLE',
                _RATE_: 'RATE',
                _HOURS_: 'HOURS',
                _HOURS_SM_: 'HRS:',
                _CREATE_INVOICE_: 'CREATE INVOICE',
                _DOWNLOAD_CSV_: 'DOWNLOAD CSV',
                _CSV_: 'CSV',
                _NEXT_: 'NEXT: CREATE INVOICE',
                _SELECT_ENTRIES_: 'NEXT: SELECT TIME ENTRIES',
                _CREATE_NEW_INVOICE_: 'Create new invoice',
                _ALL_LOGGED_TIME_: 'All logged time',
                _LOGGED_TIME_: 'Logged time',
                _BACK_TO_PROJECT_: 'Back to project',
                _BACK_TO_TIME_TRACKER_: 'Back to Time Tracker',
                _PREVIOUSLY_INVOICED_: 'Previously invoiced',
                _NON_BILLABLE_: 'Non-billable time',
                _INVOICED_: 'INVOICED',
                _START_DATE_: 'Start Date',
                _END_DATE_: 'End Date',
            },
            EMPTY_STATE: {
                _TITLE_: 'Turn your time into money',
                _TITLE_MOBILE_: 'Make every minute count.',
                _TEXT_: 'We get it - tracking time takes time. So we made it super easy for you to track every second that you work. Now time and money won\'t slip through the cracks.',
                _TEXT_FILTER_: 'There are no time entries logged for this date range.<br>You can add a new time entry or try selecting a different date range to view.',
                _TEXT_FILTER_MOBILE_: 'There are no time entries<br>logged for this date range.<br>You can add a new time entry or try<br>selecting a different date range to view.',
                _LOG_YOUR_TIME_: 'LOG YOUR TIME',
                _SEE_HOW_IT_WORKS_: 'SEE HOW IT LOOKS'
            },
            SAMPLE_MODAL: {
                _TITLE_: 'Sample of logged time',
                _TEXT_: 'This is what your time tracker should look like once you start logging time.',
            },
            TOOL_INVOICE: {
                _TITLE_: 'What project is this for?',
                _SUB_TITLE_: 'We\'ll attach this invoice to the chosen project.'
            },
            FILTER: {
                _ALL_TIME_: 'All time',
                _LAST_30_DAYS_: 'Last 30 days',
                _LAST_90_DAYS_: 'Last 90 days',
                _CUSTOM_RANGE_: 'Custom range'
            }
        },

        FIRST_PROJECT_MODAL: {
            _TITLE_: "Meet Eliot McBot",
            _BODY_: "Want to see what it’s like to book by interacting with an imaginary client? If not, feel free to kickoff a project with a real client.",
            _CREATE_PROJECT_: "USE A REAL CLIENT",
            _START_DEMO_PROJECT_: "LET'S PLAY PRETEND"
        },

        EMPTY_STATES_SCREEN: {
            FILES: {
                _CLIENT_TITLE_: "Easily find project files",
                _CLIENT_TEXT_: "Every time we share a file or attachment,\n you’ll find them here.",
                _TITLE_: "If you used it, it’ll be here",
                _TEXT_: "Keep track of your project files and their status, as well as all images and attachments you share.",
                _TEXT_INLINE_LINK_: "See a preview",
                _CTA_TEXT_: "create new",
            },

            ACTIVITY: {
                _TITLE_: "If it happened, it’ll be here",
                _TEXT_FILES_: "You’ll see active client files as well as a timeline with file-completion stages, communications, payments, and more.",
                _TEXT_: "You’ll see active client files as well as a timeline with file-completion stages, communications, payments, and more.",
                _TEXT_INLINE_LINK_: "See a preview",
                _CTA_TEXT_: "create new",
            },

            FACEBOOK_LEADS: {
                _TITLE_: "Let’s bring new leads",
                _TEXT_: "Connect one of your Facebook pages to start getting new business leads from your Lead Ads.",
                _CTA_TEXT_: "ADD FACEBOOK PAGE",
                _SECONDARY_TEXT_: "Learn more about Facebook/Instagram Lead Ads"
            },


        },

        EXAMPLES_MODAL: {
            _USE_THIS_TEMPLATE_: 'USE THIS TEMPLATE',
            _LIVE_PREVIEW_: 'LIVE PREVIEW',
        },

        TEMPLATES: {
            LABELS: {
                _USE_THIS_TEMPLATE_: 'USE THIS TEMPLATE',
                _PUBLISHED_: 'PUBLISHED',
                _TEMPLATE_: 'TEMPLATE',
                _ACTIVE_: 'ACTIVE',
                _INACTIVE_: 'INACTIVE'
            },
            TOAST: {
                _TITLE_: 'You are now editing this file from your project.',
                _SUB_TITLE_: 'Any new changes you make here will not be applied to the template.'
            }
        },

        LEADSOURCE: {
            EDIT_MODAL: {
                _AFTER_DELETION_: 'After deletion, {{type}} formerly categorized as',
                _AFTER_DELETION_OTHER_: 'will now be under "Other".',
                _OK_DELETE_: 'OK, DELETE',
                _ADD_SOURCE_: 'Add Source',
                _ADD_PROJECT_: 'Add Project Type',
                _ADD_SPACE_: 'Add Company Space',
                _REACHED_MAX_LIMIT_FOR_LEAD_SOURCES_: 'You\'ve reached the limit for lead sources. Edit or delete an existing one to add more.',
                _YOU_ALREADY_HAVE_THIS_NAME_: 'You already have a source with this name.',
                _EMPTY_NOT_ALLOWED_: 'Empty text is not allowed'
            },
            TOAST: {
                _TITLE_: 'Update contact forms with your lead source changes.',
                _SUB_TITLE_: 'changes or update your forms now.',
                _UPDATE_NOW_: 'UPDATE NOW',
                _YOU_CAN_: 'You can ',
                _REVIEW_: 'review ',
                _CONFIRMATION_SAVED_: 'Your contact forms have been successfully updated!'

            },
            EMPTY_STATE: {
                _TITLE_: 'Get deeper insights',
                _TEXT_: 'Know what lead sources are performing well so you know what channels to concentrate on.',
                _HOW_IT_WORKS_: 'SEE WHAT IT\'S LIKE',
                _DISMISS_: 'OK, GOT IT',
            },
            NO_DATA: {
                _TITLE_: "There’s not enough data to present.",
                _TITLE_MOBILE_: "There’s not enough.",
                _TEXT_: "Try changing the time frame or the metric"
            },
            CHARTS: {
                DISTRIBUTION: {
                    _NAME_: "distribution",
                    _TITLE_: "Showing all incoming <b>leads</b> by <b>lead source</b> during {{actual_dates}}",
                },
                LEADS_VALUE: {
                    _NAME_: "Leads value",
                    _TITLE_: "Showing the average <b>value ($)</b> per <b>lead source</b> during {{actual_dates}}",
                },
                CONVERSION_RATE: {
                    _NAME_: "conversion rate",
                    _TITLE_: "Showing the average <b>conversion rate (%)</b> from inquiry to booked per <b>lead source</b> during {{actual_dates}}",
                },
                TIME_TO_BOOK: {
                    _NAME_: "Time to booked",
                    _TITLE_: "Showing average <b>number of days</b> from inquiry to booked project per <b>lead source</b> during {{actual_dates}}",
                },

            }
        },

        BUSINESS_TOOLS: {
            _START_FREE_TRIAL_: 'START FREE TRIAL',
            _START_7_DAY_FREE_TRIAL_: 'START 7-DAY FREE TRIAL',
            _CTA_TEXT_: 'No credit card required.',
            _POWERFUL_TOOL_: 'POWERFUL TOOLS',
            _BUILT_FOR_CREATIVES_: 'BUILT FOR CREATIVES',
            _SUB_TEXT_1_: ' Hi {{name}}! You’ve tapped into the Free Community,',
            _SUB_TEXT_2_: 'now explore the business tools creatives trust.',
            _SUB_TEXT_3_: 'Hi {{name}}! Explore the tools creatives and <br> other small businesses trust.',
            _SUB_TEXT_4_: 'Hi {{name}}! Explore the tools {{company_type}} and <br> other small businesses trust.',
            _BACK_TO_WORKSPACE_: 'BACK TO WORKSPACE',

            TOOLS: {
                MOBILE_APP: {
                    _NAME_: "Mobile App",
                    _TITLE_: "Mobile App",
                    _SUBTITLE_: "Your office, in your pocket.",
                },
            },
        },

        HOME: {
            STATES: {
                TRIALER: {
                    _TITLE_: 'Welcome home, {{first_name}}!',
                    _SUBTITLE_: 'This is your home screen. Here you\'ll see recent activity and next steps for the day. ',
                    _SUBTITLE_PERSONAL_: 'Let’s get {{company_name}} set up for success 🚀.',
                },
                MORNING: {
                    _TITLE_: 'Good morning, {{first_name}}',
                    _SUBTITLE_: 'Coffee? Check. It\'s time to take on the day! Let\'s get started...'
                },
                AFTERNOON: {
                    _TITLE_: 'Good afternoon, {{first_name}}',
                    _SUBTITLE_: 'A quick lunch break to recharge, then back to HoneyBook to take charge! 💪'
                },
                EVENING: {
                    _TITLE_: 'Good evening, {{first_name}}',
                    _SUBTITLE_: 'Breaking the boundaries of the 9-5 🕔 We\'re with you every step of the way.'
                },
                NIGHT: {
                    _TITLE_: 'Hey there, {{first_name}}',
                    _SUBTITLE_: 'Burning the midnight oil? We\'ve all been there 😫 Keep up the good work!'
                },
                SETUP_GUIDE: {
                    _TITLE_: 'Still getting to know HoneyBook?',
                    _SUBTITLE_: 'Use the Setup Guide to continue learning about key features.'
                },
                HAPPY_BIRTHDAY: {
                    _TITLE_: 'Happy Birthday {{first_name}}!',
                    _SUBTITLE_: 'Don’t work too hard today, enjoy your special day 🥳'
                },
                YEAR_REVIEW: {
                    _TITLE_: '{{first_name}}, a new year is here!',
                    _SUBTITLE_: 'Take a moment to ',
                    _LINK_: 'review your year, 2023'
                },
                YEAR_REVIEW_OTAM: {
                    _SUBTITLE_: ", and don't forget to ",
                    _LINK_: 'try out our new builder here.'
                },
                OTAM_MIGRATION: {
                    _TITLE_: 'Exciting news, {{first_name}}!',
                    _SUBTITLE_: 'Big updates are coming your way, a powerful new builder is here!',
                },

                OOO_DURING_XMAS: {
                    _TITLE_: '{{first_name}}, ring in the new year',
                    _SUBTITLE_: 'Let clients know you’re celebrating #hello2024. Set an ',
                    _LINK_: "OOO message"
                }

            },

            _SETUP_GUIDE_LINK_: 'GO TO SETUP GUIDE',

            TOOLTIPS: {
                REPORTS: {
                    LEADS: {
                        _TITLE_: 'Your reports just got better!',
                        _TEXT_: 'Get insights to your lead sources performance and where your bookings are coming from.',
                        _CTA_TEXT_: 'See what’s new'
                    }
                }
            },

            LABELS: {
                _PROJECTS_WITH_NEW_ACTIVITY_: "project{{ (projectsNumber !== 1) ? 's' : ''}} with new activity",
                _NEW_INQUIRIES_: "new {{ (inquiriesNumber !== 1) ? 'inquiries' : 'inquiry'}}",
                _SHEDULED_EVENTS_TODAY_: "scheduled event{{ (eventsNumber !== 1) ? 's' : ''}} today",
                _SHEDULED_EVENTS_WEEK_: "scheduled event{{ (eventsNumber !== 1) ? 's' : ''}} later this week",
                _TASKS_DUE_TODAY_: "task{{ (tasksNumber !== 1) ? 's' : ''}} due today",
                _TOTAL_TASKS_: "total task{{ (tasksNumber !== 1) ? 's' : ''}}",
                _PAYMENTS_OVERDUE_: "payment{{ (paymentsNumber !== 1) ? 's' : ''}} overdue",
                _PAYMENTS_PAID_: "payment{{ (paymentsNumber !== 1) ? 's' : ''}} paid in the last week",
                _TOP_LEAD_SOURCE_: 'Top lead source this year: ',
                _VALUE_BOOKINGS_OF_YEAR_: 'Value of bookings this year',
                _SEE_MORE_: 'See more',
                _SEE_PROJECTS_: 'See more projects'
            },

            EMPTY_STATES: {
                _NO_TASKS_TODAY_: 'No tasks due today',
                _NO_TASKS_: 'No tasks due in general',
                _TASKS_TITLE_: 'Line up your to-do\'s',
                _TASKS_SUBTITLE_: 'Track your tasks across projects so nothing falls through the cracks.',
                _NO_SCHEDULED_EVENTS_TODAY_: 'No scheduled events today',
                _NO_SCHEDULED_EVENTS_WEEK_: 'No scheduled events later this week',
                _CAL_TITLE_: 'A smarter kind of calendar',
                _CAL_SUBTITLE_: 'Ciao to missing appointments, double-bookings, and back & forths.',
                _NO_INQUIRIES_: 'No new inquiries',
                _NO_PROJECTS_: 'No projects with new activity',
                _PROJECTS_TITLE_: 'Manage Projects like a Boss',
                _PROJECTS_SUBTITLE_: 'Never miss a beat. Follow up with clients at every stage of the process.',
                _NO_PAYMENTS_: 'No overdue payments',
                _NO_PAYMENTS_COMMING_: 'No payments paid in the last week',
                _PAYMENTS_TITLE_: 'Follow the money',
                _PAYMENTS_SUBTITLE_: 'Keep track of your payments and expenses so you never miss a penny',
                _YOUR_LEAD_SOURCE_: 'No lead sources recorded',
                _NO_PROJECTS_LEFT_: 'No bookings yet',
                _REPORTS_TITLE_: 'Easy-to-digest data',
                _REPORTS_SUBTITLE_: 'Your home for business analytics. Track monthly income, bookings and lead sources.',
                _CAUGHT_UP_: 'Caught up.',
                _NO_NEW_ACTIVITY_: 'No new activity in any of your projects',

                HOME_FOR_TRIALS: {
                    _CALENDAR_: 'Know what events are coming up. Connect your Google calendar to keep everything in sync.',
                    _TEMPLATES_: 'Build your emails, proposals, and files once to save time and showcase your brand every time.',
                    _TASKS_: 'Track all of your project and personal tasks to keep everything on track. ',
                }
            },

            TOOLS: {
                LABELS: {
                    _MOBILE_APP_: 'Mobile App',
                    _REFERRALS_: 'Referrals',
                    _MY_REFERRALS_: 'My Referrals',
                    _BATCH_EMAIL_: 'Batch email',
                    _LIBRARY_: 'Library',
                    _TIME_TRACKER_: 'Time Tracker',
                    _WORKFLOW_: 'Automations',
                    _CONTACT_FORM_: 'Contact Form',
                    _TEMPLATES_: 'Templates',
                    _BRANDING_: 'Branding',
                    _CALENDAR_: 'Calendar',
                    _TASKS_: 'Tasks',
                    _BOOKKEEPING_: 'Bookkeeping',
                    _REPORTS_: 'Reports',
                    _MORE_TOOLS_: 'More tools',
                    _SEE_MORE_TOOLS_: 'See more tools'
                }
            },

            MOBILE: {
                HEADING: {
                    _GET_STARTED_: 'Get started',
                    _RECENT_ACTIVITY_: 'Recent activity',
                    _CREATE_NEW_: 'Create new',
                    _EXPLORE_TOOLS_: 'Explore tools',
                },
                SETUP_GUIDE_BANNER: {
                    _COPY_: 'Explore all of the features we offer and get your account set up in no time in our Setup Guide.',
                    _CTA_: 'Go to the Setup Guide',
                }
            },
            LOGIN_TWO_FACTOR_AUTH_BANNER: {
                _TITLE_: 'Add an extra layer of security to your account',
                _SUBTITLE_: 'We recommend giving your account greater protection by using 2-step verification login.',
                _NOT_NOW_: 'NOT NOW',
                _MAIN_CTA_: 'ADD 2-STEP VERIFICATION'
            },
            TAX_ENTITIES_BANNER: {
                _TITLE_: 'Action required by month’s end: Form 1099 for 2023',
                _BODY_TEXT_: 'It’s necessary to make sure your Form 1099 is accurate and ready for submission to the IRS.\nTake 5 minutes to review, correct, and confirm your personal and business info.',
                _CTA_: 'GET STARTED'
            },
            FORM_1099_DOWNLOAD_BANNER: {
                _TITLE_: 'Your annual Form 1099 is ready for you to download',
                _BODY_TEXT_: 'Securely download your Form 1099 and use its content to accurately file your income taxes with the IRS.',
                _CTA_: 'GET YOUR FORM'
            }
        },

        REFERRAL_DASHBOARD: {
            _BANNER_TITLE_: 'Unlock the power of your network',
            _BANNER_TEXT_: 'Connect your Google account and discover a contact list brimming with untapped referrals.',
            _GOOGLE_SYNC_: 'Connect',
            _SUGGESTED_FOR_YOU_: 'Suggested for you',
            _NOT_HONEYBOOK_USERS_YET_: 'These friends aren\'t on HoneyBook yet.',
            _REFERRED_: 'referred',
            _SYNC_YOUR_CONTACTS_: 'Sync your contacts to find people you already know.',
            _GOOGLE_: 'Google',
            _SHARE_PERSONAL_LINK_: 'Share your personal referral link',
            _SHARE_: 'Share',
            _SEND_INVITE_: 'Send an invite email',
            _REFER_CTA_: 'Send email',
            _REFER_AGAIN_: 'Refer Again',
            _NO_ONES_HERE_YET_: 'No one is here yet. How about referring someone else to earn that extra',
            _TOS_: 'Terms & conditions apply',
            _REFERRAL_LINK_MESSAGE_: '💰 Get HoneyBook for {{discountAmount}} for {{discountDuration}} with my <span class="referrals-content__referral-message-footer-fake-link">referral link</span>',
            _CHOOSE_REFERRAL_OPTION_: 'Choose what you want to offer',
            _REFERRAL_REGULAR_OPTION_: 'Give 30% for 1 year, earn $100 when your referral subscribes.',
            _REFERRAL_EDUCATOR_OPTION_: 'Give 30% for 1 year, earn up to $200 when your referral subscribes.',
            _PENDING_REFERRAL_TOOLTIP_: 'This referral hasn\'t yet met the payout requirements.',
            _GO_TO_RESOURCES_TITLE_: 'Jazz up your messaging',
            _GO_TO_RESOURCES_BODY_: 'Make your emails and posts pop with exciting graphics. Find ideas on the <a class="referrals-content__go-to-resources-text__link" href="/app/invite/resources"">Resources tab</a>',
        },

        EDITABLE_HEADER: {
            LABEL: {
                _EDIT_HEADER_: 'Edit Header',
                _TAB_TO_EDIT_IMG_: 'Tap on the logo or header image to edit.',
                _CHANGES_SAVED_FILE_ONLY_: 'Any change you make will be saved for this file only.',
                _CHANGE_IMAGE_: 'Change image',
                _REMOVE_IMAGE_: 'Remove image',
                _HEADER_IMAGE_: 'Header image',
                _BILL_TO_: 'Bill to:',
                _BILL_TO_MOBILE_: 'Bill to',
                _ADD_CLIENT_: 'Add client',
                _LEAVE_FIELD_EMPTY_: 'Leave field empty',
                _ADD_BILL_TO_RECIPIENT_: 'Add bill to recipient',
                _BILL_TO_TOOLTIP_: ' Anyone added as a "bill to" recipient will also be added as a participant to this project.',
            }
        },

        SUGGESTIONS: {
            PIPELINE: {
                _TEXT_: 'Welcome to your projects! <strong>Attend a live webinar or watch an intro video</strong> to learn more about how to get started!',
                _PRIMARY_CTA_: 'SIGN UP',
                _SECONDARY_CTA_: 'Watch video'
            },
            WORKSPACE: {
                _TEXT_: 'Congratulations on creating your first project! <strong>Attend a webinar</strong> to learn more about your account!',
                _PRIMARY_CTA_: 'SIGN UP'
            },
            HOME: {
                _TEXT_: 'Want to learn more about your HoneyBook account? <strong>Attend a Q&A webinar</strong> where you can ask all your questions!',
                _PRIMARY_CTA_: 'SIGN UP'
            },
            CREATING_SENDING_PROPOSAL: {
                _TEXT_: 'Welcome to your proposal! <strong>Watch this video</strong> to learn how to easily create and send proposals through HoneyBook',
                _PRIMARY_CTA_: 'WATCH VIDEO',
                _SECONDARY_CTA_: ''
            },
            CREATING_SENDING_INVOICE: {
                _TEXT_: 'Welcome to your invoice! <strong>Watch this video</strong> to learn how to easily create and send invoices through HoneyBook.',
                _PRIMARY_CTA_: 'WATCH VIDEO'
            },
            FIRST_VISIT_TEMPLATES: {
                _TEXT_: 'Welcome to your templates! <strong>Watch this video</strong> to learn how to easily create and send files through HoneyBook',
                _PRIMARY_CTA_: 'WATCH VIDEO',
                _SECONDARY_CTA_: ''
            },
            CONNECT_CALENDAR: {
                _TEXT_: '<strong>Connect your Google calendar</strong> to<br>make sure you don’t miss any important dates.',
                _PRIMARY_CTA_: 'CONNECT NOW',
                _SECONDARY_CTA_: 'Learn more'
            },
            CONNECT_GMAIL: {
                _TEXT_: 'You’ve sent your first message! <strong>Connect your Gmail</strong> to have all your communication with your client in one place.',
                _PRIMARY_CTA_: 'CONNECT GMAIL'
            },
            SYNC_GOOGLE_CONTACTS: {
                _TEXT_: 'This is where you’ll be able to manage all of your client information. <strong>Connect your Google account</strong> to sync your current contacts.',
                _PRIMARY_CTA_: 'CONNECT NOW'
            },
            CONTACT_FORM: {
                _TEXT_: 'Hey, we noticed you added a website. Wanna take it up a notch? Add the Contact Form to your site to streamline leads right into HoneyBook!',
                _PRIMARY_CTA_: 'SET UP NOW'
            }
        },

        PEOPLE: {
            UPLOAD_CLIENTS: {
                _TITLE_: 'Import all of your clients now?',
                _SUB_TITLE_: 'Import all of your client into HoneyBook now, or click the link at the bottom of the page to just add one. (Don\'t worry, you can always do this later from the Client\'s tab, too)',
                _UPLOAD_CSV_: 'Upload a CSV',
                _UPLOAD_CSV_DESC_: 'We\'ll help you format a CSV so you can easily import your clients.',
                _UPLOAD_GMAIL_: 'Sync with GMAIL',
                _UPLOAD_GMAIL_DESC_: 'Connect your Gmail to select and import from your contacts.',
                _NO_THX_UPLOAD_ONE_CLIENT_: 'No thanks, just add one client',
                _CONNECT_WITH_GMAIL_: 'Connect with Gmail',
                _IMPORT_CLIENTS_: 'Import Clients',
                _WE_ARE_ADDING_YOUR_CLIENTS_: 'Great, we\'re adding your clients...',
                _THIS_IMPORT_COULD_TAKE_SOME_TIME_: 'This import could take some time.',
                _FEEL_FREE_TO_HANDLE_: 'Feel free to handle other business in HoneyBook and we\'ll notify you when it\'s complete.',
                _HELP_CENTER_: 'help center',
                _IMPORT_YOUR_CONTACTS_FROM_GMAIL_: 'Import you contacts from Gmail',

                CSV: {
                    _TITLE_: 'Upload a CSV of your clients',
                    _ONLY_CERTAIN_FIELDS_ARE_IMPORTED_: 'Only certain fields are supported in our CSV upload. Please',
                    _DOWNLOAD_OUR_TEMPLATE_: 'download our template',
                    _MAKE_SURE_INFO_IS_IMPORTED_CORRECTLY_: 'to make sure your client information is imported correctly. Questions?',
                    _LEARN_MORE_: 'Learn more here.',
                    _DRAG_INTO_THIS_SCREEN_: 'Drag into this screen to upload',
                    _FILE_UPLOAD_LIMIT_: 'Your file must be a .csv or .xls file type under 2MB',
                    _CHOOSE_FILE_: 'Choose File'
                },

                ERROR: {
                    _ERROR_UPLOADING_CSV_: 'Error with CSV upload',
                    _UPLOAD_IN_PROGRESS_ERROR_: 'Another CSV import is currently in progress. Please allow it some time to finish and try again',
                    _TRY_UPLOADING_AGAIN_: 'You can try uploading it again or visit our',
                    _UNSUPPORTED_FIELDS_: 'Your CSV contains unsupported fields,',
                    _INFO_CORRECTLY_: 'to be sure your client information imports correctly.'
                },
            },
            LABELS: {
                _CLIENTS_: 'Clients',
                _UPLOAD_CSV_: 'UPLOAD CSV',
                _IMPORT_MORE_THAN_ONE_CLIENT_: 'Import more than one client:',
                _ADD_CONNECTION_: 'ADD CONNECTION',
                _ADD_CLIENT_: 'ADD NEW CLIENT',
                _SYNC_CONTACTS_: 'Sync Contacts',
                _ADD_CONTACT_: '+ Add Contact'
            }
        },
        CONTACTS: {
            LABELS: {
                _CONTACTS_: 'contacts',
                _SELECTED_: 'selected:',
                _CLEAR_SELECTION_: 'CLEAR SELECTION',
                _ADD_CONTACT_: 'Add new contact',
                _INVITE_TEAM_MEMBER_: 'Invite team member',
                _IMPORT_CONTACTS_: 'Import more than one contact:',
                _SYNC_GOOGLE_CONTACTS_: 'Sync Google contacts',
                _SEND_EMAIL_: 'Send Email',
                GOOGLE: {
                    GOOGLE_API: {
                        _UNKNOWN_ERROR_: '\'Oops, it looks like we couldn\\\'t connect to your Google account.\''
                    }
                },
                _UPLOAD_CSV_: 'UPLOAD CSV',
                _EXPORT_CONTACTS_TO_CSV_: 'EXPORT CSV'
            },
            EMPTY_STATE: {
                _NO_MATCHES_LINE_: "Add clients to easily locate each client’s details, related projects, and private notes.",
                _NO_MATCHES_HEADLINE_: "No contacts that fit your search",
                _NO_CONTACTS_HEADLINE_: "Find contacts info instantly"
            },
            PREFERRED_VENDOR: {
                _PREFERRED_VENDOR_: "Preferred vendor",
                _VENDOR_ADDED_: "{{name}} was added to your Preferred Vendor List. This is a list of professionals you can recommend to your clients."
            },
            POPUPS: {
                DELETE: {
                    _MESSAGE_: "Are you sure you want to delete {{contact_count}} contact{{plural}}?",
                    _CONFIRM_: "YES, DELETE",
                    _CONTACT_FROM_WS_CANNOT_DELETED_: "Workspace contacts cannot be deleted.",
                    _CANNOT_DELETE_SOME_: " {{contact_count}} contact{{plural}} {{is_are}} connected to a project and cannot be deleted."
                },
            },
            BULK: {
                _DELETE_TOAST_SUCCESS_: '{{contact_count}} contact{{plural}} successfully deleted',
            }
        },
        ADD_USER_MODAL: {
            PROGRESS: {
                _YOUR_CLIENTS_UPLOAD_IS_IN_PROGRESS_: 'Your client upload is in progress...',
                _WE_WILL_NOTIFY_WHEN_DONE_: 'We\'ll notify you when it\'s complete. you can view you updated client list form the clients tab.'
            },
            USER_TYPES: {
                _CLIENT_: 'Client',
                _COLLABORATOR_: 'Collaborator',
                _TEAM_MEMBER_: 'Team member'
            },
            BUSINESS_DETAIL: {
                _CLIENT_: 'Business client details',
                _COLLABORATOR_: 'Company details'
            },
            EMPTY_STATE:{
                _TEAM_MEMBER_EMPTY_STATE_:'You don’t have any team members.\nHead out to your company settings to invite one.'
            }
        },

        CLIENT_FLOW_MODAL: {
            STEP_0: {
                _TITLE_: 'What will my client experience?',
                _SUB_TITLE_: 'You create a HoneyBook file and send it to your client.'
            },
            STEP_1: {
                _TITLE_: 'What will my client experience?',
                _SUB_TITLE_: 'Your client receives an email with a link to click and view the file.'
            },
            STEP_2: {
                _TITLE_: 'What will my client experience?',
                _SUB_TITLE_: 'They can then view and respond. Ready to view the file as your client?',
                _CTA_: 'Yes, View As Client'
            }
        },
        ADD_CREDIT_CARD: {
            _YOUR_CARD_WILL_SAVED_FOR_FASTER_PAYMENTS_: 'Your card will be saved for faster payments.',
            _CVV_TOOLTIP_: 'CVV for this card will be required for all future payments. HoneyBook uses world class encryption for storing card details and our systems are PCI DSS security certified.'
        },

        MEDIA_MODAL: {
            LIBRARY: {
                _TITLE_: 'Library',
                _MY_FILES_: 'My Files',
                _EMPTY_LIB_MESSAGE_: 'Think of this area as your storage, or your “Library”. You know that excel sheet with all your inventory, or that pdf you send every client? Upload files here to have access to them at all times.',
                _EMPTY_LIB_TITLE_: 'You don’t have any files yet!',
            },
            UPLOAD: {
                _DROP_IMAGE_: 'Drop image here or ',
                _DROP_ANY_FILE_: 'Drop any file here or ',
                _DROP_IMAGE_HERE_: 'Drop image here',
                _DROP_FILE_HERE_: 'Drop file here',
                _CHOOSE_IMAGE_: ' choose image',
                _CLICK_TO_CHOOSE_: ' click to choose',
                _SIZE_LIMIT_: '15 MB file limit.',
                _UPLOADING_: 'Uploading Image...'
            },
            TITLE: {
                _ADD_IMAGE_: 'Add image',
                _EDIT_IMAGE_: 'Edit image',
                _EDIT_PROJECT_IMAGE_: 'Edit project image',
                _EDIT_SQUARE_LOGO_: 'Edit square logo',
                _ADD_SQUARE_LOGO_: 'Add square logo',
                _ADD_YOUR_LOGO_: 'Add your logo',
                _ATTACH_IMG_OR_FILE_: 'Attach image or file',
                _EDIT_LOGO_: 'Edit logo',
                _EDIT_COVER_HEADER_: 'Edit default header',
                _UPLOAD_NEW_LOGO_: 'Upload New Logo',
            },
            _FOOT_NOTE_: 'Please note, this new icon will replace all icons on\nevery file you’ve sent - past and present',
            _UPLOAD_FOOT_NOTE_DESKTOP_: 'Add a square logo. Don’t have one? You can always add it later.',
            _UPLOAD_FOOT_NOTE_MOBILE_: 'Add a square logo. Don’t have one saved on your phone? You can always add it later from your computer.'
        },

        CLIENT_PORTAL: {
            _DOMAIN_NAME_TITLE_: 'Domain name',
            _DOMAIN_NAME_DESCRIPTION_: 'Personalize and brand all the links that are sent to your clients. This will change the URL for proposals, invoices, contracts, projects etc. You can learn more',
            _CLIENT_PORTAL_: 'Client Portal',
            _CLIENT_PORTAL_DESCRIPTION_: 'A secure way for your clients to login and view their project at any time. Share a link with any of your existing clients in HoneyBook. Or embed your portal into your website. You can learn more ',
            _BANNER_TITLE_: 'Subscribe to customize your domain & portal',
            _BANNER_DESCRIPTION_: 'Become a HoneyBook member to completely customize and white label your client experience, even the URLs they are sent.',
            _BANNER_LINK_: 'CHECK OUT OUR PRICING',
            _DISCLAIMER_: 'Only visible to project collaborators',
            _CREATE_DESC_: 'A unique and secure space you can share with your clients. You can ',
            _CREATE_SEND_CUSTOMIZE_: 'Create, customize and send a unique link to your branded client portal. You can ',
            _LEARN_MORE_LINK_: 'learn more here',
            _VISIT_HELP_CENTER_: ' or visit the ',
            _HELP_CENTER_LINK_: 'help center article.',
            _EDIT_AND_SHARE_URL_: 'Edit and share URL link',
            _NEW_EDIT_AND_SHARE_URL_: 'Share Client Portal URL link',
            _CLAIMED_URL_MODAL_POS_BTN_: 'GOT IT',
            _CREATE_URL_TITLE_: 'Share your unique URL with your clients',
            _NEW_CREATE_URL_TITLE_: 'Share your unique URL with your clients',
            _CREATE_URL_DESC_: 'We’ve created this one specially for you. You can edit it if you want or simply claim it and start designing your log in screen.',
            _CREATE_URL_: 'Create URL Link',
            _CLAIM_URL_: 'Claim URL',
            _CLAIMED_URL_MODAL_TITLE_: 'Your custom URL has been created',
            _CLAIMED_URL_MODAL_TEXT_: 'You can now link to it and share it with clients so they can log in and access your portal.',
            _DISABLE_EDIT_TOOLTIP_: 'In order to customize the prefix of your links you need',
            _DISABLE_EDIT_TOOLTIP_LINK: 'to become a HoneyBook member',
            _DISABLE_EDIT_CUSTOM_TOOLTIP_: 'In order to use your company subdomain you need to become a HoneyBook member',
            _EDIT_URL_: 'Edit URL',
            _SAVE_URL_: 'Save URL',
            _CANCEL_EDIT_URL_: 'Cancel',
            _COPY_URL_: 'Copy Link',
            _COPYING_URL_: 'Copied',
            _SEND_URL_: 'Send Link',
            _URL_CHANGES_COUNTDOWN_: 'You can change your URL {{changesLeft}} more time.',
            _LAST_URL_CHANGE_: 'You cannot change your URL after this.',
            _MAX_URL_CHANGES_: 'You’ve reached the maximum number of times you can change your URL. Please contact us for more info',
            _MAX_CHANGES_TOOLTIP_: 'You cannot edit your client portal prefix anymore, since you have already changed it 3 times.',
            _URL_INVALID_CHARACTERS_: 'Please use only the characters a-z, 0-9 and "-"',
            _LOGIN_SCREEN_: 'Portal login screen preview',
            _CUSTOMIZE_SCREEN_: 'Customize Screen',
            _UNSAVED_WARNING_: 'Any changes you’ve made to your login screen will be immediately visible to visitors.',
            _YES_PUBLISH_: 'Yes, Publish',
            _PUBLISH_CHANGES_: 'Publish changes?',
            _ANY_CHANGES_WILL_BE_LOST_: 'Any changes you\'ve made to your login screen will be lost',
            _YES_EXIT_: 'Yes, Exit',
            _EXIT_WITHOUT_SAVING_: 'Exit without saving?',
            _VENDOR_REDIRECT_: "We’ve recognized you as a member of HoneyBook. Please log in to your account to access your project(s).",
            _LOGIN_TO_HB_: 'LOGIN TO HONEYBOOK',

            _USE_OUR_DOMAIN_: 'Use our domain',
            _USE_OUR_DOMAIN_DESCRIPTION_: 'You can use our “hbportal.co” domain and <br> customize just the prefix:',
            _USE_YOUR_SUBDOMAIN_: 'Use your company subdomain',
            _USE_YOUR_SUBDOMAIN_DESCRIPTION_: 'Completely white label your URLs, by using a subdomain of your company website',
            _CONTACT_US_TO_CHANGE_DOMAIN_: 'Please <a href="mailto:concierge@honeybook.com">contact us</a> if you’d like to change your domain name again.',
            _SUBDOMAIN_CONNECTING_: 'Connecting domain... ',
            _SUBDOMAIN_CONNECTING_NOTIFY_: 'We will notify you when your new domain is active',
            _SUBDOMAIN_ERROR_: 'Domain connection failed',
            _CUSTOM_DOMAIN_CNAME_ERROR_: 'Please verify that the CNAME configuration is correct, and try again.',
            _USE_COMPANY_SUBDOMAIN_: 'USE COMPANY SUBDOMAIN',
            _TRY_AGAIN_: 'TRY AGAIN',
            _CUSTOMIZE_LOGIN_GO_TO_DESKTOP_: 'To customize your client portal login screen go to desktop',
            _WHITE_LABEL_GO_TO_DESKTOP_: 'To completely white label your domain, go to desktop.',
            _ACTIVE_DOMAIN_: 'Active domain',
            _CUSTOM_DOMAIN_SUCCESS_: 'Your new domain was succesfuly connected.',
            _FAILED_SAVING_: 'Failed saving the new domain prefix.',

            PREVIEW: {
                HEADER: {
                    _TITLE_: 'Customize Login Screen'
                }
            },

            SUBDOMAIN_MODAL: {
                _TITLE_: 'Use a subdomain of your company website',
                _STEP_1_TITLE_: 'Select a domain name',
                _STEP_1_DESCRIPTION_: 'Enter a domain name you\'d like to use for all client links. Make sure you own this domain and that you omit any prefix such as "www".',
                _STEP_1_CTA_: 'NEXT: ENTER SUBDOMAIN',

                _STEP_2_TITLE_: 'Enter a subdomain',
                _STEP_2_DESCRIPTION_: 'Pick any subdomain you’d like your clients to see when they access HoneyBook files or projects: ',
                _STEP_2_CTA_: 'NEXT: CREATE SUBDOMAIN',

                _STEP_3_TITLE_: 'Create & redirect subdomain',
                _STEP_3_DESCRIPTION_: 'Go to your domain host for "{{domain}}" and create a CNAME record named "{{subdomain}}".',
                _STEP_3_EASY_CONNECT_DESCRIPTION_: 'Confirm the new CNAME record for "{{domain}}" by logging into your domain host account in the popup window we just opened.',
                _STEP_3_INFO_: 'Point/redirect that CNAME to ssl.honeybook.com Once you’ve finished the step above proceed.',
                _STEP_3_EASY_CONNECT_INFO_: 'After confirming, please close the popup window we opened for you and click the button below to proceed.',
                _STEP_3_EASY_CONNECT_LINK_: 'Launch the domain host again',
                _STEP_3_CTA_: 'I HAVE CREATED A CNAME',
                _STEP_3_EASY_CONNECT_CTA_: 'ACTIVATE SUBDOMAIN',

                _FINAL_TITLE_: '{{subdomain}}.{{domain}} is being connected…',
                _FINAL_DESCRIPTION_: 'We will notify you via email once your new personalized domain is active.',
                _FINAL_CTA_: 'GOT IT',

                _DOMAIN_VERIFIED_: 'Domain verified',
                _SUBDOMAIN_SELECTED_: 'Subdomain selected',
                _VERIFY_DOMAIN_: 'Verifying domain…',
            },

            SETTINGS: {
                _BACKGROUND_: 'BACKGROUND',
                _COLOR_: 'Color',
                _LOGO_: 'Logo',
                _SHOW_LOGO_: 'Show logo',
                _SQUARE_: 'Square',
                _FULL_: 'Full',
                _BRAND_YOUR_SCREEN_: 'Brand your login screen even more with your logo.',
                _UPLOAD_NOW_: 'Upload it now',
                _UPLOAD_LOGO_FROM_SETTINGS_: 'Upload this logo in your Settings (under "Company Brand") if you\'d like to display it here'
            },

            CREATE_FORM: {
                _PW_: 'Create your password',
                _CONF_PW_: 'Confirm your password',
                _CREATE_PASS_: 'Create a login below to view and access your project(s) with {{title}} at any time.',
                _CREATE_LOGIN_: 'CREATE LOGIN',
                _CREATING_LOGIN_: 'CREATING YOUR LOGIN...',
                _PW_MISMATCH_: 'Passwords do not match'
            },

            LOGIN_FORM: {
                _ENTER_PASSWORD_: 'Enter password for {{email | hbmarker}}',
                _INCORRECT_PASSWORD_: 'The password you entered is incorrect',
                _INCORRECT_USER_OR_PASSWORD_: 'The username/password you entered is incorrect'
            },

            RESET_PW: {
                _RESET_PW_: 'Reset password for {{email | hbmarker}}',
                _SET_PW_: 'Set password for {{email}}',
                _PW_PLACEHOLDER_: 'New password (8 chars min)',
                _PW_CONF_PLACEHOLDER_: 'Confirm new password',
                _PW_MISMATCH_: 'Passwords do not match',
                _PW_MIN_CHARS_: 'Password must contain at least 8 chars'
            },

            FORGOT_PW: {
                _FORGOT_TITLE_: 'Forgot your password?',
                _INSTRUCTIONS_: 'Enter the email associated with your account, and we\'ll send you a link to reset your password.',
                _SEND_LINK_: 'SEND RESET LINK',
                _SENDING_LINK_: 'SENDING RESET LINK...',
                _CONF_PW_: 'Confirm your password',
                _INCORRECT_EMAIL_: 'Incorrect Email'
            },

            SEND_LINK: {
                _TITLE_: 'Send client portal link',
                _ENTER_EMAILS_: 'Add the email address of 1 or more contacts who have projects with you.',
                _SEND_TO_: 'Share client portal with',
                _SEND_LINK_TO_: 'Send link to',
                _REVIEW_EMAIL_: 'Review Email',
                _COPY_LINK_: 'copy link',
                _COPIED_: 'copied',
                _SELECT_WHO_TO_SEND_LINK_: 'Select who you\'d like to send your link to below.',
                _EMAIL_BODY_: 'Access your client portal via the magic link to view details, stay updated on project activities, make payments, and more.',
                _CLIENT_PORTAL_: 'Go to client portal',
                _SELECT_THE_PARTICIPANTS_: 'Select the participants you would like to send the link to your client portal to below. They will receive an email with a link to log in.',
                _SHARE_URL_LINK_: 'Quickly share access to your client portal with project participants via email or copy link.',
                _ENTER_EMAILS_OF_PARTICIPANTS_: 'Enter the emails of the participants you would like to send the link to your client portal to.'
            },

            DEMO: {
                _STEP_1_: 'After you’ve sent a file to your client or added them to a project, you can choose to send an email inviting them to log in to their client portal through your unique URL link. Once they’ve created a password for their login, your client will then be able to access the portal at any time and view all of your projects with them.',
                _STEP_1_TITLE_: 'Your client portal',
                _STEP_2_: 'In their client portal, clients see the same activity feed that you have on your side and can view and access only the files that you’ve sent to them. They can also view and make payments. The private section (found on the right side of your workspace) is not visible or accessible to your clients at all.',
                _STEP_2_TITLE_: 'What can my clients see?',
                _STEP_3_: 'Showcase your brand to clients by customizing your client portal login screen. You can change fonts, display your brand color, and add your logo.',
                _STEP_3_TITLE_: 'Showcase your brand',
            },

            WORKSPACE: {
                _SEND_CLIENT_LINK_: 'Send client portal link'
            },

            VENDOR_LOGIN: {
                _WE_RECOGNIZED_YOU_: "We’ve recognized you’re a HoneyBook member",
                _LOGIN_WITH_COMPANY_: "Please log into your account to view your project with {{companyName}}"
            }
        },

        WORKFLOW_EDU: {
            _TITLE_: 'Cue up a custom sequence of steps to keep your client process moving along even when you’re logged out.',
            _SECTION_A_: 'Respond to inquiries fast by attaching an automation to your contact form.',
            _SECTION_B_: 'Adapt automations to your own process. Create as many as you need.',
            _SECTION_C_: 'Keep it personal. Automate as much or as little of the process as you like.',
            _WANT_TO_KNOW_MORE_: 'Want to know more? Check out our',
            _NEED_HELP_: 'Need help with your automations? ',
            _VISIT_: 'Visit the ',
            _HELP_CENTER_: 'help center',
            _OR_: ' or ',
            _PROS_: 'hire a pro'
        },

        MWEB_BLOCK_MODAL: {
            _TITLE_: 'You’re all set!',
            _SUBTITLE_: 'Go to your computer to get started',
            BODY_COPY: {
                _GO_TO_: 'Go to your ',
                _EMPHASIS_: 'laptop or PC ',
                _OPEN_EMAIL_: 'and open the email we sent you. Click the link inside to auto-log in to your account.',
            },
            _RATING_LABEL_: 'Rated 5-stars by Capterra',
            _FOOTER_COPY_: 'Once you log in on your computer, you’ll have full access to your account on all devices.'
        },

        EXAMPLE_FILE: {
            _BUTTON_TEXT_: 'Button',
            _BUTTON_TEXT_CONTRACT_: 'Sign'
        },

        FLOWS_ONBOARDING: {
            _BACK_: 'Back',
            _CONTINUE_: 'Continue',
            _CONTINUE_EXPLORING_: 'Preview Now',
            _SKIP_: 'Skip',
            _SKIP_BRANDING_: 'Skip Branding',
            _LATER_: 'Do This Later',
            _USE_THIS_TEMPLATE_: 'USE THIS TEMPLATE',
            _LIST_TITLE_: 'Let’s start with the basics',
            _LIST_SUBTITLE_: 'Setting up your account for online payments will streamline your clientflow and get you closer to <strong>cha-ching!</strong>',
            STEP_BRANDING: {
                _HEADER_TITLE_: 'Personalize',
                _HEADER_TITLE_SHORT_: 'Personalize',
                _TITLE_: 'Let’s personalize your account with your brand elements.',
                _SUB_TITLE_: 'You’ll be able to showcase even more of your brand on the brand settings, including your fonts and imagery.',
                _TITLE_MWEB_: 'Let’s personalize your account with your brand elements.',
                _SUB_TITLE_MWEB_: 'You’ll be able to showcase even more of your brand on the brand settings, including your fonts and imagery.',
                PREVIEW_MODAL: {
                    _TITLE_: 'Preview',
                    _BODY_: ' Logo, header image, and button color appear on all client-facing files.',
                    _FOOTER_: 'You can always edit within a file or from:\nsettings > company brand.'
                },
            },
            STEP_SERVICES: {
                _HEADER_TITLE_: 'Your first smart file',
                _HEADER_TITLE_SHORT_: 'Your first file',
                _TITLE_: 'Looking good! Now, what would you like to explore first?',
                _SUB_TITLE_: 'Based on your selection, we’ll offer some templates for you to explore.',
                _TITLE_MWEB_: 'Looking good! Now, what would you like to explore first?',
                _SUB_TITLE_MWEB_: 'Based on your selection, we’ll offer some templates for you to explore.',
            },
            STEP_TEMPLATES: {
                _HEADER_TITLE_: 'Select a template',
                _HEADER_TITLE_SHORT_: 'Select a template',
                _TITLE_: 'Great! Choose one of these templates to see it in action.',
                _SUB_TITLE_: 'There’s more where these came from. You’ll be able to explore even more templates later in the Gallery 😉 ',
                _TITLE_MWEB_: 'Great! Choose one of these templates to see it in action.',
                _SUB_TITLE_MWEB_: 'There’s more where these came from. You’ll be able to explore even more templates later in the Gallery 😉 ',
            },
            STEP_FINAL_SCREEN: {
                _HEADER_TITLE_: 'Try it out',
                _HEADER_TITLE_SHORT_: 'Try it out',
                _TITLE_: 'Nice choice! 💪 ',
                _SUB_TITLE_: 'This template is a great way to start.\nLets see how it looks in a project, where you will be able to add clients and share it.\nWe already added your company info and branding.',
                _TITLE_MWEB_: 'Nice choice! 💪 ',
                _SUB_TITLE_MWEB_: 'Let’s see how it looks in a project, where you will be able to add clients and share it.',
                _CTA_TEXT_: 'view in project'
            },


            STEP_BRANDING_ABC: {
                _HEADER_TITLE_: 'Personalize',
                _HEADER_TITLE_SHORT_: 'Personalize',
                _TITLE_: 'Personalize your account with your business’s brand',
                _SUB_TITLE_: 'Once you finish setup, you’ll be able to showcase even more of your business’s unique style in the\nbrand settings, including your preferred fonts and imagery.',
                _TITLE_MWEB_: 'Start by adding your brand',
                _SUB_TITLE_MWEB_: 'Make your account feel like home.',
            },
            STEP_SERVICES_ABC_VARIANT_SKIP_TEMPLATE: {
                _HEADER_TITLE_: 'Your first smart file',
                _HEADER_TITLE_SHORT_: 'Your first file',
                _TITLE_: 'Choose where you’d like to start',
                _SUB_TITLE_: 'This is just a starting point, the other parts of your booking process will be available for you\nto explore in your account.',
                _TITLE_MWEB_: 'Where would you like to start?',
                _SUB_TITLE_MWEB_: 'Let’s show you what you can do with HoneyBook.'
            },
            STEP_SERVICES_ABC_VARIANT_LEGACY_LIKE: {
                _HEADER_TITLE_: 'Add services',
                _HEADER_TITLE_SHORT_: 'Services',
                _TITLE_: 'Looking good! Now, what services do you offer?',
                _SUB_TITLE_: 'Start with your top 2-3, you can always add more later.',
                _SECONDARY_TITLE_LINK_: 'Where do these go?',
                _NUDGE_TEXT_: "You're off to a great start. Click 'Continue' and add more services later.",
                EXPLAIN_MODAL: {
                    _TITLE_: 'Where do services go?',
                    _COPY_: 'Your services will be saved to an item bank for quick and easy file building.',
                    _CTA_: 'Got it',
                }
            },
            STEP_FINAL_SCREEN_ABC: {
                _HEADER_TITLE_: 'Try it out',
                _HEADER_TITLE_SHORT_: 'Try it out',
                _TITLE_: 'Great! Let’s start building your first smart file',
                _SUB_TITLE_: 'We’ve created a test project for you. You’re one step away from sending your first invoice with Honeybook. You can always explore other templates in the <a href="/app/template-gallery">template gallery</a>, or start with the invoice template we created for you.',
                _TITLE_MWEB_: 'Fantastic job!\nYour template is ready!',
                _SUB_TITLE_MWEB_: 'You can go back to all of our other templates on the <a href="/app/template-gallery">Template Gallery</a>. We’ve created a test project for you, where you can find your brand new file and send yourself a test:',
                _CTA_TEXT_: 'view in project'
            },
            STEP_FINAL_SCREEN_ABC_LEGACY_LIKE: {
                _HEADER_TITLE_: 'Test invoice',
                _HEADER_TITLE_SHORT_: 'Test invoice',
                _TITLE_: 'Great! Let’s start building your first smart file',
                _SUB_TITLE_: 'We’ve created a test project for you. You’re one step away from sending your first invoice with Honeybook. You can always explore other templates in the <a href="/app/template-gallery">template gallery</a>, or start with the invoice template we created for you.',
                _TITLE_MWEB_: 'Fantastic job!\nYour template is ready!',
                _SUB_TITLE_MWEB_: 'You can go back to all of our other templates on the <a href="/app/template-gallery">Template Gallery</a>. We’ve created a test project for you, where you can find your brand new file and send yourself a test:',
                _CTA_TEXT_: 'view in project'
            },
            STEP_INSTANT_BRANDING:{
                _HEADER_TITLE_: 'Personalize',
                _HEADER_TITLE_SHORT_: 'Personalize',
                _TITLE_: 'Take a look at the brand elements we found',
                _SUB_TITLE_: 'If we missed the mark, you can edit the results or clear them and add\nyour own. <a href="https://help.honeybook.com/en/articles/7934304" target="_blank" style="color:#597eff;">How brand elements are added</a>',
                _TITLE_MWEB_: 'Take a look at the brand elements we found',
                _SUB_TITLE_MWEB_: 'If we missed the mark, you can edit the results or clear them and add your own. <a href="https://help.honeybook.com/en/articles/7934304" target="_blank" style="color:#597eff;">How brand elements are added</a>',
                DELETE_ASSETS_TOAST: {
                    _TITLE_: 'Imported brand elements were deleted',
                    _BODY_: 'Anything you uploaded manually was not removed.'
                }
            },
            CONTRACT_ONBOARDING: {
                STEP_BRANDING: {
                    _HEADER_TITLE_: 'Add your brand',
                    _HEADER_TITLE_SHORT_: 'Add your brand',
                    _TITLE_: 'Brand your contract, make an impression',
                    _SUB_TITLE_: 'Upload your logo, add a photo, and make it pop with your brand color.',
                    _TITLE_MWEB_: 'Brand your contract, make an impression',
                    _SUB_TITLE_MWEB_: ''
                },
                STEP_TEMPLATES: {
                    _HEADER_TITLE_: 'Select a contract',
                    _HEADER_TITLE_SHORT_: 'Select a contract',
                    _TITLE_: 'Select a contract template or use your own',
                    _SUB_TITLE_: 'These templates are prepared by a lawyer—discover the best one for you.\nAnd, if it’s not a perfect fit, simply edit it to your needs.',
                    _TITLE_MWEB_: 'Select a contract template or use your own',
                    _SUB_TITLE_MWEB_: '',
                    _CHOOSE_YOUR_OWN_: 'Have your own contract?',
                    _CHOOSE_YOUR_OWN_CTA_: 'Add it to a file'
                },
                STEP_FINAL_SCREEN: {
                    _HEADER_TITLE_: 'Try it out',
                    _HEADER_TITLE_SHORT_: 'Try it out',

                    _TITLE_: 'Get ready to review and use your shiny new contract!',
                    _TITLE_MWEB_: 'Get ready to review & use your shiny new contract!',
                    _TITLE_BLANK_: 'Get ready to review and use your interactive contract!',
                    _TITLE_BLANK_MWEB_: 'Get ready to review & use your interactive contract!',

                    _SUB_TITLE_: 'This contract is uniquely yours—with your company info and brand.\n\n\n',
                    _SUB_TITLE_MWEB_: 'This contract is uniquely yours—with your company info and brand.\n',
                    _SUB_TITLE_BLANK_: 'Your clients can now eSign online—less back-and-forth means faster signing.\n\nCopy in your content, tweak the formatting, then share it with a client.',
                    _SUB_TITLE_BLANK_MWEB_: 'Your clients can now eSign online—less back-and-forth means faster signing.\n\nCopy in your content, tweak the formatting, then share it with a client.',

                    _CTA_TEXT_: 'Go to contract'
                }
            }

        },

        INTERACTIVE_ONBOARDING: {
            _HEADER_TITLE_: 'Set up your account:',
            _FINAL_HEADER_ACTION_TITLE_: 'Done',
            _LIST_TITLE_: 'Did we mention you’re doing great?',
            _LIST_TITLE_FINAL_: 'Amazing! You’re all set up.',
            _LIST_SUBTITLE_: 'Basic setup complete. Now get ready to book clients and get paid',
            _PRO_TIP_: 'PRO TIP:',
            _PRO_TIP_TEXT: 'To make changes to your\nbrand style, go to',
            _PRO_TIP_LINK_TEXT: 'Company settings',
            _LIST_CTA_FINAL_: 'Close',
            _BACK_: 'Back',
            _CONTINUE_: 'Continue',
            STEP_ACCOUNT: {
                _HEADER_TITLE_FUTURE_: 'Add profile image',
                _HEADER_TITLE_PAST_: 'Image added',
                _HEADER_TITLE_SHORT_: 'Profile',
                _HEADER_TITLE_VERBOSE_: 'Add profile image',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Next, add a profile photo',
                _TITLE_FIRST_: 'Start by adding a profile photo',
                _SECONDARY_TITLE_: 'The face behind {{companyName}}',
            },
            STEP_ACCOUNT_IN_LIST: {
                _TITLE_: 'Upload profile picture',
                _TEXT_: 'Give your brand a personal touch',
                _CTA_: 'Go to profile',
            },
            STEP_BRANDING: {
                _HEADER_TITLE_FUTURE_: 'Apply brand',
                _HEADER_TITLE_PAST_: 'Brand applied',
                _HEADER_TITLE_SHORT_: 'Brand',
                _HEADER_TITLE_VERBOSE_: 'Apply brand',
                _TIME_ESTIMATION_: '(1 min)',
                _LOGO_EMPTY_TEXT_: 'Logo',
                _LOGO_EMPTY_DIMENSIONS_: '100 x 100',
                _COVER_IMAGE_EMPTY_TEXT_: 'Default header image',
                _COVER_IMAGE_EMPTY_DIMENSIONS_: '750 x 300 png, jpg, or gif',
                _TITLE_: 'Next, add your logo and brand elements',
                _TITLE_FIRST_: 'Start by adding your logo and brand elements',
                _TITLE_CTA_: 'Preview',
                _SECONDARY_TITLE_: 'Create a custom experience for clients and edit anytime.',
                _SECONDARY_TITLE_LINK_: 'Preview',
                _LABEL_LOGO_: 'Logo:',
                _LABEL_COLOR_: 'Button color:',
                _LABEL_COLOR_PICKER_: 'Color',
                _LABEL_COVER_IMAGE_: 'Default header image:',
                EXPLAIN_MODAL: {
                    _TITLE_: 'Preview',
                    _COPY_: 'Logo, header image, and button color appear on all client-facing files (invoices, proposals, etc).',
                    _FOOTER_COPY_: ' You can always edit within a file or from settings > company brand.',
                    _EXAMPLE_FILE_NAME_: 'My file’s name',
                }
            },
            STEP_SERVICES: {
                _HEADER_TITLE_FUTURE_: 'Add services',
                _HEADER_TITLE_PAST_: 'Added services',
                _HEADER_TITLE_SHORT_: 'Services',
                _HEADER_TITLE_VERBOSE_: 'Add your services',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Looking good! Next, what type of services do you offer?',
                _SECONDARY_TITLE_: 'Start with 2-3 to help us tailor your account.',
                _SECONDARY_TITLE_LINK_: 'Where will these go?',
                _NUDGE_TEXT_: "You're off to a great start. Click 'Continue' and add more services later.",
                EXPLAIN_MODAL: {
                    _TITLE_: 'Where do services go?',
                    _COPY_: 'Your services will be saved to an item bank for quick and easy file building.',
                    _CTA_: 'Got it',
                }
            },
            STEP_CLIENT_SIDE: {
                _HEADER_TITLE_FUTURE_: 'Test invoice',
                _HEADER_TITLE_PAST_: 'Invoice tested',
                _HEADER_TITLE_SHORT_: 'View',
                _HEADER_TITLE_VERBOSE_: 'Test invoice',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Ta-da! We created an invoice for you.',
                _SECONDARY_TITLE_: 'Ready to test it out?',
                _CTA_: 'View my invoice',
            },
            STEP_COMPLETION: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: "You’re on a roll, {{firstName}}",
                _COPY_: "Next, we'll set up templates, payments, and more.",
                _CTA_: 'Continue set up',
                _OR_: 'or',
                _SECONDARY_CTA_: 'Explore on my own',
                _MWEB_SECONDARY_TITLE_: 'Go to desktop to continue setting up.',
                _CTA_CLOSE_: 'Close',
            },
            STEP_SUBSCRIBE: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Choose your plan',
                _TEXT_: 'You’ll find the right fit for your business.',
                _CTA_: 'View prices'
            },
            STEP_TEMPLATES: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'View and edit templates',
                _TEXT_: 'Have your own?',
                _LINK_: 'Import them now',
                _CTA_: 'Go to templates',
            },
            STEP_CLIENTS: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Add your clients',
                _TEXT_: 'To send them messages, files, invoices, and more.',
                _CTA_: 'Add a client',
            },
            STEP_TEMPLATE_GALLERY: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Learn about customizable templates',
                _TEXT_: 'These reusable templates can be tweaked per client.',
                _CTA_: 'GO TO TEMPLATES'
            },
            STEP_BANK_INFO: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Set yourself up for online payments',
                _TEXT_: 'Secure payments & transfers—for both you and your clients.',
                _CTA_: 'ADD BANK INFO'
            },
            STEP_ACCOUNT_SETUP: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Upload your business files',
                _TEXT_: 'Our team will turn them into smart templates—for free!',
                _CTA_: 'UPLOAD FILES',
                _CONDITIONAL_TEXT_: 'Not relevant? You can ',
                _CONDITIONAL_LINK_TEXT_: 'skip account setup',
                _CONFIRM_MARK_COMPLETE_: 'This will permanently mark the step as complete.',
                UPLOADED: {
                    _TITLE_: 'Existing materials uploaded',
                    _CTA_: 'View status',
                },
                COMPLETED: {
                    _CTA_: 'View my templates',
                }
            },
            STEP_SEND_TEST: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Take a look at the client side',
                _TEXT_: 'You can preview content before sending it to your clients.',
                _CTA_: 'Send a test'
            },
            STEP_CONTACT_FORM: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Manage leads in one place',
                _TEXT_: 'You can easily embed a contact form in your website.',
                _CTA_: 'ADD CONTACT FORM'
            },
            STEP_LEAD_FORM: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Book leads faster than ever',
                _TEXT_: 'Easily link a lead form on your website or socials and get leads booked.',
                _CTA_: 'CREATE LEAD FORM'
            },
            STEP_CHA_CHING: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(1 min)',
                _TITLE_: 'Cha-ching! Receive your first payment',
                _TEXT_: 'Complete steps 1—5 to start getting paid and growing your business.',
            },
            STEP_SERVICES_HUB: {
                _HEADER_TITLE_VERBOSE_: 'Continue',
                _TIME_ESTIMATION_: '(5 min)',
                _TITLE_: 'Manage your services',
                _TEXT_: 'Speed up your workflow—create a list of your business saved services.',
                _CTA_: 'Add a service'
            },
            RESOURCES: {
                VIDEO_TUTORIALS: {
                    _TITLE_: 'Video tutorials',
                },
                WEBINARS: {
                    _TITLE_: 'On-demand webinars',
                },
                ACCOUNT_SETUP: {
                    _TITLE_: 'Free account migration',
                },
            },
        },
        LEADS_INSIGHTS: {
            _TITLE_: "Leads insights",
            _COLLECTING_DATA_: "&nbsp;-&nbsp;Collecting data",
            _EXAMPLES_LABEL_: "Examples",
            _BETA_LABEL_: "BETA",
            _EXAMPLES_TOOLTIP_: "These are examples not based on your data",
            _COLLAPSE_EXAMPLES_: "No Thanks, I don't want any insights",
            _EXPAND_EXAMPLES_: "View examples",
            _EXPAND_RESULTS_: "View real time insights",
            _CLOSE_: "Close",
            _SUBTITLE_COLLECTING_: "Real time insights will be available once there are enough inquiries from your Contact Form",
            _SUBTITLE_LOCKED_: "To unlock",
            _SUBTITLE_LOCKED_EXTENDED_: "Unlock your insights",
            _SUBTITLE_LOCKED_CTA_: "Connect your Contact Form",
            _AVG_RESPONSE_EXP_: "The average time it took you to send the first response to a new inquiry over the last 60 days.",
            _AVG_SUBMISSION_EXP_: "The average contact form submission rate among all of your published contact forms over the last 60 days (number of views / number of submitted inquiries).",
            _AVG_RESPONSE_SUB_UNIT_: "Avg. first response time for all HoneyBook booked projects: 15 hours",
            _MOST_POP_LEAD_SOURCE_EXP_: "The lead source tagged in the highest number of projects created over the last 60 days.",
            _AVG_SUBMISSION_UNIT_: "Avg. form submission",
            _AVG_RESPONSE_UNIT_: "Avg. first response time",
            _MOST_POP_UNIT_: "Most popular lead source",
            _UNKNOWN_DATA_: "Unknown"
        },

        BOOKKEEPING: {
            CONNECT_MODAL: {
                WHY: {
                    _TITLE_: 'Expenses help you get a clear view of your financial picture',
                    _SUBTITLE_: 'Easily connect to multiple bank and credit card institutions related to your business to have your expenses automatically synced and categorized!',
                    _LEARN_MORE_: 'Learn more',
                    _MAIN_CTA_: 'add account(s)',
                    _SECURE_CONNECT_: 'secure connect'
                },
                CONNECT: {
                    _TITLE_: 'We found multiple accounts under ',
                    _SUBTITLE_: 'Which account(s) contain business-related income or expenses?',
                    _REFUND_SUBTITLE_: 'Please select the account you’d like to use to process all refunds',
                    _MAIN_CTA_: 'continue'

                },
                CONNECT_MORE: {
                    _TITLE_: 'Your financial picture is getting clearer!',
                    _SUBTITLE_: 'For the most accurate reporting, we recommend adding all business-related bank and credit card accounts.',
                    _MAIN_CTA_: 'add another account',
                    _CANCEL_CTA_: 'No thanks, I\'m done'
                }
            }
        },
        SCHEDULE_EMAIL: {
            MODAL: {
                _PREDEFINED_TITLE_: 'Schedule send',
                _CUSTOM_SELECT_: 'Select a date & time',
                _SCHEDULE_BUTTON_: 'Schedule',
                _MISSING_DATE_: 'Missing date',
                _MISSING_TIME_: 'Missing time',
                _TIME_IS_IN_PAST_: 'Time is in the past. Please select a different time',
                _FUTURE_DATE_NOT_VALID_: "You can select a date up until {{maxDate}}."
            },
            EMAIL_EDITOR: {
                _SCHEDULING_: 'Scheduling',
                _SENDING_: 'Sending',
                _SAVING_SCHEDULED_: 'Saving scheduled email',
                _SENDING_FILE_: 'Sending file to..',
            }
        },
        FILTERS: {
            _CLEAR_ALL_: 'CLEAR ALL',
            _FILTERS_: 'Filters',
            _SORT_BY_: 'Sort by'
        },

        DISCOUNT_MODAL: {
            LABELS: {
                _DISCOUNT_: 'Discount',
                _RELATIVE_AMOUNT_WARNING_: '% discount must be 100% or lower',
                _ABSOLUTE_AMOUNT_WARNING_: 'Discount must be less than subtotal',
                _FINAL_AMOUNT_: 'Final amount',
                _GRAND_TOTAL_AFTER_DISCOUNT_: 'Total after discount',
                _REMOVE_DISCOUNT_: 'Remove discount'
            }
        },
        GIFT_CARD: {
            LABELS: {
                _ENTER_CODE_: 'Enter code',
            }
        },

        OTAM: {
            MIGRATE_USER_MODAL: {
                BEFORE_MIGRATION: {
                    _TITLE_: 'Supercharge your Files into Smart Files with a powerful new builder',
                    _SUB_TITLE_FIRST_: 'Everything you love about Files and templates - now with',
                    _SUB_TITLE_SECOND_: ' infinite ',
                    _SUB_TITLE_THIRD_: 'customization options.',
                    _QUOTE_: 'In the last 30 days, I’m on track to net almost $90,000 in sales using Smart Files!',
                    _NAME_: 'Candice Coppola, Something designs',
                    _DEADLINE_: 'You will be automatically upgraded on:',
                    _OK_BUTTON_: 'I WANT IT!',
                    _LATER_BUTTON_: 'REMIND ME LATER',
                    _TOMORROW_BUTTON_: 'REMIND ME TOMORROW'
                },
                DURING_MIGRATION: {
                    _TITLE_: 'Copying your templates into the new smart file builder',
                    _SUB_TITLE_: '(about 3 minutes)',
                    _PS_: 'P.S. Don’t worry you can still access your legacy files',
                }
            },
            PRE_MIGRATE_USER_MODAL: {
                _TITLE_: 'A powerful new file is here!',
                _SUB_TITLE_: 'Everything you love about files and templates — with even more customization options. Join the waitlist to be among the first to access smart files.',
                _VIEW_EXAMPLES_: 'View examples',
                _ADDED_TO_WAITING_LIST_: 'You\'re in! Look out for the update soon',
                _JOIN_THE_WAITLIST_: 'Join the waitlist',
                _LEARN_MORE_: 'Learn about smart files'
            },
            TEAM_MEMBERS_OTAM_MODAL: {
                _TITLE_: 'You’ve got smart files in your account',
                _SUB_TITLE_: "Everything you love about templates & files, but way more customizable.\n" +
                    "<strong>Note</strong>: Only a <strong>company owner</strong> can add smart files to your team member account.",
                _SPLASH_SUB_TITLE_: "Everything you love about Files and templates - with infinite customization options.",
                _LEARN_MORE_: 'Learn about smart files',
                _BETA_: 'BETA',
                _SEE_WHATS_NEW_: "SEE WHAT'S NEW"
            },
            POPUP_DECLINE_MIGRATION: {
                _TITLE_: "<strong>We’ve got your back! We’ll remind you later</strong>",
                _BODY_: "FYI - you will be automatically upgraded to the new\n Smart Files builder on\n\n <strong>{{date}}</strong> \n\n Don’t worry, you’ll still be able to access all of your\n legacy files and templates after the upgrade."
            },
            FILES_BUILDER_BANNER: {
                _TITLE_: 'Big updates are just around the corner',
                _TEXT_: 'Files are about to get a whole lot smarter. Look out for powerful customization capabilities coming your way.',
                _CTA_TEXT_: 'Learn more'
            },
            TEMPLATES_PAGE_BANNER: {
                _TITLE_: 'Soon you will no longer have access to your legacy files',
                _TEXT_: 'We copied and upgraded your files to the new template builder - where you have the freedom to' +
                    ' build your client smart file step by step. For now, you can still create, edit, and send legacy Files.',
                _SECOND_BATCH_: {
                    _TITLE_: 'Do more with the new builder!',
                    _TEXT_: 'Need just an invoice, fine. Want to speed up your clientflow—combine a proposal, contract, and an invoice all into the same interactive template. And, should you need them, your legacy templates are still available.',
                    _PRIMARY_CTA_: 'Learn about smart files',
                    _SECONDARY_CTA_: 'Explore smart files gallery',
                }
            }
        },

        START_FREE_TRIAL_MODAL: {
            _TITLE_: 'Join thousands of independents who run their business on HoneyBook',
            _BUSINESS_ACCOUNT_DESCRIPTION_: 'Create your business account',
            _START_FREE_TRIAL_DESCRIPTION_: 'Start your </br> 7-day free trial',
            _FYI_TEXT_: 'FYI, keep using your portal to contact <i>{{companyName}}</i>',
            _CTA_TEXT_: 'Start your free trial',
            _CTA_SUBTITLE_: 'No credit card needed'
        },

        CANVA_INTEGRATION: {
            TOAST_IMPORT_IN_PROGRESS: 'Importing {{count}} Canva designs to your Library.',
            TOAST_IMPORT_COMPLETED: 'Canva designs successfully imported to HoneyBook library'
        }
    },
});
