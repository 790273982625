/**
 * Created by inonstelman on 1/5/15.
 */
(function () {
    "use strict";


    // @ngInject
    function PrintServiceCtor($q, $window) {
        this.$q = $q;
        this.$window = $window;
    }


    Services.PrintService = Class(function () {


        return {

            constructor: PrintServiceCtor,

            print: function print(apiCallPromise) {
                var self = this;
                var deferred = this.$q.defer();
                var win = self.$window.open();

                apiCallPromise.then(
                    function success(resp) {

                        if (win) {

                            // wrapper
                            var htmlContent = ['<!DOCTYPE html>',
                                '<html>',
                                '<head>',
                                '<title>File Print</title>',
                                '<script src="//www.honeybook.com/dist/print/print-libs-b8035b8a94.min.js"></script>',
                                '<script src="//www.honeybook.com/dist/print/print-6e2560dabe.min.js"></script>',
                                '</head>',
                                '<body id="print-hb"><script>',
                                'window.printContent = "' + encodeURI(resp.data) + '";',
                                'window.onload = function() { $(window.document.body).append(decodeURI(window.printContent)); };',
                                '</script></body>',
                                '</html>'].join('');

                            //win.printContent = resp.data;
                            win.document.write(htmlContent);

                            win.document.close();

                            deferred.resolve(true);
                        }
                        else {
                            deferred.reject(false);
                        }

                    }.bind(this),
                    function error() {
                        deferred.reject(false);
                    }
                );

                return deferred.promise;
            }
        };
    });
}());
