(function () {
    "use strict";

    // @ngInject
    function ReportsPaymentsControllerCtor($scope, $injector) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ReportsPaymentsController';
    }

    Controllers.ReportsPaymentsController = Class(Controllers.BaseController, {
        constructor: ReportsPaymentsControllerCtor,
    });
}());
