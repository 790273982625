(function () {
  'use strict';

  // @ngInject
  function TemplatesSecondLevelNavigationControllerCtor($scope, $injector, $state, AppStates, UsersManager, $timeout,
                                                        UiPersistenceService, DeviceService, FlowsBetaUserTypeService, OtamStatusService, $rootScope, StatsigService) {
    this.constructor.$super.call(this, $scope, $injector);
    this.__objectType = 'TemplatesSecondLevelNavigationController';
    this.AppStates = AppStates;
    this.$timeout = $timeout;
    this.UiPersistenceService = UiPersistenceService;
    this.OtamStatusService = OtamStatusService;
    this.OtamStatusService.initializeAfterLogin();
    this.OtamStatusService.listenToMigration();

    this.title = $state.$current.data.title;
    this.isOriginatedInTabClick = $state.params.isOriginatedInTabClick;
    this.user = UsersManager.getCurrUser();
    this.isMobile = DeviceService.checkIfMobileSize();
    this.showTooltip = false;
    this.showTemplateTooltip = false;
    this.isMWebReactTopNav = false;
    this.StatsigService = StatsigService;

    var templateGallery = 'Template Gallery';
    var legacyFiles = 'Legacy Files';

    if (this.isMobile) {
      templateGallery = 'Gallery';
      legacyFiles = 'Legacy';
    }

    if (FlowsBetaUserTypeService.hasFlows) {
      if (FlowsBetaUserTypeService.hasOnlyFlows) {
        /* Trialer */
        this.navItems = [
          {title: 'My Templates', goTo: this.AppStates.root_core_navigation_my_templates},
          {title: templateGallery, goTo: this.AppStates.root_core_navigation_flows_template_gallery, isGalleryLink: true},
        ];

      } else if (FlowsBetaUserTypeService.hasNewFlowExp) {
          var pullRight = false;
          var otamTooltip = false;
          if (!this.isMobile) {
              legacyFiles = "LEGACY TEMPLATES";
              templateGallery = "TEMPLATE GALLERY";
              pullRight = true;
              otamTooltip = true;
          }
        /* Flows with folders */
        this.navItems = [
          {title: "My Templates", goTo: this.AppStates.root_core_navigation_my_templates},
          {title: legacyFiles, goTo: this.AppStates.root_core_navigation_templates, otamTooltip: otamTooltip},
          {title: templateGallery, goTo: this.AppStates.root_core_navigation_flows_template_gallery, pullRight: pullRight, isGalleryLink: true}
        ];

        this.handleTooltip();
      } else {
        /* Flows without folders */
        this.navItems = [
          {title: 'My Templates', goTo: this.AppStates.root_core_navigation_templates},
          {title: templateGallery, goTo: this.AppStates.root_core_navigation_flows_template_gallery, isGalleryLink: true}
        ];
      }
    } else {
      /* Members */
      this.navItems = [
        {title: 'My Templates', goTo: this.AppStates.root_core_navigation_templates},
      ];
    }

    this.StatsigService.isGateEnabled('rollout_mweb_top_nav').then(function (isEnabled) {
        this.isMWebReactTopNav = isEnabled && this.isMobile;
    }.bind(this));
  }

  Controllers.TemplatesSecondLevelNavigationController = Class(Controllers.BaseController, {
      constructor: TemplatesSecondLevelNavigationControllerCtor,

      onMenuItemClicked: function onMenuItemClicked(stateToGoto) {
          this.goToState(stateToGoto, {isOriginatedInTabClick: true});
      },

      handleTooltip: function onToolbarInit() {
          if (!this.isOriginatedInTabClick &&
              this.OtamStatusService.shouldSeeElement('didSeeTemplatesTooltip', 2) &&
              !this.OtamStatusService.shouldSeeElement('afterMigrationBanner' ,1)){
              this.$timeout(function() {
                      this.showTooltip = true;
              }.bind(this), 1000);
          }
      },

      closeTooltip: function closeTooltip() {
          this.showTooltip = false;
          this.OtamStatusService.markElementAsSeen('didSeeTemplatesTooltip');
      },

      getTooltipTitle: function getTooltipTitle() {
          return '<span class="legacy-templates-tooltip-container-text">Your legacy templates are right here for you to use at any time</span>';
      },
  });
}());





