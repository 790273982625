(function () {
    "use strict";

    // @ngInject
    function CompanySettingsGalleryControllerCtor($scope, CompaniesManager, _, ModalService, $injector, PhotosUploadManager, AnalyticsService, $timeout, PopupMessageService, StatsigService) {

        var self = this;
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsGalleryController';
        this._ = _;
        this.PhotosUploadManager = PhotosUploadManager;
        this.AnalyticsService = AnalyticsService;
        this.$timeout = $timeout;
        this.PopupMessageService = PopupMessageService;

        this.CompaniesManager = CompaniesManager;
        this.setPhotoAsDefaultText = 'Set photo as default';
        this.photoIsCoverText = 'This is your default image';
        this.company = this.CompaniesManager.getCurrCompany();
        if (this.company.cover_images === undefined) {
            this.company.cover_images = [];
        }

        if (this.company.company_assets === undefined) {
            this.company.company_assets = [];
        }

        this.formattedCompanyAssets = [];

        var unwatchCompanyAssets = $scope.$watchCollection('companySettingsGalleryVm.company.company_assets', function (newCompanyAssets) {
            if (newCompanyAssets) {
                self.formattedCompanyAssets = newCompanyAssets.map(function (file) {
                    return {
                        _id: file._id,
                        name: file.asset_name,
                        type: file.file_type,
                        url: file.url
                    };
                });
            }
        });

        $scope.$on('$destroy', function () {
            unwatchCompanyAssets();
        });

        // ng2react - Company settings library
        this.companyLibraryVariant = 'pending';
        StatsigService.isGateEnabled('ng2react-library').then(function (isEnabled) {
            this.companyLibraryVariant = isEnabled ? 'react' : 'angular';
        }.bind(this)).catch(function () {
            this.companyLibraryVariant = 'angular';
        }.bind(this));
        this.companyAssetsUpdateCallbackForReact = this.companyAssetsUpdateCallback.bind(this);
        this.onImageDeletedCallbackForReact = this.onImageDeletedCallback.bind(this);
        this.onFileDeletedCallbackForReact = this.onFileDeletedCallback.bind(this);
        this.onSetAsDefaultCallbackForReact = this.onSetAsDefaultCallback.bind(this);
    }


    Controllers.CompanySettingsGalleryController = Class(Controllers.BaseController, {

        constructor: CompanySettingsGalleryControllerCtor,

        deleteImage: function deleteImage(id) {
            if (angular.isArray(this.company.cover_images) && this.company.cover_images.length === 1) {
                //this is the last image that user is trying to delete. Do not allow this.
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                    'COMPANY_SETTING.GALLERY._CAN_NOT_REMOVE_LAST_IMAGE_MESSAGE_');

                return;
            }
            // First send the delete to the server
            this.CompaniesManager.deleteCoverImage(this.company, id);

            // Second update the model immediately for better UI experience
            var index;
            this._.any(this.company.cover_images, function (item, idx) {
                if (item._id === id) {
                    index = idx;
                    return true; // brake the iteration
                }
            });

            if (index !== undefined) {
                this.company.cover_images.splice(index, 1);
            }
        },

        deleteFile: function deleteFile(file) {
            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                'COMPANY_SETTING.ALERT._CONFIRM_DELETE_',
                function () {
                    this.deleteFileConfirmed(file._id).then(
                        function success() {
                            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.uploaded_file_deleted, this.analyticsProperties());
                        }.bind(this),
                        function error() {
                            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.uploaded_file_deleted, this.analyticsProperties());
                        }.bind(this)
                    );
                }.bind(this));
        },


        deleteFileConfirmed: function deleteFileConfirmed(id) {
            // First send the delete to the server
            var $result = this.CompaniesManager.deleteUploadedFile(this.company, id);
            $result.then(function () {
            });

            // Second update the model immediately for better UI experience
            var index;
            this._.any(this.company.company_assets, function (item, idx) {
                if (item._id === id) {
                    index = idx;
                    return true; // brake the iteration
                }
            });

            if (index !== undefined) {
                this.company.company_assets.splice(index, 1);
                this.formattedCompanyAssets = this.formattedCompanyAssets.filter(function (file, fileIndex) {
                    return fileIndex !== index;
                });
            }
            return $result;
        },

        createFileViewModel: function createFileModel(file, type) {
            var self = this;
            var fileViewModel = {
                instance: self.company, //the instance of the model containing these files
                type: type || 'cover',
                dontUpdateServer: true,
                uploading: false,
                uploaded: true,
                dataUrl: null,
                url: null,
                file: null,
                progress: 0
            };

            if (file) {
                //add the attributes of the file to upload on the view-model
                this._.extend(fileViewModel, file);
            }

            return fileViewModel;
        },


        analyticsProperties: function analyticsProperties() {
            return {
                companyId: this.company._id
            };
        },

        addImage: function addImage(file, fileViewModelName) {
            var self = this;
            this.CompaniesManager.createCompanyImage(self.company, file.s3Response, 'cover').then(function () {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.cover_image_saved_on_company_model, {
                    companyId: this.company._id,
                    name: fileViewModelName
                });
            });
        },

        addFile: function addFile(file, fileViewModelName) {
            var self = this;
            this.CompaniesManager.createCompanyFile(self.company, file.s3Response, 'company_file').then(function () {
                this.formattedCompanyAssets = this.company.company_assets.map(function (file) {
                    return {
                        _id: file._id,
                        name: file.asset_name,
                        type: file.file_type,
                        url: file.url
                    };
                });

                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.uploaded_file_saved_on_company_model, {
                    companyId: this.company._id,
                    name: fileViewModelName
                });
            });
        },

        setDefaultCoverImage: function setDefaultCoverImage(id) {

            // update default
            this.CompaniesManager.setDefaultCoverImage(this.company, id);

            // immediately update the company model
            this.company.default_cover_image_id = id;

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.set_company_default_image, {
                companyId: this.company._id,
                imageId: id
            });

        },

        companyAssetsUpdateCallback: function companyAssetsUpdateCallback(images, files) {
            this.company.cover_images = images;
            this.company.company_assets = files;
        },

        onImageDeletedCallback: function onImageDeletedCallback(imageId) {
            this.company.cover_images = this.company.cover_images.filter(function (image) {
                return image._id !== imageId;
            });
        },

        onFileDeletedCallback: function onFileDeletedCallback(fileId) {
            this.company.company_assets = this.company.company_assets.filter(function (file) {
                return file._id !== fileId;
            });
        },

        onSetAsDefaultCallback: function onSetAsDefaultCallback(imageId) {
            this.company.default_cover_image_id = imageId;
        }
    });
}());
