(function () {
    "use strict";

    // @ngInject
    function CompanySettingsGeneralControllerCtor($scope, $injector, StatsigService) {
        var self = this;

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsGeneralController';
        
        this.rolloutVariant = 'pending';
        
        StatsigService.isGateEnabled('ng2react-company-settings-company-brand-tab').then(function(enabled) {
            self.rolloutVariant = enabled ? 'react' : 'angular';
        });
    }

    Controllers.CompanySettingsGeneralController = Class(Controllers.BaseController, {
        constructor: CompanySettingsGeneralControllerCtor,
    });

}());
