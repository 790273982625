(function () {
    "use strict";


    // @ngInject
    function AutomationServiceCtor(AnalyticsService, ModalService, EventService, PopupMessageService, $state, AppStates, CompaniesManager) {
        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.EventService = EventService;
        this.PopupMessageService = PopupMessageService;
        this.$state = $state;
        this.AppStates = AppStates;
        this.CompaniesManager = CompaniesManager;
    }

    Services.AutomationService = Class(function () {


        return {

            constructor: AutomationServiceCtor,


            buildActionInfo: function buildActionInfo(company, type, emailType, analyticsProperty, translation, emailTranslation, supportEmail,
                                                      supportQuestionnaire, supportAttachment, supportEventType, supportFlow, openedTypes, timeFrames, defaultTimeFrameKey) {
                var reminderInfo = {};
                reminderInfo.id = type;
                reminderInfo.text = translation;
                reminderInfo.emailText = emailTranslation;
                reminderInfo.automationOn = company.getAutomationStatus(type);
                reminderInfo.analyticsProperty = analyticsProperty;

                if (supportEmail) {
                    reminderInfo.template = company.getAutomationEmailTemplate(type, emailType);
                }

                if (supportAttachment) {
                    var chosenQuestionnaire = company.getAutomationSelectedQuestionnaireTemplate(type);
                    var chosenBrochure = company.getAutomationSelectedBrochureTemplate(type);

                    if (chosenQuestionnaire) {

                        reminderInfo.questionnaireTemplate = chosenQuestionnaire;
                        reminderInfo.selectedAttachmentTemplate = chosenQuestionnaire;
                        reminderInfo.selectedAttachmentTypeText = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.ACTION_COPY._PROJECT_ATTACHMENT_QUESTIONNAIRE_TEXT_';
                        reminderInfo.templateName = 'questionnaire';
                        reminderInfo.selectedAttachmentTemplateTypeText = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.ACTION_COPY._PROJECT_ATTACHMENT_QUESTIONNAIRE_TEMPLATE_';
                        reminderInfo.files = company.questionnaires;
                    } else if (chosenBrochure) {

                        reminderInfo.brochureTemplate = chosenBrochure;
                        reminderInfo.selectedAttachmentTemplate = chosenBrochure;
                        reminderInfo.selectedAttachmentTypeText = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.ACTION_COPY._PROJECT_ATTACHMENT_BROCHURE_TEXT_';
                        reminderInfo.selectedAttachmentTemplateTypeText = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.ACTION_COPY._PROJECT_ATTACHMENT_BROCHURE_TEMPLATE_';
                        reminderInfo.templateName = 'brochure';
                        reminderInfo.files = company.getCompanyBrochures(true);

                    } else {
                        reminderInfo.templateName = 'no attachment';
                        reminderInfo.selectedAttachmentTypeText = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.ACTION_COPY._PROJECT_ATTACHMENT_NO_ATTACHMENT_';
                    }
                    reminderInfo.defaultQuestionnaireTemplate = company.getAutomationQuestionnaireTemplate(type);
                    reminderInfo.defaultBrochureTemplate = company.getAutomationBrochureTemplate(type);
                } else {
                    reminderInfo.questionnaireTemplate = company.getAutomationQuestionnaireTemplate(type);
                }

                if(supportEventType){
                    var chosenEventType = company.getAutomationEventType(type);

                    if (chosenEventType === null) {
                        reminderInfo.selectedEventTypeText = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.TEMPLATE_TYPES._ANY_PROJECT_TYPE_';
                    }
                    else {
                        reminderInfo.selectedEventTypeText = chosenEventType;
                    }
                }

                reminderInfo.supportEmail = supportEmail;
                reminderInfo.supportQuestionnaire = supportQuestionnaire;
                reminderInfo.supportFlow = supportFlow;
                reminderInfo.supportAttachment = supportAttachment;
                reminderInfo.supportEventType = supportEventType;

                reminderInfo.showEditBox = !!openedTypes[type];

                if (timeFrames) {
                    reminderInfo.timeFrame = timeFrames[company.getAutomationTimeFrame(type)];

                    if (!reminderInfo.timeFrame) {
                        reminderInfo.timeFrame = timeFrames[defaultTimeFrameKey];
                    }

                    reminderInfo.timeFrames = timeFrames;
                }

                if (supportFlow) {
                    reminderInfo.flow = company.getAutomationFlow(type);
                }

                return reminderInfo;
            },

            updateAutomationCustomEmail: function updateAutomationCustomEmail(company, actionType, subject, body) {
                return company.updateAutomationCustomEmail(actionType, subject, body);
            },

            changeAutomationEventType: function changeAutomationEvent(company, action, event_type) {
                var automationObj = action;
                automationObj.event_type = event_type;
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.automation_event_type_changed, {
                    action_id: action.id,
                    event_type: event_type
                });
                company.setAutomationEventType(automationObj.id, event_type);
            },

            changeAutomationTemplate: function changeAutomationTemplate(company, action, template) {
                var automationObj = action;

                automationObj.template = template;
                company.setAutomationEmailTemplateId(automationObj.id, automationObj.template._id);
            },

            changeAutomationBrochure: function changeAutomationBrochure(company, action, brochure) {
                var analyticsArgs;
                if (brochure) {
                    var automationObj = action;

                    automationObj.brochure_container = brochure;
                    delete automationObj.questionnaire;
                    company.setAutomationBrochureTemplateId(automationObj.id, automationObj.brochure_container._id);
                    analyticsArgs = {
                        action_id: action.id
                    };

                    if (!this.brochureChosen) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.brochure_template_selected_confirmed, analyticsArgs);
                    }
                } else {

                    this.showMissingTemplate('brochure');
                    analyticsArgs = {
                        action_id: action.id
                    };

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.brochure_template_selected_error, analyticsArgs);
                }
            },

            changeAutomationQuestionnaire: function changeAutomationQuestionnaire(company, action, questionnaire) {

                var analyticsArgs;
                if (questionnaire) {

                    var automationObj = action;
                    automationObj.questionnaire = questionnaire;
                    delete automationObj.brochure_container;

                    company.setAutomationQuestionnaireTemplateId(automationObj.id, automationObj.questionnaire._id);
                    analyticsArgs = {
                        action_id: action.id
                    };
                    if (!this.questionnaireChosen) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.questionnaire_template_selected_confirmed, analyticsArgs);
                    }

                }
                else {
                    this.showMissingTemplate('questionnaire');
                    analyticsArgs = {
                        action_id: action.id
                    };

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.questionnaire_template_selected_error, analyticsArgs);

                }
            },

            clearAutomationAttachment: function clearAutomationAttachment(company, action) {
                var automationObj = action;

                delete automationObj.questionnaire;
                delete automationObj.brochure_container;
                var analyticsArgs = {
                    action_id: action.id
                };

                this.brochureChosen = false;
                this.questionnaireChosen = false;

                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.no_template_selected_confirmed, analyticsArgs);
                company.clearAutomationAttachment(automationObj.id);
            },


            toggleAutomation: function toggleAutomation(company, action) {
                var automationObj = action;

                var emailTemplate = null;
                var questionnaireTemplate = null;
                var brochureTemplate = null;
                var flowTemplate = null;

                if (automationObj.template) {
                    emailTemplate = automationObj.template._id;
                }

                if (automationObj.brochureTemplate) {
                    brochureTemplate = automationObj.brochureTemplate._id;
                }

                if (automationObj.questionnaireTemplate) {
                    questionnaireTemplate = automationObj.questionnaireTemplate._id;
                }

                if(automationObj.flow){
                    flowTemplate = automationObj.flow._id;
                }

                return company.setAutomationStatus(automationObj.id, automationObj.automationOn,
                    emailTemplate, questionnaireTemplate, brochureTemplate,flowTemplate);
            },

            showMissingTemplate: function showMissingTemplate(type) {
                var msgRes = type === 'questionnaire'  ? 'COMPANY_SETTING.AUTOMATION_TEMPLATE.MISSING_TEMPLATE._QUESTIONNAIRE_' : 'COMPANY_SETTING.AUTOMATION_TEMPLATE.MISSING_TEMPLATE._BROCHURE_';
                return this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info,
                    msgRes,
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.MISSING_TEMPLATE._GO_TO_TEMPLATES_',
                    'FREQUENT_BUTTONS._CANCEL_',
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.MISSING_TEMPLATE._TITLE_'
                ).then(function() {
                    this.$state.go(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: type});
                }.bind(this));
            },

            saveSpaces: function saveSpaces(company, spaces) {
                var _spaces = spaces.map(function (space) {
                    return {
                        _id: space._id,
                        name: space.label
                    };
                });

                return this.CompaniesManager.updateCompanySpaces(company, _spaces);
            },

            _formatSpacesData: function _formatSpacesData(spaces){
                return spaces.map(function (space) {
                    return {
                        _id: space._id,
                        label: space.name,
                        editable: true
                    };
                });
            },

            editSpaces: function editSpaces(company, spacesAnalyticsArgs){
               return this.CompaniesManager.getCompanySpaces(company).then(function() {
                    this.ModalService.openEditSpacesModal(
                      this._formatSpacesData(company.company_spaces), this.saveSpaces.bind(this), spacesAnalyticsArgs);
                   this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.company_spaces__modal_opened, spacesAnalyticsArgs);
                }.bind(this));
            },


        };
    });
}());
