(function () {
    "use strict";

    // @ngInject
    function PaymentMethodsComponentControllerFunc($injector, $scope, UsersManager, PopupMessageService, AnalyticsService, StatsigService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PaymentMethodsComponentController';
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.user = UsersManager.getCurrUser();
        this.deleteInProcess = false;
        this.creditCards = [];
        this.bankAccounts = [];

        this.rolloutVariant = 'pending';
        StatsigService.isGateEnabled('ng2react-account-settings-payment-methods').then((enabled) => {
            this.rolloutVariant = enabled ? 'react' : 'angular';
        });

        this.fetchPaymentMethods();
    }

    var PaymentMethodsComponentController = Class(Controllers.BaseController, {
        constructor: PaymentMethodsComponentControllerFunc,

        fetchPaymentMethods: function() {
            this.user.getValidPaymentMethods(null, this.user.isVendor()).then((response) => {
                if (response.data) {
                    this.paymentMethods = response.data;
                    this.calcBankAccounts();
                    this.calcCreditCards();
                }
            });
        },

        calcCreditCards: function getCreditCards() {
            this.creditCards = this.paymentMethods ? this.paymentMethods.filter(function (method) {
                return method._type === 'PaymentMethodStripeCard';
            }) : [];
        },

        calcBankAccounts: function getBankAccounts() {
            this.bankAccounts = this.paymentMethods ? this.paymentMethods.filter(function (method) {
                return method._type === 'PaymentMethodChargeableBankAccount';
            }) : [];
        },

        deleteSelectedPaymentMethod: function deleteSelectedPaymentMethod(method) {
            var analyticsParams = {payment_method_id: method._id, method_type: method._type, credit_card_name: method.name};
            this.AnalyticsService.track(this.$scope, 'click: ' + this.AnalyticsService.analytics_events.delete_credit_card, analyticsParams);
            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info, 'FILE.PAYMENT.ALERT._CONFIRM_DELETE_',
                this.deletePaymentMethod.bind(this, method));
        },

        deletePaymentMethod: function deletePaymentMethod(method) {
            this.deleteInProcess = true;

            var analyticsParams = {payment_method_id: method._id, method_type: method._type, credit_card_name: method.name};
            var promise = this.user.deletePaymentMethod(method._id, false);
            var analyticsEvent;

            if (method._type === 'PaymentMethodChargeableBankAccount') {
                analyticsEvent = this.AnalyticsService.analytics_events.bank_account_deleted;
            } else {
                analyticsEvent = this.AnalyticsService.analytics_events.delete_credit_card;
            }

            promise.then(
                function success() {
                    this.AnalyticsService.trackSuccess(this.$scope, analyticsEvent, analyticsParams);
                    this.fetchPaymentMethods();
                }.bind(this),
                function canceled(result) {
                    if (result.data && result.data.error_type === 'HBPaymentMethodInUse') {
                        this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                            "To avoid a failed payment, we recommend changing your payment method on any files set to auto-pay that are using this payment method before deleting it.",
                            function clickedYeah() {
                                this.user.deletePaymentMethod(method._id, true).then(
                                    function success() {
                                        this.AnalyticsService.trackSuccess(this.$scope, analyticsEvent, analyticsParams);
                                        this.fetchPaymentMethods();
                                    }.bind(this),
                                    function canceled() {
                                        this.AnalyticsService.trackCancel(this.$scope, analyticsEvent, analyticsParams);
                                    }.bind(this)
                                );
                            }.bind(this),
                            function clickedNay() {
                                this.AnalyticsService.trackCancel(this.$scope, analyticsEvent, analyticsParams);
                            }.bind(this),
                            "Continue and Delete",
                            "Cancel",
                            "Payment method currently in use");
                    } else {
                        this.AnalyticsService.trackCancel(this.$scope, analyticsEvent, analyticsParams);
                    }
                }.bind(this)
            ).finally(function() {
                this.deleteInProcess = false;
            }.bind(this));
        }
    });

    Components.PaymentMethods = {
        bindings: {
            user: '<',
        },
        controller: PaymentMethodsComponentController,
        controllerAs: 'paymentMethodsVm',
        name: 'hbPaymentMethods',
        templateUrl: 'angular/app/modules/core/features/payments/payment_methods/payment_methods_component_template.html',
    };

}());