//hb-nav-large-screens

Directives.NavLargeScreensDirective = function NavLargeScreensDirective() {

    // @ngInject
    function NavLargeScreensControllerFunc($scope,  $injector, $state, $translate, AppStates, UsersManager, EventService, Enums,
                                           DeviceService, AnalyticsService, ReferralService, FeaturesService, UiPersistenceService,
                                           AbTestService, OnboardingService, NavigationService, ModalService, UserService, DoodleService,
                                           ClientPortalService, ReactModalService, StatsigService, IntercomService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'NavLargeScreensDirectiveController';

        this.AppStates = AppStates;
        this.EventService = EventService;
        this.Enums = Enums;
        this.DeviceService = DeviceService;
        this.AnalyticsService = AnalyticsService;
        this.ReferralService = ReferralService;
        this.$state = $state;
        this.FeaturesService = FeaturesService;
        this.UiPersistenceService = UiPersistenceService;
        this.NavigationService = NavigationService;
        this.OnboardingService = OnboardingService;
        this.AbTestService = AbTestService;
        this.ModalService = ModalService;
        this.UserService = UserService;
        this.DoodleService = DoodleService;
        this.ClientPortalService = ClientPortalService;
        this.ReactModalService = ReactModalService;
        this.StatsigService = StatsigService;
        this.$translate = $translate;
        this.$scope = $scope;

        // init
        this.showSnowbird = true;
        this.showTools = false;
        this.showProfile = false;
        this.user = UsersManager.getCurrUser();
        this.clientPortalConfiguration = this.ClientPortalService.getConfiguration();

        this.bookkeeperModeCompany = this.user.bookkeeperModeCompany();
        this.bookkeeperAuditeeCompanyIcon = this.bookkeeperModeCompany ? this.bookkeeperModeCompany.getCompanyIconImage() : '';

        this.MAX_SHOWN_NOTIFICATIONS_NUMBER = 100;

        UsersManager.on('update_notification_count', function updateNotificationCount() {
            if (!this.user.isClient()) {
                this.totalUnseenNotifications = this.user.getUnseenNotificationsCount();
            }
        }.bind(this));

        // Medium screen hides main menu
        this.searchInput = {
            inputFocus : false
        };

        // notifications
        this.totalUnseenNotifications = 0;
        this.totalUnseenNotificationsFormatted = 0;

        // vendor notifications
        if (!this.user.isClient()) {
            this.totalUnseenNotifications = this.user.getUnseenNotificationsCount();
        }

        this.isAccountOwner = this.user.isAccountOwner();
        this.isCompanyOwner = this.user.isCompanyOwner();
        this.subscription = this.user.getSubscription();

        // referral
        ////////////////////////////////////////////////////////
        // TODO : Remove from here
        // Need to use existing 'hb-refer-friend' directive,
        // but we need to rafactor it so it will fit here as
        // well. For now, extracted the logic from there
        ////////////////////////////////////////////////////////
        this.showReferrAFriend = false;
        if (!this.DeviceService.checkIfMobileSize()) {
            this.ReferralService.getCampaignDetails(this.user).then(function success(campaignDetails) {
                if (campaignDetails && campaignDetails.campaign_uid) {
                    this.showReferrAFriend = true;
                }
            }.bind(this));
        }
        ////////////////////////////////////////////////////////
        // END
        ////////////////////////////////////////////////////////

        $scope.$watch('navLargeVm.totalUnseenNotifications', function(newNotificationsCount, oldNotificationsCount) {
            this.totalUnseenNotificationsFormatted = (newNotificationsCount < this.MAX_SHOWN_NOTIFICATIONS_NUMBER) ? newNotificationsCount : '99+';
        }.bind(this));

        this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.consultantsAdjusments,  'show_adjustments', false)
            .then(function (variant) {
                this.consultantsAdjusmentsVariant = variant;
            }.bind(this));

        this.showCalendar = !!this.user.show_calendar_in_top_nav;
        
        IntercomService.identify({new_integrations_page: true}, true);
    }

    var NavLargeScreensController = Class(Controllers.BaseController, {
        constructor: NavLargeScreensControllerFunc,

        referAFriendClick: function referAFriendClick() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.refer_a_friend, {
                icon: this.DoodleService.isDoodle(this.Enums.doodles.winter) ? 'snow globe' : 'coin'
            });

            this.ReactModalService.openReferralModal();
        },

        isDoodle: function isDoodle(name) {
            return this.DoodleService.isDoodle(name);
        },

        onItemClick: function onItemClick(menuItemKey, subItemKey) { 
            this.$scope.topNavVm.onTopNavItemClicked(menuItemKey, subItemKey);
            this.hideTools();
        },

        hideTools: function hideTools() {
            this.showTools = false;
        },

        shouldShowTrialText: function shouldShowTrialText() {
            return this.OnboardingService.showOnboardingBar()  && !this.user.isSubscriptionPaused();
        },

        scrollListToTop: function scrollListToTop() {
            angular.element('.activities-list-container').scrollTop(0);
        },

        getDaysTillTrialEnds: function getDaysTillTrialEnds() {
            return this.user && this.user.nTillTrialEnds();
        },

        onUnpauseNow: function onUnpauseNow() {
            this.$scope.topNavVm.onTopNavItemClicked('unpause_membership');
            this.navigateToSubscriptionPage();
        },

        onUpgradeNow: function onUpgradeNow() {
            this.$scope.topNavVm.onTopNavItemClicked('see_pricing');
            this.navigateToSubscriptionPage();
        },

        navigateToSubscriptionPage: function navigateToSubscriptionPage() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.action, {
                unique: 'membership page',
                source: 'top_nav',
                trial_days_left: this.getDaysTillTrialEnds()
            });

            this.$state.go(this.AppStates.root_core_navigation_settings_companySettings_subscription);
        },

        onNotificationsShowChange: function onNotificationsShowChange(isShown) {
            if (!this.user.isClient()) {
                this.totalUnseenNotifications = this.user.getUnseenNotificationsCount();
            }
        },

        onAvatarDropdownSelect: function onAvatarDropdownSelect() {
            this.showProfile = false;
        },

        onHoverProfile: function onHoverProfile(val) {
            this.showActivityNotifications = false;
        },

        toggleLeftEventPanelOpen: function toggleLeftEventPanelOpen() {
            this.appState.leftPanelEventOpen = !this.appState.leftPanelEventOpen;
        },

        shouldAddCompanyTagline: function shouldAddCompanyTagline() {
            if(this.user.shouldViewCompanyTagline()) {
                this.companyNameTagline = this.$translate.instant("MAIN_NAV._TAGLINE_." + this.user.company.company_type.toUpperCase());

                return this.consultantsAdjusmentsVariant;
            }

            return false;
        },
    });

    return {
        templateUrl: 'angular/app/modules/core/features/top_nav/nav_large_screens/nav_large_screens_template.html',
        controller: NavLargeScreensController,
        controllerAs: 'navLargeVm',
        bindToController: true,
    };
};
