Directives.EditableAgreementDirective = function EditableAgreementDirective() {

    var HEADER_REGION_ID = 'editor_header_region';
    var PACKAGES_REGION_ID = 'editor_packages_region';
    var PAYMENTS_REGION_ID = 'editor_payments_region';

    var HEADER_REGION_SELECTOR = 'div#' + HEADER_REGION_ID;
    var PACKAGES_REGION_SELECTOR = 'div#' + PACKAGES_REGION_ID;
    var PAYMENTS_REGION_SELECTOR = 'div#' + PAYMENTS_REGION_ID;

    // @ngInject
    function EditableAgreementDirectiveControllerFunc($, $scope, $q, $injector, $document, $timeout, CKEDITOR, uuid4, _, RTEFactory,
                                                      AutoInputSizeService, InputFieldsService, WorkspaceFileService, $state, AppStates,
                                                      CompaniesManager, WorkspaceFilesManager, PopupMessageService, $translate, $element,
                                                      UsersManager, AnalyticsService, OnboardingService, DeviceService, ModalService, AgreementValuesStrategyFactory, Enums,
                                                      UiPersistenceService, MobileAppService, RescheduleProjectService, TemplatesViewService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EditableAgreementDirectiveController';
        this.RescheduleProjectService = RescheduleProjectService;
        var self = this;
        self.AutoInputSizeService = AutoInputSizeService;
        self.InputFieldsService = InputFieldsService;
        self.UiPersistenceService = UiPersistenceService;
        self._ = _;
        self.$q = $q;
        self.$ = $;
        self.CompaniesManager = CompaniesManager;
        self.UsersManager = UsersManager;
        self.showAgreementTemplates = false;
        self.ModalService = ModalService;
        self.Enums = Enums;
        self.$state = $state;
        this.AppStates = AppStates;
        this.TemplatesViewService = TemplatesViewService;
        self.shouldShowTemplatesMenu = self.shouldShowTemplatesMenu === "true";
        self.OnboardingService = OnboardingService;
        self.DeviceService = DeviceService;
        self.MobileAppService = MobileAppService;
        self.rangeToSetFocusOnAfterFieldPopup = null;
        self.currUser = self.UsersManager.getCurrUser();

        if (this.fileModel) {
            this.isAgreement = this.fileModel.isAgreement();
            this.fieldHelper = {fields: []};
        }

        self.isInAppBrowser = this.DeviceService.isInAppBrowser();

        var isEditorLoaded = false;
        this.loadEditor = function loadEditor() {

            var actionFromToolbar = false;
            isEditorLoaded = true;

            // add appropriate CK toolbar items if file is only an agreement, or if it's part of a proposal
            var toolbarItemsArr = ['ChooseInput'];

            if (!self.fileModel || self.fileModel.hasPayments()) {
                toolbarItemsArr.push('AddPaymentsRegion');
            }

            if (!self.fileModel || self.fileModel.hasProposal()) {
                toolbarItemsArr.push('AddPackagesRegion');
            }

            toolbarItemsArr.push('AddHeaderRegion');

            if (self.currUser && self.currUser.is_hb_admin){
                toolbarItemsArr.push('UploadFileRegion');
            }

            this.editor = RTEFactory.newAgreementEditor('textareaPlaceholder',{
                includeToolbarItems: toolbarItemsArr,
                startupFocus: false
            });

            this.editor.on('blur', this.onBlur, this);
            this.editor.on('change', this.updateAgreementFieldHelper, this);

            // Add custom event listener for mobile app
            this.editor.on('change', _.debounce(function() {
                if (self.isInAppBrowser) {
                    self.saveAgreement();
                }
            }, 2000), this);

            CKEDITOR.once("instanceReady", function (ev) {
                ev.editor.on('addInputField', function (evt) {
                    if (evt.data === 'toolbar') {
                        actionFromToolbar = true;
                    }
                    return self.openInputPopup(evt.data);
                });
                ev.editor.on('addPaymentsRegion', function (evt) {
                    return self.addCustomFieldRegion(PAYMENTS_REGION_ID, evt.data);
                });
                ev.editor.on('addPackagesRegion', function (evt) {
                    return self.addCustomFieldRegion(PACKAGES_REGION_ID, evt.data);
                });
                ev.editor.on('addHeaderRegion', function (evt) {
                    return self.addCustomFieldRegion(HEADER_REGION_ID, evt.data);
                });
                ev.editor.on('uploadFile', function (evt) {
                    $('#agreement-file-input').trigger('click');
                    AnalyticsService.track(self, AnalyticsService.analytics_events.template_import_pdf_clicked);
                });

                self.editor.fire('lockSnapshot');

                var shouldSaveAgreement = self.setContents(self.agreementModel.html_body);
                if (shouldSaveAgreement) {
                    self.saveAgreement();
                }

                self.editor.resetDirty();
                self.adjustInputFieldsSize();

                self.editor.fire('unlockSnapshot');

            });

            // HTML elements bindings
            $document.on('click.agreement.honeybook', "#removeSection", self.removeSection.bind(self));

            $('#agreement-file-input').on('change', function(e){
                var reader = new FileReader();
                reader.onloadend = function (loadEvent) {
                    var contents = loadEvent.target.result;
                    var error  = loadEvent.target.error;
                    if (error != null || contents == null) {
                        self.PopupMessageService.showAlert(PopupMessageService.severityTypes.error, 'FILE.TEMPLATES._TEMPLATE_PDF_INVALID_');
                    } else {
                        self.uploadFile(contents);
                    }
                };
                reader.readAsDataURL(e.target.files[0]);
            }.bind(this));

            // Only for company settings template
            var companyTemplate = angular.element('#js-c-t-body');
            companyTemplate.on('scroll', function() {
                var companyTemplateScrollTop = companyTemplate.scrollTop();
                var editorScrollTop = $editorElement.offset().top;
                var isProperScrollGap = editorScrollTop - companyTemplateScrollTop < 25; // check if we're at the agreement and the toolbar is out of view

                $editorElement.toggleClass('fixed-agreement-toolbar', isProperScrollGap);
                companyTemplate.toggleClass('c-t-body-offset', isProperScrollGap);
            });

            var scrollGapOffset = 72;
            if(companyTemplate.length) {
                scrollGapOffset = 60;
            }

            var stickyClassHandler = function (event) {
                var isInModal = !!$('.nx-modal__container').length;
                var scrollFromTop = isInModal ? $('.nx-modal__container').scrollTop() : $document.scrollTop();
                var $helper = $element.find('.agreement-fields-wrapper');
                var offsetTop = $helper.offset().top;
                offsetTop -= isInModal ? 0 : 96;
                var isSticky = isInModal ? offsetTop <= 0 : scrollFromTop - offsetTop > 0;

                if (self.fixedToolbarClass !== isSticky) {
                    self.updateAgreementFieldHelper();
                    self.scopeChanged();
                }
                self.fixedToolbarClass = isSticky;
                $editorElement.toggleClass('two-nav-bars', this.TemplatesViewService.allowMigrationToFlow());
                
                if ($helper.length) {

                    $helper.toggleClass('agreement-fields-wrapper--sticky', isSticky && this.shouldShowfloatingBanner);
                    $editorElement.toggleClass('fixed-agreement-toolbar', isSticky);

                    var $message = $helper.find('.agreement-fields-message');

                    if (isSticky) {
                        $message.css('width', $helper.width());
                    }
                }
            }.bind(this);

            document.addEventListener('scroll', stickyClassHandler, true /*Capture event*/);

            $scope.$on('$destroy', function () {
                if (!self.agreementModel.isBeingDeleted &&
                    ((self.fileModel && self.fileModel.isEditable())) ||
                    (!self.fileModel && self.companyModel)) {
                    // the editable check is needed when a vendor clicks send-proposal and the workspace is saved already
                    // the workspace draft is not editable anymore so there is no need to save.
                    self.saveAgreement();
                }

                if (self.editor) {
                    self.editor.destroy();
                }

                document.removeEventListener("scroll", stickyClassHandler);
            });
        };

        this.addCustomFieldRegion = function addCustomFieldRegion(key) {
            var $loaderSpan, $placeHolderDiv;
            $placeHolderDiv = new CKEDITOR.dom.element('div');
            $placeHolderDiv.setAttributes({
                "class": key,
                contenteditable: false,
                id: key + "_new"
            });
            $placeHolderDiv.appendText('Populating your data...');
            $loaderSpan = new CKEDITOR.dom.element('span');
            $loaderSpan.setAttribute('class', 'loading loading_hb loader-xs');
            $placeHolderDiv.append($loaderSpan);
            this.editor.insertElement($placeHolderDiv);
            this.saveAgreement(false);
            this.scopeChanged();
        };

        this.uploadFile = function uploadFile(fileObj){
            this.editor.fire('lockSnapshot');
            this.agreementModel.updateAgreementWithFile(fileObj).then(
                function success(resp){
                    this.setContents(resp.model.agreement.html_body);
                    AnalyticsService.track(self, AnalyticsService.analytics_events.template_import_pdf_updated);
                }.bind(this)
            ).finally (function(){
                this.$('#agreement-file-input').val("");
                this.editor.fire('unlockSnapshot');
            });
        };

        this.setContents = function setContents(html_body) {
            this.editor.setData(html_body);

            var shouldSaveAgreement = false;
            this.$('.cke_contents input').each(function (idx, input) {
                var $input = $(input);
                var name = $input.prop('name');
                if (name.indexOf("{field_name") !== 0) {
                    // This is not a valid agreement field. removing it!!!
                    $input.remove();
                    return;
                }
                this.loadFieldClasses($input, $input.prop('name'));

                var foundEmptyId = this.updateFieldId(new CKEDITOR.dom.element(input)); //initialize field id if absent
                shouldSaveAgreement = shouldSaveAgreement || foundEmptyId;
            }.bind(this));
            this.reloadSection(HEADER_REGION_ID, html_body);
            this.removeUnsupportedSections();
            var sectionsSelector = [HEADER_REGION_SELECTOR, PAYMENTS_REGION_SELECTOR, PACKAGES_REGION_SELECTOR].join(',');
            this.addButtonToRemoveSection(this.$(sectionsSelector));
            this.$('#TEMPORARY_FIELD').remove();
            this.updateAgreementFieldHelper();

            return shouldSaveAgreement;
        };

        this.removeUnsupportedSections = function removeUnsupportedSections() {
            var triggerSave = false;
            if (this.fileModel && !this.fileModel.hasProposal()) {
                var packageSection = this.$(PACKAGES_REGION_SELECTOR);
                if (packageSection.length > 0) {
                    packageSection.remove();
                    triggerSave = true;
                }
            }

            if (this.fileModel && !this.fileModel.hasPayments()) {
                var paymentsSection = this.$(PAYMENTS_REGION_SELECTOR);
                if (paymentsSection.length > 0) {
                    paymentsSection.remove();
                    triggerSave = true;
                }
            }

            if (triggerSave) {
                this.saveAgreement();
            }
        };

        this.getContents = function getContents() {
            if (this.editor) {
                return this.editor.getData();
            }
        };

        this.loadFieldClasses = function loadFieldClasses(elem, fieldName) {
            var $input = $(elem);
            $input.removeClass('client-field vendor-field');
            var field = InputFieldsService.getField(fieldName);
            if (field.isClientField()) {
                $input.removeClass('validation-error');
                $input.addClass('client-field');
            } else {
                $input.addClass('vendor-field');
                if (this.validationState) {
                    $input.toggleClass('validation-error', !$input.val());
                }
            }

            $input.removeClass('field-required');
            if (field.isRequiredField()) {
                $input.addClass('field-required');
            }
        };

        this.onBlur = function onBlur() {
            if(!this.showInputPopup){
                //if we are looking the focus becuase the fileds popup is showing we dont want to save
                return this.saveAgreement();
            }
        };

        this.saveAgreement = function saveAgreement(byInput) {
            if (byInput == null) {
                byInput = false;
            }
            if (this.editor != null) {
                var fullHTML = this.getContents();

                // replace 3 underscores or more in an empty field
                if (fullHTML.indexOf("___") !== -1) {
                    fullHTML = fullHTML.replace(/_{3,}/g, '<input type="text" name="{field_name:other, field_type:String, field_default:nil, field_input_type:String, field_placeholder:, field_changed:false, field_hb_data_type:client_field, field_required:false}" generated value="">');
                    this.setContents(fullHTML);
                }



                if (this.editor.checkDirty()) {
                    this.agreementModel.html_body = fullHTML;
                    this.updateAgreementFieldHelper();
                    this.adjustInputFieldsSize();
                    var promise = this.agreementModel.updateAgreement(this);
                    promise.then(function updateAgreementSuccess() {
                        this.adjustInputFieldsSize();
                        this.editor.resetDirty();
                    }.bind(this))
                    .catch(function error(resp) {
                        if (resp.data && resp.data.error_type === 'UneditableFileError') {
                            // Known issue - we call save agreement on scope destroy after the file sent
                            // and the request fails as file is not editable.. swallow
                        }
                        else if (resp.data && resp.data.error_type === 'HBUserError') {
                            PopupMessageService.showAlert(PopupMessageService.severityTypes.error, resp.data.error_message);
                            AnalyticsService.trackError(this, AnalyticsService.analytics_events.agreement_failed_to_save_file, resp);
                        } else {
                            PopupMessageService.showAlert(PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                        }
                    }.bind(this));
                }
            }

        };

        this.reloadSection = function reloadSection(id, htmlBody) {
            var $newDiv;
            if (typeof htmlBody === 'string') {
                $newDiv = $("<div>" + htmlBody + "</div>").find("#" + id);
            } else {
                $newDiv = htmlBody.find("#" + id);
            }

            this.addButtonToRemoveSection($newDiv);
            if (this.$("#" + id + "_new").length > 0) {
                // this.$("#" + id).remove();
                this.$("#" + id + "_new").each(function(index, item) {
                    item.replaceWith($newDiv[index]);
                });
            } else {
                this.$("#" + id).each(function(index, item) {
                    item.replaceWith($newDiv[index]);
                });
            }
        };

        this.adjustInputFieldsSize = function adjustInputFieldsSize() {
            this.$('.cke_contents input').each(function (idx, input) {
                // Add additional width for the required indicator for required client fields
                AutoInputSizeService.setSize(input, null, $(input).hasClass("field-required") ? 10 : 0);
            });
        };

        this.addButtonToRemoveSection = function addButtonToRemoveSection($elem) {
            return $elem.prepend('<span id="removeSection" class="remove-auto-populate"></span>');
        };


        /* Compute the top-left offset of the element relative to the container.
         * The element should be a descendant of the container. If not element
         * if provided the current editor cursor position will be used.
         */
        this.getCursorPos = function getCursorPos(element, container) {
            var dummyElement, obj, pos;
            if (element == null) {
                element = null;
            }
            pos = {
                x: 0,
                y: 0
            };
            if (element === null) {
                dummyElement = this.editor.document.createElement('span');
                dummyElement.setText('.');
                this.editor.insertElement(dummyElement);
                obj = dummyElement.$;
            } else {
                if (element.$.tagName === 'BR') {
                    obj = element.$.parentElement;
                } else {
                    obj = element.$;
                }
            }

            var elementOffset = $(obj).offset();
            var containerOffset = container.offset();
            pos.x = elementOffset.left - containerOffset.left + container.scrollLeft();
            pos.y = elementOffset.top - containerOffset.top + container.scrollTop();

            if (dummyElement != null) {
                this.editor.execCommand('undo');
            }
            return pos;
        };

        this.getInputPopupPosition = function getInputPopupPosition() {
            var cursorPos, cursorElementWidth, cursorElementHeight, el, elOffset, element, elementWidth, minPopupTop, modal, modalHeight, modalWidth, modalWrapper, notch, notchHeight, notchImg, notchOverlap, notchPos, popupPos, position, sel, topAgreementBuffer, topDocumentBuffer, notchUpShimY;
            sel = this.editor.getSelection();
            element = sel.getStartElement();
            if (element.$.tagName !== "INPUT" && element.$.tagName !== "BR") {
                cursorPos = this.getCursorPos(null, $editorElement);
                cursorElementWidth = 0;
                cursorElementHeight = 20; // This is an arbitrary value
            } else {
                cursorPos = this.getCursorPos(element, $editorElement);
                cursorElementWidth = this.$(element.$).outerWidth();
                cursorElementHeight = this.$(element.$).outerHeight();
            }
            notch = this.$("#inputPopupNotch");
            notchImg = this.$("#notchImg");
            modal = this.$("#myAgreementInputChangeModal");
            modalWrapper = this.$("#inputPopupContainer");
            modalWidth = modal.width();
            modalHeight = modalWrapper.height();

            el = $editorElement;
            elementWidth = el.width();

            // Initialize the popup's origin based on the top-left corner of the starting element
            popupPos = {
                x: cursorPos.x,
                y: cursorPos.y
            };
            notchPos = {
                x: cursorPos.x,
                y: cursorPos.y
            };

            // Shift popup and notch such that they are centered relative to the element
            notchPos.x += (cursorElementWidth / 2); //- (notch.outerWidth() / 2);
            popupPos.x += (cursorElementWidth / 2) - (modalWidth / 2);

            // Maximize/minimize popup position so it always appears within the bounds of the editor
            if (popupPos.x < 0) {
                popupPos.x = 0;
            }
            if ((popupPos.x + modalWidth) > elementWidth) {
                popupPos.x = elementWidth - modalWidth;
            }

            // Compute the top offset of the popup and notch based on whether or not it appears above or below
            // the element
            topDocumentBuffer = 100;
            topAgreementBuffer = 0;

            // The notch is not flush with it's bounding box on the pointed end, this causes it to look more offset
            // from the editor element when it's in the "up" position. We need to add this shim value when it's pointed
            // up so the spacing looks consistent in both up and down states.
            notchUpShimY = 5;
            notchHeight = notch.height();
            notchOverlap = 3;
            minPopupTop = topAgreementBuffer;
            elOffset = el.offset().top - el.scrollParent().scrollTop();
            if (elOffset < topDocumentBuffer) {
                minPopupTop = Math.max(topDocumentBuffer, topDocumentBuffer - elOffset);
            }

            var notchClass;
            if ((popupPos.y - modalHeight - (notchHeight - notchOverlap)) > minPopupTop) {
                popupPos.y = popupPos.y - modalHeight - (notchHeight - notchOverlap);
                notchPos.y = cursorPos.y - notchHeight;
                notchClass = 'notch-down';
            } else {
                popupPos.y += (notchHeight - notchOverlap + cursorElementHeight / 2) + notchUpShimY;
                notchPos.y = (cursorPos.y + cursorElementHeight / 2) + notchUpShimY;
                notchClass = 'notch-up';
            }

            position = {
                input: popupPos,
                notch: notchPos,
                notchClass: notchClass
            };
            return position;
        }.bind(this);


        var modifySelectionIfNeeded = function (selection) {
            if (selection.getSelectedText() === "\n" || selection.getSelectedText() === "\r\n") { //end of line was selected
                var range = selection.getRanges()[0];
                var node = range.getTouchedStartNode();
                if (node.$.tagName === "BR") {
                    //range should replace entire line break
                    range.setStartAt(node, CKEDITOR.POSITION_AFTER_START);
                    range.setEndAt(node, CKEDITOR.POSITION_BEFORE_END);
                }
                else {
                    //range should position cursor at end of text
                    var text = node.getText();
                    range.setStart(node, text.length);
                    range.setEnd(node, text.length);
                }
                selection.selectRanges([range]);
            }
        };

        this.openInputPopup = function openInputPopup() {
            this.editor.lockSelection();
            var inputElement = this.editor.getSelection() && this.editor.getSelection().getSelectedElement();
            if(inputElement) {
                var range = this.editor.createRange();
                range.setStartAfter(inputElement);
                range.setEndAt(inputElement);
                self.rangeToSetFocusOnAfterFieldPopup = range;
            }
            if (inputElement && inputElement.getName() === "input") {
                this.selectedFieldName = inputElement.getAttribute("name");
                this.selectedFieldValue = inputElement.getValue();
            } else {
                var $tmp = new CKEDITOR.dom.element('input');
                $tmp.setAttributes({
                    type: 'text',
                    value: '',
                    id: 'TEMPORARY_FIELD',
                    name: '{field_name:other, field_type:String, field_id:tmp_id, field_default:nil, field_input_type:String, field_placeholder:, field_changed:false, field_hb_data_type:client_field, field_required:false}',
                    style: 'width: 50px;'
                });
                modifySelectionIfNeeded(this.editor.getSelection());
                this.editor.insertElement($tmp);
                this.editor.getSelection().selectElement($tmp);

                this.selectedFieldName = null;
                this.selectedFieldValue = null;
            }

            // if in app, just broadcast message
            if(this.isInAppBrowser) {
                //var selectedField = this.InputFieldsService.getField(this.selectedFieldName);
                var eventData = {field_name: this.selectedFieldName, field_value: this.selectedFieldValue};

                // mark as edited by the mobile app
                this._inputElementEditedByApp = this.editor.getSelection() && this.editor.getSelection().getSelectedElement();

                // trigger app event
                this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.edit_agreement_field, eventData);
            } else {
                this.popupPos = this.getInputPopupPosition();
            }

            // mark edit field as shown (for web app and mobile app)
            this.showInputPopup = true;

            // notify Angular about scope changes
            this.scopeChanged();
        };

        this.removeSection = function removeSection(e) {
            var $section;
            $section = $(e.target).closest('div');
            $section.remove();
            return this.saveAgreement(true);
        };

        this.closeInputPopup = function closeInputPopup() {
            this.showInputPopup = false;
            this.scopeChanged();
        };

        this.removeTemporaryFields = function removeTemporaryFields() {
            if (this.$('input#TEMPORARY_FIELD').length > 0) {
                this.editor.execCommand('undo');
            }
        };

        this.updateFileTitle = function updateFileTitle(newTitle) {
            this.$("input[name*='field_default:proposal_title']").each(function (index, elem) {
                var $elem = $(elem);
                var field = InputFieldsService.getField($elem.attr('name'));
                if (!field.isFieldChanged()) {
                    $elem.val(newTitle);
                    AutoInputSizeService.setSize($elem);
                }
            });
        };

        this.updateAgreementByTemplate = function updateAgreementByTemplate(file, templateId) {
            var deferred = $q.defer();

            this.agreementModel.updateAgreementByTemplate(templateId)
                .then(function success(resp) {
                    deferred.resolve(resp);

                    var shouldSaveAgreement = this.setContents(this.agreementModel.html_body);
                    if (shouldSaveAgreement) {
                        this.saveAgreement();
                    }
                }.bind(this))
                .catch(function error(resp) {
                    deferred.reject(resp);
                });

            return deferred.promise;
        };

        // Back button
        this.backFirstScreen = function backFirstScreen() {
            this.isSaveAsEnabled = false;
        };

        this.finalizeSaveAs = function finalizeSaveAs() {
            this.duringSaveAs = false;
            this.AgreementMenuActive = false;
            this.isSaveAsEnabled = false;
        };

        this.saveAsAgreementTemplate = function saveAsAgreementTemplate(title) {
            this.templateTitle = title;
            if (!this.templateTitle || this.templateTitle.length === 0) {
                this.duringSaveAs = false;

                PopupMessageService.showAlert(PopupMessageService.severityTypes.warning, 'FILE.TEMPLATES._MISSING_TEMPLATE_NAME_');
                return this.$q.reject();
            }

            this.duringSaveAs = true;

            return this.agreementModel.saveAsTemplate(this.companyModel, this.templateTitle)
                .then(function success() {
                    this.finalizeSaveAs();

                    var analyticsArgs = {
                        template_type: "Agreement",
                        company_id: this.companyModel._id,
                        template_title: this.templateTitle
                    };

                    // origin_copy_id changes
                    if (!this.templateMode) {
                        this.fileModel = WorkspaceFilesManager.getAgreementTemplateOrigin(this.fileModel);
                        analyticsArgs.template_origin_id = this.fileModel.agreement.origin_copy_id;
                        analyticsArgs.workspace_file_id =  this.fileModel._id;
                    }

                    AnalyticsService.track(this, AnalyticsService.analytics_events.template_save_as_confirmed, analyticsArgs);
                }.bind(this))
                .catch(function error(resp) {
                    this.finalizeSaveAs();

                    PopupMessageService.showAlert(PopupMessageService.severityTypes.warning, 'FILE.TEMPLATES._ERROR_SAVE_OCCUR_MESSAGE_');

                    var analyticsArgs = {
                        template_type: "Agreement",
                        company_id: this.companyModel._id,
                        template_title: this.templateTitle,
                        error: this.data.error_message
                    };

                    if (!this.templateMode) {
                        analyticsArgs.template_origin_id = this.fileModel.agreement.origin_copy_id;
                        analyticsArgs.workspace_file_id =  this.fileModel._id;
                    }

                    AnalyticsService.track(this, AnalyticsService.analytics_events.template_save_as_failed, analyticsArgs);
                }.bind(this));
        };

        //onboarding
        this.isOnboardingAgreementStepTime = this.OnboardingService.shouldShowAgreementPin();

        this.onboardingAgreementClose = function onboardingAgreementClose(){
            //set the ui persistence.
            self.OnboardingService.setAgreementPopoverPersistence();
        };

        //end onboarding

        ///////////////////////////////////////////////////////////////////////////////////////////
        // Controller running code starts here
        ///////////////////////////////////////////////////////////////////////////////////////////
        this.showInputPopup = false;
        var $editorElement = $('#EditorAgreement');

        // A little useful trick for creating a contextual jquery selector (like we had in backbone)
        this.$ = (function () {
            return function (selector) {
                return $editorElement.find(selector);
            };
        }());

        // Utility method for letting angular know the scope has been changed
        this.scopeChanged = function scopeChanged() {
            $scope.$applyAsync(function () {
            });
        };

        this.updateFieldId = function updateFieldId(inputElement) {
            var foundEmptyId = this._.isEmpty(inputElement.getAttribute('id'));
            if (foundEmptyId ||
                inputElement.getAttribute('id') === "TEMPORARY_FIELD") {
                var newIdForInput = uuid4.generate();
                inputElement.setAttribute('id', newIdForInput);
            }
            return foundEmptyId;
        };

        this.updateAgreementFieldHelper = function updateAgreementFieldHelper(){
            var self = this;

            if (!this.fieldHelper) {
                return;
            }

            self.fieldHelper.fields = self.$('.vendor-field[value=""]');
            var editableAgreementDocOffset = $editorElement.offset();
            if(editableAgreementDocOffset){
                editableAgreementDocOffset = editableAgreementDocOffset.top;
            }
            else{
                editableAgreementDocOffset = 0;
            }

            //for each field add the correct top css number (distanceFromAgreement)
            for (var i = 0; i< self.fieldHelper.fields.length; i++){
                var currentClientField = self.fieldHelper.fields[i];
                var elDocOffset = $(currentClientField).offset();
                if(elDocOffset){
                    elDocOffset= elDocOffset.top;
                }
                else{
                    elDocOffset = 0;
                }
                var num = elDocOffset - editableAgreementDocOffset - 5;
                currentClientField.distanceFromAgreement = num;
                //console.log(num)
            }
            self.showNumberOfFields();
            self.scopeChanged();
        };



        $scope.$watch('editableAgreementVm.showInputPopup', function (newValue) {
            if (newValue === false && this.selectedFieldName === null) {
                this.removeTemporaryFields();
            }
        }.bind(this));

        $scope.$watch('editableAgreementVm.fileModel.file_title', function (newValue) {
            this.updateFileTitle(newValue);
        }.bind(this));

        this.agreementValuesStrategy = null;
        if (this.fileModel) {
            var rescheduleFlowFile = this.RescheduleProjectService.getCreatedContract() || this.RescheduleProjectService.getExistingContractChoice();
            if (this.RescheduleProjectService.getProject() && rescheduleFlowFile && rescheduleFlowFile._id === this.fileModel._id) {
                this.agreementValuesStrategy = AgreementValuesStrategyFactory.bySimulation(this.fileModel);
            } else {
                this.agreementValuesStrategy = AgreementValuesStrategyFactory.byFile(this.fileModel);
            }
        }
        else if (this.payableFileTemplateModel) {
            this.agreementValuesStrategy = AgreementValuesStrategyFactory.byTemplate(this.payableFileTemplateModel);
        }
        else {
            this.agreementValuesStrategy = AgreementValuesStrategyFactory.byCompany(this.companyModel);
        }

        // This is different between file view and company template view
        var modelToRegister = this.fileModel ? this.fileModel : this.companyModel;

        // This binding is for when the agreement is updated from the outside - packages/payments/title

        this.register(modelToRegister, 'success', function success() {

            this.editor.fire('lockSnapshot');

            // Using setTimeout to make sure the model is up to date!
            // Using setTimeout and not $timeout to avoid unnecessary digest loops

            this.setContents(this.agreementModel.html_body);

            setTimeout(function () {
                if (isEditorLoaded) {
                    var $htmlBody = $("<div>" + self.agreementModel.html_body + "</div>");
                    self.reloadSection(PAYMENTS_REGION_ID, $htmlBody);
                    self.reloadSection(PACKAGES_REGION_ID, $htmlBody);
                    self.reloadSection(HEADER_REGION_ID, $htmlBody);

                    $editorElement.find('.cke_wysiwyg_div input').each(function (idx, elem) {
                        var $elem = $(elem);
                        var field = InputFieldsService.getField($elem.attr('name'));
                        if (!field.isFieldChanged()) {
                            var oldVal = $elem.val();
                            var newVal = field.getDefaultValue(self.agreementValuesStrategy);
                            if (oldVal !== newVal) {
                                $elem.val(newVal);
                                $elem.attr('value', newVal);
                                AutoInputSizeService.setSize($elem);
                            }
                        }
                    });
                }

                this.editor.fire('unlockSnapshot');
            }.bind(this));
        });

        // if we come from company templates - no need to check file validations
        if (!this.fileModel) {
            self.loadEditor();
            return;
        }

        this.validationState = false;
        var directiveId = uuid4.generate();
        WorkspaceFileService.addFileValidation(directiveId, function agreementValidation() {
            return $q(function agreementValidationPromise(resolve, reject) {
                this.validationState = true;
                var $firstEmptyField = null;
                var numEmptyFields = this.$('.cke_contents input:text[value=""]');

                numEmptyFields.each(function (idx, input) {
                    var $input = $(input);
                    var field = InputFieldsService.getField($input.attr('name'));
                    if (!field.isClientField()) {
                        if ($firstEmptyField === null) {
                            $firstEmptyField = $input;
                        }

                        $input.addClass('validation-error');
                    }
                });
                if ($firstEmptyField === null) {
                    resolve();
                } else {
                    var errMsg = 'AGREEMENT.MESSAGES._HAVE_MANDATORY_FIELDS_';
                    if (this.isAgreement) {
                        errMsg += 'AGREEMENT_';
                    }

                    AnalyticsService.track(this, AnalyticsService.analytics_events.agreement_vendor_send_locked_fields);

                    // This gap is because if we are at the top of the document and the sticky header is not activated we need to scroll

                    var isInModal = !!$('.nx-modal__container').length;

                    var gap = isInModal ? -50 : ($document.scrollTop() === 0 ? 225 : 150);
                    if (this.currUser.shouldSeeTrialWarnings()) {
                        gap = gap + 60; // plus the onboarding height.
                    }

                    var offsetTop = isInModal ? $firstEmptyField[0].offsetTop : $firstEmptyField.offset().top;
                    var scrollContainer = isInModal ? $('.nx-modal__container') : $document;

                    scrollContainer.scrollTo(null, offsetTop - gap, 300);
                    reject();
                }
            }.bind(this));
        }.bind(this), 400);


        $scope.$on('$destroy', function () {
            WorkspaceFileService.removeFileValidation(directiveId);
        });

        ///////////////////////////////
        // Now loading the agreement //
        ///////////////////////////////
        if (!this.agreementModel || !this.agreementModel.html_body) {
            // This binding is for loading the agreement only after the model is loaded
            // and the agreement section exists
            this.registerOnce(this.fileModel, 'success', function success() {
                self.loadEditor();
            });
        } else {
            self.loadEditor();

        }
        this.showNumberOfFields = function showNumberOfFields() {
            this.floatingBannerLeft = '';
            this.floatingBannerRight = '';
            this.floatingBannerHighlighted = '';
            if (this.fileModel.agreement.isAgreementSignedByClient() && !this.fileModel.agreement.isAgreementSignedByVendor()) {
                this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._CLIENT_HAS_SIGNED_AGREEMENT_');
                this.floatingBannerHighlighted = $translate.instant('AGREEMENT.LABELS._CLIENT_HAS_SIGNED_AGREEMENT_HIGHLIGHTED_');
                this.shouldShowfloatingBanner = true;
            } else if (!this.fileModel.agreement.isAgreementSignedByClient() && this.fieldHelper.fields.length > 0) {
                this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._NUMBER_OF_FIELDS_LEFT_LEFT_');
                this.floatingBannerRight = $translate.instant('AGREEMENT.LABELS._NUMBER_OF_FIELDS_LEFT_RIGHT_VENDOR_');
                if (this.fieldHelper.fields.length > 1) {
                    this.plural = "fields";
                } else {
                    this.plural = "field";
                }
                this.floatingBannerHighlighted = this.fieldHelper.fields.length + " highlighted " + this.plural;
                this.shouldShowfloatingBanner = true;
            } else if (!this.fileModel.agreement.isAgreementSignedByClient() && this.fieldHelper.fields.length === 0) {
                this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._ALL_FIELDS_FILLED_SEND_');
                this.shouldShowfloatingBanner = true;
            } else {
                this.shouldShowfloatingBanner = false;
            }
        };

        this.floatingBannerAction = function floatingBannerAction() {
            if (this.fieldHelper.fields.length > 0) {
                this.scrollToNextVendorField();
            } else if (this.fileModel.agreement.isAgreementSignedByClient() && !this.fileModel.agreement.isAgreementSignedByVendor()) {
                this.scrollToSignatures();
            }
        };

        this.scrollToNextVendorField = function scrollToNextClientField() {
            var $firstEmptyField = null;
            var $element = angular.element("#EditorAgreement");
            var isInModal = !!$('.nx-modal__container').length;

            $element.find("input[value=\"\"].vendor-field").each(function (idx, input) {
                var $input = $(input);
                if ($firstEmptyField === null) {
                    $firstEmptyField = $input;
                    return;
                }
            });
            var gap = self.isPreviewMode ? 225 : (isInModal ? -50 : 150);
            if (this.currUser.shouldSeeTrialWarnings()) {
                gap = gap + 60; // plus the onboarding height.
            }

            var offsetTop = isInModal ? $firstEmptyField[0].offsetTop : $firstEmptyField.offset().top;
            var scrollContainer = isInModal ? $('.nx-modal__container') : $document;
            scrollContainer.scrollTo(null, offsetTop - gap, 300);
            AnalyticsService.track(this, AnalyticsService.analytics_events.agreement_floating_bubble_scroll_to_next_required_field_vendor);
        };

        this.scrollToSignatures = function scrollToSignatures() {
            var $element = angular.element(".hb-signatures");
            if ($element.length > 0) {
                $element.scrollParent().scrollTo(null, $element.offset().top - 100, 300);
                AnalyticsService.track(this, AnalyticsService.analytics_events.agreement_floating_bubble_scroll_to_signature_vendor);
            }
        };

        ////////////////////////////////////
        // Mobile App Handling
        ////////////////////////////////////
        if(this.isInAppBrowser) {

            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._EDIT_AGREEMENT_FIELD_, function (data) {
                $scope.$applyAsync(function () {

                    // extract data
                    var fieldValue = data.field_value;
                    var fieldName = data.field_name;

                    // select the right element
                    this.editor.getSelection().selectElement(this._inputElementEditedByApp);

                    // call
                    this.handleAddPressedOnFieldPopup(fieldName, fieldValue);

                    // mark hide
                    this.showInputPopup = false;

                }.bind(this));
            }.bind(this));

            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._EDIT_AGREEMENT_FIELD_CANCEL_, function (data) {
                $scope.$applyAsync(function () {

                    // mark hide
                    this.showInputPopup = false;

                }.bind(this));
            }.bind(this));

        }
    }

    var EditableAgreementDirectiveController = Class(Controllers.BaseController, {
        constructor: EditableAgreementDirectiveControllerFunc,

        getAgreementValuesStrategy: function getAgreementValuesStrategy() {
            if (this.fileModel) {
                return this.AgreementValuesStrategyFactory.byFile(this.fileModel);
            }

            return this.payableFileTemplateModel ? this.AgreementValuesStrategyFactory.byTemplate(parent) : this.AgreementValuesStrategyFactory.byCompany(this.companyModel);
        },

        handleAddPressedOnFieldPopup: function handleAddPressedOnFieldPopup(outputFieldName, outputFieldValue) {
            this.selectedFieldValue = outputFieldValue;
            this.selectedFieldName = outputFieldName;
            var inputElement;
            if (angular.isString(this.selectedFieldValue)) {
                inputElement = this.editor && this.editor.getSelection() && this.editor.getSelection().getSelectedElement();
                if (inputElement && inputElement.getName() === "input" && inputElement.getAttribute('value') !== this.selectedFieldValue) {
                    inputElement.setAttribute('value', this.selectedFieldValue);
                    this.updateFieldId(inputElement);

                    this.AutoInputSizeService.setSize(inputElement.$);
                }
            }


            if (this.selectedFieldName) {
                inputElement = this.editor && this.editor.getSelection() && this.editor.getSelection().getSelectedElement();
                if (inputElement && inputElement.getName() === "input" && inputElement.getAttribute('name') !== this.selectedFieldName) {
                    inputElement.setAttribute('name', this.selectedFieldName);
                    var inputFieldModel = this.InputFieldsService.getField(this.selectedFieldName);
                    inputElement.setAttribute('placeholder', inputFieldModel.getPlaceholder());
                    inputElement.removeAttribute('data-cke-saved-name');
                    this.updateFieldId(inputElement);

                    this.loadFieldClasses(inputElement.$, this.selectedFieldName);
                    this.AutoInputSizeService.setSize(inputElement.$);

                    this.saveAgreement();
                }
            }

            if(this.rangeToSetFocusOnAfterFieldPopup){
                this.editor.getSelection().selectRanges( [this.rangeToSetFocusOnAfterFieldPopup] );
                this.rangeToSetFocusOnAfterFieldPopup = null;
            }

            // reset mobile app edited input element
            if(this.isInAppBrowser) {
                this.saveAgreement();
            }
            this._inputElementEditedByApp = undefined;
        }
    });



    return {
        scope: {
            fileModel: '=fileModel',
            payableFileTemplateModel: '=?',
            companyModel: '=companyModel',
            forceShowTemplatesMenu: '<',
            agreementModel: '=agreementModel',
            templateMode: '=?'
        },
        templateUrl: 'angular/app/modules/core/features/agreement/editable_agreement/editable_agreement_directive_template.html',
        controller: EditableAgreementDirectiveController,
        controllerAs: 'editableAgreementVm',
        bindToController: true
    };
};
