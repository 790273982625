(function () {
  'use strict';

  // @ngInject
  function OnboardingMobileQuizControllerCtor(
    $scope,
    $injector,
    OnboardingService,
    UsersManager,
    $state,
    UiPersistenceService,
    IntercomService,
    CouponsService,
    $cookies,
    ConfettiService
  ) {
    this.constructor.$super.call(this, $scope, $injector);
    this.UsersManager = UsersManager;
    this.$state = $state;
    this.OnboardingService = OnboardingService;
    this.UiPersistenceService = UiPersistenceService;
    this.$cookies = $cookies;
    this.IntercomService = IntercomService;
    this.CouponsService = CouponsService;
    this.currUser = this.UsersManager.getCurrUser();
    this.ConfettiService = ConfettiService;

    if (this.isQuizCompleted()) {
      this.goToOnboardingEntryPointState();
      return;
    }

    this.UiPersistenceService.setUiPersistence(
      this.UiPersistenceService.keys.mobileQuizOnboardingStarted,
      { started: true }
    );
    this.reportIntercom();
  }

  Controllers.OnboardingMobileQuizController = Class(
    Controllers.BaseController,
    {
      constructor: OnboardingMobileQuizControllerCtor,

      reportIntercom: function reportIntercom() {
        var isTrialerDiscount = !!(
          this.currUser.isReferredByMemberReferral() ||
          this.$cookies.get('growSumoPartnerKey') ||
          this.CouponsService.getCoupon()
        );
        this.IntercomService.init(this.currUser);
        this.IntercomService.identify(
          { trialer_discount: isTrialerDiscount },
          true
        );
      },

      isQuizCompleted: function isQuizCompleted() {
        var mobileQuizOnboardingCompleted =
          this.UiPersistenceService.getUiPersistence(
            this.UiPersistenceService.keys.mobileQuizOnboardingCompleted,
            {}
          );
        return (
          mobileQuizOnboardingCompleted &&
          mobileQuizOnboardingCompleted.completed
        );
      },

      goToOnboardingEntryPointState: function goToOnboardingEntryPointState(
        params
      ) {
        var nextRoute = this.OnboardingService.getOnboardingEntryPointState();
        this.$state.go(nextRoute, params);
      },
    }
  );
})();
